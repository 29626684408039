import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import { isObjectEmpty } from 'ngx-bootstrap/chronos/utils/type-checks';

@Component({
  selector: 'app-sapproval',
  templateUrl: './sapproval.component.html'
})
export class SamplingApprovalComponent extends BaseCrudComponent {

    id:any;
    loadingRequired = false;
    loadingDetails = false;
    loadingAnalysis = false;
    isReseting = false;
    isRejecting = false;
    isApproving = false;
    isReceiving = false;
    workers:any;
    translations:any;
    filters:any;
    obj:any;
    statuslist:any;
    actions:any;

    SamplingAnalysisId:any;
    SampId:any;

    isSaving = false;
    isDownloading = 0;
    momentjs: any = moment;
    all_status:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'SamplingApproveList/All';
        this.create_url = 'Analysis/Create';
        this.update_url = 'Analysis/Update';
        this.delete_url = 'Analysis/LogicalDelete';
        this.search_fields = ['sampId','UserTechnicalName', 'cmpName', 'sitName', 'SampleLocationInstanceName', 'AssignedName', 'StatusName'];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getListPost();
            }
        });

        this.fillData();
        this.all_status = Global.sampling_status;
    }

    fillData(){
        this.filters = [];
        var opt = Global.sapproval_filters;

        this.storage.get('common').then(data => {
          data.forEach(x => {
            if( opt.indexOf(x.Name) > -1 ){
                this.filters[x.Name] = x.Values;
            }
          });
        });

        this.actions = {};
        Global.sampling_status.forEach(s => {
            this.actions[s.name] = s.approv_actions;
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

              var batch = [];
              //batch.push(this.getStatusData());
              batch.push(this.getWorkersData());

              this.loadingList = true;

              concat(...batch).subscribe({
                complete: () => {
                    this.loadingList = false;
                    this.getListPost();
                }
              });
        });
    }

    public getListPost(){
        var qst = this.read_url;
        var tosend = {};

        if( this.f_datefrom != null ){
            tosend['_initialDate'] = this.getISODate(this.f_datefrom);
        }

        if( this.f_dateto != null ){
            tosend['_finalDate'] = this.getISODate(this.f_dateto);
        }

        if( this.f_custom1 != null ){
            tosend['_status'] = [parseInt(this.f_custom1)];
        }

        this.loadingList = true;
        this.srv.postCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            if(x.success){
                this.rawdata = x.val;
                this.rows = x.val;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.rows.forEach(r => {
                    r['actions'] = this.getActionsByStatusName(r['StatusName']);
                });

                this.filterAll();
            }
        });
    }

    public getStatusObj(id:any){
        let rs:any;
        rs = {Id:0, Name:''};
        let opt = Global.sapproval_filters;
        let resp = this.filters[opt+''].filter(s => {
                return s.Id == id;
            });

        if( resp.length > 0 ){
            rs = resp[0];
        }

        return rs;
    }

    public getActionsByStatusName(st:any){
        let rs:any;

        if( this.actions[st] != undefined ){
            rs = this.actions[st];
        }
        else{
            rs = [];
        }

        return rs;
    }

    public assignAndUpdate(form:any){
        var validated:any;

        if( form == undefined ){
          form = {};
        }

        if( (form['AssignedId'] == undefined) || (form['AssignedId'] == '') ){
            Swal.fire(this.translations.sampapp_error_missinguser, '', 'error');
            return;
        }

        validated = this.validateDetails(form['details']);
        if( !validated['status'] ){
            Swal.fire(validated['msg'], '', 'error');
            return;
        }

        this.isUpdating = true;
        let newstatus = {id: 76, name: 'ASIGNADO'};

        /*this.saveDetailsRemote(form['details']).subscribe(x => {
            if( x ){
                this.updateStatusRemote(form, newstatus.id).subscribe(y => {
                    this.isUpdating = false;
                    if( y ){
                        this.getListPost();
                        let st = this.getStatusObj(newstatus.id);

                        form['StatusId'] = st['Id'];
                        form['StatusName'] = st['Name'];
                        form['actions'] = this.getActionsByStatusName(st['Name']);

                        Swal.fire('Actualizado', '', 'success');
                    }
                    else{
                        Swal.fire(this.translations.sampapp_error_updating_status, '', 'error');
                    }
                });
            }
            else{
                this.isUpdating = false;
                Swal.fire(this.translations.sampapp_error_updating_details, '', 'error');
            }
        });*/

        this.updateStatusRemote(form, newstatus.id).subscribe(x => {
            if( x ){
                this.getListPost();
                let st = this.getStatusObj(newstatus.id);

                form['StatusId'] = st['Id'];
                form['StatusName'] = st['Name'];
                form['actions'] = this.getActionsByStatusName(st['Name']);

                this.saveDetailsRemote(form['details']).subscribe(y => {
                    this.isUpdating = false;
                    if( y ){
                        Swal.fire('Actualizado', '', 'success');
                    }
                    else{
                        Swal.fire(this.translations.sampapp_error_updating_details, '', 'error');
                    }
                });
            }
            else{
                this.isUpdating = false;
                Swal.fire(this.translations.sampapp_error_updating_status, '', 'error');
            }
        });
    }

    public setAsPending(form:any){
        if( form == undefined ){
          form = {};
        }

        //reset user
        form['AssignedId'] = null;

        this.isReseting = true;
        let newstatus = {id: 466};

        this.updateStatusRemote(form, newstatus.id).subscribe(y => {
            this.isReseting = false;
            if( y ){
                this.getListPost();
                let st = this.getStatusObj(newstatus.id);

                form['StatusId'] = st['Id'];
                form['StatusName'] = st['Name'];
                form['actions'] = this.getActionsByStatusName(st['Name']);

                Swal.fire('Actualizado', '', 'success');
            }
            else{
                Swal.fire(this.translations.sampapp_error_updating_status, '', 'error');
            }
        });
    }

    public setAsRejected(form:any){
        if( form == undefined ){
          form = {};
        }

        this.isRejecting = true;
        let newstatus = {id: 78};

        this.updateStatusRemote(form, newstatus.id).subscribe(y => {
            this.isRejecting = false;
            if( y ){
                this.getListPost();
                let st = this.getStatusObj(newstatus.id);

                form['StatusId'] = st['Id'];
                form['StatusName'] = st['Name'];
                form['actions'] = this.getActionsByStatusName(st['Name']);

                Swal.fire('Actualizado', '', 'success');
            }
            else{
                Swal.fire(this.translations.sampapp_error_updating_status, '', 'error');
            }
        });
    }

    public approve(form:any){
        this.setAsApproved(form);
    }

    public setAsApproved(form:any){
        if( form == undefined ){
          form = {};
        }

        this.isApproving = true;
        let newstatus = {id: 90};

        this.updateStatusRemote(form, newstatus.id).subscribe(y => {
            this.isApproving = false;
            if( y ){
                this.getListPost();
                let st = this.getStatusObj(newstatus.id);

                form['StatusId'] = st['Id'];
                form['StatusName'] = st['Name'];
                form['actions'] = this.getActionsByStatusName(st['Name']);

                Swal.fire('Actualizado', '', 'success');
            }
            else{
                Swal.fire(this.translations.sampapp_error_updating_status, '', 'error');
            }
        });
    }

    public setAsReceived(form:any){
        if( form == undefined ){
          form = {};
        }

        let curr_date = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DD H:mm:ss');

        form.sampReceptionDateTime = curr_date;
        //console.log(curr_date);return;

        this.isReceiving = true;
        let newstatus = {id: 466};

        this.updateStatusRemote(form, newstatus.id).subscribe(y => {
            this.isReceiving = false;
            if( y ){
                this.getListPost();
                let st = this.getStatusObj(newstatus.id);

                form['StatusId'] = st['Id'];
                form['StatusName'] = st['Name'];
                form['actions'] = this.getActionsByStatusName(st['Name']);

                Swal.fire('Actualizado', '', 'success');
            }
            else{
                Swal.fire(this.translations.sampapp_error_updating_status, '', 'error');
            }
        });
    }

    validateDetails(rows:any){
        let resp = {status:true, msg:''};
        let selcount = 0;

        if( Array.isArray(rows) ){
            rows.forEach(x => {
                if( !x['sel'] && ((x['observation'] == undefined) || (x['observation'] == '')) ){
                    resp = {status:false, msg: this.translations.sampapp_error_missingcomment};
                }

                if( x['sel'] ){
                    selcount++;
                }
            });
        }
        else{
            resp = {status:false, msg: this.translations.sampapp_error_missingcomment};
        }

        if( selcount == 0 ){
            resp = {status:false, msg: this.translations.sampapp_error_minanalysis};
        }

        return resp;
    }

    public getWorkersData(){
        var par_url = 'SamplingApproveList/LaboratoryWorkersList';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);

                        if(mydata.success == true ){
                            this.workers = mydata.val;
                            return of(true);
                        }
                        else{
                            return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public saveDetailsRemote(rows:any){
        var par_url = 'SamplingApproveList/UpdateStatusAndCommentSamplingDetail';
        let to = [];

        rows.forEach(r => {
            to.push({
                samdId: r['SamplingDetailId'],
                samdCheckedApproved: r['sel'],
                samdCommentaryApprovedOrRejected: r['observation']
            });
        });

        return this.srv.postCall(par_url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);
                        return of(mydata.success);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public updateStatusRemote(obj:any, status:any){
        var par_url = 'SamplingApproveList/UpdateStatusSamplingDocument';
        let to = [];

        to.push({
            _idSampling: obj['sampId'],
            _status: status,
            _assignedUser: obj['AssignedId']
        });

        if( obj['sampReceptionDateTime'] != undefined ){
            to[0]['_receptionDateTime'] = obj['sampReceptionDateTime'];
        }

        if( obj['_listReferences'] != undefined ){
            to[0]['_listReferences'] = obj['_listReferences'];
        }

        return this.srv.postCall(par_url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);
                        return of(mydata.success);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public deleteItem(form:any){
        var to = form;
        var obj = this.copyObject(this.rows);

        var contacts = [];
        obj.Contact.forEach(c => {
            if(c.ContId != to.ContId){
                contacts.push(c);
            }
        });

        obj.Contact = contacts;

        super.deleteItem(obj);
    }

    showInfo(content:any, row:any){
        this.u_obj = {};
        this.openInfoModalBig(content, {});
        this.getDetails(row);
    }


   /* showInfo(content:any, row:any){
        this.u_obj = {};
        this.openInfoModalBig(content, {});
        //console.log(this.u_obj);
        this.getDetails(row);
        //console.log(row);
        //this.getAnalysisDetails(row);

    }*/

    getDetails(row:any){
        var qst = 'SamplingApproveList/AnlysisListBySampling/' + row.sampId;
        this.u_obj = row;
        this.u_obj['details'] = [];
        this.loadingDetails = true;

        this.srv.getCall(qst).subscribe(x => {
            this.loadingDetails = false;
            if(x.success){
                var rs = x.val;
                //console.log(rs);
                rs.forEach(r => {
                    r['sel'] = r['Checked'];
                    r['observation'] = r['ApprovedComentary'];
                    r['reps'] = parseInt(r['Repetition']);

                    if( r['reps'] < 1){
                        r['reps'] = 1;
                    }
                });

                if( this.u_obj['StatusName'] == 'PENDIENTE' ){
                    rs.forEach(r => {
                        r['sel'] = true;
                    });
                }

				if( this.u_obj['StatusName'] == 'RECIBIDA' ){
                    rs.forEach(r => {
                        r['sel'] = true;
                    });
                }

                this.u_obj['details'] = rs;
                if( rs.length > 0 ){
                    this.u_obj['AnalysyGroup'] = rs[0]['AnalysyGroup'];
                }
            }

        });
    }

    public getReportAPI(id:number){
        var url = 'Laboratory/Report/' + id;
        return this.srv.getBlobCall(url);
    }

    public downloadReport(obj:any){
        var pre = 'samp';

        if( !this.isAllowed('download', obj) ){
            return;
        }

        if(this.isDownloading){
            //console.log('req denied');
            return;
        }
        this.isDownloading = obj.sampId;
        //console.log(obj);

        this.getReportAPI(obj.sampId).subscribe(x => {
            this.isDownloading = 0;
            //console.log(x);
            let fname = pre + '_' + obj.sampId + '_' + obj.cmpName + '_' + obj.sitName + '.pdf';
            fileSaver.saveAs(x, fname);
            /*const url= window.URL.createObjectURL(x);
            window.open(url);*/
        });
    }

    public showOverdue(content:any, row:any){
        if( !this.isAllowed('overdue', row) ){
            //console.log('not allowed');
            return;
        }

        this.u_obj = {};
        this.openUpdateModal(content, row);
    }

    public isAllowed(opt:any, obj:any){
        if(obj.actions.indexOf(opt) > -1){
            return true;
        }
        else{
            return false;
        }
    }

    public updateDelayAPI(obj:any){
        var par_url = 'SamplingApproveList/UpdateSamplingOverdueComment';
        let to = [];

        to.push({
            _idSampling: obj['sampId'],
            _overdueComment: obj['sampOverdueComment']
        });

        return this.srv.postCall(par_url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);
                        return of(mydata.success);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public updateDelay(form:any){
        if( form == undefined ){
          form = {};
        }

        if( (form['sampOverdueComment'] == undefined) || (form['sampOverdueComment'] == '') ){
            Swal.fire(this.translations.msg_all_required, '', 'error');
            return;
        }

        this.isUpdating = true;

        this.updateDelayAPI(form).subscribe(y => {
            this.isUpdating = false;
            if( y ){
                this.getListPost();
                Swal.fire('Actualizado', '', 'success');
            }
            else{
                Swal.fire(this.translations.sampapp_error_updating_delay, '', 'error');
            }
        });
    }

    /*public isMethodSelected(id:any, methods:any){
        let resp = false;

        if((methods != undefined) && (methods != null)){
            let rs = methods.filter(m => {
                return m.refDetId == id;
            });

            //console.log(rs);

            if( rs.length > 0 ){
                resp = true;
            }
        }

        return resp;
    }*/


    UpdatedSamplingFinished(obj:any){
        var qst = 'Laboratory/UpdateStatusToFinishedSampling';
        this.isSaving = true;
        this.rows = obj['details'];
        var band = false;

        this.rows.forEach(r => {

             if (r.sel==true){

                band=true;
                var to ={
                    "ldiSamplingId": r.sampId

                   // ldiSamplingId: r['sampId'],
            };

             }
         });

       if (band==false){
            var to ={
                          "ldiSamplingId": obj.sampId
                  };
                  this.srv.postCall(qst, to).subscribe(x => {
                      this.isSaving = false;
                      if(x.success){

                          //Swal.fire(this.translations['updated'], '', 'success');
                      }
                      else{
                          Swal.fire(x.msg, '', 'error');
                      }
                  });

        }

    }

    /*Cambio para Corrección*/


 public ToCorrect(obj:any, content:any){
        var validated:any;
        console.log('entro a ToCorrect',obj)

        if( obj == undefined ){
            obj = {};
        }

        this.isUpdating = true;

        this.updateTocorrect(obj,content);

   }


public updateTocorrect(rows:any, content:any){

    //console.log('entro a updateTocorrect',rows)

    var qst = 'Laboratory/UpdateLaboratoryDataInputLog';
    let to:any;
    let tmp:any;
    var rows2: any;

    to = {
        ldiSamplingId: rows.sampId,
        ldiAnalysisId: this.SamplingAnalysisId,
        //ldiStatus: 485,
        ldiObservation: rows.container

      };

      //console.log('entro a To',to)

      this.isSaving = true;

      this.srv.postCall(qst, to).subscribe(x => {
          //console.log('entro a modificar',x);
          this.isSaving = false;
         // this.obj['details'] = [];
          if(x.success){

              Swal.fire(this.translations['updated'], '', 'success');
              // this.getDetails(rows);
              this.SampId=null;
              this.SamplingAnalysisId=null;
              content.dismiss('cross click');
              //this.getListPost();
             // this.UpdatedSamplingFinished(rows);


          }
          else{
              Swal.fire(x.msg, '', 'error');
          }

      });

    //debugger;
    /* rows2= rows['details'];
    rows2.forEach(r => {
      // console.log('entro a for',r);
       //if (r.sel==true){
          //console.log(rows, 'aqui row');
           to = {
               ldiSamplingId: rows.sampId,
               ldiObservation: r['_container'],
               //ldiStatus: 485,
               ldiAnalysisId: r.SamplingAnalysisId

             };

            this.isSaving = true;

            this.srv.postCall(qst, to).subscribe(x => {
                //console.log('entro a modificar',x);
                this.isSaving = false;
               // this.obj['details'] = [];
                if(x.success){

                   this.getDetails(rows);

                   //this.getListPost();
                    Swal.fire(this.translations['updated'], '', 'success');

                   // this.UpdatedSamplingFinished(rows);


                }
                else{
                    Swal.fire(x.msg, '', 'error');
                }

            });
       //}
    });*/
    //this.getListPost();
}

    public showAnalysis(content: any, obj: any, SamplingAnalysisId:any) {
        //debugger;

        console.log(SamplingAnalysisId);

        this.u_obj = {};
        this.modalService.open(content, { centered: true });
        //console.log(obj);
        //this.SamplingAnalysisId = obj['SamplingAnalysisId'];
        this.SamplingAnalysisId = SamplingAnalysisId;
        this.getDetails(obj);

    }

    /*getAnalysisDetails(row:any){
        var qst = 'Laboratory/GetParametersByAnlysisLaboratory/' + row.SamplingAnalysisId + '/' + row.sampId;
        this.u_obj = row;
        this.u_obj['details'] = [];
        this.loadingAnalysis = true;

        this.srv.getCall(qst).subscribe(x => {
            //this.loadingAnalysis = false;
            if(x.success){
                var rs = x.val;
                //rs['Details'].sort((a,b) => a['parSequence'].localeCompare(b['parSequence']));
                this.u_obj['details'] = rs['Details'];

                this.u_obj['ldiId'] = rs['ldiId'];
                this.u_obj['container'] = rs['ldiObservation'];

                //console.log(this.a_obj);
            }

        });
    }*/

   /* updateTocorrect(obj:any, content:any){
    	var qst = 'Laboratory/UpdateLaboratoryDataInputLog';
    	let to:any;
    	let tmp:any;

    	to = {};
    	to.ldiId = (obj.ldiId == null) ? 0 : obj.ldiId;
    	to.ldiAnalysisId = obj.anId;
    	to.ldiSamplingId = obj.sampId;
    	//to.ldiStatus = (finish) ? 1 : 0;
    	//to.LaboratoryDataInputDetail = [];

    	obj.details.forEach(x => {
    		tmp = {
    			ldiObservation: x['_container'],
    			//ldidValue: x['value']
    		};

    		//to.LaboratoryDataInputDetail.push(tmp);
    	});


    } */

}
