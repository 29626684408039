<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn btn-custom-003d6a" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th (click)="setOrder('anId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='anId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('anName')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='anName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('anKey')" class="sh">
                                    {{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='anKey')" [ngClass]="{'m-l-20':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'an_agr' | translate}}</th>
                                <th>{{'desc' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.anId}}</td>
                                <td>{{row.anName}}</td>
                                <td>{{row.anKey}}</td>
                                <td>{{row.AnalysisGroup['agrName']}}</td>
                                <td>{{row.anDescription}}</td>
                                <td class="action-buttons">
                                    <i (click)="goTo('/params/'+row.anId, {})" style="cursor:pointer;" class="ti-settings text-info m-r-10" title="{{'parameters' | translate}}"></i>
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="deleteItem({anId:row.anId}, translations)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['anKey']" name="anKey" required maxlength="20">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['anName']" name="anName" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['anDescription']" name="anDescription">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'group' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="anGroupId" [(ngModel)]="c_obj['anGroupId']" required>
                                                <option *ngFor="let list of listAgr; let i = index" [value]="list['agrId']">{{list['agrName']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'special' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md input-group" name="anIsSpecialType" [(ngModel)]="c_obj['anIsSpecialType']" required>
                                                <option value="true">{{'yes' | translate}}</option>
                                                <option value="false">{{'no' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_approvalperc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="c_obj['anValidPercent']" name="anValidPercent" min="0" max="100" placeholder="{{'agr_perc_msg' | translate}}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_duration_hours' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="c_obj['anDurationInHours']" name="anDurationInHours">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_tooltip' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['anTooltip']" name="anTooltip">
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_range' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['anRange']" name="anRange">
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_showsampling' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md input-group" name="anShowInSampling" [(ngModel)]="c_obj['anShowInSampling']" required>
                                                <option value="true">{{'yes' | translate}}</option>
                                                <option value="false">{{'no' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <h4>{{'features' | translate}}</h4>
                                <i class="mdi mdi-plus-circle" (click)="feature(1, null)" style="color: #007D8A;"></i>
                                <div class="form-group row" *ngFor="let lstTm of c_obj['AnalysisFeature']; let i = index">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">
                                        <i class="mdi mdi-minus-circle text-danger" (click)="feature(0, i)" style="color: #007D8A;"></i> {{'name' | translate}}
                                    </label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" id="AnalysisFeature{{i}}" name="AnalysisFeature{{i}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="lstTm['anfName']">
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['anName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['anKey']" name="anKey" required maxlength="20">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['anName']" name="anName" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['anDescription']" name="anDescription">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'group' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="anGroupId" [(ngModel)]="u_obj['anGroupId']" required>
                                                <option *ngFor="let list of listAgr; let i = index" [value]="list['agrId']">{{list['agrName']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'special' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md input-group" name="anIsSpecialType" [(ngModel)]="u_obj['anIsSpecialType']" required>
                                                <option value="true">{{'yes' | translate}}</option>
                                                <option value="false">{{'no' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_approvalperc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="u_obj['anValidPercent']" name="anValidPercent" min="0" max="100" placeholder="{{'agr_perc_msg' | translate}}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_duration_hours' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="u_obj['anDurationInHours']" name="anDurationInHours">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_tooltip' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['anTooltip']" name="anTooltip">
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_range' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['anRange']" name="anRange">
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'an_showsampling' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md input-group" name="anShowInSampling" [(ngModel)]="u_obj['anShowInSampling']" required>
                                                <option value="true">{{'yes' | translate}}</option>
                                                <option value="false">{{'no' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <h4>{{'features' | translate}}</h4>
                                <i class="mdi mdi-plus-circle" (click)="featureUpdate(1, null)" style="color: #007D8A;"></i>
                                <div class="form-group row" *ngFor="let lstTm of u_obj['AnalysisFeature']; let i = index">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">
                                        <i class="mdi mdi-minus-circle text-danger" (click)="featureUpdate(0, i)" style="color: #007D8A;"></i> {{'name' | translate}}
                                    </label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" id="AnalysisFeature{{i}}" name="AnalysisFeature{{i}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="lstTm['anfName']">
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Update</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
