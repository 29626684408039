<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div *ngFor="let row of items" [routerLink]="row.url" class="col-12 col-md-4 col-lg-3" [hidden]="!hasPermission(row.perm)">
                        <div class="form-control crud-link">
                            <div class="icon"><i class="{{row.icon}} m-r-5"></i></div>
                            <div class="title">{{ row.title | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
