import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html'
})
export class SiteComponent extends BaseCrudComponent {

    id:any;
    loadingRequired = false;
    listAgr:any;
    translations:any;

    provinces:any;
    countries:any;
    water_sys:any;
    shrimp_sectors:any;
    sk_sectors:any;
    laboratories:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'Site/All';
        this.create_url = '';
        this.update_url = 'Site/Update';
        this.delete_url = '';
        this.search_fields = ['sitName', 'sitM3', 'laboratory', 'shrimpSector', 'skSector'];

        this.c_obj['SiteAddress'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

              var batch = [];
              //batch.push(this.getCountries());
              batch.push(this.getProvinces());
              batch.push(this.getWaterSystems());
              batch.push(this.getShrimpSectors());
              batch.push(this.getSkSectors());
              batch.push(this.getLaboratories());

              this.loadingList = true;

              concat(...batch).subscribe({
                complete: () => {
                    this.loadingList = false;
                    this.getList();
                }
              });
        });
    }

    public getList(){
        var qst = this.read_url;
        this.loadingList = true;
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;
            
            if(x.success){
                x.val.forEach(y => {
                    y['laboratory'] = this.getFromArray(this.laboratories, 'labId', y['sitLaboratoryId'], 'labName', '');
                    y['shrimpSector'] = this.getFromArray(this.shrimp_sectors, 'shrId', y['sitShrimpSectorId'], 'shrName', '');
                    y['skSector'] = this.getFromArray(this.sk_sectors, 'skseId', y['sitSkrettingSectorId'], 'skseName', '');
                });

                this.rawdata = x.val;
                this.rows = x.val;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
            }
          });
    }

    public getVal(obj:any, key:any){
        let resp = '';
        if( (obj !== undefined) && (obj !== null) ){
            resp = obj[key];
        }
        return resp;
    }

    public getFromArray(arr:any, key:any, val:any, to:any, def:any){
        let resp = arr.filter(f => {
            return f[key] == val;
        });

        return (resp.length > 0) ? resp[0][to] : def;
    }

    public getCountries(){
        let url = 'Country/All';
        return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          this.countries = mydata.val;
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getLaboratories(){
        let url = 'LaboratoryEntity/All';
        return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          this.laboratories = mydata.val;
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getShrimpSectors(){
        let url = 'ShrimpSector/All';
        return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          this.shrimp_sectors = mydata.val;
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getSkSectors(){
        let url = 'SkrettingSector/All';
        return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          this.sk_sectors = mydata.val;
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getProvinces(){
        let url = 'SystemParameter/GetByName/Province';
        return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          this.provinces = mydata.val[0];
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getWaterSystems(){
        let url = 'SystemParameter/GetByName/SITE_WMS';
        return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        
                        if(mydata.success == true ){
                          this.water_sys = mydata.val[0];
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        /*validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }*/

        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    /*public feature(condition: number, position: number) {
        if ( condition ===  1) {
          this.c_obj['SiteAddress'].push({sitaAddressNo: '', sitaType: 12, sitaSiteAquasimId: '' });
        } else if (condition === 0) {
          this.c_obj['SiteAddress'].splice(position, 1);
        }
    }*/

    public featureUpdate(condition: number, position: number) {
        if ( condition ===  1) {
          this.u_obj['SiteAddress'].push({sitaAddressNo: '', sitaType: 12, sitaSiteAquasimId: this.u_obj['SitAquasymId'] });
        } else if (condition === 0) {
          this.u_obj['SiteAddress'].splice(position, 1);
        }
    }

}
