import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/main/api.service';
import { Global } from '../../services/global';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, of, throwError, from } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {Cell, Txt} from 'pdfmake-wrapper';
import {environment} from '../../../environments/environment';
import {LoginService} from '../../services/authentication/login.service';

@Component({
  selector: 'app-base-crud',
  templateUrl: './base-crud.component.html',
  styleUrls: ['./base-crud.component.css']
})

export class BaseCrudComponent implements OnInit {
    public rows: any;
    public rawdata: any;
    public create_url: any;
    public read_url: any;
    public update_url: any;
    public delete_url: any;
    public regularForm: FormGroup;
    public reload = new BehaviorSubject(false);

    public c_obj: any;
    public u_obj: any;
    public i_obj: any;

    public loadingList = false;
    public isCreating = false;
    public isUpdating = false;

    page:any;
    pageTotal:any;
    pageSize:any;

    search:any;
    orderKey:any;
    orderDesc:any;
    search_fields:any;

    f1_key:string;
    f2_key:string;
    f3_key:string;

    f1_val:string;
    f2_val:string;
    f3_val:string;

    translations:any;
  vers:any;
    //filter to send
    public showFilters = false;
    public f_datefrom:any;
    public f_dateto:any;
    public f_custom1:any;
	public r_date_create:any;
    public r_date_reception:any;

    public isOffline:boolean;

    constructor(
      public srv: ApiService,
      public modalService: NgbModal,
      public storage: Storage,
      public router: Router,
      public translate?: TranslateService
    ) {
        this.rows = [];
        this.rawdata = [];
        this.create_url = '';
        this.read_url = '';
        this.update_url = '';
        this.delete_url = '';

        //pagination
        this.page = 1;
        this.pageTotal = 0;
        this.pageSize = 15;

        this.c_obj = {};
        this.u_obj = {};
        this.i_obj = {};

        this.search = '';
        this.orderKey = null;
        this.orderDesc = true;
        //should reference  only to string fields
        this.search_fields = [];
        this.f1_key = 'CompName';
        this.f2_key = 'SitName';
        this.f3_key = 'status';
        this.f1_val = '';
        this.f2_val = '';
        this.f3_val = '';

        this.f_datefrom = null;
        this.f_dateto = null;
        this.f_custom1 = null;
		this.r_date_create=null;
        this.r_date_reception=null;


        //offline status checker
        this.isOffline = false;
    }

    ngOnInit() {
        //console.log('enter base crud');
        //console.log(navigator.onLine);
        //offline mode detection
        if( navigator.onLine ){
            //console.log(this);
            var _obj = this;
            this.srv.isReachable().then(function(online) {
                //console.log(_obj);
                if (online) {
                  // handle online status
                  console.log('online');
                  //console.log(_obj);
                  //console.log(srv);
                  _obj.srv.offline.next(false);
                } else {
                  console.log('no connectivity');
                  _obj.srv.offline.next(true);
                }
            });
        }
        else{
            this.isOffline = true;
        }

        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            //console.log(translations);
        });
    }

    public clearData(){
        this.rows = [];
        this.c_obj = {};
        this.u_obj = {};
    }

    public getList(){
        var qst = this.read_url;
        this.loadingList = true;
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;
            if(x.success){
                this.rawdata = x.val;
                this.rows = x.val;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
              /*if( navigator.onLine ){
                this.getVersion();
              }*/
                this.verifiedVersion();
            }
        });
    }
  async verifiedVersion(){
    await this.srv.getServerVersion().then(x => {
      this.vers = x;
    });//debugger;
    //setTimeout(()=> {debugger;
    console.log(this.vers);
    /**/if (this.vers===environment.version){

    }else{
      let parts = this.vers.split('.');
      let f3 = parts[2];
      let partsW = environment.version.split('.');
      let fw3 = partsW[2];debugger;
      if( (f3 !== null) && (f3 !== undefined) && (fw3 !== null) && (fw3 !== undefined) ){
        debugger;
        if (fw3 < f3){
          Swal.fire({
            title: 'Se detectó una nueva versión, se procederá a actualizar.',
            text: '',
            icon: 'error',
            showCancelButton: false,
            //cancelButtonText: 'Cancelar',
            confirmButtonText: 'Aceptar'
          }).then(async(result) => {
            if (result.value) {


              /**/Swal.fire({
                title: 'Se cerrará la sesión, ingrese nuevamente para seguir usando el aplicativo.',
                text: '',
                icon: 'warning',
                showCancelButton: false,
                //cancelButtonText: this.translations.cancel,
                confirmButtonText: 'Aceptar'
              }).then(async ( result) => {
                if (result.value) {
                  //console.log(this.srv);
                  this.srv.clearData().then(() => {
                    this.router.navigate(['/authentication/login']);
                    window.location.reload(true);
                  });
                }
              });

            }
          });
        }else{
          alert("Nada que hacer")
        }
      }
    }
  }

    public getListPost(obj?:any){
        if( obj == undefined ){
            obj = {};
        }

        var qst = this.read_url;
        this.loadingList = true;
        this.srv.postCall(qst, obj).subscribe(x => {
            this.loadingList = false;
            if(x.success){
                this.rawdata = x.val;
                this.rows = x.val;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
            }
        });
    }

    translateMessage(str:any){
        let msg = '';
        if( this.translations[str+''] != undefined ){
            msg = this.translations[str+''];
        }
        return msg;
    }

    public createItem(content:any, fields:any){
        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, fields).subscribe(x => {
            this.isCreating = false;
            let msg = this.translateMessage(x.msg);

            if(x.success){
                this.reload.next(true);
                this.closeModal(content);
                Swal.fire('¡Creado!', msg, 'success');
            }
            else{
                Swal.fire(msg, '', 'error');
            }
        });
    }

    public updateItem(content:any, fields:any){
        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, fields).subscribe(x => {
            this.isUpdating = false;
            let msg = this.translateMessage(x.msg);

            if(x.success){
                this.reload.next(true);
                this.closeModal(content);
                Swal.fire('¡Actualizado!', msg, 'success');
            }
            else{
                Swal.fire(msg, '', 'error');
            }
        });
    }

    public deleteItem(obj: any, trans?:any) {
        var qst = this.delete_url;
        //console.log(trans);
        if( trans == undefined ){
            trans = {
                delete: 'Borrar',
                deleted: 'Borrado',
                cancel: 'Cancelar',
                delete_item_title: 'Está seguro que desea eliminar el registro?'
            };
        }

        Swal.fire({
            title: trans.delete_item_title,
            text: '',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: trans.cancel,
            confirmButtonText: trans.delete,
            preConfirm: () => {
                this.srv.postCall(qst, obj).subscribe(x => {
                    let msg = this.translateMessage(x.msg);

                    if(x.success){
                        this.reload.next(true);
                        Swal.fire(trans.deleted, msg, 'success');
                    }
                    else{
                        Swal.fire(msg, '', 'error');
                    }
                });
            }
        }).then((result) => {
        });
    }

    public openCreateModal(content: any) {
        this.modalService.open(content, { centered: true });
    }

    public openCreateModalLG(content: any) {
        this.modalService.open(content, { size: 'lg', centered: true });
    }

    public openUpdateModal(content: any, obj: any, size?:any) {
        let newobj = JSON.parse(JSON.stringify(obj));
        this.u_obj = newobj;

        if( size == undefined ){
            this.modalService.open(content, { centered: true });
        }
        else{
            this.modalService.open(content, { centered: true, size: size });
        }

    }

    public openUpdateModalNoDataConversion(content: any, obj: any, size?:any) {
        //this.u_obj = obj;
        let newobj = JSON.parse(JSON.stringify(obj));
        //console.log(newobj);
        this.u_obj = newobj;

        if( size == undefined ){
            this.modalService.open(content, { centered: true });
        }
        else{
            this.modalService.open(content, { centered: true, size: size });
        }
    }

    public openUpdateModalStatic(content: any, obj: any, size?:any) {
        let newobj = JSON.parse(JSON.stringify(obj));
        this.u_obj = newobj;

        if( size == undefined ){
            this.modalService.open(content, { centered: true, backdrop: 'static', keyboard: false });
        }
        else{
            this.modalService.open(content, { centered: true, size: size, backdrop: 'static', keyboard: false });
        }
    }

    public closeModal(content: any) {
        this.modalService.dismissAll(content);
    }

    public goTo(url, params){
        this.router.navigate([url], {state: params});
    }

    public filterList(fields:any){
        //this.search = event.target.value;
        var resp:boolean;
        if( !Array.isArray(fields) ){
            return;
        }

        if( this.search == '' ){
            this.rows = this.rawdata;
        }
        else{
            let test = this.rawdata.filter(s => {
                resp = false;
                fields.forEach(f => {
                    resp = resp || s[f].toLowerCase().includes(this.search.toLowerCase());
                });
                return resp;
            });

            this.rows = test;
        }

        this.pageTotal = this.rows.length;
    }

    public filterAll(){
        var fields = this.search_fields;
        var resp:boolean;
        var dummy:any;
        this.verifiedVersion();
        if( !Array.isArray(fields) ){
            return;
        }

        if( this.search == '' ){
            this.rows = this.rawdata;
        }
        else{
            let test = this.rawdata.filter(s => {
                resp = false;
                fields.forEach(f => {
                    if( f.indexOf('array.') > -1 ){
                        let parts = f.split('.');
                        let f1 = parts[0];
                        let f2 = parts[1];
                        /*console.log(s[f2]);
                        console.log(this.inArray(this.search, s[f2]));*/

                        if( (s[f2] !== null) && (s[f2] !== undefined) ){
                            resp = resp || this.inArray(this.search, s[f2]);
                        }
                    }
                    else if( (s[f] !== null) && (s[f] !== undefined) ){
                        resp = resp || String(s[f]).toLowerCase().includes(this.search.toLowerCase());
                    }

                });

                return resp;
            });

            this.rows = test;
        }

        if( this.f1_val != '' ){
            /*console.log('btw');
            console.log(this.rows);
            console.log(this.f1_val);*/
            dummy = this.rows.filter(s => {
                return s[this.f1_key] == this.f1_val;
            });

            this.rows = dummy;
        }

        if( this.f2_val != '' ){
            dummy = this.rows.filter(s => {
                return s[this.f2_key] == this.f2_val;
            });

            this.rows = dummy;
        }

        if( this.f3_val != '' ){
            dummy = this.rows.filter(s => {
                return s[this.f3_key] == this.f3_val;
            });

            this.rows = dummy;
        }

        this.pageTotal = this.rows.length;
    }

    public inArray(str:any, arr:any){
        let resp = false;

        arr.forEach(x => {
            x = x + '';

            if( x.indexOf(str) > -1 ){
                resp = true;
            }
        });

        return resp;
    }

    public onKeyUpSearch(ev:any){
        var str = ev.target.value;
        if( (str == '') && (this.rows.length != this.rawdata.length) ){
            //console.log('works');
            this.rows = this.rawdata;
            this.pageTotal = this.rows.length;
        }
    }

    public copyObject(obj:any){
        var to:any;
        to = {};
        var keys = [];

        keys = Object.keys(obj);
        if( keys.length > 0 ){
            keys.forEach(x => {
                to[x] = obj[x];
            });
        }

        return to;
    }

    public openInfoModalBig(content: any, obj: any) {
        const copy = { ...obj };
        this.i_obj = copy;
        this.modalService.open(content, { size: 'lg', centered: true});
    }

    public setOrder(str:any){
        this.orderKey = str;
        this.orderDesc = !this.orderDesc;
    }

    public clearSentFilters(){
        this.f_datefrom = null;
        this.f_dateto = null;
        this.f_custom1 = null;
    }

    public getISODate(d:any){
        let fulliso:any;
        //ngbDate count month from 1 but js Date from 0
        fulliso = new Date(d['year'], parseInt(d['month'])-1, d['day']);
        fulliso = fulliso.toISOString().substring(0, 10);
        return fulliso;
    }
    /*Métodos auxiliares para reporte*/
  setListaSubtitulos(listaTitulos: any[], listaMayorElementos:any[]) : any
  {
    let lista = [];
    listaTitulos.forEach(element => {
      for (let i of listaMayorElementos) {//debugger;
        if (i[element] !== undefined) {
          lista.push(new Cell( new Txt( i.subtitulo).color(Global.rptTxtColor).fontSize(7).alignment('center').bold().end ).fillColor(Global.rptBgColor).end);
        }
      }
    })
    return lista;
  }
  setListaUnificada(listaMayorElementos:any[]) : any
  {
    let lista = [];
    let tmpMap=new Map();
    for (let listaC of listaMayorElementos) {
      /*if (listaC.length > mayor) {
        mayor = listaC.length;
        lista_mayor_elementos = listaC;
      }*/
      listaC.forEach(elemento=>{
        let objeto=Object.values(elemento);
        if (!tmpMap.has(objeto[0]))
          tmpMap.set(objeto[0],objeto[1])
      });
      /*for (let elemento of listaC){
        if (lista_mayor_elementos.length==0)
          lista_mayor_elementos.push(elemento);
        else if (!lista_mayor_elementos.some(e => e.subtitulo === elemento.subtitulo && e[0]===elemento[0])
        )
          lista_mayor_elementos.push(elemento);
      }*/
    }
    lista=[...tmpMap].map((clave, indice)=>({[clave[0]]:clave[0], subtitulo:clave[1]}));
    return lista;
  }
  setDatosEstructurados(new_data: [], data_report_type:Number, isPreview:any) : any
  {
    let map_method_reference = new Map();
    let map_title = new Map();
    let map_grupo_fito = new Map();
    let map_content = new Map();
    let lista_subtitulos: any[] = [];
    let lista_tituls_analisis = [];
    let lista_pcr_detalles = [];
    let contenedor_listas_subtitulos = [];
    let obj = {
      name: '',
      reference: '',
      date_init: '',
      date_end: '',
      client: '',
      direction: '',
      solicit: '',
      real: '',
      date_mues: '',
      lug_mues: '',
      tipo: '',
      analisis: '',
      cant: '',
      env: '',
      img_resp: '',
      img_aprob: '',
      direction_pcr : '',
      type_pcr: '',
      reference_number_pcr: '',
      date_reception_pcr: '',
      cantidad_mues_pcr: '',
      forma_present_pcr: '',
      fecha_init_ens_pcr:'',
      condi_mues_pcr: '',
      fecha_term_ens_pcr: '',
      lab_refe_pcr:'',
      tem_amb_pcr: '',
      hum_rel_pcr: '',
      fecha_ent_pcr: '',
      muestra_mic: '',
      fundamento_mic: '',
      resultado_mic: '',
      conclusion_mic: ''
    }
    new_data.forEach((elemento: any) => {
      let sector = elemento.SkrettingSector == null ? ' ': elemento.SkrettingSector.skseName;
      let sector2 = elemento.ShrimpSector == null ? ' ': elemento.ShrimpSector.shrName;
      if (sector == ' ' && sector2 == ' ') {
        obj.direction = sector +  sector2;
      } else if(sector != ' ' && sector2 == ' '){
        obj.direction = sector + sector2;
      } else if(sector == ' ' && sector2 != ' '){
        obj.direction = sector + sector2;
      } else if (sector != ' ' && sector2 != ' ') {
        obj.direction = sector + '/' + sector2;
      }
      obj.name = elemento.LaboratoryWorker.usrFirstName + ' ' +elemento.LaboratoryWorker.usrLastName;
      obj.reference = elemento.Id;
      obj.date_init = new Date(elemento.sampReceptionDateTime).getDate().toString() + '-' + this.getMonth(new Date(elemento.sampReceptionDateTime)).toString() + '-' + new Date(elemento.sampReceptionDateTime).getFullYear().toString();
      if (isPreview==true)
        obj.date_end = '';
      else
        obj.date_end = elemento.DateApprove !== null ? new Date(elemento.DateApprove).getDate().toString() + '-' + this.getMonth(new Date(elemento.DateApprove)).toString() + '-' + new Date(elemento.DateApprove).getFullYear().toString() : '';
      obj.client = elemento.sitName;
      obj.solicit = elemento.Technician.usrFirstName + ' ' + elemento.Technician.usrLastName;
      obj.date_mues = new Date(elemento.sampDate).getDate().toString() + '-' + this.getMonth(new Date(elemento.sampDate)).toString() + '-' + new Date(elemento.sampDate).getFullYear().toString();
      obj.lug_mues = 'Piscinas Acuícolas';
      obj.cant = elemento.QUANTITY.toString();
      obj.tipo = elemento.samName;
      obj.analisis = elemento.agrName;
      obj.env = elemento.sampContainer;
      obj.direction_pcr = elemento.DireccionPCR;
      obj.type_pcr = elemento.sampProductType_n;
      obj.reference_number_pcr = elemento.sampReferenceNumber_n;
      obj.date_reception_pcr = obj.date_init;
      obj.cantidad_mues_pcr = elemento.QUANTITY.toString();
      obj.forma_present_pcr = elemento.sampPresentation_n;
      obj.fecha_init_ens_pcr = new Date(elemento.dateInitialTest).getDate().toString() + '-' + this.getMonth(new Date(elemento.dateInitialTest)).toString() + '-' + new Date(elemento.dateInitialTest).getFullYear().toString();
      obj.condi_mues_pcr = elemento.sampConditionSample_n;
      obj.fecha_term_ens_pcr = new Date(elemento.dateFinalTest).getDate().toString() + '-' + this.getMonth(new Date(elemento.dateFinalTest)).toString() + '-' + new Date(elemento.dateFinalTest).getFullYear().toString();
      obj.tem_amb_pcr = elemento.sampEnvironmentTemperature_n;
      obj.hum_rel_pcr = elemento.sampRH_n;
      obj.lab_refe_pcr= elemento.sitLaboratoryId == 1 ? 'L.S.A. El Oro':'L.S.A. Gye';
      //obj.fecha_ent_pcr = '';
      obj.fecha_ent_pcr=obj.date_end;
      if (obj.img_aprob == '') {
        obj.img_aprob = elemento.LaboratoryResponsible.Image;
      }
      if (obj.img_resp == '') {
        obj.img_resp = elemento.LaboratoryWorker.usrSign;
      }
      if (elemento.SamplingReferenceMethods.length !=0)
      {
        elemento.SamplingReferenceMethods.forEach( (ref: any) => {
          if(!map_method_reference.has(ref.refDetReference )) {
            map_method_reference.set(ref.refDetReference, ref.refDetDescription)
          }
        });
      }
      let l_temporal = [];
      elemento.ResultExams.forEach( (results : any) => {
        results.Detail.forEach((detalle: any) =>{
          let rangoMax = detalle.Parameter.parRangeMax== null ? ' ' : detalle.Parameter.parRangeMax;
          let rangoMin = detalle.Parameter.parRangeMin == null ? ' ' : detalle.Parameter.parRangeMin; //  - 1
          let rango = null;
          if ((rangoMin == ' ' || rangoMin == '') && (rangoMax == ' ' || rangoMax == '')) {
            rango = rangoMin  + rangoMax;
          }else if((rangoMin != ' ' || rangoMin != '') &&  (rangoMax == ' ' || rangoMax == '')){
            rango = rangoMin  + rangoMax;
          }else if((rangoMin == ' ' || rangoMin == '') &&  (rangoMax != ' ' || rangoMax != '')){
            rango = rangoMin  + rangoMax;
          }
          else {
            rango = rangoMin  + ' - ' + rangoMax;
          }
          // SUELO
          let new_obj = {};
          if (data_report_type == 1) // SUELO
          {
            if (!map_title.has('ldiSamplingId')) {
              map_title.set('ldiSamplingId','ldiSamplingId');
            }
            if (!map_title.has('uniName')) {
              map_title.set('uniName','uniName');
            }
            if (!map_title.has('sampDepth')) {
              map_title.set('sampDepth','sampDepth');
            }
            if (!map_title.has('sampleType')) {
              map_title.set('sampleType','sampleType');
            }
            if (!map_title.has(detalle.parName) && map_title.size <= 14) {
              map_title.set(detalle.parName, detalle.parName);
            }
            let x = detalle.parName;
            l_temporal.push({
              [x]: detalle.parName, subtitulo: rango
            });
            //console.log("map_content:",map_content);
            //console.log("map_content.size:",map_content.size,"map_content:",map_content)
            //console.log("map_tile.size:",map_title.size,"map_title:",map_title)

            //se comenta condicion de size para agregar mas elementos en el contenido de la tabla
            if (!map_content.has(detalle.ldiSamplingId))// && map_content.size <= map_title.size)
            {
              new_obj['ldiSamplingId'] = detalle.ldiSamplingId
              new_obj['uniName'] = elemento.uniName == null ? detalle.slinName : elemento.uniName;
              new_obj['sampDepth'] = elemento.sampDepth;
              new_obj['sampleType'] = detalle.sampleType
              new_obj[detalle.parName] = detalle.ldidValue == null ? 'S/N': detalle.ldidValue;
              map_content.set(detalle.ldiSamplingId, [new_obj]);
            }
            else if (map_content.has(detalle.ldiSamplingId))// && map_content.size <= map_title.size)
            {
              let tmp = map_content.get(detalle.ldiSamplingId);
              if (tmp.length <= 13) {
                tmp[0][detalle.parName] = detalle.ldidValue == null ? 'S/N': detalle.ldidValue;
                map_content.set(detalle.ldiSamplingId, tmp);
              }
            }

          }
          else if (data_report_type == 3) //BACTERIOLOGIA
          {
            // se elije el subitulo del campo anrange
            rango = detalle.UnitOfMesure;
            if (!map_title.has('ldiSamplingId')) {
              map_title.set('ldiSamplingId','ldiSamplingId');
            }
            if (!map_title.has('uniName')) {
              map_title.set('uniName','uniName');
            }
            if (!map_title.has('sampleType')) {
              map_title.set('sampleType','sampleType');
            }
            if (!map_title.has(detalle.parName) && map_title.size <= 14) {
              map_title.set(detalle.parName, detalle.parName);
            }
            let x = detalle.parName;
            l_temporal.push({
              [x]: detalle.parName, subtitulo: rango
            });
            //&& map_content.size <= map_title.size
            if (!map_content.has(detalle.ldiSamplingId)) {
              new_obj['ldiSamplingId'] = detalle.ldiSamplingId
              new_obj['uniName'] = elemento.uniName == null ? detalle.slinName : elemento.uniName;
              new_obj['sampleType'] = detalle.sampleType
              new_obj[detalle.parName] = detalle.ldidValue == null ? 'S/N': detalle.ldidValue;
              map_content.set(detalle.ldiSamplingId, [new_obj]);
            } else if (map_content.has(detalle.ldiSamplingId)) {
              let tmp = map_content.get(detalle.ldiSamplingId);
              if (tmp.length <= 13) {//numero de columnas que tiene cada fila por defecto son 13 maximo
                tmp[0][detalle.parName] = detalle.ldidValue == null ? 'S/N': detalle.ldidValue;
                map_content.set(detalle.ldiSamplingId, tmp);
              }
            } /*else if (map_content.has(detalle.ldiSamplingId) && map_content.size > map_title.size) {
              let tmp = map_content.get(detalle.ldiSamplingId);
              if (tmp.length <= 23) {
                tmp[0][detalle.parName] = detalle.ldidValue == null ? 'S/N': detalle.ldidValue;
                map_content.set(detalle.ldiSamplingId, tmp);
              }
            }*/
          }
          else if (data_report_type == 2 || data_report_type == 5 ) // AGUA - BALANCE IONICO
          {
            if (!map_title.has('ldiSamplingId')) {
              map_title.set('ldiSamplingId','ldiSamplingId');
            }
            if (!map_title.has('uniName')) {
              map_title.set('uniName','uniName');
            }//debugger;
            if (data_report_type == 2 && !map_title.has('sampleType')) {
              map_title.set('sampleType','sampleType');
            }
            if (!map_title.has(detalle.parName) && map_title.size <= 14) {
              map_title.set(detalle.parName, detalle.parName);
            }
            let x = detalle.parName;
            console.log("l_temporal:",l_temporal)
            console.log("x:",x)
            l_temporal.push({
              [x]: detalle.parName, subtitulo: rango
            });
            // console.log('Tamaño del mapa de contenido: ',map_content.size);
            // console.log('Tamaño del mapa de titulo: ',map_title.size);
            let cantidad_elementos_lista = 0;
            cantidad_elementos_lista =  map_content.get(detalle.ldiSamplingId) !== undefined ? map_content.get(detalle.ldiSamplingId).length : 0;
            if (!map_content.has(detalle.ldiSamplingId) && cantidad_elementos_lista <= map_title.size) { // && map_content.size <= map_title.size
              // console.log('El mapa no contiene el id ', detalle.ldiSamplingId)
              new_obj['ldiSamplingId'] = detalle.ldiSamplingId
              new_obj['uniName'] = elemento.uniName == null ? detalle.slinName : elemento.uniName;
              if (data_report_type == 2)
                new_obj['sampleType'] = detalle.sampleType
              new_obj[detalle.parName] = detalle.ldidValue == null ? 'S/N': detalle.ldidValue;
              // console.log('Ingreso para llenar....', new_obj)
              map_content.set(detalle.ldiSamplingId, [new_obj]);
            } else if (map_content.has(detalle.ldiSamplingId) && cantidad_elementos_lista <= map_title.size) {
              // console.log('ingreso a este if el id: ', detalle.ldiSamplingId)
              // console.log('deseo ingresar ', detalle.parName, ' con el valor: ', detalle.ldidValue)
              let tmp = map_content.get(detalle.ldiSamplingId);
              if (tmp.length <= 13) {
                tmp[0][detalle.parName] = detalle.ldidValue == null ? 'S/N': detalle.ldidValue;
                map_content.set(detalle.ldiSamplingId, tmp);
              }
            }
            // console.log('---------------------------------------------------------------------')
          }
          else if (data_report_type == 4) // FITO -- CAMBIO JEIS
          {
            if (!map_title.has(elemento.sampId) && map_title.size < 9) {
              map_title.set(elemento.sampId, elemento.sampId);
            }
            if (lista_subtitulos.length < map_title.size) {
              lista_subtitulos.push(
                new Cell( new Txt(detalle.uniName == null ? detalle.slinName : detalle.uniName).color('white').fontSize(8).alignment('center').bold().end ).end
              )

            }
            if (!map_grupo_fito.has(detalle.anName)) {
              map_grupo_fito.set(detalle.anName, detalle.anName);
            }
            if (!map_content.has(detalle.parName + '--' + detalle.anName)) {
              map_content.set(detalle.parName + '--' + detalle.anName,
                {
                  [elemento.sampId]: detalle.ldidValue,
                }
              );
            } else {
              let tmp = map_content.get(detalle.parName + '--' + detalle.anName);
              tmp[elemento.sampId] = detalle.ldidValue;
              map_content.set(detalle.parName + '--' + detalle.anName, tmp);
            }

            //CODIGO ANTERIOR
            // if (!map_title.has(elemento.sampId) && map_title.size < 9) {
            //   map_title.set(elemento.sampId, elemento.sampId);
            // }
            // if (lista_subtitulos.length < map_title.size) {
            //   lista_subtitulos.push(
            //     new Cell( new Txt(elemento.uniName).color('white').fontSize(8).alignment('center').bold().end ).end
            //   )
            //
            // }
            // if (!map_grupo_fito.has(detalle.anName)) {
            //   map_grupo_fito.set(detalle.anName, detalle.anName);
            // }
            // if (!map_content.has(detalle.parName + '--' + detalle.anName)) {
            //   map_content.set(detalle.parName + '--' + detalle.anName,
            //     {
            //       [elemento.sampId]: detalle.ldidValue,
            //     }
            //   );
            // } else {
            //   let tmp = map_content.get(detalle.parName + '--' + detalle.anName);
            //   tmp[elemento.sampId] = detalle.ldidValue;
            //   map_content.set(detalle.parName + '--' + detalle.anName, tmp);
            // }
          } else if (data_report_type == 6) // PCR
          {
            let numRef = '', methodref = '';
            if (!lista_tituls_analisis.includes(detalle.anName)){
              lista_tituls_analisis.push(detalle.anName);
            }
            if (elemento.SamplingReferenceMethods.length !=0)
            {
              elemento.SamplingReferenceMethods.forEach( (ref: any) => {
                if (ref.anName == detalle.anName) {
                  numRef = ref.refDetReference;
                  methodref = ref.refDetDescription;
                }
              });
            };
            lista_pcr_detalles.push(
              [
                detalle.anName,
                {
                  ldiSamplingId: new Cell(new Txt((detalle.ldiSamplingId).toString()).color('black').fontSize(7).alignment('center').end ).end,
                  parName: new Cell(new Txt(detalle.parName).color('black').fontSize(7).alignment('center').end ).end,
                  numRef: new Cell(new Txt(numRef).color('black').fontSize(7).alignment('center').end ).end,
                  methodref: new Cell(new Txt(methodref).color('black').fontSize(7).alignment('center').end ).end,
                  parSequence: new Cell(new Txt(detalle.uniName == null ? detalle.slinName : detalle.uniName).color('black').fontSize(7).alignment('center').end ).end,
                  sampleType: new Cell(new Txt(detalle.sampleType).color('black').fontSize(7).alignment('center').end ).end,
                  numAnalisis: new Cell(new Txt('1').color('black').fontSize(7).alignment('center').end ).end,
                  ldidValue: new Cell(new Txt(detalle.ldidValue).color('black').fontSize(7).alignment('center').end ).end,
                  pvalueInfo: new Cell(new Txt(detalle.pvalueInfo).color('black').fontSize(7).alignment('center').end ).end
                }
              ]
            );
          }

        });
      });
      contenedor_listas_subtitulos.push(l_temporal)
    });console.log(map_content);
    let arregloEstructurado={
      map_method_reference:map_method_reference,
      map_title:map_title,
      map_grupo_fito:map_grupo_fito,
      map_content:map_content,
      lista_subtitulos:lista_subtitulos,
      lista_tituls_analisis:lista_tituls_analisis,
      lista_pcr_detalles:lista_pcr_detalles,
      contenedor_listas_subtitulos:contenedor_listas_subtitulos,
      obj:obj
    }
    return arregloEstructurado;
  }
  getMonth( fecha: Date) {
    let month = '';
    var monthNames = [ "enero", "febrero", "marzo", "abril", "mayo", "junio","julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre" ];
    month = monthNames[fecha.getMonth()];
    return month;
  }
  public getVersion(){
    var ent_url = 'SystemParameter/GetVersion/'+environment.version+'/';
    return this.srv.getCall(ent_url).subscribe(data => {
      let mydata:any = {success: false, msg:'', val:[]};
      mydata = data;
      if(mydata.success == true ){
        return from(this.srv.persistData('appversion', mydata.val.value));
      }
      else{
        return of(false);
      }
    });
  }
}
