<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-md-11 col-9 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-md-1 col-3 m-b-30" style="text-align: right;">
                        <button type="button" class="btn btn-custom-003d6a" (click)="showFilters = !showFilters" style="color: #ffffff;" >
                            <i *ngIf="!showFilters" class="far fa-caret-square-down"></i>
                            <i *ngIf="showFilters" class="far fa-minus-square"></i>
                        </button>
                    </div>
                    <!-- filter box -->
                    <div class="col-12 m-b-30" style="text-align: left;" *ngIf="showFilters">
                        <div class="card filter-box">
                            <div class="card-body row">
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'date' | translate}} {{'from' | translate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp1" [(ngModel)]="f_datefrom" ngbDatepicker #d="ngbDatepicker" [readonly]="true">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                                <i class="far fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'date' | translate}} {{'to' | translate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp2" [(ngModel)]="f_dateto" ngbDatepicker #d2="ngbDatepicker" [readonly]="true">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                                <i class="far fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'company' | translate}}</label>
                                    <select class="custom-select custom-select-md" [(ngModel)]="f_custom1" name="cf1">
                                        <option *ngFor="let p of raw_companies" value="{{p.id}}">{{p.name}}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'samp_ag' | translate}}</label>
                                    <select class="custom-select custom-select-md" [(ngModel)]="f_custom2" name="cf2">
                                        <option *ngFor="let p of agroups" value="{{p.id}}">{{p.name}}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'report' | translate}}</label>
                                    <input type="text" class="form-control" name="repocode" [(ngModel)]="repocode" maxlength="20">
                                </div> -->
                                <div class="col-12 m-b-30" style="text-align: right;">
                                    <button type="button" class="btn btn-danger" (click)="clearSentFilters()">
                                        <i class="far fa-trash-alt"></i> {{'clear' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- filters -->
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th (click)="setOrder('Id')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='Id')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'code' | translate}}</th>
                                <th (click)="setOrder('cmpName')" class="sh">
                                    {{'company' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='cmpName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'group' | translate}}</th>
                                <th>{{'date' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc" [ngClass]="{'disabled-row': !row.usrActive}"> -->
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc" [ngClass]="{'selected-row':row['nuevo']=='NUEVO'}">
                                <td>{{row.Id}}</td>
                                <td>{{row.Number}}</td>
                                <td>{{row.cmpName}}</td>
                                <td>{{row.agrName}}</td>
                                <td>{{row.anCreationTime | date:'y-MM-d H:mm:ss'}}</td>
                                <td class="action-buttons">
                                    <i *ngIf="(row.Id !== isDownloading)" (click)="downloadReport(row)" style="cursor:pointer;" title="{{'export_pdf' | translate}}" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': true}"></i>

                                    <i *ngIf="(row.Id === isDownloading)" class="fas fa-sync-alt fa-spin m-r-10 text-info"></i>

                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-envelope text-info m-r-10" title="{{'mail' | translate}}"></i>

                                    <i (click)="showFollowup(follow1, row)" style="cursor:pointer;" class="fa fa-comment-alt text-info m-r-10" title="{{'samp_followup' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'send_report' | translate}}: {{u_obj['Number']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-left control-label col-form-label">{{'recommendation' | translate}}</label>
                                    <div class="col-12">
                                        <textarea class="form-control" [(ngModel)]="u_obj['comment']" name="comment" rows="5" maxlength="500"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isMailing" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="sendMail(update1, u_obj)">{{'send' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #follow1 let-follow1>
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'samp_followup' | translate}} {{'report' | translate}} #{{f_obj['Number']}}</h4>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-12">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-12 ha-ibox">
                                                <label>{{'samp_comment' | translate}}</label>
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="4" name="followcomment" [(ngModel)]="f_obj['followcomment']" style="resize: none;" [disabled]="isUpdating"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-12 ha-ibox">
                                                <div class="form-group" style="text-align: right;">
                                                    <button type="button" [ladda]="isUpdating" data-style="expand-left" class="btn btn-custom-003d6a" style="color: #ffffff;" (click)="saveFollowup(f_obj)">
                                                        <i class="fas fa-plus m-r-10"></i>{{'add' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div *ngIf="loadingFollow" class="common-loader">
                            <i class="fas fa-sync-alt fa-spin fa-3x" style="color: gray"></i>
                        </div>

                        <!-- comments row -->
                        <div *ngIf="!loadingFollow" class="row comments-list">

                            <!-- begin follow comments -->
                            <div class="col-12">
                                <div class="row title-row">
                                    <div class="col-12">{{'samp_followcomments' | translate}}</div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>{{'comment' | translate}}</th>
                                                <th>{{'date' | translate}}</th>
                                                <th>{{'action' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let r of f_obj['history']" [ngClass]="{'selected-row-followup':r['logIsRecommendation']}">
                                                <td>{{r.logText}}</td>
                                                <td>{{r.logCreationTime | date:'y-MM-dd H:mm:ss'}}</td>
                                                <td>
                                                    <i (click)="deleteFollowup({logId:r.logId})" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- end follow comments -->

                        </div>
                        <!-- end row comments -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="follow1.dismiss('Cross click')">{{'close' | translate}}</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
