<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th (click)="setOrder('cmpID')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='cmpID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('cmpName')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='cmpName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('cmpM3_ID')" class="sh">
                                    {{'site_m3' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='cmpM3_ID')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'cmp_is360' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                                  <!--<th (click)="setOrder('cmpMail')" class="sh">
                                    {{'email' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='cmpMail')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>-->
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc" [ngClass]="{'disabled-row': !row.usrActive}"> -->
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc" [ngClass]="{'selected-row':row['cmpIs360']}">
                                <td>{{row.cmpID}}</td>
                                <td>{{row.cmpName}}</td>
                                <td>{{row.cmpM3_ID}}</td>
                                <!--<td>{{row.cmpMail}}</td>-->
                                <td>{{ ( (row.cmpIs360) ? 'yes' : 'no') | translate }}</td>
                                <td class="action-buttons">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['cmpName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['cmpName']" name="cmpName" disabled="true">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'email' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['cmpMail']" name="cmpMail" disabled="true">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'site_m3' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['cmpM3_ID']" name="cmpM3_ID" required maxlength="20">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_active' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md input-group" name="cmpActive" [(ngModel)]="u_obj['cmpActive']" disabled="true">
                                                <option value="true">{{'yes' | translate}}</option>
                                                <option value="false">{{'no' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'cmp_is360' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md input-group" name="cmpIs360" [(ngModel)]="u_obj['cmpIs360']">
                                                <option value="true">{{'yes' | translate}}</option>
                                                <option value="false">{{'no' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'cmp_mails_recep' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['cmpSendEmailsReports']" name="cmpSendEmailsReports">
                                        <label>{{'cmp_email_legend' | translate}}</label>
                                    </div>
                                </div>-->
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'cmp_area' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="u_obj['cmpArea']" name="cmpArea" >
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Update</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
