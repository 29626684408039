import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';
import {environment} from '../../../../environments/environment';
import {from, of} from 'rxjs';
import {ApiService} from '../../../services/main/api.service';

@Component({
  selector: 'app-formshome',
  templateUrl: './formshome.component.html',
  styleUrls: ['./formshome.component.css']
})
export class FormshomeComponent implements OnInit {

  	translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    items:any;
    permissions = [];

  	constructor(
  		public _router: Router,
      public translate: TranslateService,
      public auth: LoginService,public api: ApiService
  	) { }

  	ngOnInit() {
        this.items = [
          {title: 'menu_encuesta', url: '/pdi', icon: 'mdi mdi-adjust', perm: 'HA_PATHOLOGICAL_ANALYSIS'},
          //{title: 'menu_pdiallcompany', url: '/pdiallcompany', icon: 'mdi mdi-adjust', perm: 'HA_PATHOLOGICAL_ANALYSIS'},
          {title: 'menu_sampling', url: '/sampling', icon: 'mdi mdi-adjust', perm: 'HA_SAMPLING'},
          //{title: 'menu_samplingallusers', url: '/samplingallusers', icon: 'mdi mdi-adjust', perm: 'HA_SAMPLING'}
        ];

        this.auth.getPermissions().then(x => {
            this.permissions = x;
        });
      if( navigator.onLine ){
        this.translate.getTranslation(this.translate.currentLang)
          .subscribe((translations) => {
            this.translations = translations;
            this.getVersion();
          });
      }
  	}

    hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }
  public getVersion(){
    var ent_url = 'SystemParameter/GetVersion/'+environment.version+'/';
    return this.api.getCall(ent_url).subscribe(data => {
        let mydata:any = {success: false, msg:'', val:[]};
        mydata = data;
        if(mydata.success == true ){
          return from(this.api.persistData('appversion', mydata.val.value));
        }
        else{
          return of(false);
        }
      });
  }
}
