import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'menu_home',
    icon: 'icon icon-home',
    class: '',
    extralink: false,
    perm: 'home',
    submenu: []
  },
  {
    path: '/formshome',
    title: 'menu_forms',
    icon: 'icon icon-note',
    class: '',
    extralink: false,
    perm: 'HA_FORM',
    submenu: []
  },
  {
    path: '/prochome',
    title: 'menu_processes',
    icon: 'icon icon-chemistry',
    class: '',
    extralink: false,
    perm: 'HA_PROC',
    submenu: []
  },
  {
    path: '/cruds',
    title: 'menu_crud',
    icon: 'icon icon-globe',
    class: '',
    extralink: false,
    perm: 'HA_MANT',
    submenu: []
  },
  {
    path: '/settings',
    title: 'menu_general',
    icon: 'icon icon-settings',
    class: '',
    extralink: false,
    perm: 'sett',
    submenu: []
  }
];
