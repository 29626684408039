<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn btn-custom-003d6a" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingList"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-info text-white">
                            <tr>
                                <th (click)="setOrder('parCode')" class="sh">
                                    {{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='parCode')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('parName')" class="sh">
                                    {{'Opciones' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='parName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'Respuesta' | translate}}</th>
                                <!--<th>{{'type' | translate}}</th>-->
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc" [ngClass]="{'selected-row':row['parShowInReport']}">
                                <td>{{row.PreguntaDetalleId}}</td>
                                <td>{{row.Descripcion}}</td>
                              <td>{{row['EsCorrecta']? translate.instant('yes'):translate.instant('no')}}</td>
                                <!--<td>{{((row['type_obj'] != undefined) ? row['type_obj']['name'] : '') | translate}}</td>-->
                                <td class="action-buttons">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="deleteItem({parId:row.PreguntaDetalleId})" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="5">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <!-- create dialog -->
                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'Pregunta' | translate}}: {{c_obj['Pregunta']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <!--<div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['parCode']" name="anKey" required maxlength="10">
                                    </div>
                                </div>-->

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['Descripcion']" name="anName" required>
                                    </div>
                                </div>
                                <!--<div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'order' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="c_obj['parSequence']" name="parSeq" required>
                                    </div>
                                </div>-->
                                <!--<div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'type' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="anGroupId" [(ngModel)]="c_obj['type']" required>
                                                <option *ngFor="let t of types" [value]="t['id']">{{t['name'] | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>-->
                                <div class="form-group row" *ngIf="c_obj['type'] == 2">
                                    <label class="col-3 text-right control-label col-form-label">{{'value' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="c_obj['value_2']" name="parval">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="c_obj['type'] == 3">
                                    <label class="col-3 text-right control-label col-form-label">{{'formula' | translate}}</label>
                                    <div class="col-7">
                                        <div class="form-control showlabel" (click)="openFormulaEditor(formula1)">
                                            <div>
                                                {{c_obj['value_3']}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action-buttons-big col-2">
                                        <i (click)="openFormulaEditor(formula1)" style="cursor:pointer;" class="icon-calculator text-info m-r-10" title="{{'par_edit_formula_input' | translate}}"></i>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="c_obj['type'] == 4">
                                    <label class="col-3 text-right control-label col-form-label">{{'range' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['value_4']" name="parval">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'Correcta' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="anreq" [(ngModel)]="c_obj['EsCorrecta']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'editable' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="anedit" [(ngModel)]="c_obj['parIsEditable']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'par_showinrep' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="parShowInReport" [(ngModel)]="c_obj['parShowInReport']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'par_showingraph' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="parShowInGraphic" [(ngModel)]="c_obj['parShowInGraphic']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'par_hideinput' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="parHidden" [(ngModel)]="c_obj['parHidden']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="maxrange" class="col-3 text-right control-label col-form-label">{{'max_range' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['parRangeMax']" name="parRangeMax" maxlength="10">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="minrange" class="col-3 text-right control-label col-form-label">{{'min_range' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['parRangeMin']" name="parRangeMin" maxlength="10">
                                    </div>
                                </div>-->
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>
                <!-- create dialog -->

                <!-- update dialog -->
                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'Pregunta' | translate}}: {{c_obj['Pregunta']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <!--<div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['parCode']" name="anKey" required maxlength="10">
                                    </div>
                                </div>-->

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['Descripcion']" name="anName" required>
                                    </div>
                                </div>
                                <!--<div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'order' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="c_obj['parSequence']" name="parSeq" required>
                                    </div>
                                </div>-->
                                <!--<div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'type' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="anGroupId" [(ngModel)]="c_obj['type']" required>
                                                <option *ngFor="let t of types" [value]="t['id']">{{t['name'] | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>-->
                                <div class="form-group row" *ngIf="c_obj['type'] == 2">
                                    <label class="col-3 text-right control-label col-form-label">{{'value' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="c_obj['value_2']" name="parval">
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="c_obj['type'] == 3">
                                    <label class="col-3 text-right control-label col-form-label">{{'formula' | translate}}</label>
                                    <div class="col-7">
                                        <div class="form-control showlabel" (click)="openFormulaEditor(formula1)">
                                            <div>
                                                {{c_obj['value_3']}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="action-buttons-big col-2">
                                        <i (click)="openFormulaEditor(formula1)" style="cursor:pointer;" class="icon-calculator text-info m-r-10" title="{{'par_edit_formula_input' | translate}}"></i>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="c_obj['type'] == 4">
                                    <label class="col-3 text-right control-label col-form-label">{{'range' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['value_4']" name="parval">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'Correcta' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="anreq" [(ngModel)]="c_obj['EsCorrecta']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'editable' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="anedit" [(ngModel)]="c_obj['parIsEditable']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'par_showinrep' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="parShowInReport" [(ngModel)]="c_obj['parShowInReport']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'par_showingraph' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="parShowInGraphic" [(ngModel)]="c_obj['parShowInGraphic']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'par_hideinput' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="parHidden" [(ngModel)]="c_obj['parHidden']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="maxrange" class="col-3 text-right control-label col-form-label">{{'max_range' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['parRangeMax']" name="parRangeMax" maxlength="10">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="minrange" class="col-3 text-right control-label col-form-label">{{'min_range' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['parRangeMin']" name="parRangeMin" maxlength="10">
                                    </div>
                                </div>-->
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, c_obj)">{{'update' | translate}}</button>
                    </div>
                </ng-template>
                <!-- update dialog -->

                <ng-template #formula1 let-modal>
                    <div>
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Editor de f&oacute;rmula</h5>
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body" style="background: #eef5f9;">
                                <div class="container">
                                    <div class="row">
                                        <!-- start params -->
                                        <div class="col-md-5 col-12">
                                            <div class="card">
                                                <div class="card-body formula-buttons">
                                                    <h4 class="card-title">Params</h4>
                                                    <div class="formula-paramlist">
                                                        <div *ngFor="let row of rows" placement="top" ngbPopover="{{row.Formula}}" popoverTitle="{{row.parName}}" triggers="mouseenter:mouseleave" (click)="addParamToFormula(row)" class="form-control param-item">{{row.parCode}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- end params -->
                                        <div class="col-md-7 col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="card-body formula-buttons">
                                                            <h4 class="card-title">Operators</h4>
                                                            <button *ngFor="let o of operands" type="button" class="btn btn-circle btn-success" (click)="addOperandToFormula(o)">{{o}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card">
                                                        <div class="card-body formula-buttons">
                                                            <h4 class="card-title">Numbers</h4>
                                                            <button *ngFor="let n of numbers" type="button" class="btn btn-outline-success" (click)="addValueToFormula(n)">{{n}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- results -->
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="card-body formula-buttons">
                                                    <h4 class="card-title">Result</h4>
                                                    <span *ngFor="let r of c_obj['form'];let i = index">
                                                        <button *ngIf="r.param" type="button" [ngClass]="{'btn btn-rounded btn-outline-primary':true, 'btn-danger':r.sel}" placement="top" ngbPopover="{{r.msg}}" popoverTitle="{{r.name}}" triggers="mouseenter:mouseleave" (click)="selItemFormula(i)">{{r.val}}</button>
                                                        <button *ngIf="r.number" type="button" [ngClass]="{'btn btn-outline-success':true, 'btn-danger':r.sel}" (click)="selItemFormula(i)">{{r.val}}</button>
                                                        <button *ngIf="!r.number && !r.param" type="button" [ngClass]="{'btn btn-circle btn-success':true, 'btn-danger':r.sel}" (click)="selItemFormula(i)">{{r.val}}</button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    {{c_obj['value_3']}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12" style="text-align: right;">
                                            <button type="button" class="btn btn-outline-danger" (click)="resetFormula()">Reset</button>
                                            <button type="button" class="btn btn-outline-danger" (click)="deleteItemFormula()">Borrar</button>
                                            <button type="button" class="btn btn-success" (click)="modal.dismiss('Cross click')">Aceptar</button>
                                        </div>
                                    </div>
                                    <!-- results -->
                                </div>
                                <!-- end container -->
                            </div>
                        </div>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
