import { Injectable } from '@angular/core';
import { PdfMakeWrapper, Txt, Stack, Columns, Table, Cell, Img } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
PdfMakeWrapper.setFonts(pdfFonts);
@Injectable({
  providedIn: 'root'
})
export class PdfestructureService {

  constructor() { }


  async getStructurePdf (info, header_detail): Promise<PdfMakeWrapper> {
    const pdf = new PdfMakeWrapper();
    pdf.info({
      title: info.title,
      author: info.autor,
      subject: info.subject,
    });

    pdf.defaultStyle({
      bold: false,
      fontSize: 10,
      alignment:'justify'
    });

    // pdf.header(
    //   new Table(
    //     [
    //       [
    //         new Cell(await new Img('../../../../assets/images/logo-skretting.png').width('160').build()).end,
    //       ]
    //     ]
    //   )
    //     .margin([0,30,30,0])
    //     .alignment('right')
    //     .layout('noBorders')
    //     .widths('*')
    //     .end,
    // );

    // pdf.header(
    //   new Stack(
    //     [
    //       new Cell(await new Img('../../../../assets/images/logo-skretting.png').width('160').build()).end,
    //     ]
    //   )
    //   .margin([0,30,30,0])
    //   .alignment('right')
    //   .end
    // );

    pdf.background(
      [
        await new Img('../../../../assets/images/logo-skretting.png').absolutePosition(405, 35).width(160).build(),
        await new Img('../../../../assets/images/footer-skretting.png').absolutePosition(0, 770).width(600).build()
      ]
    )
    pdf.pageMargins([ 35, 110, 35, 110]);
    pdf.footer( function(currentPage:any, pageCount:any) {
      return new Stack(
        [
          new Cell(new Txt('Skretting Ecuador | Km 4.5 & 6.5 vía Durán - Tambo. Durán - Ecuador | Telf: + 593 4 2598100 + 593 4 2815737').fontSize(8).color('gray').alignment('center').margin([0,10,0,0]).end).end,
          new Cell(new Txt('www.skretting.ec').link('https://www.skretting.com/es-ec/').fontSize(8).color('gray').alignment('center').end).end,
          new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).bold().alignment('right').fontSize(9).color('white').margin([0, 40,20, 0]).end).end
        ]
      ).end
    });

    pdf.add(
      [
        new Txt([new Txt('Preparado por: ').bold().fontSize(7).end,new Txt(header_detail.name).italics().fontSize(7).end]).end,
        new Txt([new Txt('Referencia Interna: ').bold().fontSize(7).end,new Txt(header_detail.reference).italics().fontSize(7).end]).end,
        new Txt([new Txt('Fecha de Llegada: ').bold().fontSize(7).end,new Txt(header_detail.date_init).italics().fontSize(7).end]).end,
        new Txt([new Txt('Fecha de envío: ').bold().fontSize(7).end,new Txt(header_detail.date_end).italics().fontSize(7).end]).end,
        new Stack([
          new Txt(header_detail.title).margin([0,10,0,0]).fontSize(11).bold().alignment('center').end,
          new Txt(header_detail.subtitle).fontSize(11).alignment('center').bold().end,
        ]).end,
      ]
    )
    return pdf;
  }
}
