import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router } from '@angular/router';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { OfflineContainer } from '../offlinecontainer';

const AQ_BASE_URL = 'https://aquasim.skretting.com/';
const IC2_BASE_URL = 'http://13.90.229.9/api/';
//max time for api requests
const MAX_TIME = 180000;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
	url:any;
	public offline = new BehaviorSubject(false);

  	constructor(
  		private _router: Router,
  		private http: HttpClient,
  		public storage: Storage
  	) {
  		//this.url = IC2_BASE_URL;
  		this.url = Global.url;
  	}

  	public getCall(qst) {
		//console.log('get call');
		var resp = {success:false, msg:'', val:[]};
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`});
	    			return this.http.get(`${this.url}`+ qst, { headers })
	    				.pipe(
					  		mergeMap(data => {
		        				let mydata:any = {STATUS: '', OBJETO: [], MENSAJE: ''};
					      		mydata = data;

					      		if( mydata.STATUS == 'success' ){
					      			//return of(mydata.OBJETO);
					      			resp.success = true;
					      		}

					      		resp.msg = mydata.MENSAJE;
					      		resp.val = mydata.OBJETO;

					      		return of(resp);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
		        				resp['msg'] = 'system error';
					            return of(resp);
					        })
					  );
				})
			);
	}

	public postCall(qst, obj) {
		//console.log('cool post call');
		var resp = {success:false, msg:'', val:[]};
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`});
					//console.log('before post');
	    			return this.http.post(`${this.url}`+qst, obj, { headers })
	    				.pipe(
					  		mergeMap(data => {
		        				let mydata:any = {STATUS: '', OBJETO: [], MENSAJE: ''};
					      		mydata = data;

					      		if( mydata.STATUS == 'success' ){
					      			//return of(mydata.OBJETO);
					      			resp.success = true;
					      		}

					      		resp.msg = mydata.MENSAJE;
					      		resp.val = mydata.OBJETO;

					      		return of(resp);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
		        				resp['msg'] = 'system error';
					            return of(resp);
					        })
					  );
				})
			);
	}

	public persistData(key:string, val:any){
		return this.storage.set(key, val);
	}
  public clearData(){
    return this.storage.clear();
  }
	public getPeristedData(key:string){
		return this.storage.get(key);
	}
  getServerVersion(){
    return this.storage.get('appversion');
  }
	public getBlobCall(qst) {
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}`});
	    			return this.http.get(`${this.url}`+ qst, { headers, responseType: 'blob' })
	    				.pipe(
					  		mergeMap(data => {
					  			//console.log(data);
					      		return of(data);
					      		//return of(true);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
					            return of(false);
					        })
					  );
				})
			);
	}

	public postBlobCall(qst, to) {
		return from(this.storage.get('token')).pipe(
				mergeMap(token => {
					const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}`});
	    			return this.http.post(`${this.url}`+ qst, to, { headers, responseType: 'blob' })
	    				.pipe(
					  		mergeMap(data => {
					  			//console.log(data);
					      		return of(data);
					      		//return of(true);
		        			}),
		        			timeout(MAX_TIME),
		        			catchError(err => {
					            return of(false);
					        })
					  );
				})
			);
	}

	public saveOfflineData(key:string, obj:any){
		let tmp = new OfflineContainer();
		tmp.setData(obj);
		return this.persistData(key, tmp);
	}

	public getOfflineData(key:string){
		return this.getPeristedData(key).then(x => {
			if( (x == undefined) || (x == null) ){
				return null;
			}
			else{
				let tmp = new OfflineContainer();
				tmp.data = x.data;
				tmp.last_update = x.last_update;
				return tmp;
			}

		});
	}

	public isReachable() {
		/**
		   * Note: fetch() still "succeeds" for 404s on subdirectories,
		   * which is ok when only testing for domain reachability.
		   *
		   * Example:
		   *   https://google.com/noexist does not throw
		   *   https://noexist.com/noexist does throw
		*/
		//var url = 'https://google.com';
		var url = 'https://ha.skretting360.com/';
		return fetch(url, { method: 'HEAD', mode: 'no-cors' })
		    .then(function(resp) {
		      return resp && (resp.ok || resp.type === 'opaque');
		    })
		    .catch(function(err) {
		      console.warn('[conn test failure]:', err);
		    });
	}
  AddFiles(url2,data: FormData): Observable < string >
  {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const httpOptions = { headers: headers };
    return this.http.post < string > (this.url + url2, data, httpOptions);
  }
}
