import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import * as fileSaver from 'file-saver';

import {Itemag} from './itemag.model';


@Component({
  selector: 'app-samplingall',
  templateUrl: './samplingall.component.html',
  styleUrls: ['./samplingall.component.css']
})
export class SamplingallComponent extends BaseCrudComponent {

 translations:any;
	fields:any;
    loadingRequired = false;
    loadingFollow = false;
    agitems:any;
    rawitems:any;
    groupselect:any;

    raw_companies:any;
    raw_sites:any;
    raw_units:any;
    raw_spots:any;

    companies:any;
    sites:any;
    units:any;
    spots:any;
    sample_types:any;

    isDownloading = 0;
    actions:any;

    f_obj:any;
    all_status:any;
    pcr_filters:any;
    ground_filters:any;

    loctypes:any;
    //raw_spots
    //raw_locations:any;
    //spots
    //locations:any;

    offline_key = 'SAMP_OFFLINE';
    offline_key_list = 'SAMPLING';
    offline_key_agr = 'SAMP_AGR_FLOW';
    offline_key_stypes = 'SAMP_TYPES';
    offline_key_loc = 'SAMP_LOCATIONS';
    offline_items = [];

	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.create_url = '';
        this.read_url = 'Sampling/AllUsersPermissions';
        this.update_url = '';
        this.delete_url = 'Sampling/DeleteLogical';

        this.fields = [];
        this.rawitems = [];
        this.agitems = [];
        this.groupselect = [];
        this.f_obj = {};

        this.c_obj['ag_obj'] = new Itemag(0, '');
        this.search_fields = ['cmpName', 'sitName', 'UserTechnicalName', 'sampId'];

        this.reload.subscribe(val => {
            if( val ){
                this.clearData();
                this.getList();
            }
        });

        this.fillData();

        this.loadingRequired = true;
        this.getSampleLocationAllBelongAP().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.loctypes = [];
                this.raw_spots = [];
                this.spots = [];

                x.forEach(sl => {
                    let tmp = {id: sl.slocId, name:sl.slocName};
                    this.loctypes.push(tmp);

                    if(Array.isArray(sl.SampleLocationInstance)){
                        sl.SampleLocationInstance.forEach(y => {
                            //this.raw_locations.push();
                            this.raw_spots.push({id:y.slinId, name:y.slinName, sitid:y.slinSiteId, loctype:sl.slocId});
                        });
                    }
                });
            }
        });

        this.all_status = Global.sampling_status;

        this.srv.offline.subscribe(r => {
            this.isOffline = r;
        });

        this.isOffline = false;
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

              this.loadingRequired = true;
              this.getAnalysisGroupData().subscribe(x => {
                  //console.log(x);
                  if(x != false){
                      this.loadingRequired = false;
                      this.rawitems = x;
                      this.parseAnalysisGroupData(x);

                      this.loadingRequired = true;
                      this.getSampleTypes().subscribe(st=>{
                          this.loadingRequired = false;
                      });
                  }
              });

              this.getList();
        });
    }

    public clearData(){
        this.rows = [];
        this.u_obj = {};
    }

    fillData(){
        this.raw_companies = [];
        this.raw_sites = [];
        this.raw_units = [];
        this.raw_spots = [];
        this.companies = [];
        this.sites = [];
        this.units = [];
        this.spots = [];
        this.sample_types = [];
        this.pcr_filters = [];
        this.ground_filters = [];

        this.storage.get('companies').then(x => {
            x.forEach(c => {
                this.raw_companies.push({id:c.CompAquasymId, name: c.cmpName});
                c.Site.forEach(s => {
                    this.raw_sites.push({id:s.SitAquasymId, name: s.sitName, sector:s.SkrettingSectorName, cmpid:c.CompAquasymId});
                    s.Unit.forEach(u => {
                        this.raw_units.push({id:u.UniAquasimId, name: u.uniName, sitid:s.SitAquasymId, isActive:u.unitActive});
                    });

                    s.SampleLocationInstance.forEach(sp => {
                        //this.raw_spots.push({id:sp.slinId, name: sp.slinName, sitid:s.SitAquasymId});
                    });
                });
            });

            this.companies = this.copyObject(this.raw_companies);
        });

        this.actions = {};
        Global.sampling_status.forEach(s => {
            this.actions[s.name] = s.samp_actions;
        });

        var opts = ['ProductTypePCR', 'PresentationPCR', 'ConditionSamplePCR', 'EnvironmentTemperaturePCR', 'RHPCR'];

        this.storage.get('common').then(data => {
            data.forEach(x => {
                if( opts.indexOf(x.Name) > -1 ){
                    this.pcr_filters[x.Name] = x.Values;
                }
            });
        });


        // Jenny tomar en cuenta esto para Suelo
        var opts2 = ['sampDepth'];

        this.storage.get('common').then(data => {
                data.forEach(x => {
                if( opts2.indexOf(x.Name) > -1 ){
                    this.ground_filters[x.Name] = x.Values;//.ground_filters[x.Name] = x.Values;
                }
            });
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( this.f_datefrom != null ){
            tosend['_dateStart'] = this.getISODate(this.f_datefrom);
        }

        if( this.f_dateto != null ){
            tosend['_dateEnd'] = this.getISODate(this.f_dateto);
        }

        if( this.f_custom1 != null ){
            tosend['_status'] = [parseInt(this.f_custom1)];
        }

        //show offline list
        if(this.isOffline){
            this.srv.getOfflineData(this.offline_key_list).then(x => {
                //console.log(x);
                this.rows = x.data;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.rows.forEach(r => {
                    r['actions'] = this.getActionsByStatusName(r['StatusName']);
                    r['estimatedDelivery'] = r['sampEstimatedDeliveryDateStart'];

                    if( r['estimatedDelivery'] != null ){
                        r['estimatedDelivery'] = r['estimatedDelivery'].substring(0, 10);
                    }

                    if( r['sampEstimatedDeliveryDateStart'] != r['sampEstimatedDeliveryDateEnd'] ){
                        if( r['sampEstimatedDeliveryDateEnd'] != null ){
                            r['estimatedDelivery'] += ' - ' + r['sampEstimatedDeliveryDateEnd'].substring(0, 10);
                        }
                    }
                });
            });
        }
        else{
            this.loadingList = true;
            this.srv.postCall(qst, tosend).subscribe(x => {
                this.loadingList = false;
                if(x.success){
                    this.rawdata = x.val;
                    //save offline data
                    this.srv.saveOfflineData(this.offline_key_list, this.rawdata);

                    this.rows = x.val;
                    this.page = 1;

                    if( Array.isArray(this.rows) ){
                        this.pageTotal = this.rows.length;
                    }

                    this.rows.forEach(r => {
                        r['actions'] = this.getActionsByStatusName(r['StatusName']);
                        r['estimatedDelivery'] = r['sampEstimatedDeliveryDateStart'];

                        if( r['estimatedDelivery'] != null ){
                            r['estimatedDelivery'] = r['estimatedDelivery'].substring(0, 10);
                        }

                        if( r['sampEstimatedDeliveryDateStart'] != r['sampEstimatedDeliveryDateEnd'] ){
                            if( r['sampEstimatedDeliveryDateEnd'] != null ){
                                r['estimatedDelivery'] += ' - ' + r['sampEstimatedDeliveryDateEnd'].substring(0, 10);
                            }
                        }
                    });

                    this.filterAll();
                }
            });
        }

        //load unsaved items
        this.getOfflineItems();
    }

    public getActionsByStatusName(st:any){
        let rs:any;

        if( this.actions[st] != undefined ){
            rs = this.actions[st];
        }
        else{
            rs = [];
        }

        return rs;
    }

    public filterSites(cmpid:any){
        /*console.log('cool');
        console.log(cmpid);*/
        var resp:boolean;
        this.resetCompanySelections();

        if( cmpid != '' ){
            let test = this.raw_sites.filter(s => {
                return s.cmpid == cmpid;
            });

            this.sites = test;
        }
        else{
          this.sites = [];
        }
    }

    public filterUnits(id:any){
        var resp:boolean;
        this.resetSiteSelections();

        if( id != '' ){
            let test = this.raw_units.filter(s => {
                return s.sitid == id;
            });

            this.units = test;
        }
        else{
          this.units = [];
        }
    }

    public filterActiveUnits(status:any, siteId:number){
        let comp = true;

        if( (status == undefined) || (siteId == undefined) ){
            return;
        }

        if( status < 0 ){
            let test = this.raw_units.filter(s => {
                return (s.sitid == siteId);
            });

            this.units = test;
        }
        else{
            if(status > 0){
                comp = true;
            }
            else{
                comp = false;
            }

            let test = this.raw_units.filter(s => {
                if (s.isActive==null)
                {
                   s.isActive=false;
                }
                return (s.isActive == comp) && (s.sitid == siteId);
            });

            this.units = test;
        }
    }

    public filterLocations(loctype:any, siteid:any){
        var resp:boolean;
        console.log(loctype);
        console.log(siteid);
        console.log(this.raw_spots);

        if( (siteid != undefined) && (siteid != '') && (loctype > -1) ){
            this.spots = [];
            let test = this.raw_spots.filter(s => {
                return ( (s.sitid == siteid) && (s.loctype == loctype) );
            });

            this.spots = test;
        }
        else{
            this.spots = [];
        }
    }

    public filterAnalysisGroup(id:any, update?:any){
        var resp:boolean;
        var obj:any;

        if( update != undefined ){
            obj = this.u_obj;
        }
        else{
            obj = this.c_obj;
        }

        if( id != '' ){
            let test = this.agitems.filter(s => {
                return s.sampAnalysisGroupId == id;
            });

            if( test.length > 0 ){
                obj['ag_obj'] = test[0];
                obj['ag_obj'].recalculateGroupFeatures();
                obj['ag_obj'].recalculateAnalysisFeatures();
            }
            else{
                obj['ag_obj'] = new Itemag(0, '');
            }
        }
        else{
          obj['ag_obj'] = new Itemag(0, '');
        }

        //console.log(obj['ag_obj']);
    }

    public resetAGItems(){
        this.agitems.forEach(x => {
            //console.log(x);
            x.group_features.forEach(i => {
                i.sel = false;
            });

            x.analysis.forEach(i => {
                i.sel = false;
                i.reps = 1;
                i['comment'] = '';

                if( (i['feat'] != undefined) && Array.isArray(i['feat']) ){
                    i['feat'].forEach(j => {
                        j.sel = false;
                    });
                }
            });
        });
    }

    public openCreateModal(content: any) {
        this.c_obj = {};
        this.c_obj['date'] = new Date();
        this.c_obj['date'] = this.c_obj['date'].toISOString();
        this.c_obj['date'] = this.c_obj['date'].substring(0, 10);
        this.c_obj['ag_obj'] = new Itemag(0, '');
        this.resetAGItems();
        //this.modalService.open(content, { size: 'lg' });
        this.modalService.open(content, { backdrop: 'static', keyboard: false });
    }

    public getAnalysisGroupData(){
        var par_url = 'AnalysisGroup/GetAnalysisFlow';

        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_agr).then(x => {
                if( x != null ){
                    return x.data;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);

                        if(mydata.success == true ){
                            this.srv.saveOfflineData(this.offline_key_agr, mydata.val);
                            return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
        }
    }

    parseAnalysisGroupData(items:any){
        var tmp:any;
        var inn:any;

        items.forEach(x => {
            this.groupselect.push({id:x['agrId'], name: x['agrName']});

            tmp = new Itemag(x['agrId'], x['agrName']);
            tmp.MinFeatures = x['MinFeatures'];

            //group features
            x['AnalysisGroupFeature'].forEach(gf => {
                tmp.group_features.push({id: gf['agfId'], name: gf['agfName'], sel: false});
            });

            //analysis items
            x['Analysis'].forEach(a => {
                inn =
                {
                    id: a['anId'],
                    name: a['anName'],
                    tooltip: a['anTooltip'],
                    MinFeatures: a['MinFeatures'],
                    MaxFeatures: 0,
                    SelFeatures: 0,
                    featColor: 'danger',
                    sel: false,
                    special: false,
                    comment: '',
                    feat: [],
                    reps: 1
                }

                if( (a['AnalysisFeature'] != undefined) && (a['AnalysisFeature'].length > 0) ){
                    a['AnalysisFeature'].forEach(af => {
                        inn.feat.push({id:af['anfId'], name:af['anfName'], sel: false});
                    });
                }

                if( a['anShowInSampling'] === true ){
                    tmp.analysis.push(inn);
                }

            });

            //special analysis items
            x['AnalysisSpecials'].forEach(a => {
                inn =
                {
                    id: a['anId'],
                    name: a['anName'],
                    tooltip: a['anTooltip'],
                    MinFeatures: a['MinFeatures'],
                    sel: false,
                    special: true,
                    comment: '',
                    feat: []
                }

                if( (a['AnalysisFeature'] != undefined) && (a['AnalysisFeature'].length > 0) ){
                    a['AnalysisFeature'].forEach(af => {
                        inn.feat.push({id:af['anfId'], name:af['anfName'], sel: false});
                    });
                }

                //tmp.analysis.push(inn);

                if( a['anShowInSampling'] === true ){
                    tmp.analysis.push(inn);
                }

            });

            this.agitems.push(tmp);
        });

        //console.log(this.agitems);
    }

    public getSampleTypes(){
        let url = 'SampleType/All';

        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_stypes).then(x => {
                if( x != null ){
                    this.sample_types = x.data;
                    return true;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;

                        if(mydata.success == true ){
                          this.sample_types = mydata.val;
                          this.srv.saveOfflineData(this.offline_key_stypes, mydata.val);
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
        }
    }

    validateCreateInfo(fields:any){
        let resp:any = {
            status: true,
            msg: ''
        };

        let mygroup:any = {};
        mygroup = new FormGroup({
            'CompAquasymId': new FormControl(fields.CompAquasymId, [Validators.required]),
            'SitAquasymId': new FormControl(fields.SitAquasymId, [Validators.required]),
            'date': new FormControl(fields.date, [Validators.required]),
            'stypeID': new FormControl(fields.stypeID, [Validators.required]),
            'loctype': new FormControl(fields.loctype, [Validators.required]),
            'agid': new FormControl(fields.agid, [Validators.required]),
            'sampSampleQuantity': new FormControl(fields.sampSampleQuantity, [Validators.required, Validators.min(1)]),
            'sampContainer': new FormControl(fields.sampContainer, [Validators.required])
        });

        if( fields.loctype == -1 ){
            //unit must be selected
            mygroup.addControl('unit_id', new FormControl(fields.unit_id, [Validators.required]));
        }
        else{
            //spot must be selected
            mygroup.addControl('spot_id', new FormControl(fields.spot_id, [Validators.required]));
        }

        if(!mygroup.valid){
            resp.status = false;
            resp.msg = this.translations.msg_all_required + ' (*)';
            return resp;
        }

        if( fields['ag_obj'].MinFeatures > fields['ag_obj'].SelFeatures ){
            resp.status = false;
            resp.msg = this.translations.samp_error_groupfeat_minrequired;
            return resp;
        }

        let selcount = 0;
        fields['ag_obj'].analysis.forEach(x => {
            if( x['sel'] ){
                selcount++;
                //console.log(selcount);
                if( x['MinFeatures'] > x['SelFeatures'] ){
                    resp.status = false;
                    resp.msg = this.translations.samp_error_analysisfeat_minrequired;
                }

                if( x['special'] && ( (x['comment'] == undefined) || (x['comment'] == '') ) ){
                    resp.status = false;
                    resp.msg = this.translations.samp_error_analysis_commentreq;
                }

                if( parseInt(x['reps']) < 1 ){
                    resp.status = false;
                    resp.msg = this.translations.samp_error_analysis_minreps;
                }
            }
        });

        if( selcount == 0 ){
            resp.status = false;
            resp.msg = this.translations.samp_error_analysis_minqty;
        }

        return resp;
    }

    createItem(content:any, obj:any, multi?:any){
        let validated:any;

        if( multi == undefined ){
            multi = false;
        }

        validated = this.validateCreateInfo(obj);
        if( !validated['status'] ){
            Swal.fire(validated['msg'], '', 'error');
            return;
        }

        if(this.isOffline){
            this.createItemLocal(obj, content, multi);
        }
        else{
            this.isCreating = true;
            this.createItemRemote(obj).subscribe(x => {
                //console.log(x);
                this.isCreating = false;

                if( x['success'] == true ){
                    this.reload.next(true);
                    Swal.fire(this.translations.created, '', 'success');

                    if( (obj.off_id != undefined) && (obj.off_id != null) ){
                        //delete local entry
                        this.deleteItemLocal(obj, false);
                    }

                    if( !multi ){
                        this.closeModal(content);
                    }
                    else{
                        obj['agid'] = null;
                        obj['ag_obj'] = new Itemag(0, '');
                        this.resetAGItems();
                        //console.log(this.c_obj);
                    }
                }
                else{
                    if( x['msg'] != '' ){
                        Swal.fire(this.translations.error_creating, x['msg'], 'error');
                    }
                    else{
                        Swal.fire(this.translations.error_creating, '', 'error');
                    }
                }
            });
        }
    }

    public createItemRemote(obj:any){
        var url = 'Sampling/Create';
        var to:any;
        var tmp:any;

        //console.log(obj);

        to = {};
        to.sampDate = obj['date'];
        to.sampUnitAquasimId = (obj['loctype'] == -1) ? obj['unit_id'] : null;
        to.sampSampleLocationInstanceId = (obj['loctype'] > -1) ? obj['spot_id'] : null;
        to.sampObservation = obj['comment'];
        to.sampAnalysisGroupId = obj['agid'];
        to.SamplingAnalysisGroupFeaturesList = null;
        to.SamplingDetail = null;
        to.sampSampleTypeId = obj['stypeID'];
        to.sampContainer = obj['sampContainer'];
        to.sampSampleQuantity = obj['sampSampleQuantity'];
        to.sampRequestedByClient = obj['sampRequestedByClient'];

        //PCR additional params
        to.sampCode = (obj['sampCode'] != undefined) ? obj['sampCode'] : null;
        to.sampProductType = (obj['sampProductType'] != undefined) ? obj['sampProductType'] : null;
        to.sampReferenceNumber = (obj['sampReferenceNumber'] != undefined) ? obj['sampReferenceNumber'] : null;
        to.sampBatchCode = (obj['sampBatchCode'] != undefined) ? obj['sampBatchCode'] : null;
        to.sampPresentation = (obj['sampPresentation'] != undefined) ? obj['sampPresentation'] : null;
        to.sampConditionSample = (obj['sampConditionSample'] != undefined) ? obj['sampConditionSample'] : null;
        to.sampEnvironmentTemperature = (obj['sampEnvironmentTemperature'] != undefined) ? obj['sampEnvironmentTemperature'] : null;
        to.sampRH = (obj['sampRH'] != undefined) ? obj['sampRH'] : null;

        //SUELO additional params, Campo de Profundidad 23/11/2020
            to.sampDepth=(obj['sampDepth'] != undefined) ? obj['sampDepth'] : null;




        if( obj['ag_obj']['group_features'].length > 0 ){
            to.SamplingAnalysisGroupFeaturesList = [];

            obj['ag_obj']['group_features'].forEach(x => {
                to.SamplingAnalysisGroupFeaturesList.push({
                    cmmAnlGrpFeatId: x.id,
                    cmmAnlChecked: x.sel
                });
            });
        }

        if( obj['ag_obj']['analysis'].length > 0 ){
            to.SamplingDetail = [];

            obj['ag_obj']['analysis'].forEach(x => {
                //console.log(x);
                if( x.sel ){
                    tmp = {};
                    tmp['samdAnalysisId'] = x.id;
                    tmp['samdChecked'] = x.sel;
                    tmp['samdRepetitionsNumber'] = x.reps;

                    if(x.special){
                        tmp['samdCommentary'] = x.comment;
                    }

                    if( x.feat.length > 0 ){
                        tmp['SamplingAnalysisFeaturesList'] = [];
                        x.feat.forEach(f => {
                            tmp['SamplingAnalysisFeaturesList'].push({
                                cmmAnlFeatId: f.id,
                                cmmAnlChecked: f.sel
                            });
                        });
                    }

                    //console.log(tmp);
                    to.SamplingDetail.push(tmp);
                }
            });
        }

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;

                        /*if(mydata.success == true ){
                          return of(true);
                        }
                        else{
                          return of(false);
                        }*/
                        return of(mydata);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
    }

    public showInfo(content:any, obj:any){
        this.openUpdateModalStatic(content, this.u_obj);
        this.resetAGItems();
        this.parseDetails(obj);
    }

    parseDetails(obj:any){
        var tmp:any;
        var inn:any;

        tmp = {};
        tmp['sampId'] = obj['sampId'];
        tmp['date'] = obj['sampDate'].substring(0, 16).replace('T', ' ');
        tmp['CompAquasymId'] = obj['CompAquasymId'];
        this.filterSites(tmp['CompAquasymId']);
        tmp['SitAquasymId'] = obj['SitAquasymId'];
        this.filterUnits(tmp['SitAquasymId']);
        tmp['unit_id'] = obj['UnitAquasimId'];
        //tmp['spot_id'] = obj['sampSampleLocationInstanceId'];
        tmp['spot_id'] = obj['SampleLocationInstanceId'];

        if( tmp['unit_id'] != null ){
            tmp['loctype'] = -1;
        }
        else{
            tmp['loctype'] = obj['SampleLocationId'];
            this.filterLocations(tmp['loctype'], tmp['SitAquasymId']);
            console.log(this.spots);
        }

        tmp['comment'] = obj['sampObservation'];
        tmp['agid'] = obj['agrId'];
        //tmp['ag_obj'] = new Itemag(obj['agrId'], obj['agrName']);
        tmp['sampContainer'] = obj['sampContainer'];
        tmp['sampSampleQuantity'] = obj['sampSampleQuantity'];
        tmp['sampRequestedByClient'] = obj['sampRequestedByClient'];
        tmp['stypeID'] = obj['stypeID'];
        tmp['sampOverdueComment'] = obj['sampOverdueComment'];
        tmp['estimatedDelivery'] = obj['estimatedDelivery'];
        tmp['actions'] = obj['actions'];
        tmp['noedit'] = !this.isAllowed('edit', obj);


        //PCR additional params
        tmp['sampCode'] = (obj['sampCode'] != undefined) ? obj['sampCode'] : null;
        tmp['sampProductType'] = (obj['sampProductType'] != undefined) ? obj['sampProductType'] : null;
        tmp['sampReferenceNumber'] = (obj['sampReferenceNumber'] != undefined) ? obj['sampReferenceNumber'] : null;
        tmp['sampBatchCode'] = (obj['sampBatchCode'] != undefined) ? obj['sampBatchCode'] : null;
        tmp['sampPresentation'] = (obj['sampPresentation'] != undefined) ? obj['sampPresentation'] : null;
        tmp['sampConditionSample'] = (obj['sampConditionSample'] != undefined) ? obj['sampConditionSample'] : null;
        tmp['sampEnvironmentTemperature'] = (obj['sampEnvironmentTemperature'] != undefined) ? obj['sampEnvironmentTemperature'] : null;
        tmp['sampRH'] = (obj['sampRH'] != undefined) ? obj['sampRH'] : null;

        // Suelo additional params, Campo de Profundidad 23/11/2020
        tmp['sampDepth'] = (obj['sampDepth'] != undefined) ? obj['sampDepth'] : null;

        this.u_obj = tmp;
        this.updateAnalysisGroupData(obj);
    }

    public getSavedGroupFeature(id:any, rows:any){
        let resp = null;

        if( Array.isArray(rows) ){
            let tmp = rows.filter(x => {
                return x.cmmAnlGrpFeatId == id;
            });

            if( tmp.length > 0 ){
                //resp = tmp[0]['cmmAnlChecked'];
                resp = tmp[0];
            }
        }

        return resp;
    }

    public getSavedAnalysis(id:any, rows:any){
        let resp = null;

        if( Array.isArray(rows) ){
            let tmp = rows.filter(x => {
                return x.anId == id;
            });

            if( tmp.length > 0 ){
                resp = tmp[0];
            }
        }

        return resp;
    }

    public getSavedAnalysisFeature(id:any, rows:any){
        let resp = null;

        if( Array.isArray(rows) ){
            let tmp = rows.filter(x => {
                return x.cmmAnlFeatId == id;
            });

            if( tmp.length > 0 ){
                resp = tmp[0];
            }
        }

        return resp;
    }

    public updateAnalysisGroupData(obj:any){
        var resp:boolean;
        var id = obj['agrId'];
        let gftmp:any;
        let atmp:any;
        let itmp:any;

        this.filterAnalysisGroup(id, true);

        this.u_obj['ag_obj']['group_features'].forEach(gf => {
            gftmp = this.getSavedGroupFeature(gf['id'], obj['SamplingAnalysisGroupFeaturesList']);
            if( gftmp != null ){
                gf['sel'] = gftmp['cmmAnlChecked'];
                gf['cmmId'] = gftmp['cmmId'];
            }
        });

        //analysis
        this.u_obj['ag_obj']['analysis'].forEach(a => {
            atmp = this.getSavedAnalysis(a['id'], obj['SamplingDetail']);
            if( atmp != null ){
                a['sel'] = atmp['samdChecked'];
                a['samdId'] = atmp['samdId'];
                a['comment'] = atmp['samdCommentary'];
                a['reps'] = atmp['samdRepetitionsNumber'];

                //analysis features
                a.feat.forEach(i => {
                    itmp = this.getSavedAnalysisFeature(i['id'], atmp['SamplingAnalysisFeaturesList']);
                    if( itmp != null ){
                        i['sel'] = itmp['cmmAnlChecked'];
                        i['cmmId'] = itmp['cmmId'];
                    }
                });
            }
        });

        this.u_obj['ag_obj'].recalculateGroupFeatures();
        this.u_obj['ag_obj'].recalculateAnalysisFeatures();
        //console.log(this.u_obj['ag_obj']);
    }

    updateItem(content:any, obj:any){
        let validated:any;

        if(this.isOffline){
            Swal.fire(this.translations.offline_not_available, '', 'warning');
            return;
        }

        validated = this.validateCreateInfo(obj);
        if( !validated['status'] ){
            Swal.fire(validated['msg'], '', 'error');
            return;
        }

        this.isUpdating = true;
        this.updateItemRemote(obj).subscribe(x => {
            this.isUpdating = false;

            if( x['success'] == true ){
                this.reload.next(true);
                this.closeModal(content);
                Swal.fire('¡Actualizado!', '', 'success');
            }
            else{
                if( x['msg'] != '' ){
                    Swal.fire('Error', x['msg'], 'error');
                }
                else{
                    Swal.fire('Error', '', 'error');
                }
            }
        });
    }

    public updateItemRemote(obj:any){
        var url = 'Sampling/Update';
        var to:any;
        var tmp:any;

        //console.log(obj);

        to = {};
        to.sampId = obj['sampId'];
        //to.sampDate = obj['date'];
        to.sampUnitAquasimId = (obj['loctype'] == -1) ? obj['unit_id'] : null;
        to.sampSampleLocationInstanceId = (obj['loctype'] > -1) ? obj['spot_id'] : null;
        to.sampObservation = obj['comment'];
        to.sampAnalysisGroupId = obj['agid'];
        to.SamplingAnalysisGroupFeaturesList = null;
        to.SamplingDetail = null;
        to.sampSampleTypeId = obj['stypeID'];
        to.sampContainer = obj['sampContainer'];
        to.sampSampleQuantity = obj['sampSampleQuantity'];
        to.sampRequestedByClient = obj['sampRequestedByClient'];

        //PCR additional params
        to.sampCode = (obj['sampCode'] != undefined) ? obj['sampCode'] : null;
        to.sampProductType = (obj['sampProductType'] != undefined) ? obj['sampProductType'] : null;
        to.sampReferenceNumber = (obj['sampReferenceNumber'] != undefined) ? obj['sampReferenceNumber'] : null;
        to.sampBatchCode = (obj['sampBatchCode'] != undefined) ? obj['sampBatchCode'] : null;
        to.sampPresentation = (obj['sampPresentation'] != undefined) ? obj['sampPresentation'] : null;
        to.sampConditionSample = (obj['sampConditionSample'] != undefined) ? obj['sampConditionSample'] : null;
        to.sampEnvironmentTemperature = (obj['sampEnvironmentTemperature'] != undefined) ? obj['sampEnvironmentTemperature'] : null;
        to.sampRH = (obj['sampRH'] != undefined) ? obj['sampRH'] : null;

        // Suelo additional params, Campo de Profundidad 23/11/2020
        to.sampDepth=(obj['sampDepth'] != undefined) ? obj['sampDepth'] : null;

        if( obj['ag_obj']['group_features'].length > 0 ){
            to.SamplingAnalysisGroupFeaturesList = [];

            obj['ag_obj']['group_features'].forEach(x => {
                to.SamplingAnalysisGroupFeaturesList.push({
                    cmmAnlGrpFeatId: x.id,
                    cmmAnlChecked: x.sel
                });
            });
        }

        if( obj['ag_obj']['analysis'].length > 0 ){
            to.SamplingDetail = [];

            obj['ag_obj']['analysis'].forEach(x => {

                if( x.sel ){
                    tmp = {};
                    tmp['samdAnalysisId'] = x.id;
                    tmp['samdChecked'] = x.sel;
                    tmp['samdRepetitionsNumber'] = x.reps;

                    if(x.special){
                        tmp['samdCommentary'] = x.comment;
                    }

                    if( x.feat.length > 0 ){
                        tmp['SamplingAnalysisFeaturesList'] = [];
                        x.feat.forEach(f => {
                            tmp['SamplingAnalysisFeaturesList'].push({
                                cmmAnlFeatId: f.id,
                                cmmAnlChecked: f.sel
                            });
                        });
                    }

                    //console.log(tmp);
                    to.SamplingDetail.push(tmp);
                }
            });
        }

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        return of(mydata);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
    }

    public getReportAPI(id:number){
        var url = 'Laboratory/Report/' + id;
        return this.srv.getBlobCall(url);
    }

    public downloadReport(obj:any){
        var pre = 'samp';

        if( !this.isAllowed('download', obj) ){
            return;
        }

        if(this.isDownloading){
            return;
        }

        this.isDownloading = obj.sampId;
        //console.log(obj);

        this.getReportAPI(obj.sampId).subscribe(x => {
            this.isDownloading = 0;
            //console.log(x);
            let fname = pre + '_' + obj.sampId + '_' + obj.cmpName + '_' + obj.sitName + '.pdf';
            fileSaver.saveAs(x, fname);
            /*const url= window.URL.createObjectURL(x);
            window.open(url);*/
        });
    }

    public isAllowed(opt:any, obj:any){
        //TODO temporary disabled followup button - need to be removed all followup logic
        if(this.isOffline && !(opt == 'offline')){
            return false;
        }

        if( opt == 'followup' ){
            return false;
        }

        if(obj.actions.indexOf(opt) > -1){
            return true;
        }
        else{
            return false;
        }
    }

    public showFollowup(content:any, obj:any){
        if( !this.isAllowed('followup', obj) ){
            return;
        }

        this.f_obj = {};
        this.modalService.open(content, { centered: true });
        this.f_obj = obj;
        this.loadFollowComments();
    }

    public saveFollowup(obj:any){
        if( (obj['followcomment'] == undefined) || (obj['followcomment'] == '') ){
            return;
        }

        this.isUpdating = true;
        this.saveFollowupRemote(obj).subscribe(x => {
            this.isUpdating = false;

            if( x['success'] == true ){
                obj['followcomment'] = '';
                this.loadFollowComments();
            }
            else{
                if( x['msg'] != '' ){
                    Swal.fire('Error', x['msg'], 'error');
                }
                else{
                    Swal.fire('Error', '', 'error');
                }
            }
        });
    }

    public saveFollowupRemote(obj:any){
        var url = 'TechnicalAdviserLog/AddAdviceLog';
        var to:any;
        to = {};

        to.advSamplingId = obj['sampId'];
        to.TechnicalAdviseLog = [{'logText': obj['followcomment']}];

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        return of(mydata);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
    }

    public getFollowupComments(obj:any){
        var par_url = 'TechnicalAdviserLog/GetCommentBySampling/' + obj['sampId'];

        return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);

                        if(mydata.success == true ){
                          return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
    }

    public loadFollowComments(){
        this.f_obj['history'] = [];

        this.loadingFollow = true;
        this.getFollowupComments(this.f_obj).subscribe(x => {
            this.loadingFollow = false;
            //console.log(x);
            if(x != false){
                if( x.length > 0 ){
                    this.f_obj['history'] = x[0]['Logs'];
                }
            }
        });
    }

    public deleteFollowup(obj:any){
        var qst = 'TechnicalAdviserLog/DeleteLogical';
        let trans = this.translations;

        Swal.fire({
            title: trans.delete_item_title,
            text: '',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: trans.cancel,
            confirmButtonText: trans.delete,
            preConfirm: () => {
                this.srv.postCall(qst, obj).subscribe(x => {
                    let msg = this.translateMessage(x.msg);

                    if(x.success){
                        this.loadFollowComments();
                        Swal.fire(trans.deleted, msg, 'success');
                    }
                    else{
                        Swal.fire(msg, '', 'error');
                    }
                });
            }
        }).then((result) => {
        });
    }

    public resetCompanySelections(){
        this.sites = [];
        this.units = [];
        this.spots = [];
        this.c_obj['SitAquasymId'] = '';
        this.u_obj['SitAquasymId'] = '';
        this.c_obj['unit_id'] = '';
        this.u_obj['unit_id'] = '';
        this.c_obj['spot_id'] = '';
        this.u_obj['spot_id'] = '';

        this.c_obj['loctype'] = '-1';
        this.u_obj['loctype'] = '-1';
    }

    public resetSiteSelections(){
        this.units = [];
        this.spots = [];
        this.c_obj['unit_id'] = '';
        this.u_obj['unit_id'] = '';
        this.c_obj['spot_id'] = '';
        this.u_obj['spot_id'] = '';

        this.c_obj['loctype'] = '-1';
        this.u_obj['loctype'] = '-1';
    }

    public createItemLocal(obj:any, content:any, multi?:any){
        let items:any;

        if( multi == undefined ){
            multi = false;
        }

        this.srv.getPeristedData(this.offline_key).then(i => {

            if( (i == undefined) || (i == null) ){
                items = [];
            }
            else{
                items = i;
            }

            //set site name
            let stmp = this.raw_sites.filter(st => {
                return st.id == obj.SitAquasymId;
            });

            if( Array.isArray(stmp) ){
                obj.site_name = stmp[0].name;
            }

            if( (obj.off_id != undefined) && (obj.off_id != null) ){
                items.forEach((x,k) => {
                    if( x.off_id == obj.off_id ){
                        items[k] = obj;
                    }
                });
            }
            else{
                obj.off_id = Date.now();
                obj.actions = this.getActionsByStatusName('OFFLINE');
                items.push(obj);
            }

            this.srv.persistData(this.offline_key, items).then(s => {

                if( !multi ){
                    this.closeModal(content);
                }
                else{
                    //set null to create a new onw when save and continue
                    obj.off_id = null;
                }

                Swal.fire(this.translations.offline_saved, '', 'success');
                this.getOfflineItems();
            });
        });
    }

    public getOfflineItems(){
        let items:any;

        this.srv.getPeristedData(this.offline_key).then(i => {

            if( (i == undefined) || (i == null) ){
                items = [];
            }
            else{
                items = i;
            }

            this.offline_items = items;
            //console.log(this.offline_items);
        });
    }

    public deleteItemLocal(obj:any, prompt=true){
        let items:any;

        if(!prompt){
            this.srv.getPeristedData(this.offline_key).then(i => {
                if( (i != undefined) && (i != null) ){
                    items = [];
                    i.forEach(x => {
                        if( x.off_id != obj.off_id ){
                            items.push(x);
                        }

                        this.srv.persistData(this.offline_key, items).then(s => {
                            this.offline_items = items;
                            //Swal.fire(this.translations.deleted, '', 'success');
                        });
                    });
                }
            });
        }
        else{
            Swal.fire({
                title: this.translations.delete_item_title,
                text: '',
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: this.translations.cancel,
                confirmButtonText: this.translations.delete,
                preConfirm: () => {
                    this.srv.getPeristedData(this.offline_key).then(i => {
                        if( (i != undefined) && (i != null) ){
                            items = [];
                            i.forEach(x => {
                                if( x.off_id != obj.off_id ){
                                    items.push(x);
                                }

                                this.srv.persistData(this.offline_key, items).then(s => {
                                    this.offline_items = items;
                                    //Swal.fire(this.translations.deleted, '', 'success');
                                });
                            });
                        }
                    });
                }
            }).then((result) => {
            });
        }
    }

    public showOfflineInfo(content:any, obj:any){
        this.resetAGItems();
        this.filterSites(obj['CompAquasymId']);
        this.filterUnits(obj['SitAquasymId']);

        this.openUpdateModalStatic(content, obj);

        //recreate Analysis group object
        let tmp = new Itemag(obj['ag_obj']['sampAnalysisGroupId'], obj['ag_obj']['name']);
        tmp.analysis = obj['ag_obj']['analysis'];
        tmp.group_features = obj['ag_obj']['group_features'];
        tmp.MaxFeatures = obj['ag_obj']['MaxFeatures'];
        tmp.MinFeatures = obj['ag_obj']['MinFeatures'];
        tmp.SelFeatures = obj['ag_obj']['SelFeatures'];
        tmp.actualcolor = obj['ag_obj']['actualcolor'];
        this.u_obj['ag_obj'] = tmp;
    }

    public getSampleLocationAllBelongAP() {
        var par_url = 'SampleLocation/AllBelongAPSampling';

        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_loc).then(x => {
                if( x != null ){
                    return x.data;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(par_url).pipe(
                mergeMap(data => {
                    let mydata:any = {success: false, msg:'', val:[]};
                    mydata = data;

                    if(mydata.success == true){
                        this.srv.saveOfflineData(this.offline_key_loc, mydata.val);
                        return of(mydata.val);
                    }
                    else{
                        return of(false);
                    }
                }),
                catchError(data => of(false))
            );
        }
    }

}

