<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th (click)="setOrder('sitId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='sitId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('sitName')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='sitName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('sitM3')" class="sh">
                                    {{'site_m3' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='sitM3')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('laboratory')" class="sh">
                                    {{'site_lab' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='laboratory')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('shrimpSector')" class="sh">
                                    {{'site_shrimp_sec' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='shrimpSector')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('skSector')" class="sh">
                                    {{'site_sk_sector' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='skSector')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc" [ngClass]="{'disabled-row': !row.sitActive}">
                                <td>{{row.sitId}}</td>
                                <td>{{row.sitName}}</td>
                                <td>{{row.sitM3}}</td>
                                <td>{{row.laboratory}}</td>
                                <td>{{row.shrimpSector}}</td>
                                <td>{{row.skSector}}</td>
                                <td class="action-buttons">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="7">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['sitName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'site_m3' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['sitM3']" name="sitM3" required maxlength="20">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['sitName']" name="sitName" disabled="true">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_country' | translate}}</label>
                                        <div class="col-9">
                                            <input type="text" class="form-control" [(ngModel)]="u_obj['sitCountry']" name="sitCountry" disabled="true">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_lat' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['sitCoordLatitude']" name="sitCoordLatitude" disabled="true">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_long' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['sitCoordLongitude']" name="sitCoordLongitude" disabled="true">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_active' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md input-group" name="sitActive" [(ngModel)]="u_obj['sitActive']" disabled="true">
                                                <option value="true">{{'yes' | translate}}</option>
                                                <option value="false">{{'no' | translate}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_province' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="sitProvince" [(ngModel)]="u_obj['sitProvince']">
                                                <option value="null"> </option>
                                                <option *ngFor="let list of provinces; let i = index" [value]="list['Id']">{{list['Name']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_shrimp_sec' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="sitShrimpSectorId" [(ngModel)]="u_obj['sitShrimpSectorId']">
                                                <option value="null"> </option>
                                                <option *ngFor="let list of shrimp_sectors; let i = index" [value]="list['shrId']">{{list['shrName']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_sk_sector' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="sitSkrettingSectorId" [(ngModel)]="u_obj['sitSkrettingSectorId']">
                                                <option value="null"> </option>
                                                <option *ngFor="let list of sk_sectors; let i = index" [value]="list['skseId']">{{list['skseName']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'addr_pcr' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['sitAddressPCR']" name="sitAddressPCR" maxlength="100">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_prodarea' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="u_obj['sitProductionArea']" name="sitProductionArea">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_water_refill' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="u_obj['sitWaterRefill']" name="sitWaterRefill">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_water_ms' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="sitWaterMgtSys" [(ngModel)]="u_obj['sitWaterMgtSys']">
                                                <option value="null"> </option>
                                                <option *ngFor="let list of water_sys; let i = index" [value]="list['Id']">{{list['Name']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'site_lab' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="sitLaboratoryId" [(ngModel)]="u_obj['sitLaboratoryId']">
                                                <option value="null"> </option>
                                                <option *ngFor="let list of laboratories; let i = index" [value]="list['labId']">{{list['labName']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'cmp_mails_recep' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['sitSendEmailsReports']" name="sitSendEmailsReports">
                                        <label>{{'cmp_email_legend' | translate}}</label>
                                    </div>
                                </div>
                                <h4>{{'site_addresses' | translate}}</h4>
                                <i class="mdi mdi-plus-circle" (click)="featureUpdate(1, null)" style="color: #007D8A;"></i>
                                <div class="form-group row" *ngFor="let lstTm of u_obj['SiteAddress']; let i = index">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">
                                        <i class="mdi mdi-minus-circle text-danger" (click)="featureUpdate(0, i)" style="color: #007D8A;"></i>&nbsp;&nbsp;{{i + 1}}.
                                    </label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" id="SiteAddress{{i}}" name="SiteAddress{{i}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="lstTm['sitaAddressNo']">
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Update</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
