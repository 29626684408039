import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import * as moment from 'moment-timezone';
// import { csvParseRows } from 'd3';

@Component({
  selector: 'app-samplinginput',
  templateUrl: './samplinginput.component.html',
  styleUrls: ['./samplinginput.component.css']
})
export class SamplinginputComponent extends BaseCrudComponent {

  	id:any;
    loadingRequired = false;
    loadingDetails = false;
    isSaving = false;
    isApproving = false;
    isInterrupt = false;
    isMailing = false;
    //workers:any;
    translations:any;
    filters:any;
    obj:any;
    statuslist:any;
    loadingAnalysis = false;
    a_obj:any;
    e_obj:any;
    actions:any;

    isShown:boolean=false;
    isShowtd:boolean=false;


    pcr_filtersT:any;
    pcr_filtersC:any;
    pcr_filtersH:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'Laboratory/All';
        this.create_url = '';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = ['sampId', 'UserTechnicalName', 'cmpName', 'sitName', 'StatusName'];
        this.a_obj = {};
        this.e_obj = {};

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getListPost();

            }
        });

        //this.fillData();
    }

    fillData(){
        this.filters = [];
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

              this.getListPost();

        });
    }

    public getListPost(){
        var qst = this.read_url;
        var tosend = {};
        console.log("entra a ver data");
        console.log("radio creacion:",this.r_date_create);
        console.log("radio recepcion:",this.r_date_reception);
        if (this.r_date_create != null )
        {
            if( this.f_datefrom != null ){
                tosend['_initialDate'] = this.getISODate(this.f_datefrom);
            }

            if( this.f_dateto != null ){
                tosend['_finalDate'] = this.getISODate(this.f_dateto);
            }
        }
        if (this.r_date_reception!= null )
        {
            console.log("entro por fecha de recepcion");
            if( this.f_datefrom != null ){
                tosend['_initialReceptionDate'] = this.getISODate(this.f_datefrom);
            }

            if( this.f_dateto != null ){
                tosend['_finalReceptionDate'] = this.getISODate(this.f_dateto);
            }
        }
        this.loadingList = true;
        this.srv.postCall(qst, tosend).subscribe(x => {
            this.loadingList = false;
            if(x.success){
                console.log(x);
                //console.log(rows);
                this.rawdata = x.val;
                this.rows = x.val;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            }

        });
    }

    validateDetails(rows:any){
        let resp = {status:true, msg:''};
        let selcount = 0;

        if( Array.isArray(rows) ){
            rows.forEach(x => {
                if( !x['sel'] && ((x['observation'] == undefined) || (x['observation'] == '')) ){
                    resp = {status:false, msg: this.translations.sampapp_error_missingcomment};
                }

                if( x['sel'] ){
                    selcount++;
                }
            });
        }
        else{
            resp = {status:false, msg: this.translations.sampapp_error_missingcomment};
        }

        if( selcount == 0 ){
            resp = {status:false, msg: this.translations.sampapp_error_minanalysis};
        }

        return resp;
    }

    showInfo(content:any, row:any){
        console.log("show:",row)
        this.u_obj = {};
        this.openInfoModalBig(content, {});
        this.getDetails(row);
        this.getParameter();


    }

    getDetails(row:any){
        var qst = 'Laboratory/AnalysisListToProcessBySamplingId/' + row.sampId;
        this.u_obj = row;
        //console.log(row
        this.u_obj['details'] = [];
        this.loadingDetails = true;

        if(this.u_obj['advacePercent']==100)
        //console.log(this.u_obj['advacePercent']);
        {
            this.isShown = !this.isShown;
        }


        this.srv.getCall(qst).subscribe(x => {
            this.loadingDetails = false;

            if(x.success){
                //console.log(rs);
                var rs = x.val;
                //console.log(rs);
                // this.getListPost();

                rs.forEach(r => {
                    r['sel'] = r['Checked'];
                    r['observation'] = r['Observation'];
                    r['reps'] = parseInt(r['Repetition']);

                    if( r['reps'] < 1){
                        r['reps'] = 1;
                    }

                    if(r['status']=='INTERRUMPIDO'){

                        r['sel'] = true;
                    }


                });

                //this.u_obj['details'] = rs;
                this.u_obj['details'] = rs;
                console.log(this.u_obj['details'])
                if( rs.length > 0 ){
                    //console.log(rs.length)
                    this.u_obj['agrName'] = rs[0]['agrName'];

                }
            }

        });
    }

    public showAnalysis(content: any, obj: any) {
        this.a_obj = {};
        //console.log("obj:",obj)
       //Condicion para controlar el ancho de la columna para el ingreso de los valores, Fito maneja valores de varios digitos.
        let groupanalysis = obj.agrName;
        const text = groupanalysis.toLocaleLowerCase();
        if(text.includes("fito"))
        {
          this.isShowtd=true;
        }
        else {
          this.isShowtd=false;
        }

        this.modalService.open(content, { centered: true });
       // console.log(obj);
        this.getAnalysisDetails(obj);
    }

    getAnalysisDetails(row:any){
        var qst = 'Laboratory/GetParametersByAnlysisLaboratory/' + row.anId + '/' + row.sampId;
        this.a_obj = row;
        this.a_obj['details'] = [];
        this.loadingAnalysis = true;

        this.srv.getCall(qst).subscribe(x => {
            //this.loadingAnalysis = false;
            if(x.success){
                var rs = x.val;
                //rs['Details'].sort((a,b) => a['parSequence'].localeCompare(b['parSequence']));
                this.a_obj['details'] = rs['Details'];
                this.parseAnalysisDetail(this.a_obj['details']);

                this.a_obj['ldiId'] = rs['ldiId'];
                this.a_obj['ldiObservation'] = rs['ldiObservation'];

                this.a_obj['SamplingReferenceMethods'] = rs['SamplingReferenceMethods'];
                //console.log(this.a_obj);
            }

            let url = 'ReferenceMethodsByAnalysis/GetReferencesByAnalysis/' + row.anId;
            this.srv.getCall(url).subscribe(y => {
                this.loadingAnalysis = false;
                if(y.success){
                    var methods = y.val;

                    methods.forEach(r => {
                        r['sel'] = this.isMethodSelected(r.refDetId, this.a_obj['SamplingReferenceMethods']);
                    });

                    this.a_obj['methods'] = methods;
                }
            });
        });
    }

    public isMethodSelected(id:any, methods:any){
        let resp = false;

        if((methods != undefined) && (methods != null)){
            let rs = methods.filter(m => {
                return m.refDetId == id;
            });

            if( rs.length > 0 ){
                resp = true;
            }
        }
        //console.log(resp);
        return resp;
    }

    parseAnalysisDetail(rows:any){
    	if( Array.isArray(rows) ){
    		rows.forEach(r => {
    			r['evaluating'] = false;
    			r['ranges'] = [];

    			if( r['parIsRangeType'] ){
    				r['ranges'] = this.parseRanges(r['ldidValue']);
    			}

    			if( r['parIsFixedType'] ){
    				r['value'] = r['ldidValue'];
    			}

                if( r['parIsManualType'] && (r['value'] === null) ){
                    r['value'] = 0;
                }
    		})
    	}
    }

    parseRanges(val:any){
    	let resp = [];
    	var strs = val.split(';');
    	//console.log(strs);

    	strs.forEach(x => {
    		resp.push({
    			id: x,
    			val: x
    		});
    	});

    	return resp;
    }

    evaluateFormula(obj:any, params:any){
    	var qst = 'Parameter/EvaluateFormula';
    	let to:any;
    	let tmp:any;

    	to = {};
    	to.ParameterId = obj['parId'];
    	to.Variables = [];

    	params.forEach(x => {
    		tmp = {
    			ParamId: x['parId'],
    			Value: x['value']
    		};

    		to.Variables.push(tmp);
    	});

    	obj['evaluating'] = true;

    	this.srv.postCall(qst, to).subscribe(x => {
            obj['evaluating'] = false;
            if(x.success){
                var rs = x.val;
                //console.log(rs);
                obj['value'] = rs;
            }
            else{
            	Swal.fire(this.translations.sinput_cannot_evaluate, '', 'error');
            }
        });
    }

    validateParams(params:any){
    	try{
    		params.forEach(x => {
	    		if( x['parIsRequired'] && ((x['value'] === undefined) || (x['value'] === null)) ){
	    			throw this.translations.msg_all_required;
	    		}
	    	});

	    	return true;
    	} catch(error){
    		return false;
    	}
    }


    saveAnalysis(obj:any, content:any, finish?:any){
    	var qst = 'Laboratory/Create';
    	let to:any;
    	let tmp:any;

    	if(!this.validateParams(obj.details)){
    		Swal.fire(this.translations.msg_all_required, '', 'error');
    		return;
    	}
      console.log("a_obj:",obj)
    	to = {};
    	to.ldiId = (obj.ldiId == null) ? 0 : obj.ldiId;
    	to.ldiAnalysisId = obj.anId;
    	to.ldiSamplingId = obj.sampId;
    	to.ldiStatus = (finish) ? 1 : 0;
    	to.LaboratoryDataInputDetail = [];

    	obj.details.forEach(x => {
    		tmp = {
    			ldidParameterId: x['parId'],
    			ldidValue: x['value']
    		};

    		to.LaboratoryDataInputDetail.push(tmp);
    	});

        let selmethods = [];
        obj['methods'].forEach(x => {
            if(x.sel){
                selmethods.push(x.refDetId);
            }
        });

        to['_listReferences'] = selmethods;


        if( selmethods.length > 0 ){
            this.saveAnalysisToAPI(to, obj, content, finish);
        }
        else{
            let trans = this.translations;
            Swal.fire({
                title: trans.sampapp_refmethodswarn,
                text: '',
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: trans.cancel,
                confirmButtonText: trans.ok,
                preConfirm: () => {
                    this.saveAnalysisToAPI(to, obj, content, finish);
                }
            }).then((result) => {
            });
        }

    }


    saveAnalysisToAPI(to:any, obj:any, content:any, finish?:any){
        var qst = 'Laboratory/Create';
        this.isSaving = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isSaving = false;
            if(x.success){
                obj.ldiId = x.val['ldiId'];

                if( finish ){
                    obj.status = 'FINALIZADO';
                    this.recalculateAdvancePerc();
                    content.dismiss('cross click');
                }
            }
            else{
                Swal.fire(x.msg, '', 'error');
            }
        });
    }

    UpdatedSamplingFinished(obj:any){
        var qst = 'Laboratory/UpdateStatusToFinishedSampling';
        this.isSaving = true;
        this.rows = obj['details'];
        var band = false;

        this.rows.forEach(r => {

             if (r.sel==true){

                band=true;
                var to ={
                    "ldiSamplingId": r.sampId
            };

             }
         });

       if (band==false){
            var to ={
                          "ldiSamplingId": obj.sampId
                  };
                  this.srv.postCall(qst, to).subscribe(x => {
                      this.isSaving = false;
                      if(x.success){
                          //Swal.fire(this.translations['updated'], '', 'success');
                          this.getListPost()
                      }
                      else{
                          Swal.fire(x.msg, '', 'error');
                      }
                  });

        }

    }

    recalculateAdvancePerc(){
    	let p = 0;
    	let total = 0;
    	let perc = 0;

    	this.u_obj['details'].forEach(r => {
    		total++;
    		if( r.status=='FINALIZADO' || r.status=='INTERRUMPIDO'){
    			p++;
    		}
    	});

    	perc = (p * 100) / total;
    	perc = Math.round(perc);
    	//this.u_obj['advacePercent'] = Number.parseFloat(perc).toFixed(2);
    	this.u_obj['advacePercent'] = perc;

        // if(perc==100){
        //     //this.isShown=false;
        //   //console.log("vamos a poner al 100")
        //   this.u_obj['sampStatusId']=99;
        //   //this.isshowsavepcr=true;
        // }
    }

    public openRichEditor(content:any, obj:any){
        this.e_obj = {};
        this.modalService.open(content, { size: 'lg', centered: true });
        this.e_obj = obj;
        //console.log(this.e_obj);
    }

    public assignAndUpdate(form:any){//, content:any
        var validated:any;

        if( form == undefined ){
          form = {};
        }
        this.isUpdating = true;

        this.updateInterruptedAnalysis(form);

   }

    public updateInterruptedAnalysis(rows:any){


             var qst = 'Laboratory/UpdateStatusToInterrupt';
             let to:any;
             let tmp:any;
             var rows2: any;


             rows2= rows['details'];
             rows2.forEach(r => {
               // console.log('entro a for',r);
                 if (r.sel==true){
                   //console.log(rows, 'aqui row');
                    to = {
                        ldiSamplingId: r['sampId'],
                        ldiObservation: r['observation'],
                        ldiStatus: 485,
                        ldiAnalysisId:r['anId']

                      }

                     this.isSaving = true;

                     this.srv.postCall(qst, to).subscribe(x => {
                         //console.log('entro a modificar',x);
                         this.isSaving = false;
                        // this.obj['details'] = [];
                         if(x.success){

                            this.getDetails(rows);

                            //this.getListPost();
                             Swal.fire(this.translations['updated'], '', 'success');

                             this.UpdatedSamplingFinished(rows);


                         }
                         else{
                             Swal.fire(x.msg, '', 'error');
                         }

                     });
                 }
             });


    }


    getParameter(){

      this.pcr_filtersT = [];
      this.pcr_filtersH = [];
      this.pcr_filtersC = [];
      this.loadingAnalysis = true;

      var qst = 'SystemParameter/GetField/' + "EnvironmentTemperaturePCR";
      this.srv.getCall(qst).subscribe(x => {
        //this.loadingAnalysis = false;
        if(x.success){
          var rs = x.val;
          this.pcr_filtersT=rs;

        }
      });

       qst = 'SystemParameter/GetField/' + "ConditionSamplePCR";
      this.srv.getCall(qst).subscribe(x => {
        //this.loadingAnalysis = false;
        if(x.success){
          var rs2 = x.val;
          this.pcr_filtersC=rs2;

        }
      });
      qst = 'SystemParameter/GetField/' + "RHPCR";
      this.srv.getCall(qst).subscribe(x => {
        //this.loadingAnalysis = false;
        if(x.success){
          var rs3 = x.val;
          this.pcr_filtersH=rs3;

        }
      });
    }

    savePCR(obj:any)
    {
      let qst = 'Sampling/UpdatePCR';
      let to:any;
      to = {
        sampId: obj['sampId'],
        sampConditionSample: obj['sampConditionSample'],
        sampEnvironmentTemperature:obj['sampEnvironmentTemperature'],
        sampRH:obj['sampRH']
      }
      console.log("to:",to)

      //validamos campos
      if( obj.sampEnvironmentTemperature == -1 || obj.sampEnvironmentTemperature =="" || obj.sampEnvironmentTemperature == null || obj.sampEnvironmentTemperature ==" "){
        //this.translations.msg_all_required+":"+this.translations.sampProductType;
        Swal.fire(this.translations.msg_all_required+":"+this.translations.sampEnvironmentTemperature, '', 'error');
        return;
      }
      //console.log("sampPresentation:",fields.sampPresentation );
      if( obj.sampConditionSample == -1  || obj.sampConditionSample =="" || obj.sampConditionSample == null || obj.sampConditionSample ==" "){
        //resp.msg = this.translations.msg_all_required+":"+this.translations.sampPresentation;
        Swal.fire(this.translations.msg_all_required+":"+this.translations.sampConditionSample, '', 'error');
        return ;
      }
      if( obj.sampRH == -1  || obj.sampRH =="" || obj.sampRH == null || obj.sampRH ==" "){
        //resp.msg = this.translations.msg_all_required+":"+this.translations.sampPresentation;
        Swal.fire(this.translations.msg_all_required+":"+this.translations.sampRH, '', 'error');
        return ;
      }

      this.loadingAnalysis = true;

      this.srv.postCall(qst, to).subscribe(x => {
        console.log('entro a modificar',x);
        this.isSaving = false;
        // this.obj['details'] = [];
        if(x.success){
          //this.getListPost();
          //this.getDetails(obj);
          this.getListPost()
          Swal.fire(this.translations['updated'], '', 'success');
          //this.closeModal("info1");
        }
        else{
          Swal.fire(x.msg, '', 'error');
        }
      });
    }

}
