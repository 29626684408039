import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
/*import { MapgoogleComponent } from './maps/map-google/map-google.component';
import { GlobalparamsComponent } from './globalparams/globalparams.component';*/
import { CompaniesComponent } from './companies/companies.component';
/*import { CompanycontactsComponent } from './companycontacts/companycontacts.component';*/
import { SitesComponent } from './sites/sites.component';
/*import { SitecontactsComponent } from './sitecontacts/sitecontacts.component';
import { SiteparamsComponent } from './siteparams/siteparams.component';*/
import { UnitsComponent } from './units/units.component';
/*import { UnitparamsComponent } from './unitparams/unitparams.component';
import { AlarmsComponent } from './alarms/alarms.component';
import { DatapanelComponent } from './datapanel/datapanel.component';*/
import { SettingsComponent } from './settings/settings.component';

import { PdiComponent } from './forms/pdi/pdi.component';
import { SamplingComponent } from './forms/sampling/sampling.component';

import { AnalysisComponent } from './maint/analysis/analysis.component';
import { ParameterComponent } from './maint/parameter/parameter.component';
import { SamplingApprovalComponent } from './processes/samplingapproval/sapproval.component';
import { SamplinginputComponent } from './processes/samplinginput/samplinginput.component';

import { CrudComponent } from './maint/crudhome/crud.component';
import { AnalysisGoupComponent } from './maint/analysis-goup/analysis-goup.component';
import { CompanyComponent } from './maint/company/company.component';
import { CountryComponent } from './maint/country/country.component';
import { LaboratoryComponent } from './maint/laboratory/laboratory.component';
import { ProductionGroupComponent } from './maint/production-group/production-group.component';
import { SampleComponent } from './maint/sample/sample.component';
import { SampleLocationComponent } from './maint/sample-location/sample-location.component';
import { SampleLocationTypeComponent } from './maint/sample-location-type/sample-location-type.component';
import { SampleTypeComponent } from './maint/sample-type/sample-type.component';
import { ShrimpSectorComponent } from './maint/shrimp-sector/shrimp-sector.component';
import { SkrettingSiteComponent } from './maint/skretting-site/skretting-site.component';
import { SkrettingSectorComponent } from './maint/skretting-sector/skretting-sector.component';
import { SiteComponent } from './maint/site/site.component';
import { SiteGroupComponent } from './maint/site-group/site-group.component';
import { UserComponent } from './maint/user/user.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormshomeComponent } from './forms/formshome/formshome.component';
import { ProchomeComponent } from './processes/prochome/prochome.component';
import { UnitComponent } from './maint/unit/unit.component';
import { RefmethodComponent } from './maint/refmethod/refmethod.component';
import { VisitComponent } from './maint/visit/visit.component';
import { ReportingComponent } from './processes/reporting/reporting.component';
import { RepocreatorComponent } from './processes/repocreator/repocreator.component';
import { ReportingapprovalComponent } from './processes/reportingapproval/reportingapproval.component';

import { LoginService } from '../services/authentication/login.service';
import { SamplingallComponent } from './forms/samplingall/samplingall.component';
import { PdiallcompanyComponent } from './forms/pdiallcompany/pdiallcompany.component';

export const Ic2routes: Routes = [
  {
    path: '',
    component: FullComponent,
    //canActivate: [LoginService],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_home',
          urls: []
        }
      },
      {
        path: 'formshome',
        component: FormshomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_forms',
          urls: []
        }
      },
      {
        path: 'prochome',
        component: ProchomeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_processes',
          urls: []
        }
      },
      {
        path: 'pdi',
        component: PdiComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_encuesta',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_encuesta'}
          ]
        }
      },
      {
        path: 'pdiallcompany',
        component: PdiallcompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_pdiallcompany',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_pdiallcompany'}
          ]
        }
      },

      {
        path: 'sampling',
        component: SamplingComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_sampling',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_sampling'}
          ]
        }
      },
      {
        path: 'samplingallusers',
        component: SamplingallComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_samplingallusers',
          urls: [
            { title: 'menu_forms', url: '/formshome' },
            { title: 'menu_samplingallusers'}
          ]
        }
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_general',
          urls: []
        }
      },
      {
        path: 'analysis_group',
        component: AnalysisGoupComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_mant_analysis_group',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_mant_analysis_group'}
          ]
        }
      },
      {
        path: 'companies',
        component: CompanyComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_company',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_company'}
          ]
        }
      },
      {
        path: 'countries',
        component: CountryComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_countries',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_countries'}
          ]
        }
      },
      {
        path: 'laboratories',
        component: LaboratoryComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_mant_laboratory',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_laboratories'}
          ]
        }
      },
      {
        path: 'prodgroups',
        component: ProductionGroupComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_prodgroups',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_prodgroups'}
          ]
        }
      },
      {
        path: 'samples',
        component: SampleComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_samples',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_samples'}
          ]
        }
      },
      {
        path: 'preguntas',
        component: SampleTypeComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_pregunta',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_pregunta'}
          ]
        }
      },
      {
        path: 'sample_locations',
        component: SampleLocationComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_sample_loctype',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_sample_loctype'}
          ]
        }
      },
      {
        path: 'locales',
        component: SampleLocationTypeComponent,//is Sample Location Instance
        canActivate: [LoginService],
        data: {
          title: 'menu_locales',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_locales'}
          ]
        }
      },
      {
        path: 'shrimp_sector',
        component: ShrimpSectorComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_mant_shrimp_sector',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_title_mant_shrimp_sector'}
          ]
        }
      },
      {
        path: 'skretting_site',
        component: SkrettingSiteComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_mant_sk_site',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_title_mant_sk_site'}
          ]
        }
      },
      {
        path: 'skretting_sector',
        component: SkrettingSectorComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_mant_sk_sector',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_title_mant_sk_sector'}
          ]
        }
      },
      {
        path: 'sites',
        component: SiteComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_sites',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_sites'}
          ]
        }
      },
      {
        path: 'sitegroups',
        component: SiteGroupComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_mant_sitegroups',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_sitegroups' }
          ]
        }
      },
      {
        path: 'units',
        component: UnitComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_units',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_units'}
          ]
        }
      },
      {
        path: 'analysis',
        component: AnalysisComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_title_mant_analysis',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_title_mant_analysis'}
          ]
        }
      },
      {
        path: 'params/:id',
        component: ParameterComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_respuesta',
          urls: [
            { title: 'menu_crud', url: '/cruds' },
            { title: 'menu_pregunta', url: '/preguntas' },
            { title: 'menu_respuesta' }
          ]
        }
      },
      {
        path: 'cruds',
        component: CrudComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_crud',
          urls: [
            /*{ title: 'Mapas', url: '' },
            { title: 'Mapas' }*/
          ]
        }
      },
      {
        path: 'users',
        component: UserComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_users',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_users'}
          ]
        }
      },
      {
        path: 'sapproval',
        component: SamplingApprovalComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_sapproval',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_sapproval'}
          ]
        }
      },
      {
        path: 'sinput',
        component: SamplinginputComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_sinput',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_sinput'}
          ]
        }
      },
      {
        path: 'repocreator',
        component: RepocreatorComponent,
        //canActivate: [LoginService],
        data: {
          title: 'menu_repocreator',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_repocreator'}
          ]
        }
      },
      {
        path: 'reporting',
        component: ReportingComponent,
        //canActivate: [LoginService],
        data: {
          title: 'menu_reporting',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_reporting'}
          ]
        }
      },
      {
        path: 'reportingapproval',
        component: ReportingapprovalComponent,
        //canActivate: [LoginService],
        data: {
          title: 'menu_reportapproval',
          urls: [
            { title: 'menu_processes', url: '/prochome' },
            { title: 'menu_reportapproval'}
          ]
        }
      },
      {
        path: 'metas',
        component: RefmethodComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_refmethods',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_refmethods'}
          ]
        }
      },
      {
        path: 'visits',
        component: VisitComponent,
        canActivate: [LoginService],
        data: {
          title: 'menu_visits',
          urls: [
              { title: 'menu_crud', url: '/cruds' },
              { title: 'menu_visits'}
          ]
        }
      }
      //end
    ]
  }
];
