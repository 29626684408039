import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

@Component({
  selector: 'app-sample-location',
  templateUrl: './sample-location.component.html'
})
export class SampleLocationComponent extends BaseCrudComponent {

    id:any;
    translations:any;
    loadingRequired = false;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'SampleLocation/All';
        this.create_url = 'SampleLocation/Create';
        this.update_url = 'SampleLocation/Update';
        this.delete_url = 'SampleLocation/LogicalDelete';
        this.search_fields = ['slocName', 'slocKey', 'slocDescription'];


        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.success){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.msg, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'slocKey': new FormControl(fields.slocKey, [Validators.required]),
            'slocName': new FormControl(fields.slocName, [Validators.required])
        });

        return mygroup.valid;
    }

}
