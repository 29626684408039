<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">

                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a btn-rounded" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th>ID</th>
                                <th>Aquasim ID</th>
                                <th>Compa&ntilde;&iacute;a</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows">
                                <td>{{row.CompId}}</td>
                                <td>{{row.CompAquasymId}}</td>
                                <td>{{row.CompName}}</td>
                                <td>
                                    <i (click)="goTo('companycontacts/'+row.CompId, {})" style="cursor:pointer;" class="icon-people text-info m-r-10"></i>
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">Editar Compa&ntilde;&iacute;a {{u_obj.CompName}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">Jira User</label>
                                    <div class="col-sm-9">
                                        <input type="email" class="form-control" [(ngModel)]="u_obj.CompJiraUser" name="CompJiraUser" #CompJiraUser="ngModel" email>
                                        <div class="form-errormsg" *ngIf="!CompJiraUser.valid">
                                            Campo debe ser Email
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">Jira Pwd</label>
                                    <div class="col-sm-9">
                                        <input type="password" class="form-control" [(ngModel)]="u_obj.CompJiraPassword" name="CompJiraPassword">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">Jira Project ID</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj.CompJiraProjectId" name="CompJiraProjectId">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="fname" class="col-sm-3 text-right control-label col-form-label">Jira URL</label>
                                    <div class="col-sm-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj.CompJiraUrl" name="CompJiraUrl">
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" (click)="clearCompanyData()">Limpiar</button>
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
