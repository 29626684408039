<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn btn-custom-003d6a" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th (click)="setOrder('samId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='samId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('samName')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='samName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('samKey')" class="sh">
                                    {{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='samKey')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'desc' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.samId}}</td>
                                <td>{{row.samName}}</td>
                                <td>{{row.samKey}}</td>
                                <td>{{row.samDescription}}</td>
                                <td class="action-buttons">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="deleteItem({samId:row.samId}, translations)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="5">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['samKey']" name="samKey" required maxlength="20">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['samName']" name="samName" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['samDescription']" name="samDescription">
                                    </div>
                                </div>
                                <!--Aqui agregamos combo grupo-->
                                <div class="form-group row">
                                    <label for="lname2" class="col-3 text-right control-label col-form-label">{{'add' | translate}} {{'group' | translate}}</label>
                                    <div class="col-8">
                                        <select class="custom-select custom-select-md" name="agrId" [(ngModel)]="curr_gro">
                                            <option *ngFor="let list of listGrupo; let i = index" [value]="list['agrId']">{{list['agrName']}}</option>
                                        </select>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-custom-003d6a" (click)="addGroup(c_obj)" style="color: #ffffff;">
                                            <i class=" fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!--fin combo grupo-->
                                <!--tabla de detalle-->
                                <div class="form-group row">
                                    <h4>{{'group' | translate}}</h4>
                                    <table class="table">
                                        <thead style="padding: 0">
                                            <tr>
                                                <th>{{'name' | translate}}</th>
                                                <th>{{'action' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let groups of c_obj['SampleDetail']; let i=index">
                                                <td>{{groups.FullName}}</td>
                                                <td class="action-buttons">
                                                    <i (click)="delGroup(c_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!--fin tabla de detalle-->
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['samName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['samKey']" name="samKey" required maxlength="20">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['samName']" name="samName" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['samDescription']" name="samDescription">
                                    </div>
                                </div>
                                <!--aQUI FALTA AGREGAR LO NUEVO-->
                                <!--Aqui agregamos combo grupo-->
                                <div class="form-group row">
                                    <label for="lname2" class="col-3 text-right control-label col-form-label">{{'add' | translate}} {{'group' | translate}}</label>
                                    <div class="col-8">
                                        <select class="custom-select custom-select-md" name="agrId" [(ngModel)]="curr_gro">
                                            <option *ngFor="let list of listGrupo; let i = index" [value]="list['agrId']">{{list['agrName']}}</option>
                                        </select>
                                    </div>
                                    <div class="col-1">
                                        <button type="button" class="btn btn-custom-003d6a" (click)="addGroup(u_obj)" style="color: #ffffff;">
                                            <i class=" fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <!--fin combo grupo-->
                                <!--tabla de detalle-->
                                <div class="form-group row">
                                    <h4>{{'group' | translate}}</h4>
                                    <table class="table">
                                        <thead style="padding: 0">
                                            <tr>
                                                <th>{{'name' | translate}}</th>
                                                <th>{{'action' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let groups of u_obj['SampleDetail']; let i=index">
                                                <td>{{groups.FullName}}</td>
                                                <td class="action-buttons">
                                                    <i (click)="delGroup(u_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!--fin tabla de detalle-->
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Update</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
