import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.css']
})
export class VisitComponent extends BaseCrudComponent {

  	id:any;
    loadingRequired = false;
    listUsers:any;
    translations:any;
    curr_usr:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'Visit/All';
        this.create_url = 'Visit/Create';
        this.update_url = 'Visit/Update';
        this.delete_url = 'Visit/LogicalDelete';
        this.search_fields = ['visGoal'];

        this.listUsers = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

              this.loadingRequired = true;
              this.getUsersData().subscribe(x => {
              		this.loadingRequired = false;

		            if(x != false){
		                this.listUsers = x;
		            }

                  	this.getList();
              });
        });
    }

    public getList(){
        var qst = this.read_url;
        this.loadingList = true;

        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;
            if(x.success){
                let rs = x.val;

                rs.forEach(y => {
                    y['username'] = this.getUserFromID(y['visUserId']);
                    y['visPeriod'] = y['visPeriod'].substring(0, 10);
                    y['visPeriodFinal'] = y['visPeriodFinal'].substring(0, 10);
                });

                console.log(rs);

                this.rawdata = rs;
                this.rows = rs;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
            }
        });
    }

    public getUsersData(){
        //var par_url = 'User/GetLaboratoryWorkers';
        var par_url = 'User/GetTechnicians';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);
                        
                        if(mydata.success == true ){
                          return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    getUserFromID(id:any){
    	let rs:any;

    	rs = this.listUsers.filter(x => {
    		return x['usrId'] == id;
    	});

    	if( rs.length > 0 ){
    		return rs[0]['usrFirstName'] + ' ' + rs[0]['usrLastName'];
    	}
    	else{
    		return '';
    	}
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'visUserId': new FormControl(fields.visUserId, [Validators.required]),
            'visPeriod': new FormControl(fields.visPeriod, [Validators.required]),
            'visPeriodFinal': new FormControl(fields.visPeriodFinal, [Validators.required]),
            'visGoal': new FormControl(fields.visGoal, [Validators.required])
        });

        return mygroup.valid;
    }

    public openCreateModal(content: any) {
        let today:any;
        today = new Date();
        today = today.toISOString();
        today = today.substring(0, 10);

        this.c_obj = {};
        this.c_obj['visPeriod'] = today;
        this.c_obj['visPeriodFinal'] = today;

        super.openCreateModal(content);
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.success){
                this.c_obj = {};
                this.c_obj['LaboratoryDetail'] = [];
                this.curr_usr = 0;
                this.getList();
                this.closeModal(content);
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.msg, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }


}
