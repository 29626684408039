<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row button-group">
                    <div class="col-lg-12 col-xlg-12 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a btn-rounded" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th>ID</th>
                                <th>Piscina</th>
                                <th>Finca</th>
                                <th>Compa&ntilde;&iacute;a</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows">
                                <td>{{row.UniId}}</td>
                                <td>{{row.UniName}}</td>
                                <td>{{row.SitName}}</td>
                                <td>{{row.CompName}}</td>
                                <td>
                                    <i (click)="goTo('unitparams/'+row.UniId, {})" style="cursor:pointer;" class="icon-settings text-info m-r-10"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>

