import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

@Component({
  selector: 'app-analysis-goup',
  templateUrl: './analysis-goup.component.html'
})
export class AnalysisGoupComponent extends BaseCrudComponent {

    id:any;
    loadingRequired = false;
    listAgr:any;
    translations:any;
    curr_container:any;
    listConatiner:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'AnalysisGroup/All';
        this.create_url = 'AnalysisGroup/Create';
        this.update_url = 'AnalysisGroup/Update';
        this.delete_url = 'AnalysisGroup/LogicalDelete';
        this.search_fields = ['agrName', 'agrKey', 'agrDescription'];

        this.c_obj['AnalysisGroupFeature'] = [];
        this.curr_container = 0;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    openCreateModal(content:any){
      //super.openCreateModalLG(content);
      this.c_obj['AnalysisGroupContainer'] = [];
      this.curr_container = 0;

      super.openCreateModal(content);
      this.loadingRequired = true;
      this.getContainerData().subscribe(x => {
        this.loadingRequired = false;
        if(x != false){
          this.listConatiner = x;
        }
      });
    }

    openUpdateModal(content:any, obj:any){
      this.curr_container = 0;
      super.openUpdateModal(content, obj);
      this.loadingRequired = true;
      this.getContainerData().subscribe(x => {
        this.loadingRequired = false;
        if(x != false){
          this.listConatiner = x;
        }
      });
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.success){
                this.c_obj = {};
                this.c_obj['AnalysisGroupFeature'] = [];
                this.getList();
                this.closeModal(content);
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.msg, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'agrKey': new FormControl(fields.agrKey, [Validators.required]),
            'agrName': new FormControl(fields.agrName, [Validators.required]),
            'agrValidPercent': new FormControl(fields.agrValidPercent, [Validators.required, Validators.min(0), Validators.max(100)])
        });

        return mygroup.valid;
    }

    public getContainerData(){
      var par_url = 'SystemParameter/GetField/SAMP_CONTAINER';

      return this.srv.getCall(par_url).pipe(
        mergeMap(data => {
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          //console.log(data);

          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        }),
        //timeout(MAX_TIME),
        catchError(data => of(false))
      );
    }

    public addContainer(obj:any) {

      if( this.curr_container == 0 ){
        return;
      }
      else{
        let usrs = this.listConatiner.filter(f => {
          return f.ID == this.curr_container;
        });

        let checker = obj['AnalysisGroupContainer'].filter(f => {
          return f.ID == this.curr_container;
        });

        if( (usrs.length > 0) && (checker.length == 0) ){
          let container = usrs[0];
          obj['AnalysisGroupContainer'].push({ID: container['ID'], value:container['value'], 'agrId':obj['agrId']});
        }
      }
    }

    public delUser(obj:any, position:number){
      obj['AnalysisGroupContainer'].splice(position, 1);
    }

    public feature(condition: number, position: number) {
        if ( condition ===  1) {
          this.c_obj['AnalysisGroupFeature'].push({agfName: '' });
        } else if (condition === 0) {
          this.c_obj['AnalysisGroupFeature'].splice(position, 1);
        }
    }

    public featureUpdate(condition: number, position: number) {
        if ( condition ===  1) {
          this.u_obj['AnalysisGroupFeature'].push({agfName: '' });
        } else if (condition === 0) {
          this.u_obj['AnalysisGroupFeature'].splice(position, 1);
        }
    }

}
