import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

@Component({
  selector: 'app-laboratory',
  templateUrl: './laboratory.component.html'
})
export class LaboratoryComponent extends BaseCrudComponent {

    id:any;
    loadingRequired = false;
    listUsers:any;
    translations:any;
    curr_usr:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'LaboratoryEntity/All';
        this.create_url = 'LaboratoryEntity/Create';
        this.update_url = 'LaboratoryEntity/Update';
        this.delete_url = 'LaboratoryEntity/LogicalDelete';
        this.search_fields = ['labKey', 'labName', 'labDescription'];

        this.c_obj['LaboratoryDetail'] = [];
        this.curr_usr = 0;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    public getUsersData(){
        var par_url = 'User/GetLaboratoryWorkers';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);
                        
                        if(mydata.success == true ){
                          return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    openCreateModal(content:any){
        //super.openCreateModalLG(content);
        this.c_obj['LaboratoryDetail'] = [];
        this.curr_usr = 0;

        super.openCreateModal(content);
        this.loadingRequired = true;
        this.getUsersData().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.listUsers = x;
            }
        });
    }

    openUpdateModal(content:any, obj:any){
        this.curr_usr = 0;
        super.openUpdateModal(content, obj);
        this.loadingRequired = true;
        this.getUsersData().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.listUsers = x;
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'labKey': new FormControl(fields.labKey, [Validators.required]),
            'labName': new FormControl(fields.labName, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.success){
                this.c_obj = {};
                this.c_obj['LaboratoryDetail'] = [];
                this.curr_usr = 0;
                this.getList();
                this.closeModal(content);
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.msg, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        //debugger;
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    public addUser(obj:any) {
        
        if( this.curr_usr == 0 ){
            return;
        }
        else{
                let usrs = this.listUsers.filter(f => {
                return f.usrId == this.curr_usr;
            });

            let checker = obj['LaboratoryDetail'].filter(f => {
                return f.labdUserId == this.curr_usr;
            });

            if( (usrs.length > 0) && (checker.length == 0) ){
                let usr = usrs[0];
                obj['LaboratoryDetail'].push({labdUserId: usr['usrId'], labdResponsible:false, FullName:usr['usrFirstName']+' '+usr['usrLastName']});
            }
        }
    }

    public delUser(obj:any, position:number){
        obj['LaboratoryDetail'].splice(position, 1);
    }

    public markResponsible(obj:any, position:number){
        //debugger;
        let i = 0;
        obj['LaboratoryDetail'].forEach(x => {
            //x['labdResponsible'] = false;
            
            if( i == position ){
                if (x['labdResponsible']==true)
                {
                    x['labdResponsible'] = false;
                }
                else{
                    x['labdResponsible'] = true;
                }
                
            }

            i++;
        });
    }

}
