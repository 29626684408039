import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import * as moment from 'moment-timezone';

//FOR REPORT
import {PdfMakeWrapper, Txt, Stack, Columns, Table, Cell, Img, Ul, IDocumentNode} from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {PdfestructureService} from "../../../services/pdf/pdfestructure.service";
PdfMakeWrapper.setFonts(pdfFonts);
// import pdfFonts from '../../../../assets/calibri-fonts';
// PdfMakeWrapper.setFonts(pdfFonts, {
//   calibri: {
//     normal: 'calibri.ttf',
//     bold: 'calibri-bold.ttf',
//     italics: 'calibri-italics.ttf',
//     bolditalics: 'calibri-bolditalics.ttf'
//   }
// });
// PdfMakeWrapper.useFont('calibri');


@Component({
  selector: 'app-repocreator',
  templateUrl: './repocreator.component.html',
  styleUrls: ['./repocreator.component.css']
})
export class RepocreatorComponent extends BaseCrudComponent {
  	id:any;
    loadingRequired = false;
    listAgr:any;
    translations:any;

    //for report
    archivo = null;
    DataReport:any;
    new_data: any;
    new_data_grupos_fito: any;
    new_data_grafico_bacte: any;
    imgFooter:any;
    typeReport:string = '';

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private pdfService: PdfestructureService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'Report/All';
        this.create_url = '';
        this.update_url = '';
        this.delete_url = '';
        this.search_fields = ['cmpName', 'sitName', 'agrName', 'UserTechnicalName', 'array.ids'];

        this.c_obj['AnalysisFeature'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    verArchivo() {
      console.log(this.archivo)
    }

    public getList(){
        var qst = this.read_url;
        this.loadingList = true;
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;
            if(x.success){
            	let code = '';
            	let blocks = [];
            	let tmp:any;

            	x.val.forEach(r => {
            		/*r.sel = false;
            		code = r.SitAquasymId + '-' + r.agrId + '-' + r.UserTechnicalId;
            		tmp = blocks.filter(f => {
	            			return f.code == code;
	            		});

            		if( tmp.length > 0 ){
            			tmp = tmp[0];
            			tmp.details.push(r);
            		}
            		else{
            			tmp = {code:code, details:[]};
            			tmp.details.push(r);
            			blocks.push(tmp);
            		}*/

            		r.container = '';
                    r.ids = [];

                    r.Details.forEach(d => {
                        d.sel = false;
                        r.ids.push(d.id);
                    });

            		blocks.push(r);
            	});

                this.rawdata = blocks;
                this.rows = blocks;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
            }
        });
    }

    public openPreviewModal(content:any, obj:any) {
    	var selected = [];
    	  this.typeReport=obj.agrName;
        obj['Details'].forEach(x => {
        	if( x.sel ){
        		selected.push(x.id);
        	}
        });

        if( selected.length <= 0 ){
        	Swal.fire('Debe seleccionar al menos 1 solicitud', '', 'error');
            return;
        }


        this.c_obj = obj;
        //this.modalService.open(content, { size: 'lg' });
        this.modalService.open(content, { backdrop: 'static', keyboard: false });
    }

    public previewItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        /*if( form.container == '' ){
        	Swal.fire('Debe ingresar envase', '', 'error');
            return;
        }*/

        var selected = [];
        form.Details.forEach(x => {
        	if( x.sel ){
        		selected.push(x.id);
        	}
        });

        if( selected.length <= 0 ){
        	Swal.fire('Debe seleccionar al menos 1 solicitud', '', 'error');
            return;
        }

        var to:any;
        to = {
        	"_listSamplingId": selected,
        	"_container": form.container
        };
        //llamada a la data para generar el nuevo formato de reportes

        if(to !=null)
        {
          this.getDataReport(to);

        }

        // const text2 = this.typeReport.toLocaleLowerCase();
        // if (text2.includes('mic'))
        // {
          var qst = 'Report/Preview';
          this.isCreating = true;

          this.srv.postBlobCall(qst, to).subscribe(x => {
            this.isCreating = false;
            //console.log(x);
            let fname = 'preview.pdf';
            fileSaver.saveAs(x, fname);
          });

       //}

    }

    public saveItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        var selected = [];
        form.Details.forEach(x => {
        	if( x.sel ){
        		selected.push(x.id);
        	}
        });

        if( selected.length <= 0 ){
        	Swal.fire('Debe seleccionar al menos 1 solicitud', '', 'error');
            return;
        }

        var to:any;
        to = {
        	"_listSamplingId": selected,
        	"_container": form.container
        };

        var qst = 'Report/Save';
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.success){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
                let numreport=x.val['Id'];
                Swal.fire(this.translations['created'], 'Reporte #: QRN-'+numreport, 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'anKey': new FormControl(fields.anKey, [Validators.required]),
            'anName': new FormControl(fields.anName, [Validators.required]),
            'anGroupId': new FormControl(fields.anGroupId, [Validators.required]),
            'anIsSpecialType': new FormControl(fields.anIsSpecialType, [Validators.required])
        });

        return mygroup.valid;
    }

    checkSampling(item:any, block:any){
        if( block.agrName == 'MIC' ){
            //console.log(block);
            block.Details.forEach(x => {
                x.sel = false;
            });
        }

        item.sel = !item.sel;
    }

    generatePdf(){
      //this.isCreating = true;//para cuando se quite la descarga vieja de los reportes
      //console.log(this.new_data)
      //console.log(this.new_data_grafico_bacte);
      const text = this.typeReport.toLocaleLowerCase();
      //console.log("text:",text)
      let data_report_type:Number = 0;
      if (text.includes('suelo')) {
        data_report_type = 1;
      } else if (text.includes('agua')) {
        data_report_type = 2;
      } else if (text.includes('pcr')) {
        data_report_type = 6;
      } else if (text.includes('bacter')) {
        data_report_type = 3;
      } else if (text.includes('fito')) {
        data_report_type = 4;
      } else if (text.includes('mic')) {
        data_report_type = 7;
      } else if (text.includes('balance')) {
        data_report_type = 5;
      }

      let map_method_reference = new Map();
      let map_title = new Map();
      let map_subTitle = new Map();
      let map_grupo_fito = new Map();
      let map_content = new Map();
      let lista_titulos: any[] = [];
      let lista_subtitulos: any[] = [];
      let lista_contenido: any[] = [];
      let lista_metodos_referencia: any[] = [];
      let lista_grupos_fito: any[] = [];
      let lista_subtitulos_fito: any[] = [];
      let resultado_total = [];
      let total_fito_Valores_x_solicitud = [];
      let titulos_para_concatenar_subtitulos = [];
      let objetoNuevo = {};
      let lista_objetos_de_contenido = [];
      let l = [];
      let l_final =[];
      let lista_detalle_grupos_fito = [];
      let lista_Graficos_bacte = [];
      let lista_tablas_pcr = [];
      let lista_tituls_analisis = [];
      let lista_pcr_detalles = [];
      let obj = {
        name: '',
        reference: '',
        date_init: '',
        date_end: '',
        client: '',
        direction: '',
        solicit: '',
        real: '',
        date_mues: '',
        lug_mues: '',
        tipo: '',
        analisis: '',
        cant: '',
        env: '',
        img_resp: '',
        img_aprob: '',
        direction_pcr : '',
        type_pcr: '',
        reference_number_pcr: '',
        date_reception_pcr: '',
        cantidad_mues_pcr: '',
        forma_present_pcr: '',
        fecha_init_ens_pcr:'',
        condi_mues_pcr: '',
        fecha_term_ens_pcr: '',
        lab_refe_pcr:'',
        tem_amb_pcr: '',
        hum_rel_pcr: '',
        fecha_ent_pcr: '',
        muestra_mic: '',
        fundamento_mic: '',
        resultado_mic: '',
        conclusion_mic: ''
      }
      let contenedor_listas_subtitulos = [];

      let miArregloEstructurado = this.setDatosEstructurados(this.new_data, data_report_type, true);
      map_method_reference = miArregloEstructurado.map_method_reference;
      map_title = miArregloEstructurado.map_title;
      map_grupo_fito = miArregloEstructurado.map_grupo_fito;
      map_content = miArregloEstructurado.map_content;
      lista_subtitulos = miArregloEstructurado.lista_subtitulos;
      lista_tituls_analisis = miArregloEstructurado.lista_tituls_analisis;
      lista_pcr_detalles = miArregloEstructurado.lista_pcr_detalles;
      contenedor_listas_subtitulos = miArregloEstructurado.contenedor_listas_subtitulos;
      obj = miArregloEstructurado.obj;
      // SE REGISTRA OS TITULOS INICIALES
      // console.log(map_title);
      // console.log(lista_tituls_analisis);
      // console.log(lista_pcr_detalles);
      if (data_report_type == 1 || data_report_type == 2 || data_report_type == 5 || data_report_type == 3) {// APLICA AL SER SUELO AGUA Y BALANCE
        lista_titulos = [...map_title].map(([name]) => (
          new Cell( new Txt(name).color('white').fontSize(8).alignment('center').bold().end ).end),
        );
        for ( [key,value] of map_title) {
          if (key != 'ldiSamplingId' && key != 'uniName' && key != 'sampDepth' && key != 'sampleType') {
            titulos_para_concatenar_subtitulos.push(value)
          } else if (key != 'ldiSamplingId' && key != 'uniName' && key != 'sampleType') {
            titulos_para_concatenar_subtitulos.push(value)
          } else if (key != 'ldiSamplingId' && key != 'uniName') {
            titulos_para_concatenar_subtitulos.push(value)
          }
        }
        let mayor = 0;
        let lista_mayor_elementos = null;
        /*for (let listaC of contenedor_listas_subtitulos) {
          if (listaC.length > mayor) {
            mayor = listaC.length;
            lista_mayor_elementos = listaC;
          }
        }*/
        lista_mayor_elementos=this.setListaUnificada(contenedor_listas_subtitulos);//debugger;
        // console.log(contenedor_listas_subtitulos)
        // console.log(lista_mayor_elementos)
        for (var [key, value] of map_method_reference) {
          lista_metodos_referencia.push(
            [
              new Cell( new Txt(key).color('black').fontSize(7).bold().end).fillColor('#E8E7E8').end,
              new Cell( new Txt(value).color('black').fontSize(7).end ).end
            ]
          )

        }

        for (var [key, value] of map_content) {
          for(let valor of value) {
            lista_objetos_de_contenido.push(valor);
            for (let propiedad in valor) {
              if (objetoNuevo[propiedad] == undefined) {
                objetoNuevo[propiedad] = "S/N";
              }
            }
          }
        }
        console.log("map_content:",map_content)
        lista_objetos_de_contenido.forEach( element => {
          let obj_tmp = JSON.parse(JSON.stringify(objetoNuevo))
          for (let propiedad in element) {
            obj_tmp[propiedad] = element[propiedad];
          }
          l.push(obj_tmp);
        })
        for (let i of l)
        {
          let l_tmp=[];
          for (var [key, value] of map_title)
          {
            let valor=value;
            if (i[valor] !== undefined)
            {
              if(data_report_type !== 3) {
                l_tmp.push(new Cell( new Txt(i[valor]).color('black').fontSize(7).alignment('center').end ).end)
              } else {
                if (valor != 'ldiSamplingId' && valor != 'uniName' && valor != 'sampleType') {
                  if (i[valor] !== 'S/N' && i[valor] !== 'tnt' ) {
                    let v = Number(i[valor]);
                    l_tmp.push(new Cell( new Txt(v.toExponential(2)).color('black').fontSize(7).alignment('center').end ).end)
                  } else {
                    l_tmp.push(new Cell( new Txt(i[valor]).color('black').fontSize(7).alignment('center').end ).end)
                  }
                } else {
                  l_tmp.push(new Cell( new Txt(i[valor]).color('black').fontSize(7).alignment('center').end ).end)
                }
              }
            }
          }
          l_final.push(l_tmp);
        }
        // UNA VEZ GENERADO EL CONTENIDO, SE PROCEDE A ELIMINAR LOS TITULOS AÑADIDOS
        if (data_report_type == 1) {
          lista_titulos.splice(0,4);
          // l_final.splice(10,4);
        }
        else if (data_report_type == 5) {
          lista_titulos.splice(0,2);
          // l_final.splice(10,4);
        } else if (data_report_type == 2 || data_report_type == 3) {
          lista_titulos.splice(0,3);
        }
        // SE RECORRE ESTA LISTA PARA POSICIONAR LOS SUBTITULOS DE ACORDE CON LOS TITULOS
        lista_subtitulos = this.setListaSubtitulos(titulos_para_concatenar_subtitulos, lista_mayor_elementos);
      }
      else if (data_report_type == 4) // APLICA AL SER FITO - CAMBIO JEIS
      {
        lista_titulos = [...map_title].map(([name]) => (
          new Cell( new Txt(name).color('white').fontSize(10).alignment('center').bold().end ).end),
        );
        lista_grupos_fito = [...map_grupo_fito].map(([name]) => (
          new Cell( new Txt(name + '*').color('003D6A').fontSize(9).alignment('center').bold().end ).fillColor('#E8E7E8').end),
        );
        lista_subtitulos_fito = [...map_grupo_fito].map(([name]) => (
          name
        ));
        let tmp_l =  [...map_title].map(([name]) => (
          name
        ));
        let celdas_vacias = [];
        for (let i = 0; i < tmp_l.length -1; i++) {
          celdas_vacias.push(new Cell(new Txt('').end).end);
        }
        //console.log("map_content:",map_content)
        for(let [key, value] of map_content) {
          let x = [], z = [];
          let llave = key.split('--');
          x.push(llave[1]);
          x.push(new Cell(new Txt(llave[0]).color('black').fontSize(8).alignment('left').end ).end);
          for (let t of tmp_l) {
            if(value[t] !== undefined) {
              z.push(new Cell(new Txt(value[t]).color('black').fontSize(8).alignment('center').end).end);
            } else {
              z.push(new Cell(new Txt('0').color('black').fontSize(8).alignment('center').end).end);
            }
          }
          x.push(z)
          lista_detalle_grupos_fito.push(x)
        }
        // console.log(resultado_total);
        for ( let i = 0; i < tmp_l.length; i++) {
          for(let [key, value] of map_content) {
            // console.log(value[tmp_l[i]]);
            if (tmp_l[i], '-', value[tmp_l[i]] !== undefined) {
              if (resultado_total[i] == undefined) {
                resultado_total[i] = 0;
              }
              resultado_total[i] = resultado_total[i] + Number(value[tmp_l[i]])
            } else {
              if (resultado_total[i] == undefined) {
                resultado_total[i] = 0;
              }
              resultado_total[i] = resultado_total[i] + 0
            }
          }
        }
        //console.log("resultado_total:",resultado_total)
        for (let r of resultado_total) {
          total_fito_Valores_x_solicitud.push(new Cell(new Txt(r.toString()).color('white').fontSize(9).bold().alignment('center').end).border([true,true,true,true]).fillColor('#f62d51').end);
        }
        // console.log(resultado_total); // lista de subtitulos
        for ( let variable of lista_detalle_grupos_fito ) {
          let ls = [] ;
          ls.push(variable[0]);
          variable[2].unshift(variable[1]);
          ls.push(variable[2]);
          l_final.push(ls);
        }
        // ESTA FUNCION SE ENCARGA DE AGREGAR EN UNA LISTA LOS GRUPOS DE FITO Y POSTERIOR A ELLA AÑADIR CADA VARAIBLE QUE TENGA EL MISMO GRUPO
        for (let sb of lista_subtitulos_fito) {
          let a = [];
          if (a.length == 0) {
            a.push(new Cell( new Txt(sb.trim() + ' *').color('#003D6A').fontSize(9).bold().end ).alignment('left').fillColor('#E8E7E8').colSpan(tmp_l.length + 1).end);
            for (let i = 0 ; i < tmp_l.length; i++) {
              a.push(new Cell(new Txt('').end).end)
            }
            lista_contenido.push(a);
          }
          //console.log("lista_contenido:",lista_contenido)
          for (let v of l_final) {
            if (sb == v[0]) {
              lista_contenido.push(v[1])

            }
          }
          //console.log("new_data_grupos_fito:",this.new_data_grupos_fito)
          for (let df of this.new_data_grupos_fito) {
            if (df.anName == sb) {
              let valores = Object.values(df);
              let recorte = valores.splice(valores.length -1, 1);
              // console.log(valores)
              let ltmp = [];
              ltmp.push(new Cell(new Txt('Total').color('#f62d51').fontSize(9).bold().end ).alignment('left').end);
              for (let vl of valores) {
                ltmp.push(new Cell(new Txt(vl == null ? '0': vl.toString()).color('#f62d51').fontSize(9).bold().end ).alignment('center').end);
              }
              lista_contenido.push(ltmp);contenedor_listas_subtitulos
            }
          }
        }
        for (var [key, value] of map_method_reference) {
          lista_metodos_referencia.push(
            [
              new Cell( new Txt(key).color('black').fontSize(9).end).end, // .fillColor('#E8E7E8')
              new Cell( new Txt(value).color('black').fontSize(9).end ).end
            ]
          )

        }


        // METODO PARA ELIMINAR LAS FILAS CON NUMEROS 0
        let lista_contenido_temporal = lista_contenido.slice();
        lista_contenido = [];
        for (let j = 0; j < lista_contenido_temporal.length; j ++) {
          let contador = 0;
          for (let k = 1; k < lista_contenido_temporal[j].length; k++) {
            if (lista_contenido_temporal[j][k].text == '0') {
              contador ++;
            }
          }
          if(contador !== tmp_l.length) {
            lista_contenido.push(lista_contenido_temporal[j]);
          }
        }

        //CODIGO ANTERIOR JEIS
        // lista_titulos = [...map_title].map(([name]) => (
        //   new Cell( new Txt(name).color('white').fontSize(10).alignment('center').bold().end ).end),
        // );
        // lista_grupos_fito = [...map_grupo_fito].map(([name]) => (
        //   new Cell( new Txt(name + '*').color('003D6A').fontSize(9).alignment('center').bold().end ).fillColor('#E8E7E8').end),
        // );
        // lista_subtitulos_fito = [...map_grupo_fito].map(([name]) => (
        //   name
        // ));
        // let tmp_l =  [...map_title].map(([name]) => (
        //   name
        // ));
        // let celdas_vacias = [];
        // for (let i = 0; i < tmp_l.length -1; i++) {
        //   celdas_vacias.push(new Cell(new Txt('').end).end);
        // }
        // for(let [key, value] of map_content) {
        //   let x = [], z = [];
        //   let llave = key.split('--');
        //   x.push(llave[1]);
        //   x.push(new Cell(new Txt(llave[0]).color('black').fontSize(8).alignment('left').end ).end);
        //   for (let t of tmp_l) {
        //     if(value[t] !== undefined) {
        //       z.push(new Cell(new Txt(value[t]).color('black').fontSize(8).alignment('center').end).end);
        //     } else {
        //       z.push(new Cell(new Txt('0').color('black').fontSize(8).alignment('center').end).end);
        //     }
        //   }
        //   x.push(z)
        //   lista_detalle_grupos_fito.push(x)
        // }
        // // console.log(resultado_total);
        // for ( let i = 0; i < tmp_l.length; i++) {
        //   for(let [key, value] of map_content) {
        //     // console.log(value[tmp_l[i]]);
        //     if (tmp_l[i], '-', value[tmp_l[i]] !== undefined) {
        //       if (resultado_total[i] == undefined) {
        //         resultado_total[i] = 0;
        //       }
        //       resultado_total[i] = resultado_total[i] + Number(value[tmp_l[i]])
        //     } else {
        //       if (resultado_total[i] == undefined) {
        //         resultado_total[i] = 0;
        //       }
        //       resultado_total[i] = resultado_total[i] + 0
        //     }
        //   }
        // }
        // for (let r of resultado_total) {
        //   total_fito_Valores_x_solicitud.push(new Cell(new Txt(r.toString()).color('white').fontSize(9).bold().alignment('center').end).border([true,true,true,true]).fillColor('#f62d51').end);
        // }
        // // console.log(resultado_total); // lista de subtitulos
        // for ( let variable of lista_detalle_grupos_fito ) {
        //   let ls = [] ;
        //   ls.push(variable[0]);
        //   variable[2].unshift(variable[1]);
        //   ls.push(variable[2]);
        //   l_final.push(ls);
        // }
        // // ESTA FUNCION SE ENCARGA DE AGREGAR EN UNA LISTA LOS GRUPOS DE FITO Y POSTERIOR A ELLA AÑADIR CADA VARAIBLE QUE TENGA EL MISMO GRUPO
        // for (let sb of lista_subtitulos_fito) {
        //   let a = [];
        //   if (a.length == 0) {
        //     a.push(new Cell( new Txt(sb.trim() + ' *').color('#003D6A').fontSize(9).bold().end ).alignment('left').fillColor('#E8E7E8').colSpan(tmp_l.length + 1).end);
        //     for (let i = 0 ; i < tmp_l.length; i++) {
        //       a.push(new Cell(new Txt('').end).end)
        //     }
        //     lista_contenido.push(a);
        //   }
        //   for (let v of l_final) {
        //     if (sb == v[0]) {
        //       lista_contenido.push(v[1])
        //
        //     }
        //   }
        //   for (let df of this.new_data_grupos_fito) {
        //     if (df.anName == sb) {
        //       let valores = Object.values(df);
        //       let recorte = valores.splice(valores.length -1, 1);
        //       // console.log(valores)
        //       let ltmp = [];
        //       //ltmp.push(new Cell(new Txt(' ').fontSize(8).bold().end ).alignment('center').end);
        //       ltmp.push(new Cell(new Txt('Total').color('#f62d51').fontSize(9).bold().end ).alignment('left').end);
        //       for (let vl of valores) {
        //         ltmp.push(new Cell(new Txt(vl == null ? '0': vl.toString()).color('#f62d51').fontSize(9).bold().end ).alignment('center').end);
        //       }
        //       lista_contenido.push(ltmp);contenedor_listas_subtitulos
        //     }
        //   }
        // }
        // for (var [key, value] of map_method_reference) {
        //   lista_metodos_referencia.push(
        //     [
        //       new Cell( new Txt(key).color('black').fontSize(9).end).end, // .fillColor('#E8E7E8')
        //       new Cell( new Txt(value).color('black').fontSize(9).end ).end
        //     ]
        //   )
        //
        // }

        // METODO PARA ELIMINAR LAS FILAS CON NUMEROS 0
        // let lista_contenido_temporal = lista_contenido.slice();
        // lista_contenido = [];
        // for (let j = 0; j < lista_contenido_temporal.length; j ++) {
        //   let contador = 0;
        //   for (let k = 1; k < lista_contenido_temporal[j].length; k++) {
        //     if (lista_contenido_temporal[j][k].text == '0') {
        //       contador ++;
        //     }
        //   }
        //   if(contador !== tmp_l.length) {
        //     lista_contenido.push(lista_contenido_temporal[j]);
        //   }
        // }
        // console.log(tmp_l)
        // console.log(lista_subtitulos_fito)
        // console.log(l_final)
        // console.log(lista_contenido)
      }
      else if (data_report_type == 6) { // APLICA AL SER PCR
        for (let i of lista_tituls_analisis) {
          let l = [];
          for (let j of lista_pcr_detalles) {
            if (i === j[0]) {
              l.push([j[1].ldiSamplingId,j[1].parName,j[1].numRef,j[1].methodref,j[1].parSequence,j[1].sampleType,j[1].numAnalisis,j[1].ldidValue,j[1].pvalueInfo])
            }
          }
          let x = new Table(
            [
              [
                new Cell( new Txt('Id de Solicitud').color('white').fontSize(7).bold().end ).end,
                new Cell( new Txt('Parámetros').color('white').fontSize(7).bold().end ).end,
                new Cell( new Txt('Número de Referencia').color('white').fontSize(7).bold().end ).end,
                new Cell( new Txt('Método de Referencia').color('white').fontSize(7).bold().end ).end,
                new Cell( new Txt('Cod. de Muestras').color('white').fontSize(7).bold().end ).end,
                new Cell( new Txt('Tipo de Muestra').color('white').fontSize(7).bold().end ).end,
                new Cell( new Txt('No. Análisis').color('white').fontSize(7).bold().end ).end,
                new Cell( new Txt('Resultado').color('white').fontSize(7).bold().end ).end,
                new Cell( new Txt('Resultado Esperado').color('white').fontSize(7).bold().end ).end,
              ],
              ...l
            ]
          )
            .dontBreakRows(true)
            .alignment('center')
            .layout({
              fillColor: (rowIndex: number, node:any, columnIndex: number) => {
                return rowIndex === 0 ? '#f62d51' : '';
              },
            })
            .margin([0,10,0,0])
            .widths('auto')
            .end;
          lista_tablas_pcr.push(x);
        }
      }
      // console.log(lista_titulos);
      // console.log(lista_subtitulos);
      // console.log(l_final);
      // console.log(lista_tablas_pcr)

      let info = {
        title: 'REPORTE',
        author: 'SKRETTING',
        subject: 'LISTADO',
      }
      let header_detail = {
        name: obj.name,
        reference: '',
        date_init: obj.date_init,
        date_end: '',
        title : 'LABORATORIO DE ENSAYO DE SERVICIOS ACUÍCOLAS',
        subtitle: 'GISIS S.A.'
      }
      //console.log(lista_contenido)
      const pdf = this.pdfService.getStructurePdf(info, header_detail).then(async data => {
        if (data_report_type == 1 || data_report_type == 2  ||  data_report_type == 5 || data_report_type == 3) {
          data.add([
              new Txt('INFORME DE RESULTADOS').fontSize(11).alignment('left').bold().margin([0,12,0,5]).end,
              new Columns([new Txt('Cliente: ').bold().fontSize(8).end, new Txt(obj.client).fontSize(8).end, new Txt('Fecha de muestreo: ').fontSize(8).bold().end,new Txt(obj.date_mues).fontSize(8).end ]).end,
              new Columns([new Txt('Direccion o Zona: ').fontSize(8).bold().end, new Txt(obj.direction).fontSize(8).end, new Txt('Lugar de muestreo: ').fontSize(8).bold().end, new Txt(obj.lug_mues).fontSize(8).end ]).end,
              new Columns([new Txt('Solicitado por: ').fontSize(8).bold().end, new Txt(obj.solicit).fontSize(8).end,'','']).end,
              new Columns([new Txt('Realizado por: ').fontSize(8).bold().end, new Txt('Skretting').fontSize(8).end,'','']).end,
              new Txt('Datos de muestra:').fontSize(10).alignment('left').bold().margin([0,12,0,5]).end,
              new Table(
                [
                  [
                    new Cell( new Txt('Tipo: ').fontSize(8).bold().end ).fillColor('#e5e4e4').end,new Cell( new Txt(obj.tipo).fontSize(8).end ).end
                  ],
                  [
                    new Cell( new Txt('Analisis solicitado: ').fontSize(8).bold().end ).fillColor('#e5e4e4').end,new Cell( new Txt(obj.analisis).fontSize(8).end ).end
                  ],
                  [
                    new Cell( new Txt('Cantidad: ').fontSize(8).bold().end ).fillColor('#e5e4e4').end,new Cell( new Txt(obj.cant).fontSize(8).end ).end
                  ],
                  [
                    new Cell( new Txt('Envase: ').fontSize(8).bold().end ).fillColor('#e5e4e4').end,new Cell( new Txt(obj.env).fontSize(8).end ).end
                  ]
                ]
              )
                .widths(['auto','*'])
                .end,
              new Txt('Resultados:').fontSize(10).alignment('left').bold().margin([0,12,0,5]).end
            ]
          );
          if(data_report_type == 1) { // SUELO
            data.add([
                new Table(
                  [
                    [
                      new Cell( new Txt('Parametros ').color('white').fontSize(8).bold().end ).colSpan(2).end,
                      new Cell(new Txt('').end).end,
                      new Cell( new Txt('Profundidad ').color('white').fontSize(8).bold().end ).end,
                      new Cell( new Txt('Tipo de muestra ').color('white').fontSize(8).bold().end ).end,
                      ...lista_titulos
                    ],
                    [
                      new Cell( new Txt('Id de Solicitud ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      new Cell( new Txt('Códigos de muestra ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      new Cell( new Txt('0-10 cm ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      new Cell( new Txt(' ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      ...lista_subtitulos
                    ],
                    ...l_final

                  ]
                )
                  .dontBreakRows(true)
                  .alignment('center')
                  .layout({
                    fillColor: (rowIndex: number, node:any, columnIndex: number) => {
                      return rowIndex === 0 ? '#f62d51' : '';
                    },
                  })
                  .widths('auto')
                  .end,
                new Txt('*SN: No Solicitado').fontSize(6.5).alignment('left').margin([0,7,0,10]).end,
              ]
            )
          }
          else if(data_report_type == 2) { // AGUA
            data.add([
                new Table(
                  [
                    [
                      new Cell( new Txt('Parametros ').color('white').fontSize(7).bold().end ).colSpan(2).end,
                      new Cell(new Txt('').end).end,
                      new Cell( new Txt('Tipo de muestra').color('white').fontSize(8).bold().end ).end,
                      ...lista_titulos
                    ],
                    [
                      new Cell( new Txt('Id de Solicitud ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      new Cell( new Txt('Códigos de muestra ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      new Cell( new Txt(' ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      ...lista_subtitulos
                    ],
                    ...l_final

                  ]
                )
                  .dontBreakRows(true)
                  .alignment('center')
                  .layout({
                    fillColor: (rowIndex: number, node:any, columnIndex: number) => {
                      return rowIndex === 0 ? '#f62d51' : '';
                    },
                  })
                  .widths('auto')
                  .end,
                new Txt('*SN: No Solicitado').fontSize(6.5).alignment('left').margin([0,7,0,10]).end,
              ]
            )
          }
          else if(data_report_type == 3) { // BACTERIOLOGIA
            data.add([
                new Table(
                  [
                    [
                      new Cell( new Txt('Parámetros').color('white').fontSize(7).bold().end ).colSpan(2).end,
                      new Cell(new Txt('').end).end,
                      new Cell( new Txt('Tipo de muestra').color('white').fontSize(8).bold().end ).end,
                      ...lista_titulos
                    ],
                    [
                      new Cell( new Txt('Id de Solicitud').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      new Cell( new Txt('Códigos de muestra').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      new Cell( new Txt(' ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      ...lista_subtitulos
                    ],
                    ...l_final
                  ]
                )
                  .dontBreakRows(true)
                  .alignment('center')
                  .layout({
                    fillColor: (rowIndex: number, node:any, columnIndex: number) => {
                      return rowIndex === 0 ? '#f62d51' : '';
                    },
                  })
                  .widths('auto')
                  .end,
                new Txt('*SN: No Solicitado').fontSize(6.5).alignment('left').margin([0,7,0,10]).end,
                new Txt('e= Exponencial').fontSize(6.5).alignment('left').italics().margin([0,0,0,0]).end,
                new Txt('mnp= muy numerosas para contar').fontSize(6.5).alignment('left').italics().margin([0,0,0,0]).end,
                new Txt('<100 significa ausencia en 2 diluciones').fontSize(6.5).alignment('left').italics().margin([0,0,0,0]).end,
                new Txt('UFC : Unidad Formadoras de Colonias').fontSize(6.5).alignment('left').italics().margin([0,0,0,0]).end,
                new Txt('Gráficos:').fontSize(10).alignment('left').bold().margin([0,20,0,5]).end
              ]
            );
            if (this.new_data_grafico_bacte.length > 0) {
              for (let i = 0; i < this.new_data_grafico_bacte.length ; i++) {
                data.add(await new Img(this.new_data_grafico_bacte[i].Grafic).width(350).alignment('center').build());
              }
            }
          }
          else if(data_report_type == 5) // BALANCE
          {
            data.add([
                new Table(
                  [
                    [
                      new Cell( new Txt('Parametros ').color('white').fontSize(7).bold().end ).colSpan(2).end,
                      new Cell(new Txt('').end).end,
                      ...lista_titulos
                    ],
                    [
                      new Cell( new Txt('Id de Solicitud ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      new Cell( new Txt('Piscinas ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
                      ...lista_subtitulos
                    ],
                    ...l_final

                  ]
                )
                  .dontBreakRows(true)
                  .alignment('center')
                  .layout({
                    fillColor: (rowIndex: number, node:any, columnIndex: number) => {
                      return rowIndex === 0 ? '#f62d51' : '';
                    },
                  })
                  .widths('auto')
                  .end,
                new Txt('*SN: No Solicitado').fontSize(6.5).alignment('left').margin([0,7,0,10]).end,
              ]
            );
            data.add(
              [

                new Stack(
                  [
                    new Txt('Los rangos de Referencia corresponden a las concentraciones de los iones presentes en agua de mar').fontSize(8).end,
                    new Txt('La Concentración mínima de Ca, K, Mg presente en 1 parte de sal es la siguiente:').fontSize(8).end,
                    new Ul([
                      'Calcio 11.6 mg/L',
                      'Potasio 10.7 mg /L',
                      'Magnesio 39.1 mg / Lt'
                    ]).fontSize(8).end,
                    new Txt('Simbología').bold().fontSize(8).margin([0,3,0,3]).end,
                    new Txt([new Txt('Rango Bajo y Elevado es de acuerdo de la sensibilidad del equipo ').fontSize(8).end, new Txt('Move 100').bold().fontSize(8).end]).margin([0,0,0,3]).end,
                    new Ul([
                      'Ca = 5 – 160 mg/l',
                      'K = 5– 50 mg/l',
                      'Mg = 5 – 75 mg/l'
                    ]).fontSize(8).end,
                  ]
                ).end
              ]
            )
          }

          if (lista_metodos_referencia.length !=0)
          {
            data.add([

                new Txt('Método de referencia:').fontSize(10).alignment('left').bold().margin([0,12,0,5])
                  .end,
                new Table(
                  [
                    ...lista_metodos_referencia
                  ]
                )
                  .margin([0,0,0,20])
                  .widths(['auto','*'])
                  .end,
              ]
            );
          } else {
            data.add(
              new Txt('').margin([0,25,0,0]).end
            )
          }

          data.add([
            new Table(
              [
                [
                  new Cell(new Txt('Realizado por:').fontSize(7).alignment('center').bold().end).end,
                  new Cell(new Txt('Aprobado por:').fontSize(7).alignment('center').bold().end).end
                ],
                [
                  new Cell(await new Img(obj.img_resp).width('150').build()).end,
                  new Cell(await new Img(obj.img_aprob).width('150').build()).end
                ],
                [
                  new Txt('Nota:').fontSize(6.5).bold().alignment('left').margin([0,7,0,0]).end,
                  ''
                ],
                [
                  new Txt('Este reporte no tiene validez sino tiene la firma autorizada.').alignment('left').fontSize(6.5).margin([0,0,0,10]).end,
                  ''
                ]
              ]
            )
              .alignment('center')
              .layout('noBorders')
              .widths('*')
              .end,
          ]);
          data.pageBreakBefore(
            (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
              let positionReference = 0, positionnombrefirmas = 0, positionfirmas = 0, positionNota = 0;
              let valor = 0;
              if (currentNode.text === 'Método de referencia:') {
                 console.log("followingNodesOnPage:",followingNodesOnPage);
                console.log("nodesOnNextPage:",nodesOnNextPage);
                positionReference = currentNode.pageNumbers[0];
                // Voy a verificar si existe el campo con imagen afuera o dentro de una tabla
                for ( let i of followingNodesOnPage) {
                  // verifico si existe el image
                  if (i.table !== undefined) {
                    // existe la tabla y pregunto por la imagen
                    if (i.table.body !== undefined) {
                      // console.log(i.table.body)
                      if (i.table.body.length > 1) {
                        positionnombrefirmas = i.table.body[0][0].nodeInfo.pageNumbers[0];
                        if (i.table.body[1][0].image !== undefined) {
                          if (i.table.body[1][0].image !== undefined) { // i.table.body[1][0].image == '$$pdfmake$$2'
                            positionfirmas = i.table.body[1][0].nodeInfo.pageNumbers[0];
                          }
                        }
                      }
                      if (i.table.body.length > 3) {
                        if (i.table.body[3][0].text == 'Este reporte no tiene validez sino tiene la firma autorizada.') {
                          positionNota = i.table.body[3][0].nodeInfo.pageNumbers[0];
                        }
                      }
                    }
                  }
                }
                 //console.log(positionReference, positionnombrefirmas, positionfirmas,positionNota);
                if (positionReference < positionnombrefirmas) {
                   //console.log('entro 1')
                  valor = 1;
                } else if ((positionReference == positionnombrefirmas) && (positionnombrefirmas < positionfirmas)) {
                   //console.log('entro 2')
                  valor = 1;
                } else if ((positionReference == positionnombrefirmas) && (positionnombrefirmas == positionfirmas) && (positionfirmas < positionNota)) {
                   //console.log('entro 3')
                  valor = 1;
                } else if(positionReference==1 && positionnombrefirmas==0 && positionfirmas==0 && positionNota==0)
                {
                  //console.log('nuevo 5')
                  valor = 1;
                }
                else {
                   //console.log('entro 4')
                  valor = 0;
                }
              }
              return valor == 1 ? true : false;
            }
          )
          // data.create().getDataUrl( dataUrl => {
          //     console.log(dataUrl)
          // })
          // data.create().getBase64( base64 => {
          //     console.log(base64)
          // })
          data.create().download('reporte');
        }
        else if (data_report_type == 4)  // FITO
        {
          data.add([
            new Txt('INFORME DE RESULTADOS').fontSize(11).alignment('left').bold().margin([0,12,0,5]).end,
            new Columns([new Txt('Cliente: ').bold().fontSize(8).end, new Txt(obj.client).fontSize(8).end, new Txt('Fecha de muestreo: ').fontSize(8).bold().end,new Txt(obj.date_mues).fontSize(8).end ]).end,
            new Columns([new Txt('Direccion o Zona: ').fontSize(8).bold().end, new Txt(obj.direction).fontSize(8).end, new Txt('Lugar de muestreo: ').fontSize(8).bold().end, new Txt(obj.lug_mues).fontSize(8).end ]).end,
            new Columns([new Txt('Solicitado por: ').fontSize(8).bold().end, new Txt(obj.solicit).fontSize(8).end,'','']).end,
            new Columns([new Txt('Realizado por: ').fontSize(8).bold().end, new Txt('Skretting').fontSize(8).end,'','']).end
          ]);
          data.add([
            new Table(
              [
                [
                  new Cell( new Txt('ID Solicitud').color('white').fontSize(10).bold().end ).end,
                  ...lista_titulos
                ],
                [
                  new Cell( new Txt('Grupo / C. Muestra').color('white').fontSize(10).bold().end ).end,
                  ...lista_subtitulos
                ],
                ...lista_contenido,
                [
                  new Cell( new Txt('TOTAL FITOPLANCTON').color('black').fontSize(10).bold().end).end,
                  ...total_fito_Valores_x_solicitud
                ]
              ]
            )
              .dontBreakRows(true)
              .alignment('center')
              .layout(
                {
                  fillColor: (rowIndex: number, node:any, columnIndex: number) => {
                    return rowIndex === 0 || rowIndex === 1 ? '#f62d51' : '';
                  },
                  vLineColor: (rowIndex?: number, node?: any, columnIndex?: number) => {
                    if (columnIndex > 2 && columnIndex < node.table.body.length - 1 ) {
                      return rowIndex ===0 || rowIndex === node.table.widths.length ? 'black' : 'white';
                    }
                  },
                  // hLineColor: (rowIndex?: number, node?: any, columnIndex?: number) => {
                  //   if (rowIndex > 2 && rowIndex < (node.table.body.length - 1) ) {
                  //     return (columnIndex  >= 0  && columnIndex <= node.table.body.length) ? 'white' : 'black';
                  //   }
                  // },
                }
              )
              .width('auto')
              .margin([0,15,0,0])
              .end
          ]);
          data.add([

            new Txt('Referencia de Resultados:').fontSize(10).alignment('left').bold().margin([0,12,0,5])
              .end,
            new Table(
              [
                [
                  new Cell( new Txt('Rangos para estanques de producción').color('white').fontSize(10).bold().alignment('center').end).fillColor('#f62d51').colSpan(3).end,
                  '',''
                ],
                [
                  new Cell(new Txt('GRUPOS').color('black').fontSize(8).margin([0,5,0,0]).end).fillColor('#E8E7E8').rowSpan(2).end,
                  new Cell( new Txt('Cel/ml').color('white').fontSize(8).bold().alignment('center').end).fillColor('#f62d51').colSpan(2).end,
                  ''
                ],
                [
                  '',
                  new Cell( new Txt('Mínimo').color('black').fontSize(8).alignment('center').end).fillColor('#E8E7E8').end,
                  new Cell( new Txt('Máximo').color('black').fontSize(8).alignment('center').end).fillColor('#E8E7E8').end
                ],
                [
                  new Cell( new Txt('DIATOMEAS').color('black').fontSize(8).alignment('left').end).end,
                  new Cell( new Txt('20000').color('black').fontSize(8).alignment('center').end).end,
                  new Cell( new Txt('-').color('black').fontSize(8).alignment('center').end).end,
                ],
                [
                  new Cell( new Txt('CHLOROPHYTA').color('black').fontSize(8).alignment('left').end).end,
                  new Cell( new Txt('50000').color('black').fontSize(8).alignment('center').end).end,
                  new Cell( new Txt('-').color('black').fontSize(8).alignment('center').end).end
                ],
                [
                  new Cell( new Txt('CIANOPHYTA').color('black').fontSize(8).alignment('left').end).end,
                  new Cell( new Txt('10000').color('black').fontSize(8).alignment('center').end).end,
                  new Cell( new Txt('40000').color('black').fontSize(8).alignment('center').end).end
                ],
                [
                  new Cell( new Txt('DINOPHYTA').color('black').fontSize(8).alignment('left').end).end,
                  new Cell( new Txt('-').color('black').fontSize(8).alignment('center').end).end,
                  new Cell( new Txt('500').color('black').fontSize(8).alignment('center').end).end
                ],
                [
                  new Cell( new Txt('MICROALGAS TOTALES').color('black').bold().fontSize(8).alignment('left').italics().end).end,
                  new Cell( new Txt('80000').color('white').fontSize(8).bold().alignment('center').end).fillColor('#f62d51').end,
                  new Cell( new Txt('300000').color('white').fontSize(8).bold().alignment('center').end).fillColor('#f62d51').end
                ]
              ]
            )
              .dontBreakRows(true)
              .widths('*')
              .end
          ]);
          if (lista_metodos_referencia.length !=0)
          {
            data.add([

                new Txt('Método de referencia:').fontSize(10).alignment('left').bold().margin([0,12,0,5])
                  .end,
                new Table(
                  [
                    ...lista_metodos_referencia
                  ]
                )
                  .margin([0,0,0,20])
                  .widths(['auto','*'])
                  .end,
              ]
            );
          } else {
            data.add(
              new Txt('').margin([0,25,0,0]).end
            )
          }
          data.add([
            new Table(
              [
                [
                  new Cell(new Txt('Realizado por:').fontSize(7).alignment('center').bold().end).end,
                  new Cell(new Txt('Aprobado por:').fontSize(7).alignment('center').bold().end).end
                ],
                [
                  new Cell(await new Img(obj.img_resp).width('150').build()).end,
                  new Cell(await new Img(obj.img_aprob).width('150').build()).end
                ],
                [
                  new Txt('Nota:').fontSize(6.5).bold().alignment('left').margin([0,7,0,0]).end,
                  ''
                ],
                [
                  new Txt('Este reporte no tiene validez sino tiene la firma autorizada.').alignment('left').fontSize(6.5).margin([0,0,0,10]).end,
                  ''
                ]
              ]
            )
              .alignment('center')
              .layout('noBorders')
              .widths('*')
              .end,
          ]);
          data.pageBreakBefore(
            (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {

              if (currentNode.text === 'Método de referencia:') {
                let positionReference = 0, positionnombrefirmas = 0, positionfirmas = 0, positionNota = 0;
                let valor = 0;
                positionReference = currentNode.pageNumbers[0];
                // Voy a verificar si existe el campo con imagen afuera o dentro de una tabla
                for ( let i of followingNodesOnPage) {
                  // verifico si existe el image
                  if (i.table !== undefined) {
                    // existe la tabla y pregunto por la imagen
                    if (i.table.body !== undefined) {
                      // console.log(i.table.body)
                      if (i.table.body.length > 1) {
                        positionnombrefirmas = i.table.body[0][0].nodeInfo.pageNumbers[0];
                        if (i.table.body[1][0].image !== undefined) {
                          if (i.table.body[1][0].image == '$$pdfmake$$1') {
                            positionfirmas = i.table.body[1][0].nodeInfo.pageNumbers[0];
                          }
                        }
                      }
                      if (i.table.body.length > 3) {
                        if (i.table.body[3][0].text == 'Este reporte no tiene validez sino tiene la firma autorizada.') {
                          positionNota = i.table.body[3][0].nodeInfo.pageNumbers[0];
                        }
                      }
                    }
                  }
                }
                if (positionReference < positionnombrefirmas) {
                  // console.log('entro 1')
                  valor = 1;
                } else if ((positionReference == positionnombrefirmas) && (positionnombrefirmas < positionfirmas)) {
                  // console.log('entro 2')
                  valor = 1;
                } else if ((positionReference == positionnombrefirmas) && (positionnombrefirmas == positionfirmas) && (positionfirmas < positionNota)) {
                  // console.log('entro 3')
                  valor = 1;
                }else if(positionReference==1 && positionnombrefirmas==0 && positionfirmas==0 && positionNota==0)
                {
                  //console.log('nuevo 5')
                  valor = 1;
                } else {
                  // console.log('entro 4')
                  valor = 0;
                }
                return valor == 1 ? true : false;
              }
              if (currentNode.text == 'Referencia de Resultados:') {
                let positionTable = 0, value = 0;
                followingNodesOnPage.forEach(element => {
                  if (element.table != undefined) {
                    if (element.table.body[0][0].text =='Rangos para estanques de producción')
                      positionTable = element.pageNumbers.length;
                  }
                })
                if (positionTable > 1) { // cuando la tabla de resultados se encuentra en dos paginas
                  value = 1;
                } else {
                  value = 0;
                }
                return value == 1 ? true : false;;
              }
            }
          )
          data.create().download('reporte');
        }
        else if (data_report_type == 6 )
        { // PCR
          data.add([
            new Txt('INFORME DE RESULTADOS DE ENSAYO').fontSize(11).alignment('left').bold().margin([0,12,0,5]).end,
            new Table(
              [
                [
                  new Cell( new Txt('EMPRESA').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt('NOMBRE:').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.client).alignment('left').fontSize(8).end ).colSpan(4).end,
                  '','',''
                ],
                [
                  new Cell( new Txt('DIRECCIÓN').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.direction_pcr).fontSize(8).end ).colSpan(5).end,
                  '','','',''
                ],
                [
                  new Cell( new Txt('TIPO PRODUCTO').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.type_pcr).fontSize(8).end ).colSpan(5).end,
                  '','','',''
                ],
                [
                  new Cell( new Txt('NO. REFERENCIA').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.lab_refe_pcr).fontSize(8).end ).end,
                  new Cell( new Txt('CÓDIGO/LOTE').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt('L.S.A.').fontSize(8).end ).end,
                  new Cell( new Txt('FECHA RECEPCIÓN').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.date_reception_pcr).fontSize(8).end ).end,
                ],
                [
                  new Cell( new Txt('NÚMERO DE MUESTRAS').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.cantidad_mues_pcr).fontSize(8).end ).end,
                  new Cell( new Txt('FORMA DE PRESENTACIÓN').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.forma_present_pcr).fontSize(8).end ).end,
                  new Cell( new Txt('FECHA DE INICIO DE ENSAYOS').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.fecha_init_ens_pcr).fontSize(8).end ).end,
                ],
                [
                  new Cell( new Txt('CONDICION DE LA MUESTRA').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.condi_mues_pcr).fontSize(8).end ).colSpan(3).end,
                  new Cell( new Txt(' ').fontSize(8).bold().end ).end,
                  new Cell( new Txt(' ').fontSize(8).bold().end ).end,
                  new Cell( new Txt('FECHA DE TÉRMINOS DE ENSAYOS').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.fecha_term_ens_pcr).fontSize(8).end ).end,
                ],
                [
                  new Cell( new Txt('TEMPERATURA AMBIENTAL').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.tem_amb_pcr).fontSize(8).end ).end,
                  new Cell( new Txt('HUMEDAD RELATIVA').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.hum_rel_pcr).fontSize(8).end ).end,
                  new Cell( new Txt('FECHA DE ENTREGA DE RESULTADOS').alignment('left').fontSize(8).bold().end ).end,
                  new Cell( new Txt(obj.fecha_ent_pcr).fontSize(8).end ).end,
                ],
              ],
            )
              .widths('*')
              .end,
            new Txt('Resultados:').fontSize(10).alignment('left').bold().margin([0,12,0,5]).end,
            ...lista_tablas_pcr,
            new Txt('Observaciones:').fontSize(10).alignment('left').bold().margin([0,12,0,5]).end,
            new Ul(
              [
                'Equipos utilizados: Termociclador Pro Flex PCR System',
                'Las muestras son entregadas por el personal del CRM al laboratorio',
                'Los resultados de este informe de ensayo solo son aplicables a las muestras analizadas',
                'Este informe de ensayo no deberá reproducirse sin la autorización escrita de la LSA',
                'Los resultados de los ensayos son archivados por 5 años'
              ]
            ).margin([0,0,0,20]).fontSize(8).end,
          ]);
          data.add([
            new Table(
              [
                [
                  new Cell(new Txt('Realizado por:').fontSize(7).alignment('center').bold().end).end,
                  new Cell(new Txt('Aprobado por:').fontSize(7).alignment('center').bold().end).end
                ],
                [
                  new Cell(await new Img(obj.img_resp).width('150').build()).end,
                  new Cell(await new Img(obj.img_aprob).width('150').build()).end
                ],
                [
                  new Txt('Nota:').fontSize(6.5).bold().alignment('left').margin([0,7,0,0]).end,
                  ''
                ],
                [
                  new Txt('Este reporte no tiene validez sino tiene la firma autorizada.').alignment('left').fontSize(6.5).margin([0,0,0,10]).end,
                  ''
                ]
              ]
            )
              .alignment('center')
              .layout('noBorders')
              .widths('*')
              .end,
          ]);
          data.pageBreakBefore(
            (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
              let positionReference = 0, positionnombrefirmas = 0, positionfirmas = 0, positionNota = 0;
              let valor = 0;
              if (currentNode.text === 'Observaciones:') {
                positionReference = currentNode.pageNumbers[0];
                // console.log(followingNodesOnPage);
                // Voy a verificar si existe el campo con imagen afuera o dentro de una tabla
                for ( let i of followingNodesOnPage) {
                  // verifico si existe el image
                  if (i.table !== undefined) {
                    // existe la tabla y pregunto por la imagen
                    if (i.table.body !== undefined) {
                      // console.log(i.table.body)
                      if (i.table.body.length > 1) {
                        positionnombrefirmas = i.table.body[0][0].nodeInfo.pageNumbers[0];
                        if (i.table.body[1][0].image !== undefined) {
                          if (i.table.body[1][0].image == '$$pdfmake$$1') {
                            positionfirmas = i.table.body[1][0].nodeInfo.pageNumbers[0];
                          }
                        }
                      }
                      if (i.table.body.length > 3) {
                        if (i.table.body[3][0].text == 'Este reporte no tiene validez sino tiene la firma autorizada.') {
                          positionNota = i.table.body[3][0].nodeInfo.pageNumbers[0];
                        }
                      }
                    }
                  }
                }
                if (positionReference < positionnombrefirmas) {
                  // console.log('entro 1')
                  valor = 1;
                } else if ((positionReference == positionnombrefirmas) && (positionnombrefirmas < positionfirmas)) {
                  // console.log('entro 2')
                  valor = 1;
                } else if ((positionReference == positionnombrefirmas) && (positionnombrefirmas == positionfirmas) && (positionfirmas < positionNota)) {
                  // console.log('entro 3')
                  valor = 1;
                } else if(positionReference==1 && positionnombrefirmas==0 && positionfirmas==0 && positionNota==0)
                {
                  //console.log('nuevo 5')
                  valor = 1;
                }else {
                  // console.log('entro 4')
                  valor = 0;
                }
                // console.log(positionReference, positionnombrefirmas, positionfirmas, positionNota);
              }
              return valor == 1 ? true : false;
            }
          )
          // data.create().getDataUrl( dataUrl => {
          //     console.log(dataUrl)
          // })
          // data.create().getBase64( base64 => {
          //     console.log(base64)
          // })
          data.create().download('reporte')
        }
        // else if (data_report_type == 7) { // MIC
        //   data.add([
        //     new Txt('INFORME DE RESULTADOS DE PRUEBAS').fontSize(11).alignment('center').bold().margin([0,0,0,0]).end,
        //     new Txt('Datos de cliente').fontSize(10).alignment('left').bold().margin([0,12,0,5]).end,
        //     new Columns([new Txt('Cliente: ').bold().fontSize(8).end, new Txt(obj.client).fontSize(8).end, '','']).end,
        //     new Columns([new Txt('Direccion o Zona: ').fontSize(8).bold().end, new Txt(obj.direction).fontSize(8).end, '','']).end,
        //     new Columns([new Txt('Fecha de Entrega Reporte: ').fontSize(8).bold().end, new Txt(obj.solicit).fontSize(8).end, '','']).end,
        //     new Columns([new Txt('Asesor Técnico').fontSize(8).bold().end, new Txt(obj.solicit).fontSize(8).end, '','']).end,
        //     new Txt('Datos de muestra').fontSize(10).alignment('left').bold().margin([0,12,0,5]).end,
        //     ]
        //   );

        //   data.create().download('reporte');

        // }
        // console.log(data)
      })
      //this.isCreating = false;//para cuando se quite la descarga vieja de los reportes
    }

    async getDataReport(lista_sampid:any)
    {
      var par_url = 'ReportNew/All' ;
      var to:any;
      to={};
      to=lista_sampid;
      await this.srv.postCall(par_url,to).toPromise().then(
        data => {
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          this.new_data = data.val;
          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        },
        catchError(data => {return of(false)})
      );
      var par_url_2 = 'ReportNew/SubTotalGroupFito' ;
      var to_2:any;
      to_2={};
      to_2=lista_sampid;
      await this.srv.postCall(par_url_2,to_2).toPromise().then(
        data => {
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          this.new_data_grupos_fito = data.val;
          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        },
        catchError(data => {return of(false)})
      );

      var par_url_3 = 'ReportNew/GraficoBacter' ;
      var to_3:any;
      to_3={};
      to_3=lista_sampid;
      await this.srv.postCall(par_url_3,to_3).toPromise().then(
        data => {
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          this.new_data_grafico_bacte = data.val;
          if(mydata.success == true ){
            return of(mydata.val);
          }
          else{
            return of(false);
          }
        },
        catchError(data => {return of(false)})
      );
      // console.log("Grupos fito: ",this.new_data_grupos_fito);
      // console.log("Subtotales: ",this.new_data_grupos_fito);
      this.generatePdf();
    }

    public getImgFooter()
    {
      var par_url = 'ReportNew/ImageForReport' ;
      var to:any;
      to={};
      return this.srv.getCall(par_url).subscribe(
        data => {
          // console.log(data,'data');
          let mydata:any = {success: false, msg:'', val:[]};
          mydata = data;
          this.imgFooter = data.val;
          if(mydata.success == true ){

            return of(mydata.val);
          }
          else{
            return of(false);
          }
        },
        catchError(data => {return of(false)})

      );

    }
}
