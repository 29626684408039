import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html'
})
export class ParameterComponent extends BaseCrudComponent {

    id:any;
    loadingRequired = false;
    listAgr:any;
    translations:any;
    types = [
        {id:1, name:'par_type_manual'},
        {id:2, name:'par_type_fixed'},
        {id:3, name:'par_type_formula'},
        {id:4, name:'par_type_range'},
        {id:5, name:'par_type_richtext'}
    ];
    savevalue_url:string;

    operands = ['+', '-', '/', '*', '(', ')'];
    numbers = [0, 1, 2 ,3, 4, 5, 6, 7, 8, 9];

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'PreguntaDetalle/GetListAnswerByQuestion/'+this.id;//GetListParameterByAnalysis
        this.create_url = 'PreguntaDetalle/Create';
        this.savevalue_url = 'Parameter/SaveValue';
        this.update_url = 'PreguntaDetalle/Update';
        this.delete_url = 'PreguntaDetalle/LogicalDelete';
        this.search_fields = ['parName', 'parCode'];

        //formula create init
        this.clearCreateObject();

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    clearCreateObject(){
        this.c_obj = {};
        this.c_obj['form'] = [];
        this.c_obj['value_3'] = '';
        this.c_obj['parIsEditable'] = true;
        this.c_obj['parIsRequired'] = true;
        this.c_obj['parShowInReport'] = false;
        this.c_obj['parHidden'] = false;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var types = [2, 3, 4];
        form['pvalueInfo'] = null;
        var next = true;

        types.forEach(t => {
            if( (form['type'] == t) && ((form['value_'+t] == undefined) || (form['value_'+t] == '')) ){
                Swal.fire(this.translations['msg_all_required'], '', 'error');
                next = false;
                return;
            }

            if( form['type'] == t ){
                form['pvalueInfo'] = form['value_'+t];
            }
        });

        if( !next ){
            return;
        }

        if( form['type'] != 3 ){
            form['pvalueFactors'] = '';
            form['FormulaParameter'] = null;
        }

        var to:any;
        to = {};
        to['parSequence'] = form['parSequence'];
        to['PreguntaId'] = this.id;
        to['parCode'] = form['parCode'];
        to['Descripcion'] = form['Descripcion'];
        to['EsCorrecta'] = form['EsCorrecta'];

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.success){
                this.isCreating = true;
                /*this.saveValue(x.val['PreguntaDetalleId'], form).subscribe(y => {
                    this.isCreating = false;
                    this.c_obj = {};

                    if(!y.success){
                        Swal.fire(y.msg, '', 'error');
                    }

                    this.getList();
                    this.closeModal(content);
                });*/
              this.isCreating = false;
              this.c_obj = {};
              this.getList();
              this.closeModal(content);
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    public saveValue(id:any, obj:any){
        var qst = this.savevalue_url;
        var to:any;
        to = {};
        to['pvalueParameterId'] = id;
        to['pvalueInfo'] = obj['pvalueInfo'];
        to['pvalueFactors'] = obj['pvalueFactors'];
        to['FormulaParameter'] = obj['FormulaParameter'];

        return this.srv.postCall(qst, to);
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var types = [2, 3, 4];
        form['pvalueInfo'] = null;
        var next = true;

        types.forEach(t => {
            if( (form['type'] == t) && ((form['value_'+t] == undefined) || (form['value_'+t] == '')) ){
                Swal.fire(this.translations['msg_all_required'], '', 'error');
                next = false;
                return;
            }

            if( form['type'] == t ){
                form['pvalueInfo'] = form['value_'+t];
            }
        });

        if( !next ){
            return;
        }

        if( form['type'] != 3 ){
            form['pvalueFactors'] = '';
            form['FormulaParameter'] = null;
        }

        var to:any;
        to = {};
        to['PreguntaDetalleId'] = form['PreguntaDetalleId'];
        /*to['parSequence'] = form['parSequence'];*/
        to['PreguntaId'] = this.id;
        /*to['parCode'] = form['parCode'];*/
        to['Descripcion'] = form['Descripcion'];
        to['CreadoAl'] = form['CreadoAl'];
        to['EsCorrecta'] = form['EsCorrecta'];
        /*to['parShowInReport'] = form['parShowInReport'];
        to['parShowInGraphic'] = form['parShowInGraphic'];
        to['parHidden'] = form['parHidden'];
        to['parIsManualType'] = (form['type'] == 1) ? true : false;
        to['parIsFixedType'] = (form['type'] == 2) ? true : false;
        to['parIsFormulaType'] = (form['type'] == 3) ? true : false;
        to['parIsRangeType'] = (form['type'] == 4) ? true : false;
        to['parIsRichTextType'] = (form['type'] == 5) ? true : false;
        to['parRangeMin'] = form['parRangeMin'];
        to['parRangeMax'] = form['parRangeMax'];*/

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.isUpdating = true;
                /*this.saveValue(x.val['parId'], form).subscribe(y => {
                    this.isUpdating = false;
                    this.c_obj = {};

                    if(!y.success){
                        Swal.fire(y.msg, '', 'error');
                    }

                    this.getList();
                    this.closeModal(content);
                });*/
              this.isUpdating = false;
              this.getList();
              this.closeModal(content);
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'Descripcion': new FormControl(fields.Descripcion, [Validators.required]),
            //'parName': new FormControl(fields.parName, [Validators.required]),
            //'parSequence': new FormControl(fields.parSequence, [Validators.required]),
            'EsCorrecta': new FormControl(fields.EsCorrecta, [Validators.required])
        });

        return mygroup.valid;
    }

    public getList(){
        var qst = this.read_url;
        this.loadingList = true;
        this.srv.getCall(qst).subscribe(x => {
            this.loadingList = false;

            if(x.success){
                x.val.forEach(y => {
                    let counter = 1;
                    let temptypes = ['parIsManualType', 'parIsFixedType', 'parIsFormulaType', 'parIsRangeType', 'parIsRichTextType'];
                    temptypes.forEach(t => {
                        if( y[t] ){
                            y['type'] = counter;
                            y['type_obj'] = this.types.filter(s => {
                                return s.id == counter;
                            })[0];
                            //set values saved in pvalueInfo
                            y['value_'+counter] = y['Formula'];
                        }
                        counter++;
                    });

                    if( (y['pvalueFactors'] != null) && (y['pvalueFactors'] != '') ){
                        //console.log(y['pvalueFactors']);
                        y['form'] = JSON.parse(y['pvalueFactors']);
                    }
                    else{
                        y['form'] = [];
                    }
                });

                this.rawdata = x.val;
                this.rows = x.val;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }
            }
          });
    }

    public openUpdateModal(content: any, obj: any) {
        const copy = { ...obj };
        this.c_obj = copy;
        this.formulaToString(obj['form']);
        //console.log(obj);
        this.modalService.open(content, { centered: true });
    }

    public openCreateModal(content: any) {
        this.clearCreateObject();
        super.openCreateModal(content);
    }

    openFormulaEditor(content: any) {
        /*this.modalService.open(content, { centered: true });
        this.modalService.open(content, { size: 'lg'});*/
        this.modalService.open(content, { size: 'lg'});
    }

    addParamToFormula(obj:any){
        //this.c_obj['form'].push({val:obj.parCode, name:obj.parName, msg:obj.Formula, tooltip:true, param:true, number:false, sel:false});
        this.addItemToFormula({val:obj.parCode, name:obj.parName, msg:obj.Formula, tooltip:true, param:true, number:false, sel:false, id:obj.parId});
    }

    addValueToFormula(val){
        //this.c_obj['form'].push({val:val, name:'', msg:'', tooltip:false, param:false, number:true, sel:false});
        this.addItemToFormula({val:val, name:'', msg:'', tooltip:false, param:false, number:true, sel:false, id:null});
    }

    addOperandToFormula(val){
        //this.c_obj['form'].push({val:val, name:'', msg:'', tooltip:false, param:false, number:false, sel:false});
        this.addItemToFormula({val:val, name:'', msg:'', tooltip:false, param:false, number:false, sel:false, id:null});
    }

    addItemToFormula(obj:any){
        var counter = 0;
        var curr:any;
        curr = this.c_obj['form'].length;

        this.c_obj['form'].forEach(x => {
            if( x.sel ){
                curr = counter;
            }

            counter++;
        });

        this.c_obj['form'].splice(curr, 0, obj);
        this.formulaToString(this.c_obj['form']);
    }

    selItemFormula(i){
        var counter = 0;
        this.c_obj['form'].forEach(x => {
            if( counter == i ){
                x.sel = !x.sel;
            }
            else{
                x.sel = false;
            }
            counter++;
        });
    }

    resetFormula(){
        this.c_obj['form'] = [];
        this.c_obj['value_3'] = '';
        this.c_obj['pvalueFactors'] = '';
        this.c_obj['FormulaParameter'] = null;
    }

    deleteItemFormula(){
        var counter = 0;
        var selected:any;
        selected = null;

        this.c_obj['form'].forEach(x => {
            if( x.sel ){
                selected = counter;
            }

            counter++;
        });

        if( selected != null ){
            this.c_obj['form'].splice(selected, 1);
        }

        this.formulaToString(this.c_obj['form']);
    }

    formulaToString(obj:any){
        var str = '';
        var sentparams = [];

        obj.forEach(x => {
            if( x.param ){
                str += '<' + x.val + '>';
                sentparams.push({formpParamId: x.id});
            }
            else{
                str += x.val;
            }
        });

        this.c_obj['value_3'] = str;
        this.c_obj['pvalueFactors'] = JSON.stringify(obj);
        this.c_obj['FormulaParameter'] = sentparams;
    }

}
