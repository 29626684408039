<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn btn-custom-003d6a" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-info text-white">
                            <tr>
                                <th (click)="setOrder('slinId')" class="sh">
                                    Codigo<i *ngIf="(orderKey!==null)&&(orderKey=='slinId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('slinName')" class="sh">
                                    {{'Formato' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='slinName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('slinKey')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='slinKey')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'Zona' | translate}}</th>
                              <!--<th>{{'site' | translate}}</th>-->
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.CodigoLocal}}</td>
                                <td>{{row.FormatoLocal}}</td>
                                <td>{{row.NombreLocal}}</td>
                                <td>{{row.Zona}}</td>
                              <!--<td>{{row.SiteName}}</td>-->
                                <td class="action-buttons">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="deleteItem({slinId:row.slinId}, translations)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="6">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <!--<div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['slinKey']" name="slinKey" required maxlength="20">
                                    </div>
                                </div>-->

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['slinName']" name="slinName" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['slinDescription']" name="slinDescription">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'type' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="FormatoLocal" [(ngModel)]="c_obj['FormatoLocal']" required>
                                                <option *ngFor="let list of listSamples; let i = index" [value]="list['FormatoLocal']">{{list['FormatoLocal']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'company' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" [(ngModel)]="c_obj['CompAquasymId']" name="cmp" (change)="filterSites($event.target.value)" required>
                                                <option *ngFor="let p of raw_companies" value="{{p.id}}">{{p.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'Ciudad' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" [(ngModel)]="c_obj['Ciudad']" name="site" required>
                                                <option *ngFor="let p of sites" value="{{p.Ciudad}}">{{p.Ciudad}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['slinName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['CodigoLocal']" name="CodigoLocal" required maxlength="20" [readonly]="true">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['NombreLocal']" name="NombreLocal" required>
                                    </div>
                                </div>
                                <!--<div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['slinDescription']" name="slinDescription">
                                    </div>
                                </div>-->
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'Formato' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="FormatoLocal" [(ngModel)]="u_obj['FormatoLocal']" required>
                                                <option *ngFor="let list of listSamples; let i = index" [value]="list['FormatoLocal']">{{list['FormatoLocal']}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'company' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" [(ngModel)]="u_obj['CompAquasymId']" name="cmp" (change)="filterSites($event.target.value)" required>
                                                <option *ngFor="let p of raw_companies" value="{{p.id}}">{{p.name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'Ciudad' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" [(ngModel)]="u_obj['Ciudad']" name="Ciudad" required>
                                                <option *ngFor="let p of sites" value="{{p.Ciudad}}">{{p.Ciudad}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Update</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
