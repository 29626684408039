<div class="row">
 <div class="col-lg-12">
  <div class="card">
   <div class="card-body nopadding">
    <div class="row button-group">
     <div class="col-md-11 col-9 m-b-30" style="text-align: left;">
      <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
     </div>
     <div class="col-md-1 col-3 m-b-30" style="text-align: right;">
      <button type="button" class="btn btn-custom-003d6a" (click)="showFilters = !showFilters" style="color: #ffffff;" >
       <i *ngIf="!showFilters" class="far fa-caret-square-down"></i>
       <i *ngIf="showFilters" class="far fa-minus-square"></i>
      </button>
     </div>
     <!-- filter box -->
     <div class="col-12 m-b-30" style="text-align: left;" *ngIf="showFilters">
      <div class="card filter-box">
       <div class="card-body row">
           <div class="col-12 col-md-4 col-lg-3 m-b-30">
               <label>{{'date' | translate}} {{'from' | translate}}</label>
               <div class="input-group">
                   <input class="form-control" placeholder="yyyy-mm-dd" name="dp1" [(ngModel)]="f_datefrom" ngbDatepicker #d="ngbDatepicker" [readonly]="true">
                   <div class="input-group-append">
                       <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                           <i class="far fa-calendar-alt"></i>
                       </button>
                   </div>
               </div>
           </div>
           <div class="col-12 col-md-4 col-lg-3 m-b-30">
               <label>{{'date' | translate}} {{'to' | translate}}</label>
               <div class="input-group">
                   <input class="form-control" placeholder="yyyy-mm-dd" name="dp2" [(ngModel)]="f_dateto" ngbDatepicker #d2="ngbDatepicker" [readonly]="true">
                   <div class="input-group-append">
                       <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                           <i class="far fa-calendar-alt"></i>
                       </button>
                   </div>
               </div>
           </div>
           <div class="col-12 m-b-30" style="text-align: right;">
               <button type="button" class="btn btn-danger" (click)="clearSentFilters()">
                   <i class="far fa-trash-alt"></i> {{'clear' | translate}}
               </button>
           </div>
       </div>
      </div>
     </div>
     <!-- filters -->
     <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
      <button type="button" [disabled]="loadingRequired" class="btn btn-custom-003d6a" (click)="openCreateModal(create1)" style="color: #ffffff;"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
     </div>
     <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
         <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
     </div>
    </div>

    <div *ngIf="isOffline" class="offline-banner">{{'offline_mode' | translate}}</div>

    <div *ngIf="offline_items.length > 0">
        <h4>{{'offline_saved_list' | translate}}</h4>
        <div class="table-responsive">
            <table class="table">
                <thead class="bg-info text-white">
                    <tr>
                        <th class="sh">Temp ID</th>
                        <th class="sh">{{'Local' | translate}}</th>
                      <th class="sh">{{'Encargado' | translate}}</th>
                      <th class="sh">{{'date' | translate}}</th>
                        <th class="sh">{{'Cal_Local' | translate}}</th>
                      <th class="sh">{{'Cal_Colab' | translate}}</th>
                     <th class="sh">{{'Cal_Global' | translate}}</th>
                        <th>{{'action' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of offline_items">
                        <td>{{row.off_id}}</td>
                        <td>{{row.NombreLocal}}</td>
                        <td>{{row.EncargadoId}}</td>
                        <td>{{row.CreadoAl | date:'y-MM-dd'}}</td>
                        <td>{{row.CalificacionMarca}}</td>
                      <td>{{row.CalificacionTelefono}}</td>
                     <td>{{(row.CalificacionMarca+row.CalificacionTelefono)/2}}</td>
                        <td class="action-buttons">
                          <!--<i *ngIf="(row.Id !== isDownloading)" (click)="downloadReportOffline(row)" style="cursor:pointer;" title="{{'export_pdf' | translate}}" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': true}"></i>-->
                          <i *ngIf="(row.Id === isDownloading)" class="fas fa-sync-alt fa-spin m-r-10 text-info"></i>
                            <i (click)="showOfflineInfo(update1, row)" style="cursor:pointer;" class="fa fa-external-link-alt text-info m-r-10"></i>
                            <i (click)="deleteItemLocal(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="table-responsive">
        <table class="table">
            <thead class="bg-info text-white">
                <tr>
                    <th (click)="setOrder('pdiId')" class="sh">
                        ID<i *ngIf="(orderKey!==null)&&(orderKey=='pdiId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                    </th>
                    <th (click)="setOrder('cmpName')" class="sh">
                        {{'Local' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='cmpName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                    </th>
                    <th (click)="setOrder('sitName')" class="sh">
                        {{'Encargado' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='sitName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                    </th>
                    <th (click)="setOrder('pdiSiteDateTime')" class="sh">
                        {{'date' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='pdiSiteDateTime')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                    </th>
                    <th>{{'Cal_Local' | translate}}</th>
                  <th>{{'Cal_Colab' | translate}}</th>
                 <th class="sh">{{'Cal_Global' | translate}}</th>
                    <th>{{'action' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                    <td>{{row.EncuestaId}}</td>
                    <td>{{row.NombreLocal}}</td>
                    <td>{{row.EncargadoId}}</td>
                    <!-- <td>{{row.pdiSiteDateTime | date:'y-MM-d HH:mm:ss'}}</td> -->
                    <td>{{row.CreadoAl | date:'y-MM-d'}}</td>
                    <td>{{row.CalificacionMarca}}</td>
                    <td>{{row.CalificacionTelefono}}</td>
                 <td>{{(row.CalificacionMarca+row.CalificacionTelefono)/2}}</td>
                    <td class="action-buttons">
                        <!--<i *ngIf="(row.pdiId !== isDownloading) && !isOffline" (click)="downloadReport(row)" style="cursor:pointer;" class="ti-export text-info m-r-15" title="{{'export_pdf' | translate}}"></i>
                        <i *ngIf="row.pdiId === isDownloading" class="fas fa-sync-alt fa-spin m-r-15 text-info"></i>-->
                        <i *ngIf="!isOffline" (click)="showInfo(update1, row.EncuestaId)" style="cursor:pointer;" class="far fa-newspaper text-info m-r-15" title="{{'view' | translate}}"></i>
                        <i *ngIf="!isOffline" (click)="deleteItem({EncuestaId:row.EncuestaId})" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-15" title="{{'delete' | translate}}"></i>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <td colspan="6">
                    <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                </td>
            </tfoot>
        </table>
    </div>

    <ng-template #create1 let-c="close" let-d="dismiss">
     <div class="modal-header" style="background-color: #0076b3;">
            <h4 class="modal-title" style="color: #ffffff;">{{'Nueva encuesta' | translate}}</h4>
     </div>
     <div class="modal-body">
      <!-- main row -->
      <div class="row">

       <div class="col-12">
        <form class="">
            <div class="form-group">
             <div class="row">
              <div class="col-md-12 ha-ibox" *ngIf="sites.length>0">
               <!--<label>{{'site' | translate}} *</label>
               <select class="custom-select custom-select-md" [(ngModel)]="c_obj['SitAquasymId']" name="site" (change)="filterUnits($event.target.value)" required>
                <option *ngFor="let p of sites" value="{{p.id}}">{{p.name}}</option>
               </select>-->

               <div class="table-responsive">
                <table class="table">
                 <thead class="bg-info text-white">
                 <tr>
                  <th (click)="setOrder('id')" class="sh">
                   {{'id' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='id')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                  </th>
                  <th (click)="setOrder('Local')" class="sh">
                   {{'Local' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Local')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                  </th>
                  <th (click)="setOrder('Fecha')" class="sh">
                   {{'Fecha' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Fecha')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                  </th>
                  <th (click)="setOrder('Cal_Local')" class="sh">
                   {{'Cal_Local' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Cal_Local')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                  </th>
                  <th (click)="setOrder('Cal_Colab')" class="sh">
                   {{'Cal_Colab' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Cal_Colab')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                  </th>
                 </tr>
                 </thead>
                 <tbody>
                 <tr *ngFor="let row of sites | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                  <td>{{row.id}}</td>
                  <td>{{row.NombreLocal}}</td>
                  <td>{{row.CreadoAl}}</td>
                  <td class="text-right">{{row.CalificacionMarca | number : '1.2-2'}} %</td>
                  <td class="text-right">{{row.CalificacionTelefono | number : '1.2-2'}} %</td>
                 </tr>
                 </tbody>
                 <tfoot>
                 <td colspan="6">
                  <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                 </td>
                 </tfoot>
                </table>
               </div>

              </div>

              <div class="col-md-6 ha-ibox">
               <label>{{'Local' | translate}} *</label>
               <select class="custom-select custom-select-md" [(ngModel)]="c_obj['CodigoLocal']" name="cmp" required (change)="filterSites($event)">
                                     <option *ngFor="let p of raw_companies" value="{{p.id}}">{{p.name}}</option>
                                 </select>
              </div>
              <!--<div class="col-md-6 ha-ibox">
               <label>{{'site' | translate}} *</label>
               <select class="custom-select custom-select-md" [(ngModel)]="c_obj['SitAquasymId']" name="site" (change)="filterUnits($event.target.value)" required>
                                     <option *ngFor="let p of sites" value="{{p.id}}">{{p.name}}</option>
                                 </select>
              </div>-->
              <div class="col-md-6 ha-ibox">
               <label>{{'date' | translate}} *</label>
               <input type="datetime-local" class="form-control" name="ndate" [(ngModel)]="c_obj['CreadoAl']" required>
              </div>
              <!--<div class="col-md-6 ha-ibox">
               <label>{{'pdi_zone' | translate}}</label>
               <input type="text" class="form-control" name="zone" [(ngModel)]="sel_sector" [disabled]="true">
              </div>-->
              <div class="col-md-6 ha-ibox">
               <label>{{'Encargado' | translate}} *</label>
               <input type="text" class="form-control" [(ngModel)]="c_obj['EncargadoId']" name="req" required>
              </div>
              <!-- <div class="col-md-6 ha-ibox">
               <label>{{'advisor' | translate}}</label>
               <select class="custom-select custom-select-md" [(ngModel)]="c_obj['advisor']" name="advisor">
                                     <option value="0">cmp 1</option>
             <option value="1">cmp 2</option>
                                 </select>
              </div> -->

             </div>
            </div>
        </form>
       </div>
       <!-- end general info -->

       <div class="col-12">
         <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
         <ngb-panel *ngFor="let f of fields;let i = index">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0">{{f.unitname}}</h5>
               <div>
                <button ngbPanelToggle class="btn ml-2 btn-md btn-outline-dark">
                 <i class="fas fa-chevron-down"></i>
                </button>
                <button (click)="removePondForm(i)" class="btn ml-2 btn-md btn-outline-danger">
                 <i class="fas fa-times"></i>
                </button>
               </div>
         </div>
        </ng-template>
        <ng-template ngbPanelContent>
         <!-- head -->
         <!---->
         <div class="row" *ngIf="f.unitid == 1">
          <div class="col-4">
           <label>Cód. colaborador</label>
           <input type="number" min="0" class="form-control" name="snum" [(ngModel)]="f.shrimps">
          </div>
          <div class="col-4">
           <label>Nombre completo</label>
           <input type="text" class="form-control" name="days" [(ngModel)]="f.days">
          </div>

         </div>
         <!-- end head -->
         <!-- details -->
         <div class="row">
          <div class="col-12">
           <table class="table pdi-details" *ngFor="let x of f.items">
            <tbody>
             <!--<tr>
                             <td colspan="3"><h4>{{x.group | translate}}</h4></td>
                           </tr>-->
                           <tr *ngFor="let c of x.items; index as z">
                             <table>
                               <tr>
                                 <td width="100%">{{z + 1}}) {{c.name | translate}}</td>
                               </tr>
                               <tr>
                                 <td width="30%">
                                   <!--<input *ngIf="c.qty != ''" type="number" min="0" class="form-control" name="cqty" [(ngModel)]="c.qval" {{c.roc>1? 'checkbox':'radio'}} >   [(ngModel)]="c.fval"-->

                                  <div class="row" *ngIf="c.roc>1">

                                   <div class="col-md-12 sk-ibox">
                                    <ng-select [(ngModel)]="c.fval" name="{{c.fname}}" [multiple]="true">
                                     <ng-option *ngFor="let p of c.source; index as i" value="{{p.id}}">{{i + 1}} -
                                      {{p.name}}</ng-option>
                                    </ng-select>
                                   </div>
                                  </div>

                                  <div *ngIf="c.roc==1">
                                   <div *ngFor="let p of c.source">
                                    <label>
                                     <input type="radio" value="{{p.id}}" [(ngModel)]="c.fval" name="{{c.fname}}"/>
                                     <span>{{p.name}}</span>
                                    </label>
                                   </div>
                                  </div>

                                 </td>
                                 <!--<td>
                                   <select *ngIf="c.source != ''" class="custom-select custom-select-md" [(ngModel)]="c.fval" name="cfname">
                                     <option *ngFor="let p of c.source" value="{{p.id}}">{{p.name}}</option>
                                   </select>
                                 </td>-->
                               </tr>
                             </table>
                           </tr>
                 </tbody>
           </table>
          </div>
         </div>
         <!-- end details 'pdi_'+ filters[]-->
         <!-- img -->
         <div class="row" style="padding-top: 20px" *ngIf="f.unitid != 1">
          <div class="col-12">
           <h4>Subir imagen</h4>
           <div class="pdi-pondsel">
                           {{'pdi_max_img_size' | translate}} {{max_img_size}} MB
                          </div>
           <div class="custom-file">
                              <input type="file" class="custom-file-input" name="imgfile" accept="image/*" (change)="handleInputChange($event, f)">
                              <label class="custom-file-label">Choose file</label>
                          </div>
                          <!-- <button type="button" class="btn btn-danger" (click)="clearImgPrev(f.img[0])" *ngIf="f.img[0].src != ''" style="margin-top: 10px">Borrar</button> -->
          </div>
          <div class="col-md-6" style="text-align: center;">
           <!-- <img [src]="f.img[0].src" (click)="openPreview(f.img[0])" class="pdi-img" /> -->
          </div>
         </div>
         <!-- multi imgs -->
         <div class="row pdi-imgsel" *ngFor="let y of f.img">
          <div class="col-6" style="text-align: center;">
           <img [src]="y.src" (click)="openPreview(y)" class="pdi-img" />
          </div>
          <div class="col-6">
           <h4>{{'comment' | translate}}</h4>
           <div class="form-group">
            <textarea class="form-control" rows="3" name="sug" [(ngModel)]="y.comment" maxlength="150"></textarea>
           </div>
           <div style="text-align: right;">
            <button type="button" class="btn btn-danger" (click)="clearImg(f.img, y.imId)" *ngIf="y.src != ''" style="margin-top: 10px">Borrar</button>
           </div>
          </div>
         </div>
         <!-- end multi imgs -->
         <!-- end img -->
         <!-- suggestion -->
         <!-- <div class="row" style="padding-top: 20px">
          <div class="col-12">
           <h4>{{'desc' | translate}}</h4>
           <div class="form-group">
            <textarea class="form-control" rows="3" name="sug" [(ngModel)]="f.comment" style="margin-top: 10px" maxlength="150"></textarea>
           </div>
          </div>
         </div> -->
         <!-- end suggestion -->
        </ng-template>
       </ngb-panel>
       </ngb-accordion>
       </div>

       <!-- unit selector -->
       <div class="col-12 pdi-pondsel" style="text-align: right;">
       <div class="row">
       <!-- <div class="d-lg-block col-lg-6"></div> -->
       <!-- <div class="col-md-4 col-4">
       <select class="custom-select custom-select-md" [(ngModel)]="u_obj['curr_unit2']" name="cunit2">
       <option *ngFor="let p of units" value="{{p.id}}">{{p.Name}}</option>
       </select>
       </div>-->
       <div class="col-5 m-b-30" style="text-align: left;">
       <label>{{'type' | translate}}</label>
        <select class="custom-select custom-select-md" [(ngModel)]="c_obj['loctype']" name="loctype" (change)="onChange($event)">
                   <!--<option value="-1">{{'unit' | translate}}</option>  (change)="filterLocations($event.target.value, c_obj['SitAquasymId'])"
                   <option *ngFor="let p of loctypes" value="{{p.id}}">{{p.name}}</option>-->
                   <option value="0">{{'Estetica' | translate}}</option>
                   <option value="1">{{'Prueba de conocimiento' | translate}}</option>
                 </select>
       </div>
       <!--<div *ngIf="c_obj['loctype'] > -1" class="col-7 m-b-30" style="text-align: left;">
        <label>{{'location' | translate}}</label>
        <select class="custom-select custom-select-md" [(ngModel)]="c_obj['curr_slin']" name="cslin">
                                       &lt;!&ndash; <option value="null">[ {{'unit' | translate}} ]</option> &ndash;&gt;
                                       <option *ngFor="let p of locations" value="{{p.id}}">{{p.name}}</option>
                                   </select>
       </div>
       <div *ngIf="c_obj['loctype'] == -1" class="col-7 col-md-3 m-b-30" style="text-align: left;">
        <label>{{'pdi_production' | translate}}</label>
        <select class="custom-select custom-select-md" [(ngModel)]="c_obj['curr_unit_active']" name="cunitactive" (change)="filterActiveUnits($event.target.value, c_obj['SitAquasymId'])">
                                       <option value="-1">{{'all' | translate}}</option>
                                       <option value="1">{{'is_open' | translate}}</option>
                                       <option value="0">{{'is_closed' | translate}}</option>
                                   </select>
       </div>
       <div *ngIf="c_obj['loctype'] == -1" class="col-12 col-md-4 m-b-30" style="text-align: left;">
        <label>{{'unit' | translate}}</label>
        <select class="custom-select custom-select-md" [(ngModel)]="c_obj['curr_unit']" name="cunit">
                                       &lt;!&ndash; <option value="null">[ {{'unit' | translate}} ]</option> &ndash;&gt;
                                       <option *ngFor="let p of units" value="{{p.id}}">{{p.name}}</option>
                                   </select>
       </div>-->
       </div>
       <div class="row">
       <div class="col-12" style="text-align: right;">
        <button type="button" class="btn btn-custom-003d6a" (click)="addPondForm()" style="color: #ffffff;margin:0"><i class="fas fa-plus"></i>&nbsp;&nbsp;{{'add' | translate}}</button>
       </div>
       </div>
       </div>
       <!-- end unit selector -->
       <div class="col-12 ha-ibox">
        <label>{{'Observacion' | translate}}</label>
        <div class="form-group">
         <textarea class="form-control" rows="3" name="Observacion" [(ngModel)]="c_obj['Observacion']"></textarea>
        </div>
       </div>

     </div>
     <!-- end row main -->

     </div>
     <div class="modal-footer">
      <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
      <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(create1, c_obj)" title="Guarda y finaliza la encuesta">{{'finish' | translate}}</button>
     </div>
    </ng-template>

    <ng-template #update1 let-c="close" let-d="dismiss">
        <div class="modal-header" style="background-color: #0076b3;">
            <h4 class="modal-title" style="color: #ffffff;">{{'Ver encuesta' | translate}} #{{u_obj['EncuestaId']}}</h4>
        </div>
        <div class="modal-body">

            <div *ngIf="loadingDetails" class="common-loader">
                <i class="fas fa-sync-alt fa-spin fa-3x" style="color: gray"></i>
            </div>

         <!-- main row -->
         <div *ngIf="!loadingDetails" class="row">

          <div class="col-12">
        <form class="">
            <div class="form-group">
             <div class="row">
              <div class="col-md-6 ha-ibox">
               <label>{{'Local' | translate}} *</label>
               <select class="custom-select custom-select-md" [(ngModel)]="u_obj['CodigoLocal']" name="cmp" (change)="filterSites($event)" required [disabled]="true">
                                     <option *ngFor="let p of raw_companies" value="{{p.id}}">{{p.name}}</option>
                                 </select>
              </div>
              <!--<div class="col-md-6 ha-ibox">
               <label>{{'site' | translate}} *</label>
               <select class="custom-select custom-select-md" [(ngModel)]="u_obj['SitAquasymId']" name="site" (change)="filterUnits($event.target.value)" required>
                                     <option *ngFor="let p of sites" value="{{p.id}}">{{p.name}}</option>
                                 </select>
              </div>-->
              <div class="col-md-6 ha-ibox">
               <label>{{'date' | translate}} *</label>
               <input type="datetime-local" class="form-control" name="ndate" [(ngModel)]="u_obj['CreadoAl']" [disabled]="true"> <!--Habilitar la fecha disabled="true" -->
              </div>
              <!--<div class="col-md-6 ha-ibox">
               <label>{{'pdi_zone' | translate}}</label>
               &lt;!&ndash; <div>{{ sel_sector }}</div> &ndash;&gt;
               <input type="text" class="form-control" name="zone" [(ngModel)]="sel_sector" [disabled]="true">
              </div>-->
              <div class="col-md-6 ha-ibox">
               <label>{{'Encargado' | translate}} *</label>
               <input type="text" class="form-control" [(ngModel)]="u_obj['EncargadoId']" name="req" required [disabled]="true">
              </div>
              <div class="col-12 ha-ibox">
               <label>{{'Observacion' | translate}}</label>
               <div class="form-group">
                <textarea class="form-control" rows="3" name="Observacion" [(ngModel)]="u_obj['Observacion']" [disabled]="true"></textarea>
               </div>
              </div>
             </div>
            </div>
        </form>
    </div>
    <!-- end general info -->

<div class="col-12">
<ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
<ngb-panel *ngFor="let f of fields;let i = index">
 <ng-template ngbPanelHeader let-opened="opened">
   <div class="d-flex align-items-center justify-content-between">
       <h5 class="m-0">{{f.unitname}}</h5>
      <div>
       <button ngbPanelToggle class="btn ml-2 btn-md btn-outline-dark">
        <i class="fas fa-chevron-down"></i>
       </button>
       <button (click)="removePondForm(i)" class="btn ml-2 btn-md btn-outline-danger">
        <i class="fas fa-times"></i>
       </button>
      </div>
   </div>
 </ng-template>
 <ng-template ngbPanelContent>
  <!-- head -->
  <!----><div class="row" *ngIf="f.unitid == 1">
   <div class="col-4">
    <label>Cód. colaborador</label>
    <input type="number" min="0" class="form-control" name="snum" [(ngModel)]="f.shrimps">
   </div>
   <div class="col-4">
    <label>Nombre completo</label>
    <input type="text" class="form-control" name="days" [(ngModel)]="f.days">
   </div>
   <!--<div class="col-4">
    <label>Peso (gr)</label>
    <input type="number" min="0" class="form-control" name="weight" [(ngModel)]="f.weight">
   </div>-->
  </div>
  <!-- end head -->
  <!-- details -->
  <div class="row">
   <div class="col-12">
    <table class="table pdi-details" *ngFor="let x of f.items">
     <tbody>
      <!--<tr><td colspan="3"><h4>
      {{x.group | translate}}
      </h4></td>
                    </tr>-->
            <tr *ngFor="let c of x.items; index as z">
                      <table>
                        <tr>
                          <td width="30%">{{z + 1}}) {{c.name | translate}}</td>
                        </tr>
                        <tr>
                          <td width="30%">
                            <!--<input *ngIf="c.qty != ''" type="number" min="0" class="form-control" name="cqty" [(ngModel)]="c.qval">-->
                            <div *ngFor="let p of c.source">
                              <label>
                                <!--<input type="{{c.roc>1? 'checkbox':'radio'}}" value="{{p.id}}" [(ngModel)]="c.fval" name="{{c.fname}}"/>-->
                                <input type="radio" value="{{p.id}}" [(ngModel)]="c.fval" name="{{c.fname}}" [disabled]="true"/>
                                <span>{{p.name}}</span>
                              </label>
                            </div>
                          </td>
                          <!--<td>
                            <select *ngIf="c.source != ''" class="custom-select custom-select-md" [(ngModel)]="c.fval" name="cfname">
                              <option *ngFor="let p of c.source" value="{{p.id}}">{{p.name}}</option>
                            </select>
                          </td>-->
                        </tr>
                      </table>
                    </tr>
                  </tbody>
          <!-- 'pdi_'+ filters[] -->
    </table>
   </div>
  </div>
  <!-- end details -->
  <!-- img -->
  <!--<div class="row" style="padding-top: 20px">
   <div class="col-12">
    <h4>Subir imagen</h4>
    <div class="pdi-pondsel">
                    {{'pdi_max_img_size' | translate}} {{max_img_size}} MB
                   </div>
    <div class="custom-file">
                       <input type="file" class="custom-file-input" name="imgfile" accept="image/*" (change)="handleInputChange($event, f)">
                       <label class="custom-file-label">Choose file</label>
                   </div>
   </div>
   <div class="col-md-6" style="text-align: center;">
   </div>
  </div>-->
  <!-- end img -->

  <!-- multi imgs -->
  <div class="row pdi-imgsel" *ngFor="let y of f.img">
   <div class="col-6" style="text-align: center;">
    <img [src]="y.src" (click)="openPreview(y)" class="pdi-img" />
   </div>
   <div class="col-6">
    <h4>{{'comment' | translate}}</h4>
    <div class="form-group">
     <textarea class="form-control" rows="3" name="sug" [(ngModel)]="y.comment" maxlength="150"></textarea>
    </div>
    <div style="text-align: right;">
     <button type="button" class="btn btn-danger" (click)="clearImg(f.img, y.imId)" *ngIf="y.src != ''" style="margin-top: 10px">Borrar</button>
    </div>
   </div>
  </div>
  <!-- end multi imgs -->
  <!-- suggestion -->
  <!-- <div class="row" style="padding-top: 20px">
   <div class="col-12">
    <h4>{{'desc' | translate}}</h4>
    <div class="form-group">
     <textarea class="form-control" rows="3" name="sug" [(ngModel)]="f.comment" style="margin-top: 10px" maxlength="150"></textarea>
    </div>
   </div>
  </div> -->
  <!-- end suggestion -->
 </ng-template>
</ngb-panel>
</ngb-accordion>
</div>

     <!-- unit selector -->
     <!--<div class="col-12 pdi-pondsel" style="text-align: right;">
     <div class="row">
     <div class="col-5 m-b-30" style="text-align: left;">
     <label>{{'type' | translate}}</label>
      <select class="custom-select custom-select-md" [(ngModel)]="u_obj['loctype']" name="loctype" (change)="filterLocations($event.target.value, u_obj['SitAquasymId'])">
                                     <option value="-1">{{'unit' | translate}}</option>
                                     <option *ngFor="let p of loctypes" value="{{p.id}}">{{p.name}}</option>
                                 </select>
     </div>
     <div *ngIf="u_obj['loctype'] > -1" class="col-7 m-b-30" style="text-align: left;">
      <label>{{'location' | translate}}</label>
      <select class="custom-select custom-select-md" [(ngModel)]="u_obj['curr_slin']" name="cslin">
                                     &lt;!&ndash; <option value="null">[ {{'unit' | translate}} ]</option> &ndash;&gt;
                                     <option *ngFor="let p of locations" value="{{p.id}}">{{p.name}}</option>
                                 </select>
     </div>

     <div *ngIf="u_obj['loctype'] == -1" class="col-7 col-md-3 m-b-30" style="text-align: left;">
      <label>{{'pdi_production' | translate}}</label>
      <select class="custom-select custom-select-md" [(ngModel)]="u_obj['curr_unit_active']" name="cunitactive" (change)="filterActiveUnits($event.target.value, u_obj['SitAquasymId'])">
                                     <option value="-1">{{'all' | translate}}</option>
                                     <option value="1">{{'is_open' | translate}}</option>
                                     <option value="0">{{'is_closed' | translate}}</option>
                                 </select>
     </div>
     <div *ngIf="u_obj['loctype'] == -1" class="col-12 col-md-4 m-b-30" style="text-align: left;">
      <label>{{'unit' | translate}}</label>
      <select class="custom-select custom-select-md" [(ngModel)]="u_obj['curr_unit']" name="cunit">
                                     &lt;!&ndash; <option value="null">[ {{'unit' | translate}} ]</option> &ndash;&gt;
                                     <option *ngFor="let p of units" value="{{p.id}}">{{p.name}}</option>
                                 </select>
     </div>
     </div>
     <div class="row">
     <div class="col-12" style="text-align: right;">
      <button type="button" class="btn btn-custom-003d6a" (click)="addPondFormU()" style="color: #ffffff;margin:0"><i class="fas fa-plus"></i>&nbsp;&nbsp;{{'add' | translate}}</button>
     </div>
     </div>
     </div>-->
     <!-- end unit selector -->

      </div>
      <!-- end row main -->
         <div class="table-responsive">
          <table class="table">
           <thead class="bg-info text-white">
           <tr>
            <th (click)="setOrder('Mes')" class="sh">
             {{'month' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='Mes')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
            </th>
            <th (click)="setOrder('PorcentajeCe')" class="sh">
             {{'PorcentajeCe' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='PorcentajeCe')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
            </th>
            <th (click)="setOrder('PorcentajeCh')" class="sh">
             {{'PorcentajeCh' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='PorcentajeCh')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
            </th>
            <th (click)="setOrder('PorcentajeSe')" class="sh">
             {{'PorcentajeSe' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='PorcentajeSe')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
            </th>
           </tr>
           </thead>
           <tbody>
           <tr *ngFor="let row of u_obj['IndicadoresDetalle'] | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
            <td>{{'month'+row.Mes | translate}}</td>
            <td class="text-right">{{row.PorcentajeCe | number : '1.2-2'}} %</td>
            <td class="text-right">{{row.PorcentajeCh | number : '1.2-2'}} %</td>
            <td class="text-right">{{row.PorcentajeSe | number : '1.2-2'}} %</td>
           </tr>
           </tbody>
           <tfoot>
           <td colspan="6">
            <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
           </td>
           </tfoot>
          </table>
         </div>

     </div>
     <div class="fix_panel_de_datos">
      <div class="col-md-3">
       <div class="card-piscina-abierta">
        <div class="imagen">
        </div>
        <div class="texto">
         <p class="titulo semibold">Cal. Global</p>
         <p class="subtitulos semibold">{{(u_obj['CalificacionMarca']+u_obj['CalificacionTelefono'])/2 | number:'1.2-2':'en-US'}}</p>
         <!-- <p class="subtitulos semibold">{{x.value}}</p> -->
        </div>
       </div>
      </div>
     </div>

     <div class="modal-footer">
         <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
         <!--<button [disabled]="isOffline" *ngIf="u_obj['offline'] == false" [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">{{'update' | translate}}</button>
         <button *ngIf="u_obj['offline'] == true" [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(update1, u_obj)">{{'save' | translate}}</button>-->
     </div>
    </ng-template>

   </div>
  </div>
 </div>
</div>
