<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-md-11 col-9 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-md-1 col-3 m-b-30" style="text-align: right;">
                        <button type="button" class="btn btn-custom-003d6a" (click)="showFilters = !showFilters" style="color: #ffffff;" >
                            <i *ngIf="!showFilters" class="far fa-caret-square-down"></i>
                            <i *ngIf="showFilters" class="far fa-minus-square"></i>
                        </button>
                    </div>
                    <!-- filter box -->
                    <div class="col-12 m-b-30" style="text-align: left;" *ngIf="showFilters">
                        <div class="card filter-box">
                            <div class="card-body row">
                                   <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'date' | translate}} {{'from' | translate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp1" [(ngModel)]="f_datefrom" ngbDatepicker #d="ngbDatepicker" [readonly]="true">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                                <i class="far fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'date' | translate}} {{'to' | translate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp2" [(ngModel)]="f_dateto" ngbDatepicker #d2="ngbDatepicker" [readonly]="true">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                                <i class="far fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 m-b-30" style="text-align: left;">
                                       <input type="radio"  name="date" [(ngModel)]="r_date_create" value="1" >
                                        &nbsp;
                                        <label>{{'samp_date_create' | translate}}</label>
                                        &nbsp;&nbsp;&nbsp;
                                        <input type="radio"  name="date" [(ngModel)]="r_date_reception" value="2">
                                        &nbsp;
                                        <label> {{'sampapp_datereceive' | translate}}</label>
                                </div>
                                <div class="col-12 m-b-30" style="text-align: right;">

                                    <button type="button" class="btn btn-danger" (click)="clearSentFilters()">
                                        <i class="far fa-trash-alt"></i> {{'clear' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- filters -->
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getListPost()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th>ID</th>
                                <th>{{'technician' | translate}}</th>
                                <th>{{'company' | translate}}</th>
                                <th>{{'site' | translate}}</th>
                                <th>{{'samp_date_create' | translate}}</th>
                                <th>{{'sampapp_datereceive' | translate}}</th>
                                <th>Grupo de análisis</th>
                                <th>{{'progress' | translate}}</th>
                                <!-- <th>{{'status' | translate}}</th> -->
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.sampId}}</td>
                                <td>{{row.UserTechnicalName}}</td>
                                <td>{{row.cmpName}}</td>
                                <td>{{row.sitName}}</td>
                                <td>{{row.sampDate | date:'y-MM-d HH:mm:ss'}}</td>
                                <td>{{row.sampReceptionDateTime | date:'y-MM-d HH:mm:ss'}}</td>
                                <td>{{row.GroupName}}</td>
                                <td style="text-align: center;">{{row.advacePercent}} %</td>
                                <!-- <td>{{row.StatusName}}</td> -->
                                <td class="action-buttons" style="text-align: left;">
                                    <i (click)="showInfo(info1, row)" title="{{'open' | translate}}" style="cursor:pointer;" class="fa fa-external-link-alt text-info m-r-10">
                                    </i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="9">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #info1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'sampapp_edit' | translate}} #{{u_obj['sampId']}}</h4>
                    </div>
                    <div class="modal-body">

                        <div *ngIf="loadingDetails" class="common-loader">
                            <i class="fas fa-sync-alt fa-spin fa-3x" style="color: gray"></i>
                        </div>

                        <!-- main row -->
                        <div *ngIf="!loadingDetails" class="row">

                            <div class="col-12">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'date' | translate}}</label>
                                                <input type="text" class="form-control" name="ndate" value="{{u_obj['sampDate'] | date:'y-MM-d HH:mm:ss'}}" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'progress' | translate}} %</label>
                                                <input type="text" class="form-control" name="advacePercent" [(ngModel)]="u_obj['advacePercent']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'company' | translate}}</label>
                                                <input type="text" class="form-control" name="company" [(ngModel)]="u_obj['cmpName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'site' | translate}}</label>

                                                <input type="text" class="form-control" name="site" [(ngModel)]="u_obj['sitName']" disabled="true">
                                            </div>
                                            <!-- <div class="col-md-6 ha-ibox" *ngIf="u_obj['SampleLocationInstanceName'] != null">
                                                <label>{{'samp_spot' | translate}}</label>
                                                <input type="text" class="form-control" name="spot" [(ngModel)]="u_obj['SampleLocationInstanceName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['UnitName'] != null">
                                                <label>{{'unit' | translate}}</label>
                                                <input type="text" class="form-control" name="unit" [(ngModel)]="u_obj['UnitName']" disabled="true">
                                            </div> -->
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['SampleLocationType'] != null">
                                                <label>{{'samp_location_type' | translate}}</label>
                                                <input type="text" class="form-control" name="spotype" [(ngModel)]="u_obj['SampleLocationType']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['SampleLocationType'] == null">
                                                <label>{{'samp_location_type' | translate}}</label>
                                                <input type="text" class="form-control" name="spotype" value="{{'unit' | translate}}" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['UnitName'] == null">
                                                <label>{{'samp_spot' | translate}}</label>
                                                <input type="text" class="form-control" name="spot" [(ngModel)]="u_obj['SampleLocationInstanceName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['UnitName'] != null">
                                                <label>{{'unit' | translate}}</label>
                                                <input type="text" class="form-control" name="unit" [(ngModel)]="u_obj['UnitName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'technician' | translate}}</label>
                                                <input type="text" class="form-control" name="UserTechnicalName" [(ngModel)]="u_obj['UserTechnicalName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_ag' | translate}}</label>
                                                <input type="text" class="form-control" name="agr" [(ngModel)]="u_obj['GroupName']" disabled="true">
                                            </div>
                                            <!--aqui vamos a aumentar nuevos campos-->
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sample' | translate}}</label>
                                                <input type="text" class="form-control" name="samples" [(ngModel)]="u_obj['Samples']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_type' | translate}}</label>
                                                <input type="text" class="form-control" name="sampletype" [(ngModel)]="u_obj['SampleType']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_qty' | translate}}</label>
                                                <input type="text" class="form-control" name="SampleQuantity" [(ngModel)]="u_obj['sampSampleQuantity']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_container' | translate}}</label>
                                                <input type="text" class="form-control" name="Container" [(ngModel)]="u_obj['Container']" disabled="true">
                                            </div>
                                            <div *ngIf="u_obj['sampRequestedByClient'] == true" class="col-md-6 ha-ibox">
                                                <!--<label>{{'samp_reqbyclient' | translate}} *</label>-->
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" [attr.checked]="(u_obj['sampRequestedByClient']) ? 'checked' : null" id="requestedby" name="requestedby" disabled="true" >
                                                    <label class="custom-control-label" for="requestedby">{{'samp_reqbyclient' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-12 ha-ibox">
                                                <label>{{'samp_comment' | translate}}</label>
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="3" name="Commentary" [(ngModel)]="u_obj['Commentary']" disabled="true" style="resize: none;"></textarea>
                                                </div>
                                            </div>
                                            <!--fin aqui vamos a  aumentar nuevos campos-->

                                            <!-- <div class="col-12 ha-ibox">
                                                <label>{{'suggestions' | translate}}</label>
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="3" name="sampObservation" [(ngModel)]="u_obj['sampObservation']" disabled="true" style="resize: none;"></textarea>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- end general info -->
                          <!--Datos de PCR para ser llenados x laboratorio-->
                          <div class="col-12" *ngIf="u_obj['GroupName'] == 'PCR' || u_obj['GroupName'] == 'Pcr'">
                            <form class="">
                              <div class="form-group">
                                <div class="row">
                                  <div class="col-md-4 ha-ibox">
                                    <label>{{'sampConditionSample' | translate}}</label>
                                    <select class="custom-select custom-select-md" [(ngModel)]="u_obj['sampConditionSample']" name="sampConditionSample">
                                    <option *ngFor="let p of this.pcr_filtersC" value="{{p.ID}}">{{p.value}}</option>
                                    </select>
                                  </div>
                                  <div class="col-md-3 ha-ibox">
                                    <label>{{'sampEnvironmentTemperature' | translate}}</label>
                                    <select class="custom-select custom-select-md" [(ngModel)]="u_obj['sampEnvironmentTemperature']" name="sampEnvironmentTemperature">
                                    <option *ngFor="let p of this.pcr_filtersT" value="{{p.ID}}">{{p.value}}</option>
                                    </select>
                                  </div>
                                  <div class="col-md-3 ha-ibox">
                                    <label>{{'sampRH' | translate}}</label>
                                    <select class="custom-select custom-select-md" [(ngModel)]="u_obj['sampRH']" name="sampRH">
                                    <option *ngFor="let p of this.pcr_filtersH" value="{{p.ID}}">{{p.value}}</option>
                                    </select>
                                  </div>

                                  <div class="col-md-2 ha-ibox" style="padding: 30px"  >
                                    <button type="button" class="btn btn-custom-005c3c" title="{{'tooltips_pcr'| translate}}"  style="color: #ffffff;" (click)="savePCR(u_obj)"> {{'save' | translate}}</button>
                                  </div>
                                  <!--<div>

                                    <i class="ti-check-box m-r-10">{{u_obj.sampStatusId}}</i>
                                    <i class="ti-check-box m-r-10">{{u_obj['advacePercent']}}</i>


                                  </div>-->
<!--                                  <div class="col-md-3 ha-ibox" *ngIf="u_obj['sampStatusId'] == '76' || u_obj['sampStatusId'] == '77' ">-->
<!--                                    &nbsp;<br>-->
<!--                                    <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="savePCR(u_obj)"> <i class="ti-check-box m-r-10"></i>{{'save' | translate}}</button>-->
<!--                                  </div>-->

                                </div>
                              </div>
                            </form>
                          </div>
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <!-- <thead class="bg-danger text-white"> -->
                                        <thead>
                                            <tr>
                                                <th>{{'name' | translate}}</th>
                                                <th>{{'status' | translate}}</th>
                                                <th>Reps</th>
                                                <th>{{'observation' | translate}}</th>
                                                <th>{{'action' | translate}}</th>
                                                <th>{{'interrupt' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let r of u_obj['details']"  [ngClass]="{'pending-border': r.status=='PENDIENTE', 'done-border': r.status!='PENDIENTE'}">
                                                <td>{{r.anName}}</td>
                                                <td>{{r.status}}</td>
                                                <td>{{r.reps}}</td>
                                                <td>
                                                    <input type="text" class="form-control" name="obs" [(ngModel)]="r['observation']" [disabled]="!r.sel || (r.status!= 'PENDIENTE')">
                                                </td>

                                                <td class="action-buttons" style="text-align: center;">
                                                    <i (click)="showAnalysis(analysis1, r)" title="{{'open' | translate}}" style="cursor:pointer;" class="fa fa-external-link-alt text-info m-r-10">
                                                    </i>
                                                </td>
                                                <td style="text-align: center;">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" [attr.checked]="(r.sel) ?  'checked': null" id="{{r.samdId}}" name="{{r.samdId}}" (change)="r.sel=!r.sel;r.observation='';" [disabled]="r.status !='PENDIENTE'">
                                                        <label class="custom-control-label" for="{{r.samdId}}">&nbsp;&nbsp;&nbsp;</label>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                          <!-- end details -->

                        </div>
                        <!-- end row main -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(info1)">{{'close' | translate}}</button>

                      <div [ngClass]="{'hidden': isShown}">
                       <button [ladda]="isInterrupt" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="assignAndUpdate(u_obj)">
                            <i class="ti-check-box m-r-10"></i>{{'interrupt' | translate}}
                        </button>
                     </div>
                      <!--*ngIf="u_obj['sampStatusId'] == '76' || u_obj['sampStatusId'] == '77' "-->
                      <!--<div *ngIf="(u_obj['GroupName'] == 'PCR' || u_obj['GroupName'] == 'Pcr') && (u_obj['sampStatusId'] == '76' || u_obj['sampStatusId'] == '77') " >
                         <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="savePCR(u_obj)"> <i class="ti-check-box m-r-10"></i>{{'save' | translate}}</button>
                      </div>-->
                    </div>
                </ng-template>

                <ng-template #analysis1 let-amodal>

                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'sinput_process_analysis' | translate}}: {{a_obj['anName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <div *ngIf="loadingAnalysis" class="common-loader">
                            <i class="fas fa-sync-alt fa-spin fa-3x" style="color: gray"></i>
                        </div>

                        <div *ngIf="!loadingAnalysis" class="row">
                            <div class="col-12 p-0">
                                <div class="table-responsive">
                                    <table class="table">
                                        <!-- <thead class="bg-danger text-white"> -->
                                        <thead>
                                            <tr>
                                                <th>{{'name' | translate}}</th>
                                                <th>{{'code' | translate}}</th>
                                                <th>{{'value' | translate}}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let r of a_obj['details']">
                                                <td *ngIf="isShowtd===true " style="width:4em" >{{r.parName}}<label *ngIf="r.parIsRequired">&nbsp;*</label></td>
                                                <td *ngIf="isShowtd===false">{{r.parName}}<label *ngIf="r.parIsRequired">&nbsp;*</label></td>
                                                <td>{{r.parCode}}</td>
                                                <td *ngIf="r.parIsManualType">
                                                    <input type="text" class="form-control pr-2 pl-0 text-right" name="{{r.parName}}" [(ngModel)]="r['value']" [disabled]="a_obj['status'] != 'PENDIENTE'">
                                                </td>
                                                <td *ngIf="r.parIsRangeType">
                                                    <select class="custom-select custom-select-md" [(ngModel)]="r['value']" name="{{r.parName}}" [disabled]="a_obj['status'] != 'PENDIENTE'">
                                                        <option *ngFor="let p of r['ranges']" value="{{p.id}}">{{p.val}}</option>
                                                    </select>
                                                </td>
                                                <td *ngIf="r.parIsFixedType">
                                                    <input type="text" class="form-control pr-2 pl-0 text-right" name="{{r.parName}}" [(ngModel)]="r['value']" disabled="true">
                                                </td>
                                                <td *ngIf="r.parIsFormulaType">
                                                    <input type="text" class="form-control pr-2 pl-0 text-right" name="{{r.parName}}" [(ngModel)]="r['value']" disabled="{{!r.parIsEditable}}">
                                                </td>
                                                <td *ngIf="r.parIsRichTextType">
                                                    <input type="text" class="form-control pr-2 pl-0 text-right" name="{{r.parName}}" [(ngModel)]="r['value']" disabled="true">
                                                </td>
                                                <td>
                                                    <button *ngIf="r.parIsFormulaType && (a_obj['status'] == 'PENDIENTE')" [ladda]="r.evaluating" data-style="expand-left" type="button" class="btn btn-custom-003d6a btn-sm" (click)="evaluateFormula(r, a_obj['details'])" style="color: #ffffff;margin:0">{{'evaluate' | translate}}</button>

                                                    <button *ngIf="r.parIsRichTextType" type="button" class="btn btn-custom-003d6a" (click)="openRichEditor(richeditor, r)" style="color: #ffffff;margin:0">{{'edit' | translate}}</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- begin ref methods -->
                            <div class="col-12">
                                <div class="row title-row">
                                    <div class="col-12">{{'sampapp_refmethods' | translate}}</div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <!-- <thead class="bg-danger text-white"> -->
                                        <thead>
                                            <tr>
                                                <th>{{'name' | translate}}</th>
                                                <th>{{'desc' | translate}}</th>
                                                <th style="text-align: center;">{{'select' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let r of a_obj['methods']">
                                                <td>{{r.refDetReference}}</td>
                                                <td>{{r.refDetDescription}}</td>
                                                <td style="text-align: center;">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" [attr.checked]="(r.sel) ? 'checked' : null" id="m{{r.refDetId}}" name="m{{r.refDetId}}" [disabled]="a_obj['status'] != 'PENDIENTE'" (change)="r.sel=!r.sel;"><!--null-->
                                                        <label class="custom-control-label" for="m{{r.refDetId}}">&nbsp;&nbsp;&nbsp;</label>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- end ref methods -->
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="amodal.dismiss('Cross click')">{{'close' | translate}}</button>

                        <button [ladda]="isSaving" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="saveAnalysis(a_obj, amodal, false)" *ngIf="a_obj['status'] == 'PENDIENTE'">
                            <i class="ti-save m-r-10"></i>{{'save' | translate}}
                        </button>

                        <button [ladda]="isSaving" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="saveAnalysis(a_obj, amodal, true)" *ngIf="a_obj['status'] == 'PENDIENTE'">
                            <i class="ti-check-box m-r-10"></i>{{'save_finish' | translate}}
                        </button>
                    </div>
                </ng-template>

                <ng-template #richeditor let-richmodal>

                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit' | translate}} {{'parameter' | translate}}: {{e_obj['parName']}}</h4>
                    </div>
                    <div class="modal-body">
                        <quill-editor [(ngModel)]="e_obj['value']" [styles]="{height: '400px', width: '100%', color:'#000'}"></quill-editor>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="richmodal.dismiss('Cross click')">{{'close' | translate}}</button>


                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>

