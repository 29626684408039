<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-md-11 col-9 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-md-1 col-3 m-b-30" style="text-align: right;">
                        <button type="button" class="btn btn-custom-003d6a" (click)="showFilters = !showFilters" style="color: #ffffff;" >
                            <i *ngIf="!showFilters" class="far fa-caret-square-down"></i>
                            <i *ngIf="showFilters" class="far fa-minus-square"></i>
                        </button>
                    </div>
                    <!-- filter box -->
                    <div class="col-12 m-b-30" style="text-align: left;" *ngIf="showFilters">
                        <div class="card filter-box">
                            <div class="card-body row">
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'date' | translate}} {{'from' | translate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp1" [(ngModel)]="f_datefrom" ngbDatepicker #d="ngbDatepicker" [readonly]="true">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                                <i class="far fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'date' | translate}} {{'to' | translate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp2" [(ngModel)]="f_dateto" ngbDatepicker #d2="ngbDatepicker" [readonly]="true">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                                <i class="far fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'status' | translate}}</label>
                                    <select class="custom-select custom-select-md" [(ngModel)]="f_custom1" name="cf1">
                                        <option *ngFor="let p of all_status" value="{{p.origid}}">{{p.name}}</option>
                                    </select>
                                </div>
                                <div class="col-12 m-b-30" style="text-align: right;">
                                    <button type="button" class="btn btn-danger" (click)="clearSentFilters()">
                                        <i class="far fa-trash-alt"></i> {{'clear' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- filters -->
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getListPost()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th>ID</th>
                                <th>{{'technician' | translate}}</th>
                                <th>{{'company' | translate}}</th>
                                <th>{{'site' | translate}}</th>
                                <th>{{'date' | translate}}</th>
                                <th>{{'status' | translate}}</th>
                                <th>Grupo de análisis</th>
                                <th>{{'assignedto' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.sampId}}</td>
                                <td>{{row.UserTechnicalName}}</td>
                                <td>{{row.cmpName}}</td>
                                <td>{{row.sitName}}</td>
                                <td>{{row.sampDate | date:'y-MM-d HH:mm:ss'}}</td>
                                <td>{{row.StatusName}}</td>
                                <td>{{row.GroupName}}</td>
                                <td>{{row.AssignedName}}</td>
                                <td class="action-buttons" style="text-align: left;">

                                    <i (click)="showInfo(info1, row)" title="{{'open' | translate}}" style="cursor:pointer;" class="fa fa-external-link-alt text-info m-r-10">
                                    </i>

                                    <i (click)="showOverdue(overd1, row)" title="{{'overdue' | translate}}" style="cursor:pointer;" [ngClass]="{'fa fa-comment-alt m-r-10': true, 'text-info': isAllowed('overdue', row), 'text-muted': !isAllowed('overdue', row)}">
                                    </i>

                                    <!-- <i *ngIf="(row.sampId !== isDownloading)" (click)="downloadReport(row)" style="cursor:pointer;" class="ti-cloud-down text-info m-r-15" title="{{'export_pdf' | translate}}"></i> -->
                                    <i *ngIf="(row.sampId !== isDownloading)" (click)="downloadReport(row)" style="cursor:pointer;" title="{{'export_pdf' | translate}}" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': isAllowed('download', row), 'text-muted': !isAllowed('download', row)}"></i>

                                    <i *ngIf="(row.sampId === isDownloading)" class="fas fa-sync-alt fa-spin m-r-10 text-info"></i>


                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="8">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #info1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'sampapp_edit' | translate}} #{{u_obj['sampId']}}</h4>
                    </div>
                    <div class="modal-body">

                        <div *ngIf="loadingDetails" class="common-loader">
                            <i class="fas fa-sync-alt fa-spin fa-3x" style="color: gray"></i>
                        </div>

                        <!-- main row -->
                        <div *ngIf="!loadingDetails" class="row">

                            <div class="col-12">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'date' | translate}}</label>
                                                <input type="text" class="form-control" name="ndate" value="{{u_obj['sampDate'] | date:'y-MM-d HH:mm:ss'}}" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'status' | translate}}</label>
                                                <input type="text" class="form-control" name="StatusName" [(ngModel)]="u_obj['StatusName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'company' | translate}}</label>
                                                <input type="text" class="form-control" name="company" [(ngModel)]="u_obj['cmpName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'site' | translate}}</label>
                                                <input type="text" class="form-control" name="site" [(ngModel)]="u_obj['sitName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['SampleLocationType'] != null">
                                                <label>{{'samp_location_type' | translate}}</label>
                                                <input type="text" class="form-control" name="spotype" [(ngModel)]="u_obj['SampleLocationType']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['SampleLocationType'] == null">
                                                <label>{{'samp_location_type' | translate}}</label>
                                                <input type="text" class="form-control" name="spotype" value="{{'unit' | translate}}" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['UniName'] == null">
                                                <label>{{'samp_spot' | translate}}</label>
                                                <input type="text" class="form-control" name="spot" [(ngModel)]="u_obj['SampleLocationInstanceName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['UniName'] != null">
                                                <label>{{'unit' | translate}}</label>
                                                <input type="text" class="form-control" name="unit" [(ngModel)]="u_obj['UniName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'technician' | translate}}</label>
                                                <input type="text" class="form-control" name="UserTechnicalName" [(ngModel)]="u_obj['UserTechnicalName']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_ag' | translate}}</label>
                                                <input type="text" class="form-control" name="agr" [(ngModel)]="u_obj['AnalysyGroup']" disabled="true">
                                            </div>
                                             <!--Aqui vamos a umentar los nuevos campos-->
                                             <div class="col-md-6 ha-ibox">
                                                <label>{{'sample' | translate}}</label>
                                                <input type="text" class="form-control" name="samples" [(ngModel)]="u_obj['samples']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_type' | translate}}</label>
                                                <input type="text" class="form-control" name="sampletype" [(ngModel)]="u_obj['sampletype']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_qty' | translate}}</label>
                                                <input type="text" class="form-control" name="SampleQuantity" [(ngModel)]="u_obj['sampSampleQuantity']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_container' | translate}}</label>
                                                <input type="text" class="form-control" name="Container" [(ngModel)]="u_obj['sampContainer']" disabled="true">
                                            </div>
                                            <div *ngIf="u_obj['sampRequestedByClient'] == true" class="col-md-6 ha-ibox">
                                                <!--<label>{{'samp_reqbyclient' | translate}} *</label>-->
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" [attr.checked]="(u_obj['sampRequestedByClient']) ? 'checked' : null" id="requestedby" name="requestedby" disabled="true" >
                                                    <label class="custom-control-label" for="requestedby">{{'samp_reqbyclient' | translate}}</label>
                                                </div>
                                            </div>
                                            <!--fin vamos a umentar los nuevos campos-->
                                            <div class="col-12 ha-ibox">
                                                <label>{{'samp_comment' | translate}}</label>
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="3" name="sampObservation" [(ngModel)]="u_obj['sampObservation']" disabled="true" style="resize: none;"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['StatusName'] != 'PENDIENTE'">
                                                <label>{{'assigned_user' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['AssignedId']" name="AssignedId" [disabled]="!isAllowed('assign', u_obj)">
                                                    <option *ngFor="let p of workers" value="{{p.usrId}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampapp_datereceive' | translate}}</label>
                                                <input type="text" class="form-control" name="ndate" value="{{u_obj['sampReceptionDateTime'] | date:'y-MM-d HH:mm:ss'}}" disabled="true">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- end general info -->

                            <!-- begin analyzes details -->
                            <div class="col-12" *ngIf="u_obj['StatusName'] != 'PENDIENTE'">
                                <div class="row title-row">
                                    <div class="col-12">{{'sampapp_analysis' | translate}}</div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <!-- <thead class="bg-danger text-white"> -->
                                        <thead>
                                            <tr>
                                                <th>{{'type' | translate}}</th>
                                                <th>{{'special' | translate}}</th>
                                                <th>{{'comment' | translate}}</th>
                                                <th>{{'observation' | translate}}</th>
                                                <th>Reps</th>
                                                <th>{{'select' | translate}}</th>
                                                <th>{{'to_correct' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let r of u_obj['details']">
                                                <td>{{r.Analysis}}</td>
                                                <td>
                                                    {{ ((r.IsSpecial) ? 'yes' : 'no' ) |translate}}
                                                </td>
                                                <td>{{r.Comment}}</td>
                                                <td>
                                                    <input type="text" class="form-control" name="obs" [(ngModel)]="r['observation']" [disabled]="r.sel || (u_obj['StatusName'] != 'RECIBIDA')">
                                                </td>
                                                <td>{{r.reps}}</td>
                                                <td style="text-align: center;">
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" [attr.checked]="(r.sel) ? 'checked' : null" id="{{r.SamplingDetailId}}" name="{{r.SamplingDetailId}}" (change)="r.sel=!r.sel;r.observation='';" [disabled]="u_obj['StatusName'] != 'RECIBIDA'">
                                                        <label class="custom-control-label" for="{{r.SamplingDetailId}}">&nbsp;&nbsp;&nbsp;</label>
                                                    </div>
                                                </td>
                                                <td>
                                                  <i (click)="showAnalysis(create1, u_obj, r.SamplingAnalysisId)" style="cursor:pointer;" class="fa fa-external-link-alt text-info m-r-10"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- end analyzes details -->
                            <ng-template #create1 let-c="close" let-d="dismiss" let-amodal>
                                <div class="modal-header" style="background-color: #0076b3;">
                                    <h4 class="modal-title" style="color: #ffffff;">{{'to_correct_model' | translate}}: {{c_obj['agrName']}}</h4>
                                </div>
                                <div class="modal-body">
                                    <form class="form-horizontal">
                                        <div class="card-body">
                                            <div class="form-group row">
                                                <label for="fname" class="col-3 text-right control-label col-form-label">{{'comment' | translate}}</label>
                                                <div class="col-9">
                                                    <input type="text" class="form-control" [(ngModel)]="u_obj['container']" name="anKey" maxlength="200">
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                                    <!--<button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="previewItem(create1, c_obj)">{{'preview' | translate}}</button>-->
                                      <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="ToCorrect(u_obj, amodal)" >{{'save' | translate}}</button>
                                </div>
                            </ng-template>
                        </div>
                        <!-- end row main -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(info1)">{{'close' | translate}}</button>

                        <button [ladda]="isRejecting" data-style="expand-left" type="button" class="btn btn-danger" (click)="setAsRejected(u_obj)" *ngIf="u_obj['actions'].indexOf('reject') > -1">
                            <i class="ti-na m-r-10"></i>{{'reject' | translate}}
                        </button>

                        <button [ladda]="isReseting" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="setAsPending(u_obj)" *ngIf="u_obj['actions'].indexOf('reset') > -1">
                            <i class="ti-back-left m-r-10"></i>{{'reset' | translate}}
                        </button>

                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="assignAndUpdate(u_obj)" *ngIf="u_obj['actions'].indexOf('assign') > -1">
                            <i class="ti-user m-r-10"></i>{{'assign' | translate}}
                        </button>
                        <!--comentamos para revisar si asi no aprueba los analisis ya terminados -->
                       <!-- <button [ladda]="isApproving" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="approve(u_obj)" *ngIf="u_obj['actions'].indexOf('approve') > -1">
                            <i class="ti-check-box m-r-10"></i>{{'approve' | translate}}
                        </button>-->

                        <button [ladda]="isReceiving" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="setAsReceived(u_obj)" *ngIf="u_obj['actions'].indexOf('take') > -1">
                            {{'receive' | translate}}
                        </button>
                    </div>
                </ng-template>

                <ng-template #overd1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'sampapp_overdue' | translate}} #{{u_obj['sampId']}}</h4>
                    </div>
                    <div class="modal-body">

                        <div *ngIf="loadingDetails" class="common-loader">
                            <i class="fas fa-sync-alt fa-spin fa-3x" style="color: gray"></i>
                        </div>

                        <!-- main row -->
                        <div *ngIf="!loadingDetails" class="row">

                            <div class="col-12">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'last_updated' | translate}}</label>
                                                <input type="text" class="form-control" name="ndate" value="{{u_obj['sampOverdueDatetime'] | date:'y-MM-d HH:mm:ss'}}" disabled="true">
                                            </div>

                                            <div class="col-12 ha-ibox">
                                                <label>{{'sampapp_reasonoverdue' | translate}} *</label>
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="3" name="sampOverdueComment" [(ngModel)]="u_obj['sampOverdueComment']" style="resize: none;" [disabled]="(u_obj['StatusName'] == 'APROBADO')"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <!-- end row main -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(overd1)">{{'close' | translate}}</button>

                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateDelay(u_obj)" [disabled]="(u_obj['StatusName'] == 'APROBADO')">
                            <i class="ti-check-box m-r-10"></i>{{'save' | translate}}
                        </button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
