<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn btn-custom-003d6a" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th (click)="setOrder('agrId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='agrId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('agrName')" class="sh">
                                    {{'name' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='agrName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th (click)="setOrder('agrKey')" class="sh">
                                    {{'code' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='agrKey')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'desc' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc">
                                <td>{{row.agrId}}</td>
                                <td>{{row.agrName}}</td>
                                <td>{{row.agrKey}}</td>
                                <td>{{row.agrDescription}}</td>
                                <td class="action-buttons">
                                    <i (click)="openUpdateModal(update1, row)" style="cursor:pointer;" class="far fa-edit text-info m-r-10" title="{{'edit' | translate}}"></i>
                                    <i (click)="deleteItem({agrId:row.agrId}, translations)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="5">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_item' | translate}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['agrKey']" name="agrKey" required maxlength="20">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['agrName']" name="agrName" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['agrDescription']" name="agrDescription">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'agr_approvalperc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="c_obj['agrValidPercent']" name="agrValidPercent" min="0" max="100" placeholder="{{'agr_perc_msg' | translate}}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'agr_allanalysis' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="agrAllAnalysis" [(ngModel)]="c_obj['agrAllAnalysis']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                            <div>{{'agr_allanalysis_msg' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <div class="input-group mb-3">
                                    <label for="lname2" class="col-3 text-right control-label col-form-label">{{'add' | translate}} {{'samp_container' | translate}}</label>
                                    <div class="col-8">
                                      <select class="custom-select custom-select-md" name="usrId" [(ngModel)]="curr_container">
                                        <option *ngFor="let list of listConatiner; let i = index" [value]="list['ID']">{{list['value'] }}</option>
                                      </select>
                                    </div>
                                    <div class="col-1">
                                      <button type="button" class="btn btn-custom-003d6a" (click)="addContainer(c_obj)" style="color: #ffffff;">
                                        <i class=" fas fa-plus"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <h4>{{'samp_container' | translate}}</h4>
                                  <table class="table">
                                    <thead style="padding: 0">
                                    <tr>
                                      <th>{{'name' | translate}}</th>
                                      <th>{{'action' | translate}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let container of c_obj['AnalysisGroupContainer']; let i=index">
                                      <td>{{container.value}}</td>
                                      <td class="action-buttons">
                                        <i (click)="delUser(c_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <h4>{{'features' | translate}}</h4>
                                <i class="mdi mdi-plus-circle" (click)="feature(1, null)" style="color: #007D8A;"></i>
                                <div class="form-group row" *ngFor="let lstTm of c_obj['AnalysisGroupFeature']; let i = index">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">
                                        <i class="mdi mdi-minus-circle text-danger" (click)="feature(0, i)" style="color: #007D8A;"></i> {{'name' | translate}}
                                    </label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" id="AnalysisGroupFeature{{i}}" name="AnalysisGroupFeature{{i}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="lstTm['agfName']">
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'create' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'edit_item' | translate}}: {{u_obj['agrName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'code' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['agrKey']" name="agrKey" required maxlength="20">
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'name' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['agrName']" name="agrName" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'desc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="u_obj['agrDescription']" name="agrDescription">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">{{'agr_approvalperc' | translate}}</label>
                                    <div class="col-9">
                                        <input type="number" class="form-control" [(ngModel)]="u_obj['agrValidPercent']" name="agrValidPercent" min="0" max="100" placeholder="{{'agr_perc_msg' | translate}}">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="input-group mb-3">
                                        <label for="lname" class="col-3 text-right control-label col-form-label">{{'agr_allanalysis' | translate}}</label>
                                        <div class="col-9">
                                            <select class="custom-select custom-select-md" name="agrAllAnalysis" [(ngModel)]="u_obj['agrAllAnalysis']" required>
                                                <option [value]="true">{{ 'yes' | translate }}</option>
                                                <option [value]="false">{{ 'no' | translate }}</option>
                                            </select>
                                            <div>{{'agr_allanalysis_msg' | translate}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                  <div class="input-group mb-3">
                                    <label for="lname2" class="col-3 text-right control-label col-form-label">{{'add' | translate}} {{'samp_container' | translate}}</label>
                                    <div class="col-8">
                                      <select class="custom-select custom-select-md" name="usrId" [(ngModel)]="curr_container">
                                        <option *ngFor="let list of listConatiner; let i = index" [value]="list['ID']">{{list['value'] }}</option>
                                      </select>
                                    </div>
                                    <div class="col-1">
                                      <button type="button" class="btn btn-custom-003d6a" (click)="addContainer(u_obj)" style="color: #ffffff;">
                                        <i class=" fas fa-plus"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <h4>{{'samp_container' | translate}}</h4>
                                  <table class="table">
                                    <thead style="padding: 0">
                                    <tr>
                                      <th>{{'name' | translate}}</th>
                                      <th>{{'action' | translate}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let container of u_obj['AnalysisGroupContainer']; let i=index">
                                      <td>{{container.value}}</td>
                                      <td class="action-buttons">
                                        <i (click)="delUser(u_obj, i)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10" title="{{'delete' | translate}}"></i>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <h4>{{'features' | translate}}</h4>
                                <i class="mdi mdi-plus-circle" (click)="featureUpdate(1, null)" style="color: #007D8A;"></i>
                                <div class="form-group row" *ngFor="let lstTm of u_obj['AnalysisGroupFeature']; let i = index">
                                    <label for="lname" class="col-3 text-right control-label col-form-label">
                                        <i class="mdi mdi-minus-circle text-danger" (click)="featureUpdate(0, i)" style="color: #007D8A;"></i> {{'name' | translate}}
                                    </label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" id="AnalysisGroupFeature{{i}}" name="AnalysisGroupFeature{{i}}" [ngModelOptions]="{standalone: true}" [(ngModel)]="lstTm['agfName']">
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, u_obj)">Update</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
