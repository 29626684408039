<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-12 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <!-- <button type="button" class="btn btn-custom-003d6a" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired"><i class=" fas fa-plus"></i> {{'create' | translate}}</button> -->
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table group-box" *ngFor="let block of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                        <thead class="">
                            <tr>
                                <th>{{'company' | translate}}</th>
                                <th>{{'site' | translate}}</th>
                                <th>{{'group' | translate}}</th>
								<th>{{'sample' | translate}}</th>
                                <th>{{'date' | translate}}</th>
                                <th>{{'sample' | translate}} ID</th>
                                <th>{{'requested_by' | translate}}</th>
                                <th>{{'select' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of block.Details">
                                <td>{{block.cmpName}}</td>
                                <td>{{block.sitName}}</td>
                                <td>{{block.agrName}}</td>
								<td>{{block.samName}}</td>
                                <td>{{block.Fecha | date:'y-MM-dd H:mm:ss'}}</td>
                                <td>{{row.id}}</td>
                                <td>{{block.UserTechnicalName}}</td>
                                <td style="text-align: center;">
                                    <div class="custom-control custom-checkbox">
                                        <!-- <input type="checkbox" class="custom-control-input" [attr.checked]="(row.sel) ? 'checked' : null" id="{{row.id}}" name="{{row.id}}" (change)="checkSampling(row, block)" [disabled]="row.status!='X'"> -->
                                        <!--<input type="checkbox" class="custom-control-input" [checked]="row.sel" id="{{row.id}}" name="{{row.id}}" (change)="checkSampling(row, block)" [disabled]="row.status!='X'">-->
                                        <input type="checkbox" class="custom-control-input" [checked]="row.sel" id="{{row.id}}" name="{{row.id}}" (change)="checkSampling(row, block)">
                                        <label class="custom-control-label" for="{{row.id}}">&nbsp;&nbsp;&nbsp;</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                            	<td colspan="8" style="text-align: right;">
                            		<button type="button" class="btn btn-custom-003d6a" (click)="openPreviewModal(create1, block)" style="color: #ffffff;">{{'next' | translate}} <i class=" fas fa-arrow-right"></i></button>
                            	</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="width: 100%">
                    	<ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                    </div>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'create_report' | translate}}: {{c_obj['agrName']}}</h4>
                    </div>
                    <div class="modal-body">

                        <form class="form-horizontal">
                            <div class="card-body">
                                <!--<div class="form-group row">
                                    <label for="fname" class="col-3 text-right control-label col-form-label">{{'samp_container' | translate}}</label>
                                    <div class="col-9">
                                        <input type="text" class="form-control" [(ngModel)]="c_obj['container']" name="anKey" maxlength="20">
                                    </div>
                                </div>-->
                            </div>
                        </form>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="previewItem(create1, c_obj)">{{'preview' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="saveItem(create1, c_obj)">{{'save' | translate}}</button>
                    </div>
                </ng-template>


            </div>
        </div>
    </div>
</div>
