import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Global } from '../global';
import { Router, Params, CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReturnStatement } from '@angular/compiler';
import { Observable, from, of } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
//import { ApiService } from '../main/api.service';
import * as moment from 'moment-timezone';

//max time for api requests
const MAX_TIME = 60000;
const TOKEN_KEY = 'token';
const REFRESHTOKEN_KEY = 'refresh';
const PERMISSIONS_KEY = 'permissions';
const COMPANIES_KEY = 'companies';
const VERSION_KEY = 'appversion';
const TOKEN_KEY_AQ = 'token_aquasim';
const REFRESHTOKEN_KEY_AQ = 'refreshtoken_aquasim';

const TOKEN_EXPIRE_KEY = 'token_expires_aquasim';
const TOKEN_EXPIRES_PERIOD = 20;

@Injectable({
  providedIn: 'root'
})

export class LoginService implements CanActivate {

  private url: any;
  private token: any;
  public cont: number;
  momentjs: any = moment;

  pages = [
      {id: 'pdi', name: "HA_PATHOLOGICAL_ANALYSIS"},
      {id: 'pdiallcompany', name: "HA_PATHOLOGICAL_ANALYSIS"},
      {id: 'sampling', name: "HA_SAMPLING"},
      {id: 'sampling', name: "HA_SAMPLING_REQBYCLIENT"},
      {id: 'samplingallusers', name: "HA_SAMPLING"},
      {id: 'sapproval', name: "HA_SAMPLING_APPROVE"},
      {id: 'sinput', name: "HA_SAMPLING_INPUT"},
      {id: 'analysis_group', name: "HA_ANALYSIS_GROUP"},
      {id: 'companies', name: "HA_COMPANY"},
      {id: 'countries', name: "HA_COUNTRY"},
      {id: 'laboratories', name: "HA_LABORATORY_ENTITY"},
      {id: 'prodgroups', name: "HA_PRODUCTION_GROUP"},
      {id: 'samples', name: "HA_SAMPLE"},
      {id: 'preguntas', name: "HA_SAMPLE_TYPE"},
      {id: 'sample_locations', name: "HA_SAMPLE_LOCATION"},
      {id: 'locales', name: "HA_SAMPLE_LOCATION_INSTANCE"},
      {id: 'shrimp_sector', name: "HA_SHRIMP_SECTOR"},
      {id: 'skretting_site', name: "HA_SKRETTING_SITE"},
      {id: 'skretting_sector', name: "HA_SKRETTING_SECTOR"},
      {id: 'sites', name: "HA_SITE"},
      {id: 'sitegroups', name: "HA_SITE_GROUP"},
      {id: 'units', name: "HA_UNIT"},
      {id: 'analysis', name: "HA_ANALYSIS_TYPE"},
      {id: 'params', name: "HA_PARAMETERS"},
      {id: 'users', name: "HA_USER"},
      {id: 'metas', name: "HA_REFMETHODS"},
      {id: 'visits', name: "HA_VISIT"},
      {id: 'formshome', name: "HA_FORM"},
      {id: 'prochome', name: "HA_PROC"},
      {id: 'cruds', name: "HA_MANT"}
    ];

  constructor(
    private _router: Router,
    private _http: HttpClient,
    private storage: Storage,
    private route: ActivatedRoute/*,
    private api: ApiService*/
  ) {
      this.url = Global.url;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        //var page = route.routeConfig.path;
        var page = state.url;
        //console.log(route);
        //console.log(state);
        //console.log(page);

        return this.storage.get(PERMISSIONS_KEY).then(mods => {
            let permissions = ['dashboard', 'settings'];
            let ipage:any;
            //console.log('inside permissions check');

            if( !Array.isArray(mods) ){
                this.clearData().then(() => {
                    this._router.navigate(['/authentication/login']);
                });
                return false;
            }

            mods.forEach(i => {
                ipage = this.getPageIdFromModule(i);
                if( ipage != false ){
                    permissions.push(ipage);
                }
            });

            /*console.log(page);
            console.log(permissions);*/

            let checker = permissions.filter(p => {
                  return page.toLowerCase().includes(p.toLowerCase());
            });

            //console.log(checker);

            if( checker.length > 0 ){
                return true;
            }
            else{
                this.clearData().then(() => {
                    this._router.navigate(['/authentication/login']);
                });

                return false;
            }
        });

        /*return this.refreshToken().toPromise().then(x => {
            console.log(x);
            if( x != false ){
                //permissions check
            }
            else{
                this.clearData().then(() => {
                    this._router.navigate(['/authentication/login']);
                });

                return false;
            }
        });*/
    }

  getPageIdFromModule(md){
      let tmp = [];
      tmp = this.pages.filter(function(a){
                        return a.name == md;
                      });

      if( tmp.length > 0 ){
          return tmp[0].id;
      }

      return false;
  }

  getPermissions(){
      return this.storage.get(PERMISSIONS_KEY);
  }
  getServerVersion(){
    return this.storage.get(VERSION_KEY);
  }
  /*public getToken() {
    if (localStorage.getItem('Token') && localStorage.getItem('Refresh')) {
        this.token = localStorage.getItem('Token'), localStorage.getItem('Refresh');
    } else {
        this.token = null;
    }
  }

  public log() {
    return (this.token != null) ? true : false;
  }*/

  public login(data: any) {
    const params = JSON.stringify(data);
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${this.url}login/authenticate`, params, { headers });
  }

  public getPinCode(email: any) {
    //https://aquasim.skretting.com/
    var aq_url = 'https://az-aquasimuat.skretting.com/';
    var aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';

    const params = {
        servicetoken: aq_apptoken,
        email: email
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}services/api/v1.0/passwordreset/GetPincode`, params, { headers });
  }

  public resetPwd(email: any, pin:any, pwd:any) {
    //https://aquasim.skretting.com/
    var aq_url = 'https://az-aquasimuat.skretting.com/';
    var aq_apptoken = '{22261112-BC9B-4042-A9D6-5B9AEC292F85}';

    const params = {
        servicetoken: aq_apptoken,
        email: email,
        pincode: pin,
        newpassword: pwd
    };

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this._http.post(`${aq_url}services/api/v1.0/passwordreset/resetpassword`, params, { headers });
  }

  public saveData(data: any) {
      this.cont = 0;
      var permissions = [];
      const my_companies: Array<any> = [];
      const temp = Object.keys(data['Permissions']['result']).map(function(key) {
        return [(data['Permissions']['result'])[key]];
      });

      if( (data['Permissions'] != undefined) && (data['Permissions']['result'] != undefined) ){
          if( Array.isArray(data['Permissions']['result']) ){
              var companies = data['Permissions']['result'];

              companies.forEach(x => {
                //console.log(x);
                  my_companies.push({name: x.cmpName, id: x.cmpID});
                  x.Modules.forEach(y => {
                      if( permissions.indexOf(y.Name) < 0 ){
                          permissions.push(y.Name);
                      }
                  });
              });
          }
      }

      var promises =
      [
        this.storage.set('username', data['FullName']),
        this.storage.set('token', data['TokenHealthAdvisor']),
        this.storage.set('refresh', data['RefreshTokenHealthAdvisor']),
        this.storage.set('token_aquasim', data['TokenAquasym']),
        this.storage.set('refreshtoken_aquasim', data['RefreshTokenAquasym']),
        this.storage.set('permissions', permissions),
        this.storage.set('appversion', data['AppVersion']),
        //this.storage.set('companies', my_companies),
        //this.storage.set('company_id', data['UserInfo']['result']['selectedCompanyId'])
      ];

      return Promise.all(promises);
  }

  public clearData(){
      return this.storage.clear();
  }

}
