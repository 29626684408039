import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import {Itemag} from './itemag.model';

// import { PdfMakeWrapper, Txt, Stack, Columns, Table, Cell, Img } from 'pdfmake-wrapper';
//import pdfFonts from '../../../../assets/calibri-fonts';
// // PdfMakeWrapper.setFonts(pdfFonts);
// PdfMakeWrapper.setFonts(pdfFonts, {
//   calibri: {
//     normal: 'calibri.ttf',
//     bold: 'calibri-bold.ttf',
//     italics: 'calibri-italics.ttf',
//     bolditalics: 'calibri-bolditalics.ttf'
//   }
// });
// PdfMakeWrapper.useFont('calibri');
//Aqui para validar permiso

import { LoginService } from '../../../services/authentication/login.service';
import {PdfMakeWrapper} from "pdfmake-wrapper";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import {PdfestructureService} from "../../../services/pdf/pdfestructure.service";

@Component({
  selector: 'app-sampling',
  templateUrl: './sampling.component.html',
  styleUrls: ['./sampling.component.css']
})
export class SamplingComponent extends BaseCrudComponent {

	translations:any;
	fields:any;
	DataReport:any;
    loadingRequired = false;
    loadingFollow = false;
    agitems:any;
    rawitems:any;
    groupselect:any;

    raw_companies:any;
    raw_sites:any;
    raw_units:any;
    raw_spots:any;
    raw_labs:any;
    raw_spotssam:any;
    raw_spotsgroup:any;

    companies:any;
    sites:any;
    units:any;
    spots:any;
    labs:any;
    sample_types:any;
    spotsample:any;
    spotsampletype:any;
    samples_:any;
    spotgroup:any;
    spotcontainer:any;

    isDownloading = 0;
    actions:any;

    f_obj:any;
    all_status:any;
    pcr_filters:any;
    ground_filters:any;

    loctypes:any;
    sample:any;

    sitelab:any;


    //Aqui para validar el permiso
    //translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    items:any;
    permissions = [];

    //Aqui para la lista de envases
    offline_key_container:'SAMP_CONTAINER';
    offline_key_laboratory:'SAMP_LABORATORY';
    listContainer:any;
    listLaboratory:any;


    //raw_spots
    //raw_locations:any;
    //spots
    //locations:any;

    offline_key_sam='SAMPLE';
    offline_key = 'SAMP_OFFLINE';
    offline_key_list = 'SAMPLING';
    offline_key_agr = 'SAMP_AGR_FLOW';
    offline_key_stypes = 'SAMP_TYPES';
    offline_key_loc = 'SAMP_LOCATIONS';

    offline_key_sitLab='SAMP_SITLAB';

    offline_items = [];

    offline_key_client='SAMP_CLIENT_FLOW';

    //ReadOnlyStyleGuideNotes: boolean;
    isShown: any;

    new_data: any;
    imgFooter:any;

	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        public translate: TranslateService,
        public auth: LoginService,
      ) {
        super(srv, modalService, storage, router);
        this.create_url = '';
        this.read_url = 'Sampling/All';
        this.update_url = '';
        this.delete_url = 'Sampling/DeleteLogical';

        this.fields = [];
        this.rawitems = [];
        this.agitems = [];
        this.groupselect = [];
        this.f_obj = {};
        this.new_data = [];

        this.c_obj['ag_obj'] = new Itemag(0, '');
        this.search_fields = ['cmpName', 'sitName', 'UserTechnicalName', 'sampId'];

        this.reload.subscribe(val => {
            if( val ){
                this.clearData();
                this.getList();
            }
        });

        this.fillData();

        this.loadingRequired = true;
        this.getSampleLocationAllBelongAP().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.loctypes = [];
                this.raw_spots = [];
                this.spots = [];

                x.forEach(sl => {
                    let tmp = {id: sl.slocId, name:sl.slocName};
                    this.loctypes.push(tmp);
                    //debugger;
                    if(Array.isArray(sl.SampleLocationInstance)){
                        sl.SampleLocationInstance.forEach(y => {
                            //this.raw_locations.push();
                            this.raw_spots.push({id:y.slinId, name:y.slinName, sitid:y.slinSiteId, loctype:sl.slocId});
                        });
                    }
                });
            }
        });
      (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;
       /* this.loadingRequired = true;
        this.getListActiveLaboratory().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.sitelab = [];
                this.raw_labs = [];
                this.labs= [];

                x.forEach(sl => {
                    let tmp = {id: sl.sitId, name:sl.sitName, labsite:sl.sitLaboratoryId};
                    this.sitelab.push(tmp);
                    //debugger;
                    if(Array.isArray(sl.Laboratory)){
                        sl.Laboratory.forEach(y => {
                            //this.raw_locations.push();
                            this.raw_labs.push({id:y.labId, name:y.labName, labsite:y.labsite});
                        });
                    }
                });
            }
        })*/


        this.all_status = Global.sampling_status;

        this.srv.offline.subscribe(r => {
            this.isOffline = r;
        });

        this.isOffline = false;
    }


    ngOnInit() {
        //this.getDataReport();
        //this.getImgFooter();

        this. isShown = false;
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

              this.loadingRequired = true;
              this.getAnalysisGroupData().subscribe(x => {
                  //console.log(x);
                  if(x != false){
                      this.loadingRequired = false;
                      this.rawitems = x;
                      this.parseAnalysisGroupData(x);

                      this.loadingRequired = true;
                      this.getSampleTypes().subscribe(st=>{
                          this.loadingRequired = false;
                      });
                        //llenando arreglo sample
                        this.sample = [];
                        this.raw_spotssam = [];
                        this.spotsample = [];
                        //debugger;
                        x.forEach(sa=>{
                            if(Array.isArray(sa.SampleDetail)){
                                sa.SampleDetail.forEach(y => {
                                    this.sample.push({id:y.SamDSampleId, name:y.samName,group:y.SamDGroupId, samisdeleted:y.samIsDeleted});
                                });
                            }

                        });
                        //aqui llenamos sampletype
                        //debugger;
                        x.forEach(sa=>{
                            if(Array.isArray(sa.SampleTypeDetail)){
                                sa.SampleTypeDetail.forEach(y => {
                                    y.SampleType.forEach(t => {
                                        this.raw_spotssam.push({id:t.stypeID, name:t.stypeName, typesamid:t.stypeSampleId, groupId:sa.agrId, status:t.stypeIsDeleted});
                                    });
                                });
                            }
                         });
                          //console.log('sampletype',this.raw_spotssam);

                      //aqui vamos a llenar los envases segun el grupo de analisis
                     this.listContainer=[];

                      x.forEach(sa=>{
                        if(Array.isArray(sa.AnalysisGroupContainer)){
                          sa.AnalysisGroupContainer.forEach(y => {
                            this.listContainer.push({id:y.ID, name:y.value,group:y.agrId});
                          });
                        }

                      });
                      //console.log("this.listContainer:",this.listContainer)
                    }
              });

              /*this.getAllSamplingContainer().subscribe(x => {
                if (x != false) {
                    //console.log(this.listContainer,'listContainer');
                    this.listContainer = x;
                }

              });*/

              this.getListActiveLaboratory().subscribe(x => { //getListActiveLaboratory
                if (x != false) {
                    //console.log(this.listLaboratory,'listLaboratory');
                    this.listLaboratory = x;
                }

              });

              this.getList();
              //this.hasPermission('HA_SAMPLING_REQBYCLIENT');

        });

        this.auth.getPermissions().then(x => {
            this.permissions = x;
        });

    }


    public clearData(){
        this.rows = [];
        this.u_obj = {};
    }

    fillData(){
        this.raw_companies = [];
        this.raw_sites = [];
        this.raw_units = [];
        this.raw_spots = [];
        this.raw_labs = [];
        this.companies = [];
        this.sites = [];
        this.units = [];
        this.spots = [];
        this.labs = [];
        this.sample_types = [];
        this.pcr_filters = [];
        this.ground_filters = [];
        this.samples_=[];

        this.storage.get('companies').then(x => {
            x.forEach(c => {
                this.raw_companies.push({id:c.CompAquasymId, name: c.cmpName});
                c.Site.forEach(s => {
                    this.raw_sites.push({id:s.SitAquasymId, name: s.sitName, sector:s.SkrettingSectorName, cmpid:c.CompAquasymId});
                    s.Unit.forEach(u => {
                        this.raw_units.push({id:u.UniAquasimId, name: u.uniName, sitid:s.SitAquasymId, isActive:u.unitActive});
                    });

                    s.SampleLocationInstance.forEach(sp => {
                        //this.raw_spots.push({id:sp.slinId, name: sp.slinName, sitid:s.SitAquasymId});
                    });

                });
            });

            this.companies = this.copyObject(this.raw_companies);
        });

        this.actions = {};
        Global.sampling_status.forEach(s => {
            this.actions[s.name] = s.samp_actions;
        });

        var opts = ['ProductTypePCR', 'PresentationPCR', 'ConditionSamplePCR', 'EnvironmentTemperaturePCR', 'RHPCR'];

        this.storage.get('common').then(data => {
            //console.log(data);
            data.forEach(x => {
                if( opts.indexOf(x.Name) > -1 ){
                    this.pcr_filters[x.Name] = x.Values;
                }
            });
        });


        // Jenny tomar en cuenta esto para Suelo
        var opts2 = ['sampDepth'];

        this.storage.get('common').then(data => {
                data.forEach(x => {
                if( opts2.indexOf(x.Name) > -1 ){
                    this.ground_filters[x.Name] = x.Values;//.ground_filters[x.Name] = x.Values;
                }
            });
        });
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( this.f_datefrom != null ){
            tosend['_dateStart'] = this.getISODate(this.f_datefrom);
        }

        if( this.f_dateto != null ){
            tosend['_dateEnd'] = this.getISODate(this.f_dateto);
        }

        if( this.f_custom1 != null ){
            tosend['_status'] = [parseInt(this.f_custom1)];
        }

        //show offline list
        if(this.isOffline){
            this.srv.getOfflineData(this.offline_key_list).then(x => {
                //console.log(x);
                this.rows = x.data;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.rows.forEach(r => {
                    r['actions'] = this.getActionsByStatusName(r['StatusName']);
                    r['estimatedDelivery'] = r['sampEstimatedDeliveryDateStart'];

                    if( r['estimatedDelivery'] != null ){
                        r['estimatedDelivery'] = r['estimatedDelivery'].substring(0, 10);
                    }

                    if( r['sampEstimatedDeliveryDateStart'] != r['sampEstimatedDeliveryDateEnd'] ){
                        if( r['sampEstimatedDeliveryDateEnd'] != null ){
                            r['estimatedDelivery'] += ' - ' + r['sampEstimatedDeliveryDateEnd'].substring(0, 10);
                        }
                    }
                });
            });
        }
        else{
            this.loadingList = true;
            this.srv.postCall(qst, tosend).subscribe(x => {
                this.loadingList = false;
                if(x.success){
                    this.rawdata = x.val;
                    //save offline data
                    this.srv.saveOfflineData(this.offline_key_list, this.rawdata);

                    this.rows = x.val;
                    this.page = 1;

                    if( Array.isArray(this.rows) ){
                        this.pageTotal = this.rows.length;
                    }

                    this.rows.forEach(r => {
                        r['actions'] = this.getActionsByStatusName(r['StatusName']);
                        r['estimatedDelivery'] = r['sampEstimatedDeliveryDateStart'];

                        if( r['estimatedDelivery'] != null ){
                            r['estimatedDelivery'] = r['estimatedDelivery'].substring(0, 10);
                        }

                        if( r['sampEstimatedDeliveryDateStart'] != r['sampEstimatedDeliveryDateEnd'] ){
                            if( r['sampEstimatedDeliveryDateEnd'] != null ){
                                r['estimatedDelivery'] += ' - ' + r['sampEstimatedDeliveryDateEnd'].substring(0, 10);
                            }
                        }
                    });
                    this.filterAll();
                    //console.log('antes de entrar al hasspermisiion');
                    //this.hasPermission();

                }
            });
        }
        //load unsaved items
        this.getOfflineItems();
        //this.hasPermission('HA_VISIT');
    }
    public getActionsByStatusName(st:any){
        let rs:any;

        if( this.actions[st] != undefined ){
            rs = this.actions[st];
        }
        else{
            rs = [];
        }

        return rs;
    }

    public filterSites(cmpid:any){
        /*console.log('cool');
        console.log(cmpid);*/
        var resp:boolean;
        this.resetCompanySelections();

        if( cmpid != '' ){
            let test = this.raw_sites.filter(s => {
                return s.cmpid == cmpid;
            });

            this.sites = test;
        }
        else{
          this.sites = [];
        }
       //this.getGetLaboratorySite(SitAquasymId);
    }

    public filterUnits(id:any){
        var resp:boolean;
        this.resetSiteSelections();

        if( id != '' ){
            let test = this.raw_units.filter(s => {
                return s.sitid == id;

            });
            //console.log(id);

            this.units = test;
        }
        else{
          this.units = [];
        }
        this.getGetLaboratorySite(id);
    }

    public filterActiveUnits(status:any, siteId:number){
        let comp = true;

        if( (status == undefined) || (siteId == undefined) ){
            return;
        }

        if( status < 0 ){
            let test = this.raw_units.filter(s => {
                return (s.sitid == siteId);
            });

            this.units = test;
        }
        else{
            if(status > 0){
                comp = true;
            }
            else{
                comp = false;
            }

            let test = this.raw_units.filter(s => {
                if (s.isActive==null)
                {
                   s.isActive=false;
                }
                return (s.isActive == comp) && (s.sitid == siteId);
            });

            this.units = test;
        }
    }

  //filter container
  public filterContainer(GroupID:any){
    var resp:boolean;
    console.log("entro a filtar container")
    console.log("listContainer:",this.listContainer)
    //debugger;
    if( (GroupID != undefined) && (GroupID != '') ){
      this.spotcontainer= [];
      let testgroup = this.listContainer.filter(s => {
        return ( (s.group == GroupID) );// && (s.group == this.sample_types) );
      });
      this.spotcontainer = testgroup;
    }
    else{
      this.spotcontainer = [];
      //this.spotsampletype = [];
    }

  }
  //fin filter container

    //filter sample
    public filterSample(GroupID:any){
        var resp:boolean;

        //debugger;
        if( (GroupID != undefined) && (GroupID != '') ){
            this.spotsample= [];
            let testgroup = this.sample.filter(s => {
                return ( (s.group == GroupID) && (s.samisdeleted !=true));// && (s.group == this.sample_types) );
            });
            this.spotsample = testgroup;
        }
        else{
            this.spotsample = [];
            //this.spotsampletype = [];
        }
    }
    //fin filter sample

    //filter para sampletypes
    public filterSampleTypes(samId:any,GroupId:any){
        var resp:boolean;

       //debugger;
        if( (samId != undefined) && (samId != '') ){
            this.spotsampletype= [];

            let testsamtype = this.raw_spotssam.filter(s => {
                return ( (s.typesamid == samId) && (s.groupId == GroupId) && (s.status != true));
            });
            this.spotsampletype = testsamtype;
        }
        else{
            this.spotsampletype = [];
        }
    }
    //fin filter para sampletypes

    public filterLocations(loctype:any, siteid:any){
        var resp:boolean;
        //console.log(loctype);
        //console.log(siteid);
        //console.log(this.raw_spots);

        if( (siteid != undefined) && (siteid != '') && (loctype > -1) ){
            this.spots = [];
            let test = this.raw_spots.filter(s => {
                return ( (s.sitid == siteid) && (s.loctype == loctype) );
            });

            this.spots = test;
        }
        else{
            this.spots = [];
        }
    }

    public filterAnalysisGroup(id:any, update?:any){
        var resp:boolean;
        var obj:any;

        if( update != undefined ){
            obj = this.u_obj;
        }
        else{
            obj = this.c_obj;
        }

        if( id != '' ){
            //aumentado limpieza de los 2 arryas para poder controlar que se llene el tipo de muestra cuando solo e tenga una muestra. Jeis 10/08/2021
            //this.spotsampletype = [];
            this.spotsample = [];
            let test = this.agitems.filter(s => {
                return s.sampAnalysisGroupId == id;
            });

            if( test.length > 0 ){
                obj['ag_obj'] = test[0];
                obj['ag_obj'].recalculateGroupFeatures();
                obj['ag_obj'].recalculateAnalysisFeatures();
            }
            else{
                obj['ag_obj'] = new Itemag(0, '');
            }
        }

        else{
          obj['ag_obj'] = new Itemag(0, '');
        }
        //debugger;
        this.filterSample(id);
        this.filterContainer(id);

        //console.log(obj['ag_obj']);
    }

    public resetAGItems(){
        this.agitems.forEach(x => {
            //console.log(x);
            x.group_features.forEach(i => {
                i.sel = false;
            });

            x.analysis.forEach(i => {
                i.sel = false;
                i.reps = 1;
                i['comment'] = '';

                if( (i['feat'] != undefined) && Array.isArray(i['feat']) ){
                    i['feat'].forEach(j => {
                        j.sel = false;
                    });
                }
            });
        });
    }

    public openCreateModal(content: any) {
        this.c_obj = {};
        this.c_obj['date'] = new Date();
        this.c_obj['date'] = this.c_obj['date'].toISOString();
        this.c_obj['date'] = this.c_obj['date'].substring(0, 10);
        this.c_obj['ag_obj'] = new Itemag(0, '');
        this.c_obj['sampContainer'] = null;
        console.log(this.c_obj)
        this.resetAGItems();
        //this.modalService.open(content, { size: 'lg' });
        this.modalService.open(content, { backdrop: 'static', keyboard: false });
    }

    public getAnalysisGroupData(){
        var par_url = 'AnalysisGroup/GetAnalysisFlow';


        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_agr).then(x => {
                if( x != null ){
                    return x.data;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);

                        if(mydata.success == true ){
                            this.srv.saveOfflineData(this.offline_key_agr, mydata.val);
                            return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
        }
    }

    parseAnalysisGroupData(items:any){
        var tmp:any;
        var inn:any;

        items.forEach(x => {
            this.groupselect.push({id:x['agrId'], name: x['agrName']});

            //aqui debo crear el array para sample y sample detail
            /*let tmpsam = {id: x.samId, name:x.samName, group:x.SamDGroupId};//,group:sa.SamDGroupId};
            this.sample.push(tmpsam);
            console.log('sample:',this.sample);
             if(Array.isArray(x.SampleType)){
                        x.SampleType.forEach(y => {
                            //this.raw_locations.push();
                            this.raw_spotssam.push({id:y.stypeID, name:y.stypeName, samtype:x.samId});
                        });
                        //console.log('llego es spotsam',this.raw_spotssam);
             }
             */
            //fin aqui debo crear el array para sample

            tmp = new Itemag(x['agrId'], x['agrName']);
            tmp.MinFeatures = x['MinFeatures'];

            //group features
            x['AnalysisGroupFeature'].forEach(gf => {
                tmp.group_features.push({id: gf['agfId'], name: gf['agfName'], sel: false});
            });

            //analysis items
            x['Analysis'].forEach(a => {
                inn =
                {
                    id: a['anId'],
                    name: a['anName'],
                    tooltip: a['anTooltip'],
                    MinFeatures: a['MinFeatures'],
                    MaxFeatures: 0,
                    SelFeatures: 0,
                    featColor: 'danger',
                    sel: false,
                    special: false,
                    comment: '',
                    feat: [],
                    reps: 1
                }

                if( (a['AnalysisFeature'] != undefined) && (a['AnalysisFeature'].length > 0) ){
                    a['AnalysisFeature'].forEach(af => {
                        inn.feat.push({id:af['anfId'], name:af['anfName'], sel: false});
                    });
                }

                if( a['anShowInSampling'] === true ){
                    tmp.analysis.push(inn);
                }

            });

            //special analysis items
            x['AnalysisSpecials'].forEach(a => {
                inn =
                {
                    id: a['anId'],
                    name: a['anName'],
                    tooltip: a['anTooltip'],
                    MinFeatures: a['MinFeatures'],
                    sel: false,
                    special: true,
                    comment: '',
                    feat: []
                }

                if( (a['AnalysisFeature'] != undefined) && (a['AnalysisFeature'].length > 0) ){
                    a['AnalysisFeature'].forEach(af => {
                        inn.feat.push({id:af['anfId'], name:af['anfName'], sel: false});
                    });
                }

                //tmp.analysis.push(inn);

                if( a['anShowInSampling'] === true ){
                    tmp.analysis.push(inn);
                }

            });

            this.agitems.push(tmp);
        });

        //console.log(this.agitems);
    }

    public getSampleTypes(){
        let url = 'SampleType/All';

        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_stypes).then(x => {
                if( x != null ){
                    this.sample_types = x.data;
                    return true;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;

                        if(mydata.success == true ){
                          this.sample_types = mydata.val;
                          this.srv.saveOfflineData(this.offline_key_stypes, mydata.val);
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
        }
    }

    validateCreateInfo(fields:any){
        let resp:any = {
            status: true,
            msg: ''
        };
    //console.log("entro a valdate:",fields)
        let mygroup:any = {};
        mygroup = new FormGroup({
            'CompAquasymId': new FormControl(fields.CompAquasymId, [Validators.required]),
            'SitAquasymId': new FormControl(fields.SitAquasymId, [Validators.required]),
            'date': new FormControl(fields.date, [Validators.required]),
            'stypeID': new FormControl(fields.stypeID, [Validators.required]),
            'loctype': new FormControl(fields.loctype, [Validators.required]),
            'agid': new FormControl(fields.agid, [Validators.required]),
            'sampSampleQuantity': new FormControl(fields.sampSampleQuantity, [Validators.required, Validators.min(1)]),
            'sampContainer': new FormControl(fields.sampContainer, [Validators.required]),
            //'sampDepth': new FormControl(fields.sampDepth, [Validators.required]),
        });

        //validación para campo profundidad. 19/08/2021. Jeis
        if(fields.agid==6)//6 en producción
        {

          if(fields.sampDepth=="" || fields.sampDepth==null || fields.sampDepth==" " )
          {

            resp.status = false;
            resp.msg = this.translations.msg_all_required+":"+this.translations.sampDepth;
            return resp;
          }
          if (fields.sampDepth<0 || fields.sampDepth>10)
          {
            resp.status = false;
            resp.msg = this.translations.samp_error_analysis_minsampDepth;
            return resp;
          }

        }

        //console.log("grupo:",fields.agid);
        //aqui validar los campos de PCR que seran obligatorios llenar para los tecnicos // aun estamos trabajando
        if(fields.agid==9)//9 en producción
        {
          //console.log("================= entro a pcr ==============" )
          //console.log("sampCode:",fields.Code );
          //debugger;
          //fields.Code=fields.Code.trim();
          //comentado hasta segunda orden
         /* if (fields.sampCode="" || fields.sampCode==null || fields.sampCode==" " || fields.sampCode == undefined)
          {
            //console.log("entro a Code")
            //mygroup.addControl('',new FormControl(fields.Code,[Validators.required]));
            resp.status = false;
            resp.msg = this.translations.msg_all_required+":"+this.translations.sampCode;
            return resp;
          }
          //console.log("ReferenceNumber:",fields.sampReferenceNumber );
          if (fields.sampReferenceNumber="" || fields.sampReferenceNumber==null || fields.sampReferenceNumber==" " || fields.sampReferenceNumber== undefined)
          {
            resp.status = false;
            resp.msg = this.translations.msg_all_required+":"+this.translations.sampReferenceNumber;
            return resp;
            //console.log("entro a validar:",fields.ReferenceNumber );
            //mygroup.addControl('sampReferenceNumber', new FormControl(fields.sampReferenceNumber, [Validators.required]));
          }*/
          //console.log("sampProductType:",fields.sampProductType );
          if( fields.sampProductType == -1 || fields.sampProductType =="" || fields.sampProductType == null || fields.sampProductType ==" "){
            //mygroup.addControl('sampProductType', new FormControl(fields.sampProductType, [Validators.required]));
            resp.status = false;
            resp.msg = this.translations.msg_all_required+":"+this.translations.sampProductType;
            return resp;
          }
          //console.log("sampPresentation:",fields.sampPresentation );
          if( fields.sampPresentation == -1  || fields.sampPresentation =="" || fields.sampPresentation == null || fields.sampPresentation ==" "){
            //mygroup.addControl('sampPresentation', new FormControl(fields.sampPresentation, [Validators.required]));
            resp.status = false;
            resp.msg = this.translations.msg_all_required+":"+this.translations.sampPresentation;
            return resp;
          }
        }

        if( fields.loctype == -1 ){
            //unit must be selected
            mygroup.addControl('unit_id', new FormControl(fields.unit_id, [Validators.required]));
        }
        else{
            //spot must be selected
            mygroup.addControl('spot_id', new FormControl(fields.spot_id, [Validators.required]));
        }

        if(!mygroup.valid){
            resp.status = false;
            resp.msg = this.translations.msg_all_required + ' (*)';
            return resp;
        }

        if( fields['ag_obj'].MinFeatures > fields['ag_obj'].SelFeatures ){
            resp.status = false;
            resp.msg = this.translations.samp_error_groupfeat_minrequired;
            return resp;
        }

        let selcount = 0;
        fields['ag_obj'].analysis.forEach(x => {
            if( x['sel'] ){
                selcount++;
                //console.log(selcount);
                if( x['MinFeatures'] > x['SelFeatures'] ){
                    resp.status = false;
                    resp.msg = this.translations.samp_error_analysisfeat_minrequired;
                }

                if( x['special'] && ( (x['comment'] == undefined) || (x['comment'] == '') ) ){
                    resp.status = false;
                    resp.msg = this.translations.samp_error_analysis_commentreq;
                }

                if( parseInt(x['reps']) < 1 ){
                    resp.status = false;
                    resp.msg = this.translations.samp_error_analysis_minreps;
                }
            }
        });

        if( selcount == 0 ){
            resp.status = false;
            resp.msg = this.translations.samp_error_analysis_minqty;
        }

        return resp;
    }

    createItem(content:any, obj:any, multi?:any){
        let validated:any;

        if( multi == undefined ){
            multi = false;
        }

        validated = this.validateCreateInfo(obj);
        if( !validated['status'] ){
            Swal.fire(validated['msg'], '', 'error');
            return;
        }

        if(this.isOffline){
            this.createItemLocal(obj, content, multi);
        }
        else{
            this.isCreating = true;
            this.createItemRemote(obj).subscribe(x => {
                //console.log(x);
                this.isCreating = false;

                if( x['success'] == true ){
                    this.reload.next(true);
                    Swal.fire(this.translations.created, '', 'success');

                    if( (obj.off_id != undefined) && (obj.off_id != null) ){
                        //delete local entry
                        this.deleteItemLocal(obj, false);
                    }

                    if( !multi ){
                        this.closeModal(content);
                    }
                    else{
                        obj['agid'] = null;
                        obj['ag_obj'] = new Itemag(0, '');
                        obj['sampContainer'] = null;
                        this.resetAGItems();
                        //console.log(this.c_obj);
                    }
                }
                else{
                    if( x['msg'] != '' ){
                        Swal.fire(this.translations.error_creating, x['msg'], 'error');
                    }
                    else{
                        Swal.fire(this.translations.error_creating, '', 'error');
                    }
                }
            });
        }
    }

   public createItemRemote(obj:any){
        var url = 'Sampling/Create';
        var to:any;
        var tmp:any;

        //console.log(obj);

        to = {};
        to.sampDate = obj['date'];
        to.sampUnitAquasimId = (obj['loctype'] == -1) ? obj['unit_id'] : null;
        to.sampSampleLocationInstanceId = (obj['loctype'] > -1) ? obj['spot_id'] : null;
        to.sampObservation = obj['comment'];
        to.sampAnalysisGroupId = obj['agid'];
        to.SamplingAnalysisGroupFeaturesList = null;
        to.SamplingDetail = null;
        to.sampSampleTypeId = obj['stypeID'];
        to.sampCurrentLaboratory = obj['sampCurrentLaboratory'];//obj['sampLaboratoryId'];
        to.sampContainer = obj['sampContainer'];
        to.sampSampleQuantity = obj['sampSampleQuantity'];
        to.sampRequestedByClient = obj['sampRequestedByClient'];

        //PCR additional params
        to.sampCode = (obj['sampCode'] != undefined) ? obj['sampCode'] : null;
        to.sampProductType = (obj['sampProductType'] != undefined) ? obj['sampProductType'] : null;
        to.sampReferenceNumber = (obj['sampReferenceNumber'] != undefined) ? obj['sampReferenceNumber'] : null;
        to.sampBatchCode = (obj['sampBatchCode'] != undefined) ? obj['sampBatchCode'] : null;
        to.sampPresentation = (obj['sampPresentation'] != undefined) ? obj['sampPresentation'] : null;
        to.sampConditionSample = (obj['sampConditionSample'] != undefined) ? obj['sampConditionSample'] : null;
        to.sampEnvironmentTemperature = (obj['sampEnvironmentTemperature'] != undefined) ? obj['sampEnvironmentTemperature'] : null;
        to.sampRH = (obj['sampRH'] != undefined) ? obj['sampRH'] : null;

        //SUELO additional params, Campo de Profundidad 23/11/2020
            to.sampDepth=(obj['sampDepth'] != undefined) ? obj['sampDepth'] : null;


        if( obj['ag_obj']['group_features'].length > 0 ){
            to.SamplingAnalysisGroupFeaturesList = [];

            obj['ag_obj']['group_features'].forEach(x => {
                to.SamplingAnalysisGroupFeaturesList.push({
                    cmmAnlGrpFeatId: x.id,
                    cmmAnlChecked: x.sel
                });
            });
        }

        if( obj['ag_obj']['analysis'].length > 0 ){
            to.SamplingDetail = [];

            obj['ag_obj']['analysis'].forEach(x => {
                //console.log(x);
                if( x.sel ){
                    tmp = {};
                    tmp['samdAnalysisId'] = x.id;
                    tmp['samdChecked'] = x.sel;
                    tmp['samdRepetitionsNumber'] = x.reps;

                    if(x.special){
                        tmp['samdCommentary'] = x.comment;
                    }

                    if( x.feat.length > 0 ){
                        tmp['SamplingAnalysisFeaturesList'] = [];
                        x.feat.forEach(f => {
                            tmp['SamplingAnalysisFeaturesList'].push({
                                cmmAnlFeatId: f.id,
                                cmmAnlChecked: f.sel
                            });
                        });
                    }

                    //console.log(tmp);
                    to.SamplingDetail.push(tmp);
                }
            });
        }

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;

                        /*if(mydata.success == true ){
                          return of(true);
                        }
                        else{
                          return of(false);
                        }*/
                        return of(mydata);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
    }

    public showInfo(content:any, obj:any){
        this.openUpdateModalStatic(content, this.u_obj);
        this.resetAGItems();
        this.parseDetails(obj);
        //this.hasPermission();
    }

    parseDetails(obj:any){
        var tmp:any;
        var inn:any;

        tmp = {};
        tmp['sampId'] = obj['sampId'];
        tmp['date'] = obj['sampDate'].substring(0, 16).replace('T', ' ');
        tmp['CompAquasymId'] = obj['CompAquasymId'];
        this.filterSites(tmp['CompAquasymId']);
        tmp['SitAquasymId'] = obj['SitAquasymId'];
        this.filterUnits(tmp['SitAquasymId']);
        tmp['unit_id'] = obj['UnitAquasimId'];
        //tmp['spot_id'] = obj['sampSampleLocationInstanceId'];
        tmp['spot_id'] = obj['SampleLocationInstanceId'];


        if( tmp['unit_id'] != null ){
            tmp['loctype'] = -1;
        }
        else{
            tmp['loctype'] = obj['SampleLocationId'];
            this.filterLocations(tmp['loctype'], tmp['SitAquasymId']);
            console.log(this.spots);
        }

        tmp['comment'] = obj['sampObservation'];
        tmp['agid'] = obj['agrId'];
        tmp['SampleId'] = obj['SampleId'];
        this.filterSample(tmp['agid']);//cambio sample con sample type
        this.filterSampleTypes(tmp['SampleId'],tmp['agid']);//cambio sample con sample type

        //tmp['ag_obj'] = new Itemag(obj['agrId'], obj['agrName']);
        tmp['sampContainer'] = obj['sampContainer'];
        tmp['sampSampleQuantity'] = obj['sampSampleQuantity'];
        tmp['sampRequestedByClient'] = obj['sampRequestedByClient'];
        tmp['stypeID'] = obj['stypeID'];
        tmp['sampCurrentLaboratory'] = obj['sampCurrentLaboratory'];
        tmp['sampOverdueComment'] = obj['sampOverdueComment'];
        tmp['estimatedDelivery'] = obj['estimatedDelivery'];
        tmp['actions'] = obj['actions'];
        tmp['noedit'] = !this.isAllowed('edit', obj);

        //PCR additional params
        tmp['sampCode'] = (obj['sampCode'] != undefined) ? obj['sampCode'] : null;
        tmp['sampProductType'] = (obj['sampProductType'] != undefined) ? obj['sampProductType'] : null;
        tmp['sampReferenceNumber'] = (obj['sampReferenceNumber'] != undefined) ? obj['sampReferenceNumber'] : null;
        tmp['sampBatchCode'] = (obj['sampBatchCode'] != undefined) ? obj['sampBatchCode'] : null;
        tmp['sampPresentation'] = (obj['sampPresentation'] != undefined) ? obj['sampPresentation'] : null;
        tmp['sampConditionSample'] = (obj['sampConditionSample'] != undefined) ? obj['sampConditionSample'] : null;
        tmp['sampEnvironmentTemperature'] = (obj['sampEnvironmentTemperature'] != undefined) ? obj['sampEnvironmentTemperature'] : null;
        tmp['sampRH'] = (obj['sampRH'] != undefined) ? obj['sampRH'] : null;

        // Suelo additional params, Campo de Profundidad 23/11/2020
        tmp['sampDepth'] = (obj['sampDepth'] != undefined) ? obj['sampDepth'] : null;

        this.u_obj = tmp;
        this.updateAnalysisGroupData(obj);
    }

    public getSavedGroupFeature(id:any, rows:any){
        let resp = null;

        if( Array.isArray(rows) ){
            let tmp = rows.filter(x => {
                return x.cmmAnlGrpFeatId == id;
            });

            if( tmp.length > 0 ){
                //resp = tmp[0]['cmmAnlChecked'];
                resp = tmp[0];
            }
        }

        return resp;
    }

    public getSavedAnalysis(id:any, rows:any){
        let resp = null;

        if( Array.isArray(rows) ){
            let tmp = rows.filter(x => {
                return x.anId == id;
            });

            if( tmp.length > 0 ){
                resp = tmp[0];
            }
        }

        return resp;
    }

    public getSavedAnalysisFeature(id:any, rows:any){
        let resp = null;

        if( Array.isArray(rows) ){
            let tmp = rows.filter(x => {
                return x.cmmAnlFeatId == id;
            });

            if( tmp.length > 0 ){
                resp = tmp[0];
            }
        }

        return resp;
    }

    public updateAnalysisGroupData(obj:any){
        var resp:boolean;
        var id = obj['agrId'];
        let gftmp:any;
        let atmp:any;
        let itmp:any;

        this.filterAnalysisGroup(id, true);

        this.u_obj['ag_obj']['group_features'].forEach(gf => {
            gftmp = this.getSavedGroupFeature(gf['id'], obj['SamplingAnalysisGroupFeaturesList']);
            if( gftmp != null ){
                gf['sel'] = gftmp['cmmAnlChecked'];
                gf['cmmId'] = gftmp['cmmId'];
            }
        });

        //analysis
        this.u_obj['ag_obj']['analysis'].forEach(a => {
            atmp = this.getSavedAnalysis(a['id'], obj['SamplingDetail']);
            if( atmp != null ){
                a['sel'] = atmp['samdChecked'];
                a['samdId'] = atmp['samdId'];
                a['comment'] = atmp['samdCommentary'];
                a['reps'] = atmp['samdRepetitionsNumber'];

                //analysis features
                a.feat.forEach(i => {
                    itmp = this.getSavedAnalysisFeature(i['id'], atmp['SamplingAnalysisFeaturesList']);
                    if( itmp != null ){
                        i['sel'] = itmp['cmmAnlChecked'];
                        i['cmmId'] = itmp['cmmId'];
                    }
                });
            }
        });

        this.u_obj['ag_obj'].recalculateGroupFeatures();
        this.u_obj['ag_obj'].recalculateAnalysisFeatures();
        //console.log(this.u_obj['ag_obj']);
    }

    updateItem(content:any, obj:any){
        let validated:any;

        if(this.isOffline){
            Swal.fire(this.translations.offline_not_available, '', 'warning');
            return;
        }

        console.log("obj:",obj)
        validated = this.validateCreateInfo(obj);
        if( !validated['status'] ){
            Swal.fire(validated['msg'], '', 'error');
            return;
        }

        this.isUpdating = true;
        this.updateItemRemote(obj).subscribe(x => {
            this.isUpdating = false;

            if( x['success'] == true ){
                this.reload.next(true);
                this.closeModal(content);
                Swal.fire('¡Actualizado!', '', 'success');
            }
            else{
                if( x['msg'] != '' ){
                    Swal.fire('Error', x['msg'], 'error');
                }
                else{
                    Swal.fire('Error', '', 'error');
                }
            }
        });
    }

    public updateItemRemote(obj:any){
        var url = 'Sampling/Update';
        var to:any;
        var tmp:any;

        //console.log(obj);

        to = {};
        to.sampId = obj['sampId'];
        //to.sampDate = obj['date'];
        to.sampUnitAquasimId = (obj['loctype'] == -1) ? obj['unit_id'] : null;
        to.sampSampleLocationInstanceId = (obj['loctype'] > -1) ? obj['spot_id'] : null;
        to.sampObservation = obj['comment'];
        to.sampAnalysisGroupId = obj['agid'];
        to.SamplingAnalysisGroupFeaturesList = null;
        to.SamplingDetail = null;
        to.sampSampleTypeId = obj['stypeID'];
        to.sampCurrentLaboratory = obj['sampCurrentLaboratory'];
        to.sampContainer = obj['sampContainer'];
        to.sampSampleQuantity = obj['sampSampleQuantity'];
        to.sampRequestedByClient = obj['sampRequestedByClient'];


        //PCR additional params
        to.sampCode = (obj['sampCode'] != undefined) ? obj['sampCode'] : null;
        to.sampProductType = (obj['sampProductType'] != undefined) ? obj['sampProductType'] : null;
        to.sampReferenceNumber = (obj['sampReferenceNumber'] != undefined) ? obj['sampReferenceNumber'] : null;
        to.sampBatchCode = (obj['sampBatchCode'] != undefined) ? obj['sampBatchCode'] : null;
        to.sampPresentation = (obj['sampPresentation'] != undefined) ? obj['sampPresentation'] : null;
        to.sampConditionSample = (obj['sampConditionSample'] != undefined) ? obj['sampConditionSample'] : null;
        to.sampEnvironmentTemperature = (obj['sampEnvironmentTemperature'] != undefined) ? obj['sampEnvironmentTemperature'] : null;
        to.sampRH = (obj['sampRH'] != undefined) ? obj['sampRH'] : null;

        // Suelo additional params, Campo de Profundidad 23/11/2020
        to.sampDepth=(obj['sampDepth'] != undefined) ? obj['sampDepth'] : null;

        if( obj['ag_obj']['group_features'].length > 0 ){
            to.SamplingAnalysisGroupFeaturesList = [];

            obj['ag_obj']['group_features'].forEach(x => {
                to.SamplingAnalysisGroupFeaturesList.push({
                    cmmAnlGrpFeatId: x.id,
                    cmmAnlChecked: x.sel
                });
            });
        }

        if( obj['ag_obj']['analysis'].length > 0 ){
            to.SamplingDetail = [];

            obj['ag_obj']['analysis'].forEach(x => {

                if( x.sel ){
                    tmp = {};
                    tmp['samdAnalysisId'] = x.id;
                    tmp['samdChecked'] = x.sel;
                    tmp['samdRepetitionsNumber'] = x.reps;

                    if(x.special){
                        tmp['samdCommentary'] = x.comment;
                    }

                    if( x.feat.length > 0 ){
                        tmp['SamplingAnalysisFeaturesList'] = [];
                        x.feat.forEach(f => {
                            tmp['SamplingAnalysisFeaturesList'].push({
                                cmmAnlFeatId: f.id,
                                cmmAnlChecked: f.sel
                            });
                        });
                    }

                    //console.log(tmp);
                    to.SamplingDetail.push(tmp);
                }
            });
        }

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        return of(mydata);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
    }

    public getReportAPI(id:number){
        var url = 'Laboratory/Report/' + id;
        return this.srv.getBlobCall(url);
    }

    public downloadReport(obj:any){
        var pre = 'samp';

        if( !this.isAllowed('download', obj) ){
            return;
        }

        if(this.isDownloading){
            return;
        }

        this.isDownloading = obj.sampId;
        //console.log(obj);

        this.getReportAPI(obj.sampId).subscribe(x => {
            this.isDownloading = 0;
            //console.log(x);
            let fname = pre + '_' + obj.sampId + '_' + obj.cmpName + '_' + obj.sitName + '.pdf';
            fileSaver.saveAs(x, fname);
            /*const url= window.URL.createObjectURL(x);
            window.open(url);*/
        });
    }

    public isAllowed(opt:any, obj:any){
        //TODO temporary disabled followup button - need to be removed all followup logic
        if(this.isOffline && !(opt == 'offline')){
            return false;
        }

        if( opt == 'followup' ){
            return false;
        }

        if(obj.actions.indexOf(opt) > -1){
            return true;
        }
        else{
            return false;
        }
    }

    public showFollowup(content:any, obj:any){
        if( !this.isAllowed('followup', obj) ){
            return;
        }

        this.f_obj = {};
        this.modalService.open(content, { centered: true });
        this.f_obj = obj;
        this.loadFollowComments();
    }

    public saveFollowup(obj:any){
        if( (obj['followcomment'] == undefined) || (obj['followcomment'] == '') ){
            return;
        }

        this.isUpdating = true;
        this.saveFollowupRemote(obj).subscribe(x => {
            this.isUpdating = false;

            if( x['success'] == true ){
                obj['followcomment'] = '';
                this.loadFollowComments();
            }
            else{
                if( x['msg'] != '' ){
                    Swal.fire('Error', x['msg'], 'error');
                }
                else{
                    Swal.fire('Error', '', 'error');
                }
            }
        });
    }

    public saveFollowupRemote(obj:any){
        var url = 'TechnicalAdviserLog/AddAdviceLog';
        var to:any;
        to = {};

        to.advSamplingId = obj['sampId'];
        to.TechnicalAdviseLog = [{'logText': obj['followcomment']}];

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        return of(mydata);
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
    }

    public getFollowupComments(obj:any){
        var par_url = 'TechnicalAdviserLog/GetCommentBySampling/' + obj['sampId'];

        return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);

                        if(mydata.success == true ){
                          return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})
                );
    }

    public loadFollowComments(){
        this.f_obj['history'] = [];

        this.loadingFollow = true;
        this.getFollowupComments(this.f_obj).subscribe(x => {
            this.loadingFollow = false;
            //console.log(x);
            if(x != false){
                if( x.length > 0 ){
                    this.f_obj['history'] = x[0]['Logs'];
                }
            }
        });
    }

    public deleteFollowup(obj:any){
        var qst = 'TechnicalAdviserLog/DeleteLogical';
        let trans = this.translations;

        Swal.fire({
            title: trans.delete_item_title,
            text: '',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            cancelButtonText: trans.cancel,
            confirmButtonText: trans.delete,
            preConfirm: () => {
                this.srv.postCall(qst, obj).subscribe(x => {
                    let msg = this.translateMessage(x.msg);

                    if(x.success){
                        this.loadFollowComments();
                        Swal.fire(trans.deleted, msg, 'success');
                    }
                    else{
                        Swal.fire(msg, '', 'error');
                    }
                });
            }
        }).then((result) => {
        });
    }

    public resetCompanySelections(){
        this.sites = [];
        this.units = [];
        this.spots = [];
        this.c_obj['SitAquasymId'] = '';
        this.u_obj['SitAquasymId'] = '';
        this.c_obj['unit_id'] = '';
        this.u_obj['unit_id'] = '';
        this.c_obj['spot_id'] = '';
        this.u_obj['spot_id'] = '';

        this.c_obj['loctype'] = '-1';
        this.u_obj['loctype'] = '-1';

        this.c_obj['labsite'] = '-1';
        this.u_obj['labsite'] = '-1';

    }

    public resetSiteSelections(){
        this.units = [];
        this.spots = [];
        this.c_obj['unit_id'] = '';
        this.u_obj['unit_id'] = '';
        this.c_obj['spot_id'] = '';
        this.u_obj['spot_id'] = '';

        this.c_obj['loctype'] = '-1';
        this.u_obj['loctype'] = '-1';

        this.c_obj['labsite'] = '-1';
        this.u_obj['labsite'] = '-1';
    }

    public createItemLocal(obj:any, content:any, multi?:any){
        let items:any;

        if( multi == undefined ){
            multi = false;
        }

        this.srv.getPeristedData(this.offline_key).then(i => {

            if( (i == undefined) || (i == null) ){
                items = [];
            }
            else{
                items = i;
            }

            //set site name
            let stmp = this.raw_sites.filter(st => {
                return st.id == obj.SitAquasymId;
            });

            if( Array.isArray(stmp) ){
                obj.site_name = stmp[0].name;
            }

            if( (obj.off_id != undefined) && (obj.off_id != null) ){
                items.forEach((x,k) => {
                    if( x.off_id == obj.off_id ){
                        items[k] = obj;
                    }
                });
            }
            else{
                obj.off_id = Date.now();
                obj.actions = this.getActionsByStatusName('OFFLINE');
                items.push(obj);
            }

            this.srv.persistData(this.offline_key, items).then(s => {

                if( !multi ){
                    this.closeModal(content);
                }
                else{
                    //set null to create a new onw when save and continue
                    obj.off_id = null;
                    //obj.ag_obj = new Itemag(0, '');
                    obj.sampContainer = null;
                }

                Swal.fire(this.translations.offline_saved, '', 'success');
                this.getOfflineItems();
            });
        });
    }

    public getOfflineItems(){
        let items:any;

        this.srv.getPeristedData(this.offline_key).then(i => {

            if( (i == undefined) || (i == null) ){
                items = [];
            }
            else{
                items = i;
            }

            this.offline_items = items;
            //console.log(this.offline_items);
        });
    }

    public deleteItemLocal(obj:any, prompt=true){
        let items:any;

        if(!prompt){
            this.srv.getPeristedData(this.offline_key).then(i => {
                if( (i != undefined) && (i != null) ){
                    items = [];
                    i.forEach(x => {
                        if( x.off_id != obj.off_id ){
                            items.push(x);
                        }

                        this.srv.persistData(this.offline_key, items).then(s => {
                            this.offline_items = items;
                            //Swal.fire(this.translations.deleted, '', 'success');
                        });
                    });
                }
            });
        }
        else{
            Swal.fire({
                title: this.translations.delete_item_title,
                text: '',
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: this.translations.cancel,
                confirmButtonText: this.translations.delete,
                preConfirm: () => {
                    this.srv.getPeristedData(this.offline_key).then(i => {
                        if( (i != undefined) && (i != null) ){
                            items = [];
                            i.forEach(x => {
                                if( x.off_id != obj.off_id ){
                                    items.push(x);
                                }

                                this.srv.persistData(this.offline_key, items).then(s => {
                                    this.offline_items = items;
                                    //Swal.fire(this.translations.deleted, '', 'success');
                                });
                            });
                        }
                    });
                }
            }).then((result) => {
            });
        }
    }

    public showOfflineInfo(content:any, obj:any){
        this.resetAGItems();
        this.filterSites(obj['CompAquasymId']);
        this.filterUnits(obj['SitAquasymId']);
        this.filterAnalysisGroup(obj['agid']);
        this.filterSampleTypes(obj['SampleId'],obj['agid']);
        obj.actions = this.getActionsByStatusName('OFFLINE');
        console.log(obj);

        this.openUpdateModalStatic(content, obj);

        //recreate Analysis group object
        let tmp = new Itemag(obj['ag_obj']['sampAnalysisGroupId'], obj['ag_obj']['name']);
        tmp.analysis = obj['ag_obj']['analysis'];
        tmp.group_features = obj['ag_obj']['group_features'];
        tmp.MaxFeatures = obj['ag_obj']['MaxFeatures'];
        tmp.MinFeatures = obj['ag_obj']['MinFeatures'];
        tmp.SelFeatures = obj['ag_obj']['SelFeatures'];
        tmp.actualcolor = obj['ag_obj']['actualcolor'];

        this.u_obj['ag_obj'] = tmp;
    }

    public getSampleLocationAllBelongAP() {
        var par_url = 'SampleLocation/AllBelongAPSampling';

        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_loc).then(x => {
                if( x != null ){
                    return x.data;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(par_url).pipe(
                mergeMap(data => {
                    let mydata:any = {success: false, msg:'', val:[]};
                    mydata = data;

                    if(mydata.success == true){
                        this.srv.saveOfflineData(this.offline_key_loc, mydata.val);
                        return of(mydata.val);
                    }
                    else{
                        return of(false);
                    }
                }),
                catchError(data => of(false))
            );
        }
    }

    public getSampleAllBelongAP() {
        var par_url = 'Sample/All';

        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_sam).then(x => {
                if( x != null ){
                    return x.data;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(par_url).pipe(
                mergeMap(data => {
                    let mydata:any = {success: false, msg:'', val:[]};
                    mydata = data;

                    if(mydata.success == true){
                        this.srv.saveOfflineData(this.offline_key_sam, mydata.val);
                        return of(mydata.val);
                    }
                    else{
                        return of(false);
                    }
                }),
                catchError(data => of(false))
            );
        }
    }

    //Aqui para validar el permiso

    hasPermission(str:string){

        if( this.permissions.indexOf(str) > -1 ){
            //console.log(this.permissions);
            return true;
        }
        else{
            //console.log(this.permissions);
            return false;

        }
    }

   public getAllSamplingContainer() {
        var par_url = 'Sampling/AllSamplingContainer';

        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_container).then(x => {
                if( x != null ){
                    return x.data;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(par_url).pipe(
                mergeMap(data => {
                    let mydata:any = {success: false, msg:'', val:[]};
                    mydata = data;

                    if(mydata.success == true){
                        this.srv.saveOfflineData(this.offline_key_container, mydata.val);
                        return of(mydata.val);
                    }
                    else{
                        return of(false);
                    }
                }),
                catchError(data => of(false))
            );
        }
    }

    public getListActiveLaboratory() {
        var par_url = 'Laboratory/ListActiveLaboratory';

        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_laboratory).then(x => {
                if( x != null ){
                    return x.data;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(par_url).pipe(
                mergeMap(data => {
                    let mydata:any = {success: false, msg:'', val:[]};
                    mydata = data;

                    if(mydata.success == true){
                        this.srv.saveOfflineData(this.offline_key_laboratory, mydata.val);
                        return of(mydata.val);
                    }
                    else{
                        return of(false);
                    }
                }),
                catchError(data => of(false))
            );
        }
    }

    public getGetLaboratorySite(idSitSite:number) {
        var par_url = 'Sampling/GetLaboratorySite/'+ idSitSite;
        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_sitLab).then(x => {
                if( x != null ){
                  // this.c_obj['sampCurrentLaboratory']=x.labId;
                    return x.data;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
           // console.log(this.isOffline,'Offline else');
            return this.srv.getCall(par_url).subscribe(
                data => {

                    let mydata:any = {success: false, msg:'', val:[]};
                    mydata = data;
                    //console.log(mydata);
                    if(mydata.success == true){
                        //console.log(idSitSite,'Aqui else');
                        //console.log(mydata);
                        this.srv.saveOfflineData(this.offline_key_sitLab, mydata.val);
                        this.c_obj['sampCurrentLaboratory']=mydata.val.sitLaboratoryId;
                        return of(mydata.val);
                    }
                    else{
                        return of(false);
                    }
                },
                catchError(data => of(false))
            );
        }
    }

    // async generarpdf()
    // {
    //   console.log(this.new_data);
    //   let map_method_reference = new Map();
    //   let map_title = new Map();
    //   let map_subTitle = new Map();
    //   let map_content = new Map();
    //   let lista_titulos: any[] = [];
    //   let lista_subtitulos: any[] = [];
    //   let lista_contenido: any[] = [];
    //   let lista_metodos_referencia: any[] = [];
    //   let obj = {
    //     name: '',
    //     reference: '',
    //     date_init: '',
    //     date_end: '',
    //     client: '',
    //     direction: '',
    //     solicit: '',
    //     real: '',
    //     date_mues: '',
    //     lug_mues: '',
    //     tipo: '',
    //     analisis: '',
    //     cant: '',
    //     env: '',
    //     img_resp: '',
    //     img_aprob: ''
    //   }
    //   this.new_data.forEach((elemento: any) => {
    //     obj.name = elemento.LaboratoryWorker.usrFirstName + ' ' +elemento.LaboratoryWorker.usrLastName;
    //     obj.reference = '';
    //     obj.date_init = new Date(elemento.sampDate).getDate().toString() + '-' + this.getMonth(new Date(elemento.sampDate)).toString() + '-' + new Date(elemento.sampDate).getFullYear().toString();
    //     obj.date_end = '';
    //     obj.client = elemento.sitName;
    //     obj.direction = elemento.SkrettingSector.skseName + '/' + elemento.ShrimpSector.shrName;
    //     obj.solicit = elemento.Technician.usrFirstName + ' ' + elemento.Technician.usrLastName;
    //     obj.date_mues = obj.date_init;
    //     obj.lug_mues = 'Piscinas Acuícolas';
    //     obj.cant = elemento.sampSampleQuantity.toString();
    //     obj.tipo = elemento.samName;
    //     obj.analisis = elemento.agrName;
    //     obj.env = elemento.sampContainer;
    //     if (obj.img_aprob == '') {
    //       obj.img_aprob = elemento.LaboratoryResponsible.Image;
    //     }
    //     if (obj.img_resp == '') {
    //       obj.img_resp = elemento.LaboratoryWorker.usrSign;
    //     }
    //     elemento.SamplingReferenceMethods.forEach( (ref: any) => {
    //       if(!map_method_reference.has(ref.refDetReference )) {
    //         map_method_reference.set(ref.refDetReference, ref.refDetDescription)
    //       }
    //     });
    //     elemento.ResultExams.forEach( (results : any) => {
    //       results.Detail.forEach((detalle: any) =>{
    //         let rangoMax = detalle.Parameter.parRangeMax== null ? ' ' : detalle.Parameter.parRangeMax;
    //         let rangoMin = detalle.Parameter.parRangeMin == null ? ' ' : detalle.Parameter.parRangeMin; //  - 1
    //         let rango = null;
    //         if (rangoMin == ' ' || rangoMax == ' ') {
    //           rango = rangoMin  + rangoMax;
    //         }else if(rangoMin != ' ' || rangoMax == ' '){
    //           rango = rangoMin  + rangoMax;
    //         }
    //         else {
    //           rango = rangoMin  + ' - ' + rangoMax;
    //         }
    //         if (!map_title.has(detalle.parName) && map_title.size <= 10) {
    //           map_title.set(detalle.parName, detalle.parName);
    //         }
    //         if (!map_subTitle.has(rango) && map_subTitle.size <= map_title.size ) {
    //           map_subTitle.set(rango, rango);
    //         }
    //         if (!map_content.has(detalle.ldiSamplingId)) {
    //           map_content.set(detalle.ldiSamplingId, [detalle.ldiSamplingId,detalle.uniName,detalle.sampDepth,detalle.sampleType,detalle.ldidValue]);
    //         } else {
    //           let tmp = map_content.get(detalle.ldiSamplingId);
    //           if (tmp.length <=13) {
    //             tmp.push(detalle.ldidValue);
    //             map_content.set(detalle.ldiSamplingId, tmp);
    //           }
    //         }
    //         // lista_titulos.push(new Cell( new Txt(detalle.parName).color('white').fontSize(7).alignment('center').bold().end ).end);
    //         if (lista_subtitulos.length < map_title.size) {
    //           lista_subtitulos.push(new Cell( new Txt( rango).color('#003D6A').fontSize(7).alignment('center').bold().end ).fillColor('#E8E7E8').end);
    //         }
    //       })
    //     });
    //   });
    //   // console.log(map_title);
    //   // console.log(map_subTitle);
    //   // console.log(map_content);
    //   lista_titulos = [...map_title].map(([name]) => (new Cell( new Txt(name).color('white').fontSize(7).alignment('center').bold().end ).end));
    //   // lista_contenido = [...map_content].map(([value]) => (new Cell( new Txt(value).color('black').fontSize(7).alignment('center').bold().end ).end));
    //   for (var [key, value] of map_content) {
    //     // console.log('-----------------------------')
    //     // console.log(key);
    //     let l: any[] = [];
    //     for(let valor of value) {
    //       l.push(new Cell( new Txt(valor.toString()).color('black').fontSize(7).alignment('center').end ).end);
    //
    //     }
    //       lista_contenido.push(l);
    //   }
    //
    //   for (var [key, value] of map_method_reference) {
    //     lista_metodos_referencia.push(
    //       [
    //         new Cell( new Txt(key).color('black').fontSize(7).bold().end).fillColor('#E8E7E8').end,
    //         new Cell( new Txt(value).color('black').fontSize(7).end ).end
    //       ]
    //     )
    //
    //   }
    //
    //   console.log(lista_titulos);
    //   console.log(lista_subtitulos);
    //   console.log(lista_contenido);
    //   console.log(lista_metodos_referencia);
    //   console.log(obj);
    //   console.log(this.imgFooter)
    //
    //   const pdf = new PdfMakeWrapper();
    //   pdf.info({
    //     title: 'PREVIEW',
    //     author: 'ROYALTIC',
    //     subject: 'PRUEBA',
    //   });
    //   pdf.header(
    //     new Table(
    //       [
    //         [
    //           new Cell(await new Img('../../../../assets/images/logo-skretting.png').width('160').build()).end,
    //         ]
    //       ]
    //     )
    //       .margin([0,30,30,0])
    //       .alignment('right')
    //       .layout('noBorders')
    //       .widths('*')
    //       .end,
    //   );
    //
    //   pdf.background(
    //     await new Img(this.imgFooter.value).absolutePosition(0, 770).width(600).build()
    //   )
    //
    //   pdf.footer( function(currentPage, pageCount) {
    //     return new Stack(
    //       [
    //         new Cell(new Txt('Skretting Ecuador | Km 4.5 & 6.5 vía Durán - Tambo. Durán - Ecuador | Telf: + 593 4 2598100 + 593 4 2815737').fontSize(8).color('gray').alignment('center').margin([0,0,0,5]).end).end,
    //         new Cell(new Txt('www.skretting.ec').link('https://www.skretting.com/es-ec/').fontSize(8).color('gray').alignment('center').end).end,
    //         new Cell(new Txt(' Página ' + currentPage.toString() + ' de ' + pageCount).alignment('right').fontSize(9).color('white').margin([0, 40,20, 0]).end).end
    //       ]
    //     ).end
    //   });
    //
    //
    //   pdf.pageMargins([ 35, 110, 35, 110]);
    //   pdf.add(
    //     [
    //       new Txt([new Txt('Preparado por: ').bold().fontSize(7).end,new Txt(obj.name).italics().fontSize(7).end]).end,
    //       new Txt([new Txt('Referencia Interna: ').bold().fontSize(7).end,new Txt(obj.reference).italics().fontSize(7).end]).end,
    //       new Txt([new Txt('Fecha de Llegada: ').bold().fontSize(7).end,new Txt(obj.date_init).italics().fontSize(7).end]).end,
    //       new Txt([new Txt('Fecha de envío: ').bold().fontSize(7).end,new Txt(obj.date_end).italics().fontSize(7).end]).end,
    //       new Stack([
    //         new Txt('LABORATORIO DE ENSAYO DE SERVICIOS ACUÍCOLAS').margin([0,10,0,0]).fontSize(11).bold().alignment('center').end,
    //         new Txt('GISIS S.A.').fontSize(11).alignment('center').bold().end,
    //         new Txt('INFORME DE RESULTADOS').fontSize(11).alignment('left').bold().margin([0,12,0,5]).end,
    //       ]).end,
    //       //new Columns([ 'Hello', 'world' ]).columnGap(10).end
    //       new Columns([new Txt('Cliente: ').bold().fontSize(8).end, new Txt(obj.client).fontSize(8).end, new Txt('Fecha de muestreo: ').fontSize(8).bold().end,new Txt(obj.date_mues).fontSize(8).end ]).end,
    //       new Columns([new Txt('Direccion o Zona: ').fontSize(8).bold().end, new Txt(obj.direction).fontSize(8).end, new Txt('Lugar de muestreo: ').fontSize(8).bold().end, new Txt(obj.lug_mues).fontSize(8).end ]).end,
    //       new Columns([new Txt('Solicitado por: ').fontSize(8).bold().end, new Txt(obj.solicit).fontSize(8).end,'','']).end,
    //       new Columns([new Txt('Realizado por: ').fontSize(8).bold().end, new Txt('Skretting').fontSize(8).end,'','']).end,
    //       new Txt('Datos de muestra:').fontSize(10).alignment('left').bold().margin([0,12,0,5]).end,
    //       new Table(
    //         [
    //           [
    //             new Cell( new Txt('Tipo: ').fontSize(8).bold().end ).fillColor('#e5e4e4').end,new Cell( new Txt(obj.tipo).fontSize(8).end ).end
    //           ],
    //           [
    //             new Cell( new Txt('Analisis solicitado: ').fontSize(8).bold().end ).fillColor('#e5e4e4').end,new Cell( new Txt(obj.analisis).fontSize(8).end ).end
    //           ],
    //           [
    //             new Cell( new Txt('Cantidad: ').fontSize(8).bold().end ).fillColor('#e5e4e4').end,new Cell( new Txt(obj.cant).fontSize(8).end ).end
    //           ],
    //           [
    //             new Cell( new Txt('Envase: ').fontSize(8).bold().end ).fillColor('#e5e4e4').end,new Cell( new Txt(obj.env).fontSize(8).end ).end
    //           ]
    //         ]
    //       )
    //         .widths(['auto','*'])
    //         .end,
    //       new Txt('Resultados:').fontSize(10).alignment('left').bold().margin([0,12,0,5]).end,
    //       new Table(
    //         [
    //           [
    //             new Cell( new Txt('Parametros ').color('white').fontSize(7).bold().end ).colSpan(2).end,
    //             new Cell(new Txt('').end).end,
    //             new Cell( new Txt('Profundidad ').color('white').fontSize(7).bold().end ).end,
    //             new Cell( new Txt('Tipo de muestra ').color('white').fontSize(7).bold().end ).end,
    //             ...lista_titulos
    //           ],
    //           [
    //             new Cell( new Txt('Id de Solicitud ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
    //             new Cell( new Txt('Códigos de muestra ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
    //             new Cell( new Txt('0-10 cm ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
    //             new Cell( new Txt(' ').color('#003D6A').fontSize(7).bold().end ).fillColor('#E8E7E8').end,
    //             ...lista_subtitulos
    //           ],
    //           //...lista_contenido,
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //           [
    //             new Cell( new Txt('85546').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('PA01').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('prestamo 1 ').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //             new Cell( new Txt('12').color('black').fontSize(7).alignment('center').end ).end,
    //           ],
    //         ]
    //       )
    //         .dontBreakRows(true)
    //         .alignment('center')
    //         .layout({
    //           fillColor: (rowIndex: number, node:any, columnIndex: number) => {
    //             return rowIndex === 0 ? '#f62d51' : '';
    //           },
    //         })
    //         .widths('auto')
    //         .end,
    //       new Txt('*SN: No Solicitado').fontSize(6.5).alignment('left').margin([0,7,0,10]).end,
    //       new Txt('Método de referencia:').fontSize(10).alignment('left').bold().margin([0,12,0,5]).end,
    //       new Table(
    //         [
    //           ...lista_metodos_referencia
    //         ]
    //       )
    //         .margin([0,0,0,20])
    //         .widths(['auto','*'])
    //         .end,
    //       new Table(
    //         [
    //           [
    //             new Cell(new Txt('Realizado por:').fontSize(7).alignment('center').bold().end).end,
    //             new Cell(new Txt('Aprobado por:').fontSize(7).alignment('center').bold().end).end
    //           ],
    //           [
    //             new Cell(await new Img(obj.img_resp).width('150').build()).end,
    //             new Cell(await new Img(obj.img_aprob).width('150').build()).end
    //           ]
    //         ]
    //       )
    //         .alignment('center')
    //         .layout('noBorders')
    //         .widths('*')
    //         .end,
    //       new Txt('Nota:').fontSize(6.5).bold().margin([0,7,0,0]).end,
    //       new Txt('Este reporte no tiene validez sino tiene la firma autorizada.').fontSize(6.5).margin([0,0,0,10]).end,
    //     ]
    //   )
    //   pdf.create().open();
    //
    //   // this.getDataReport();
    //
    //
    // }

  //   getDataReport()
  //   {
  //     var par_url = 'ReportNew/All' ;
  //     var to:any;
  //     to={};
  //     // to={
  //     //   _listSamplingId:[85546,85547]
  //     // }
  //     //console.log("entramos a ver si hay data:")
  //     //debugger;
  //     /*this.srv.postCall(par_url, to).subscribe(x => {
  //       if (x.success) {
  //         this.DataReport =x.val ;
  //       }
  //     });*/
  //     return this.srv.getCall(par_url).subscribe(
  //       data => {
  //         // console.log(data,'data');
  //         let mydata:any = {success: false, msg:'', val:[]};
  //         mydata = data;
  //         this.new_data = data.val;
  //         //this.DataReport=mydata;
  //         console.log('mydata',mydata);
  //         console.log("new_data:",this.new_data);
  //         if(mydata.success == true ){
  //
  //           return of(mydata.val);
  //         }
  //         else{
  //           return of(false);
  //         }
  //       },
  //       //timeout(MAX_TIME),
  //       catchError(data => {return of(false)})
  //
  //     );
  //
  //   }
  //
  // getImgFooter()
  // {
  //   var par_url = 'ReportNew/ImageForReport' ;
  //   var to:any;
  //   to={};
  //   return this.srv.getCall(par_url).subscribe(
  //     data => {
  //       // console.log(data,'data');
  //       let mydata:any = {success: false, msg:'', val:[]};
  //       mydata = data;
  //       this.imgFooter = data.val;
  //       if(mydata.success == true ){
  //
  //         return of(mydata.val);
  //       }
  //       else{
  //         return of(false);
  //       }
  //     },
  //     catchError(data => {return of(false)})
  //
  //   );
  //
  // }
  //
  //   getMonth( fecha: Date) {
	//     let month = '';
  //     var monthNames = [ "enero", "febrero", "marzo", "abril", "mayo", "junio","julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre" ];
	//     month = monthNames[fecha.getMonth()];
	//     return month;
  //   }
 /*   public getGetLaboratorySite(obj:any){
        var par_url = 'Sampling/GetLaboratorySite/' + obj;//+ obj['sampId'];
        console.log(obj,'idSitSite Gaby');

        return this.srv.getCall(par_url).subscribe(
                   data => {
                       // console.log(data,'data');
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(mydata,'mydataval');
                        //console.log(mydata.val);
                        if(mydata.success == true ){
                         // return of(mydata.val['LabId']);
                        //console.log(mydata.val.sitLaboratoryId);
                        this.c_obj['sampCurrentLaboratory']=mydata.val.sitLaboratoryId;
                        return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                    },
                      //timeout(MAX_TIME),
                      catchError(data => {return of(false)})

                );
    } */
}
