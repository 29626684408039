import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { LoginService } from '../../../services/authentication/login.service';
import {from, of} from 'rxjs';
import {ApiService} from '../../../services/main/api.service';

@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.css']
})
export class CrudComponent implements OnInit {

    translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    items:any;
    permissions = [];

  	constructor(
  		public _router: Router,
      public translate: TranslateService,
      public auth: LoginService,public api: ApiService
  	) { }

  	ngOnInit() {
        this.items = [
          {title: 'menu_title_mant_analysis', url: '/analysis', icon: 'icon icon-doc', perm: 'HA_ANALYSIS_TYPE'},
          {title: 'menu_analysis_group', url: '/analysis_group', icon: 'icon icon-docs', perm: 'HA_ANALYSIS_GROUP'},
          {title: 'menu_company', url: '/companies', icon: 'far fa-building', perm: 'HA_COMPANY'},
          {title: 'menu_sites', url: '/sites', icon: 'mdi mdi-adjust', perm: 'HA_SITE'},
          {title: 'menu_sitegroups', url: '/sitegroups', icon: 'mdi mdi-adjust', perm: 'HA_SITE_GROUP'},
          {title: 'menu_prodgroups', url: '/prodgroups', icon: 'icon icon-refresh', perm: 'HA_PRODUCTION_GROUP'},
          {title: 'menu_units', url: '/units', icon: 'mdi mdi-adjust', perm: 'HA_UNIT'},
          {title: 'menu_countries', url: '/countries', icon: 'mdi mdi-adjust', perm: 'HA_COUNTRY'},
          {title: 'menu_laboratories', url: '/laboratories', icon: 'icon icon-chemistry', perm: 'HA_LABORATORY_ENTITY'},
          {title: 'menu_samples', url: '/samples', icon: 'mdi mdi-adjust', perm: 'HA_SAMPLE'},
          {title: 'menu_pregunta', url: '/preguntas', icon: 'mdi mdi-adjust', perm: 'HA_SAMPLE_TYPE'},
          {title: 'menu_sample_loctype', url: '/sample_locations', icon: 'icon-location-pin', perm: 'HA_SAMPLE_LOCATION'},
          {title: 'menu_locales', url: '/locales', icon: 'mdi mdi-adjust', perm: 'HA_SAMPLE_LOCATION_INSTANCE'},
          {title: 'menu_refmethods', url: '/metas', icon: 'mdi mdi-adjust', perm: 'HA_REFMETHODS'},
          {title: 'menu_sk_site', url: '/skretting_site', icon: 'mdi mdi-adjust', perm: 'HA_SKRETTING_SITE'},
          {title: 'menu_sk_sector', url: '/skretting_sector', icon: 'mdi mdi-adjust', perm: 'HA_SKRETTING_SECTOR'},
          {title: 'menu_shrimp_sector', url: '/shrimp_sector', icon: 'mdi mdi-adjust', perm: 'HA_SHRIMP_SECTOR'},
          {title: 'menu_users', url: '/users', icon: 'icon icon-people', perm: 'HA_USER'},
          {title: 'menu_visits', url: '/visits', icon: 'mdi mdi-adjust', perm: 'HA_VISIT'}
        ];

        this.auth.getPermissions().then(x => {
            this.permissions = x;
        });
      if( navigator.onLine ){
        this.getVersion();
      }
  	}

    hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }
  public getVersion(){
    var ent_url = 'SystemParameter/GetVersion/'+environment.version+'/';
    return this.api.getCall(ent_url).subscribe(data => {
      let mydata:any = {success: false, msg:'', val:[]};
      mydata = data;
      if(mydata.success == true ){
        return from(this.api.persistData('appversion', mydata.val.value));
      }
      else{
        return of(false);
      }
    });
  }
}
