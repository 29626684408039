import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ConnectionService } from 'ng-connection-service';
import { ApiService } from './services/main/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  	title = 'app';
  	version:any;

  	constructor(
      private translate: TranslateService,
      private swUpdate: SwUpdate,
      private connectionService: ConnectionService,
      public srv: ApiService
    ) {
        var userLang = 'es';
        translate.setDefaultLang(userLang);
        translate.use(userLang);

        /**/this.connectionService.monitor().subscribe(isConnected => {

            if(isConnected){
                this.srv.isReachable().then(function(online) {
                    if (online) {
                      // handle online status
                      console.log('online');

                      srv.offline.next(false);
                    } else {
                      console.log('no connectivity');
                      srv.offline.next(true);
                    }
                });
            }
            else{
                this.srv.offline.next(true);
            }

        });
    }

    ngOnInit() {
	    this.version = environment.version;

	    if (this.swUpdate.isEnabled) {
	        this.swUpdate.available.subscribe(() => {
	           	let upd_msg = "New version available. Load New Version?";

	            if(confirm(upd_msg)) {
	                window.location.reload();
	            }
	        });
	    }
	}
}
