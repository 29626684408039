//import { Component, OnInit } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';
import { Lightbox } from 'ngx-lightbox';
import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';
import * as fileSaver from 'file-saver';
import * as moment from 'moment-timezone';
import {Itempdi} from './itempdi.model';
import { data } from 'jquery';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-pdiallcompany',
  templateUrl: './pdiallcompany.component.html',
  styleUrls: ['./pdiallcompany.component.css']
})
export class PdiallcompanyComponent extends BaseCrudComponent {

  translations:any;
    fields:any;
    listSamples:any;

	raw_companies:any;
	raw_sites:any;
    raw_units:any;
    //raw_samplings:any;

	companies:any;
	sites:any;
    units:any;
    //samplings:any;
    sel_sector:any;
    loctypes:any;
    raw_locations:any;
    locations:any;

	filters:any;
	myunit:any;

	//curr details obj
	obj:any;

	loadingDetails = false;
    loadingRequired = false;
    isDownloading = 0;
    momentjs: any = moment;

    isFiltering = false;

    offline_key = 'PDI_OFFLINE';
    offline_key_list = 'PDI';
    offline_key_loc = 'PDI_LOCATIONS';
    offline_key_stypes = 'PDI_TYPES';
    offline_items = [];

    //in MB
    max_img_size = 3;
    max_img_count = 5;

	constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        public translate: TranslateService,
        private _lightbox: Lightbox,
        private imageCompress: NgxImageCompressService
      ) {
        super(srv, modalService, storage, router);
        this.create_url = '';
        this.read_url = 'PathologicalDataInput/AllCompanyUsers';
        this.update_url = '';
        this.delete_url = 'PathologicalDataInput/DeleteLogical';
        this.search_fields = ['pdiId', 'cmpName', 'sitName', 'pdiRequestedBy'];

        this.fields = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });

        this.fillData();

        this.loadingRequired = true;
        this.getSampleLocationAllBelongAP().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.loctypes = [];
                this.raw_locations = [];
                this.locations = [];

                x.forEach(sl => {
                    let tmp = {id: sl.slocId, name:sl.slocName};
                    this.loctypes.push(tmp);

                    if(Array.isArray(sl.SampleLocationInstance)){
                        sl.SampleLocationInstance.forEach(y => {
                            //tmp.details.push({id:y.slinId, name:y.slinName, sitid:y.slinSiteId});
                            this.raw_locations.push({id:y.slinId, name:y.slinName, sitid:y.slinSiteId, loctype:sl.slocId});
                            //this.locations.push({id:y.slinId, name:y.slinName, sitid:y.slinSiteId, loctype:sl.slocId});
                        });
                    }
                });
            }
        });

        this.srv.offline.subscribe(r => {
            this.isOffline = r;
        });

        this.isOffline = false;
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
            this.translations = translations;
            super.ngOnInit();
            this.getList();
        });
    }

    fillData(){
    	this.raw_companies = [];
    	this.raw_sites = [];
        this.raw_units = [];
        //this.raw_samplings = [];

    	this.companies = [];
    	this.sites = [];
        this.units = [];
        //this.samplings = [];
    	this.filters = [];
        this.locations = [];

    	this.storage.get('companies').then(x => {
    		x.forEach(c => {
    			this.raw_companies.push({id:c.CompAquasymId, name: c.cmpName});
    			c.Site.forEach(s => {
    				this.raw_sites.push({id:s.SitAquasymId, name: s.sitName, sector:s.SkrettingSectorName, cmpid:c.CompAquasymId});
    				s.Unit.forEach(u => {
    					this.raw_units.push({id:u.UniAquasimId, name: u.uniName, sitid:s.SitAquasymId, isActive:u.unitActive});
    				});
                });

    		});

    		this.companies = this.copyObject(this.raw_companies);
            this.raw_units.sort((a,b) => a.name.localeCompare(b.name));
    	});

    	var opt = Global.pdi_filters;

    	this.storage.get('common').then(data => {
    		data.forEach(x => {
    			if( opt.indexOf(x.Name) > -1 ){
	    			this.filters[x.Name] = x.Values;
	    		}
    		});
    		//console.log(this.filters);
    	});
    }

    public filterSites(cmpid:any){
        var resp:boolean;
        this.resetCompanySelections();

        if( cmpid != '' ){
            let test = this.raw_sites.filter(s => {
                return s.cmpid == cmpid;
            });

            this.sites = test;
        }
        else{
        	this.sites = [];
        }
    }

    public filterUnits(id:any){
        var resp:boolean;
        this.resetSiteSelections();

        //set selected sector
        let site = this.sites.filter(s => {
            return s.id == id;
        })[0];
        //console.log(site);
        this.sel_sector = site['sector'];
        //this.sel_sector = '';

        if( id != '' ){
            let test = this.raw_units.filter(s => {
                return s.sitid == id;
            });

            this.units = test;
        }
        else{
        	this.units = [];
        }
    }

    public filterActiveUnits(status:any, siteId:number){
        let comp = true;

        if( (status == undefined) || (siteId == undefined) ){
            return;
        }

        this.isFiltering = true;
        console.log(this.isFiltering);
        if( status < 0 ){
            let test = this.raw_units.filter(s => {
                return (s.sitid == siteId);
            });

            this.units = test;
        }
        else{
            if(status > 0){
                comp = true;
            }
            else{
                comp = false;
            }

            let test = this.raw_units.filter(s => {
                if (s.isActive==null)
                {
                   s.isActive=false;
                }
                return (s.isActive == comp) && (s.sitid == siteId);
            });

            this.units = test;
        }

        this.isFiltering = false;
        console.log(this.isFiltering);
    }

    public filterLocations(loctype:any, siteid:any){
        var resp:boolean;
        /*console.log(loctype);
        console.log(siteid);
        console.log(this.raw_locations);*/

        if( (siteid != undefined) && (siteid != '') && (loctype != '-1') ){
            this.locations = [];
            let test = this.raw_locations.filter(s => {
                return ( (s.sitid == siteid) && (s.loctype == loctype) );
            });

            this.locations = test;
        }
        else{
            this.locations = [];
        }
    }

    public getList(){
        var qst = this.read_url;
        var tosend = {};

        if( this.f_datefrom != null ){
            tosend['_dateStart'] = this.getISODate(this.f_datefrom);
        }

        if( this.f_dateto != null ){
            tosend['_dateEnd'] = this.getISODate(this.f_dateto);
        }

        //show offline list
        if(this.isOffline){
            this.srv.getOfflineData(this.offline_key_list).then(x => {
                //console.log(x);
                this.rows = x.data;
                this.page = 1;

                if( Array.isArray(this.rows) ){
                    this.pageTotal = this.rows.length;
                }

                this.filterAll();
            });
        }
        else{
            this.loadingList = true;
            this.srv.postCall(qst, tosend).subscribe(x => {
                this.loadingList = false;

                if(x.success){
                    this.rawdata = x.val;
                    //save offline data
                    this.srv.saveOfflineData(this.offline_key_list, this.rawdata);

                    this.rows = x.val;
                    this.page = 1;

                    if( Array.isArray(this.rows) ){
                        this.pageTotal = this.rows.length;
                    }

                    this.filterAll();
                }
            });
        }

        //load unsaved items
        this.getOfflineItems();
    }

    public openCreateModal(content: any) {
        //this.modalService.open(content, { centered: true });
        this.c_obj = {};
        this.fields = [];
        let curr_date = this.momentjs().tz('America/Guayaquil').format('YYYY-MM-DDThh:mm');
        this.c_obj['date'] = curr_date;
        /*this.c_obj['date'] = new Date();
        this.c_obj['date'] = this.c_obj['date'].toISOString();
        this.c_obj['date'] = this.c_obj['date'].substring(0, 16);*/

        this.modalService.open(content, { size: 'lg', backdrop: 'static', keyboard: false });
    }

    addPondForm(){
        if( this.c_obj['loctype'] != undefined ){
            if( this.c_obj['loctype'] > -1 ){
                let id = this.c_obj['curr_slin'];
                let slin = this.locations.filter(s => {
                        return s.id == id;
                    });

                if(slin.length <= 0){
                    return;
                }

                let tmp = new Itempdi(slin[0].id, slin[0].name);
                tmp.isSlin = true;
                console.log(tmp);
                this.fields.push(tmp);
            }
            else{
                let id = this.c_obj['curr_unit'];
                let unit = this.units.filter(s => {
                        return s.id == id;
                    });

                if(unit.length <= 0){
                    return;
                }

                this.fields.push(new Itempdi(unit[0].id, unit[0].name));
            }
        }
    	//console.log(this.fields);
    }

    removePondForm(i:number){
    	//
    	this.fields.splice(i, 1);
    }

    handleInputChange(e, obj:any) {
	    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
	    var pattern = /image-*/;
	    var reader = new FileReader();
	    /*if (!file.type.match(pattern)) {
	      alert('invalid format');
	      return;
	    }*/

	    //console.log(file);

	    reader.onload = (_event) => {
	      //this.imgURL = reader.result;
	      /*obj.img[0].src = reader.result;
	      obj.img[0].fname = file.name;*/
            if( (obj.img.length + 1) > this.max_img_count ){
                Swal.fire(this.translations.pdi_max_img_count + ' ' + this.max_img_count, '', 'warning');
            }
            else{
                //this.compressFile(this.localUrl,fileName);
                this.compressFile(reader.result, file.name).then(x => {
                    //console.log(x);
                    if( x != false ){
                        obj.img.push({src: x, obj:undefined, fname: file.name, imId:Date.now(), comment:''});
                    }
                    else{
                        Swal.fire(this.translations.pdi_img_toobig, '', 'warning');
                    }
                    //obj.img.push({src: reader.result, obj:undefined, fname: file.name, imId:Date.now(), comment:''});
                });

            }
	    }

	    reader.readAsDataURL(file);
	}

	openPreview(obj:any): void {
		var album:any;
		album = [
			{src: obj.src}
		];
	    // open lightbox
	    this._lightbox.open(album, 0);
	}

	clearImgPrev(obj:any){
		obj.src = '';
		//console.log(obj);
	}

    clearImg(obj:any, id:any){
        /*console.log(obj);
        console.log(id);*/
        var i = -1;
        var counter = 0;

        obj.forEach(x => {
            if( x.imId == id ){
                i = counter;
            }

            counter++;
        });

        if( i != -1 ){
            obj.splice(i,1);
        }
    }

	createItem(content:any, obj:any){
		var validated = false;
        var go = true;
		/*console.log(obj);
        console.log(this.fields);
		return;*/

		validated = this.validateCreateInfo(obj);
        if( !validated ){
            Swal.fire('Ingrese todos los campos requeridos', '', 'error');
            return;
        }

        if( this.fields.length <= 0 ){
        	Swal.fire('No existen items', '', 'error');
            return;
        }

        this.fields.forEach(x => {
            x.img.forEach(y => {
                if( ((y.src != '') && (y.comment == '')) /*|| ((y.src == '') && (y.comment != ''))*/ ){
                    go = false;
                }
            });
        });

        if( !go ){
            Swal.fire(this.translations['pdi_error_img_sug'], '', 'error');
            return;
        }

        if(this.isOffline){
            obj.fields = this.fields;
            this.createItemLocal(obj, content);
        }
        else{
            this.isCreating = true;
            this.createHeader(obj, this.fields.length).subscribe(x => {
                //console.log(x);
                this.isCreating = false;

                if( x != false ){
                    var batch = [];

                    this.fields.forEach(i => {
                        batch.push(this.createDetail(i, x));
                    });

                    this.isCreating = true;

                    concat(...batch).subscribe({
                        complete: () => {
                            this.isCreating = false;
                            console.log('Complete');
                            this.reload.next(true);
                            this.closeModal(content);
                            Swal.fire('¡Creado!', '', 'success');

                            if( (obj.off_id != undefined) && (obj.off_id != null) ){
                                //delete local entry
                                this.deleteItemLocal(obj, false);
                            }
                        }
                    });
                }
                else{
                    this.isCreating = false;
                    Swal.fire('Error al crear', '', 'error');
                }
            });
        }
	}

	validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'CompAquasymId': new FormControl(fields.CompAquasymId, [Validators.required]),
            'SitAquasymId': new FormControl(fields.SitAquasymId, [Validators.required]),
            'date': new FormControl(fields.date, [Validators.required]),
            'requested': new FormControl(fields.requested, [Validators.required])
        });

        return mygroup.valid;
    }

    public createHeader(obj:any, total:number){
        var url = 'PathologicalDataInput/SaveHeader';
        var to:any;
        to = {};
        to.pdiSiteAquasimId = obj.SitAquasymId;
        to.pdiRequestedBy = obj.requested;
        to.pdiSiteDateTime = obj.date;
        to.pdiRecommendation = obj.pdiRecommendation;
        to.pdiItemTotals = total;

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;

                        if(mydata.success == true ){
                          return of(mydata.val['pdiId']);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public createDetail(obj:any, id:number){
    	/*console.log(obj);
    	console.log(id);*/
        var url = 'PathologicalDataInput/SaveDetail';
        var to:any;
        to = {};
        to.pdidPathologicalDataInputId = id;



        if(obj.isSlin){
            //is precria
            to.pdidUnitAquasimId = null;
            to.pdiSlinId = obj.unitid;
        }
        else{
            //is unit
            to.pdidUnitAquasimId = obj.unitid;
        }

        to.pdidShrimpQty = obj.shrimps;
        to.pdidDays = obj.days;
        to.pdidWeight = obj.weight;
        //to.pdidComment = obj.comment;
        to.Images = [];

        if( obj.img.length > 0 ){
            obj.img.forEach(im => {
                if( im['src'] != '' ){
                    to.Images.push({
                        imImageFileName: im['fname'],
                        imImageBase64String: im['src'],
                        imComment: im['comment']
                    });
                }
            });
        }

        obj.items.forEach(g => {
        	g.items.forEach(i => {
        		if( (i['fname'] != '') && (i['fval'] != '') ){
        			to[i['fname']] = i['fval'];
        		}

        		if( (i['qty'] != '') && (i['qval'] != '') ){
        			to[i['qty']] = i['qval'];
        		}
        	});
        });

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;

                        if(mydata.success == true ){
                          return of(true);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    showInfo(content:any, id:number){
        this.fields = [];
        this.openUpdateModalStatic(content, {}, 'lg');
        this.getAnalysis(id);
    }

    getAnalysis(id:number){
        var qst = 'PathologicalDataInput/Get/' + id;
        this.loadingDetails = true;

        this.srv.getCall(qst).subscribe(x => {
            this.loadingDetails = false;
            if(x.success){
                var rs = x.val;
                this.parseDetails(rs[0]);
            }
        });
    }

    parseDetails(obj:any){
    	var tmp:any;
        var inner:any;

        tmp = {};
        tmp['pdiId'] = obj['pdiId'];
        //tmp['date'] = obj['pdiSiteDateTime'].substring(0, 10);
        tmp['date'] = obj['pdiSiteDateTime'];
        tmp['CompAquasymId'] = obj['CompAquasymId'];
        this.filterSites(tmp['CompAquasymId']);
        tmp['SitAquasymId'] = obj['SitAquasymId'];
        this.filterUnits(tmp['SitAquasymId']);
        tmp['requested'] = obj['pdiRequestedBy'];
        tmp['sel_sector'] = obj['SkrettingSectorName'];
        tmp['pdiRecommendation'] = obj['pdiRecommendation'];

        //fill details
        if( Array.isArray(obj['PathologicalDataInputDetail']) ){

            obj['PathologicalDataInputDetail'].forEach(x => {
                //precria
                //console.log( x['UniAquasimId']);
                if( x['UniAquasimId'] == null ){

                    inner = new Itempdi(x['SlinId'], x['SlinName']);
                    inner.isSlin = true;
                }
                else{
                    inner = new Itempdi(x['UniAquasimId'], x['uniName']);
                }


                inner['shrimps'] = x['pdidShrimpQty'];
                inner['days'] = x['pdidDays'];
                inner['weight'] = x['pdidWeight'];
                inner['comment'] = x['pdidComment'];

                //fields
                inner['items'].forEach(y => {
                    y['items'].forEach(i => {
                        i.fval = x[i.fname];
                        i.qval = x[i.qty];
                    });
                });

                //images
                let imgtmp:any;
                if( Array.isArray(x['Images']) && (x['Images'].length > 0) ){
                    //console.log(x['Images']);
                    x['Images'].forEach(y => {
                        inner['img'].push({
                            src: y['imImageBase64String'],
                            obj:undefined,
                            fname: y['imImageFileName'],
                            imId: y['imId'],
                            comment: y['imComment']
                        });
                    });

                    /*inner.img[0].src = x['Images'][0]['imImageBase64String'];
                    inner.img[0].fname = x['Images'][0]['imImageFileName'];
                    inner.img[0].imId = x['Images'][0]['imId'];*/
                }

                this.fields.push(inner);
            });
            //console.log(this.fields);
        }

        this.u_obj = tmp;
        this.u_obj['offline'] = false;
    }

    addPondFormU(){
        if( this.u_obj['loctype'] != undefined ){
            if( this.u_obj['loctype'] > -1 ){
                let id = this.u_obj['curr_slin'];
                let slin = this.locations.filter(s => {
                        return s.id == id;
                    });

                if(slin.length <= 0){
                    return;
                }

                let tmp = new Itempdi(slin[0].id, slin[0].name);
                tmp.isSlin = true;
                console.log(tmp);
                this.fields.push(tmp);
            }
            else{
                let id = this.u_obj['curr_unit'];
                let unit = this.units.filter(s => {
                        return s.id == id;
                    });

                if(unit.length <= 0){
                    return;
                }

                this.fields.push(new Itempdi(unit[0].id, unit[0].name));
            }
        }
    }

    updateItem(content:any, obj:any){
        var validated = false;
        var go = true;
        /*console.log(obj);
        console.log(this.fields);*/

        validated = this.validateCreateInfo(obj);
        if( !validated ){
            Swal.fire('Ingrese todos los campos requeridos', '', 'error');
            return;
        }

        if( this.fields.length <= 0 ){
            Swal.fire('No existen items', '', 'error');
            return;
        }

        /*this.fields.forEach(x => {
            if( ((x.img[0].src != '') && (x.comment == '')) || ((x.img[0].src == '') && (x.comment != '')) ){
                go = false;
            }
        });*/

        this.fields.forEach(x => {
            x.img.forEach(y => {
                if( ((y.src != '') && (y.comment == '')) || ((y.src == '') && (y.comment != '')) ){
                    go = false;
                }
            });
        });

        if( !go ){
            Swal.fire(this.translations['pdi_error_img_sug'], '', 'error');
            return;
        }

        this.isUpdating = true;
        this.updateHeader(obj, this.fields.length).subscribe(x => {
            //console.log(x);
            this.isUpdating = false;

            if( x != false ){
                var batch = [];

                this.fields.forEach(i => {
                    batch.push(this.createDetail(i, x));
                });

                this.isUpdating = true;

                concat(...batch).subscribe({
                    complete: () => {
                        this.isUpdating = false;
                        console.log('Complete update');
                        this.reload.next(true);
                        this.closeModal(content);
                        Swal.fire('¡Actualizado!', '', 'success');
                    }
                });
            }
            else{
                this.isUpdating = false;
                Swal.fire('Error al crear', '', 'error');
            }
        });
    }

    public updateHeader(obj:any, total:number){
        var url = 'PathologicalDataInput/Update';
        var to:any;
        to = {};
        to.pdiId = obj.pdiId;
        to.pdiSiteAquasimId = obj.SitAquasymId;
        to.pdiRequestedBy = obj.requested;
        //to.pdiSiteDateTime = obj.date;
        to.pdiRecommendation = obj.pdiRecommendation;
        to.pdiItemTotals = total;

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;

                        if(mydata.success == true ){
                          return of(mydata.val['pdiId']);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );

    }


    public DeleteDetail(obj:any){
        var url = 'PathologicalDataInput/DeletePhysical';
        var to:any;
        to = {};

        return this.srv.postCall(url, to).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;

                        if(mydata.success == true ){
                          return of(mydata.val['pdiId']);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public getReportAPI(id:number){
        var url = 'PathologicalDataInput/GetReport/' + id;
        return this.srv.getBlobCall(url);
    }

    public downloadReport(obj:any){
        var pre = 'anpat';

        if(this.isDownloading){
            console.log('req denied');
            return;
        }
        this.isDownloading = obj.pdiId;
        //console.log(obj);

        this.getReportAPI(obj.pdiId).subscribe(x => {
            this.isDownloading = 0;
            //console.log(x);
            let fname = pre + '_' + obj.pdiId + '_' + obj.cmpName + '_' + obj.sitName + '.pdf';
            fileSaver.saveAs(x, fname);
            /*const url= window.URL.createObjectURL(x);
            window.open(url);*/
        });
    }

    public resetCompanySelections(){
        this.sites = [];
        this.units = [];
        this.locations = [];

        this.c_obj['SitAquasymId'] = '';
        this.u_obj['SitAquasymId'] = '';
        this.c_obj['curr_unit'] = '';
        this.u_obj['curr_unit'] = '';

        this.c_obj['curr_unit_active'] = '-1';
        this.u_obj['curr_unit_active'] = '-1';

        this.c_obj['loctype'] = '-1';
        this.u_obj['loctype'] = '-1';

        this.c_obj['curr_slin'] = '';
        this.u_obj['curr_slin'] = '';
    }

    public resetSiteSelections(){
        this.units = [];
        this.locations = [];
        this.c_obj['curr_unit'] = '';
        this.u_obj['curr_unit'] = '';

        this.c_obj['curr_unit_active'] = '-1';
        this.u_obj['curr_unit_active'] = '-1';

        this.c_obj['loctype'] = '-1';
        this.u_obj['loctype'] = '-1';

        this.c_obj['curr_slin'] = '';
        this.u_obj['curr_slin'] = '';
    }

    /**
     * getSampleLocationAllBelongAP
     */
    public getSampleLocationAllBelongAP() {
        var par_url = 'SampleLocation/AllBelongAP';

        if(this.isOffline){
            //return of(false);
            return from( this.srv.getOfflineData(this.offline_key_loc).then(x => {
                if( x != null ){
                    return x.data;
                }
                else{
                    return false;
                }
            }) );
        }
        else{
            return this.srv.getCall(par_url).pipe(
                mergeMap(data => {
                    let mydata:any = {success: false, msg:'', val:[]};
                    mydata = data;

                    if(mydata.success == true){
                        this.srv.saveOfflineData(this.offline_key_loc, mydata.val);
                        return of(mydata.val);
                    }
                    else{
                        return of(false);
                    }
                }),
                catchError(data => of(false))
            );
        }
    }

    public createItemLocal(obj:any, content:any){
        let items:any;

        this.srv.getPeristedData(this.offline_key).then(i => {

            if( (i == undefined) || (i == null) ){
                items = [];
            }
            else{
                items = i;
            }

            //set site name
            let stmp = this.raw_sites.filter(st => {
                return st.id == obj.SitAquasymId;
            });

            if( Array.isArray(stmp) ){
                obj.site_name = stmp[0].name;
            }

            if( (obj.off_id != undefined) && (obj.off_id != null) ){
                items.forEach((x,k) => {
                    if( x.off_id == obj.off_id ){
                        items[k] = obj;
                    }
                });
            }
            else{
                obj.off_id = Date.now();
                //obj.actions = this.getActionsByStatusName('OFFLINE');
                items.push(obj);
            }

            this.srv.persistData(this.offline_key, items).then(s => {
                this.closeModal(content);
                Swal.fire(this.translations.offline_saved, '', 'success');
                this.getOfflineItems();
            });
        });
    }

    public getOfflineItems(){
        let items:any;

        this.srv.getPeristedData(this.offline_key).then(i => {

            if( (i == undefined) || (i == null) ){
                items = [];
            }
            else{
                items = i;
            }

            this.offline_items = items;
            //console.log(this.offline_items);
        });
    }

    public deleteItemLocal(obj:any, prompt=true){
        let items:any;

        if(!prompt){
            this.srv.getPeristedData(this.offline_key).then(i => {
                if( (i != undefined) && (i != null) ){
                    items = [];
                    i.forEach(x => {
                        if( x.off_id != obj.off_id ){
                            items.push(x);
                        }

                        this.srv.persistData(this.offline_key, items).then(s => {
                            this.offline_items = items;
                            //Swal.fire(this.translations.deleted, '', 'success');
                        });
                    });
                }
            });
        }
        else{
            Swal.fire({
                title: this.translations.delete_item_title,
                text: '',
                icon: 'warning',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                cancelButtonText: this.translations.cancel,
                confirmButtonText: this.translations.delete,
                preConfirm: () => {
                    this.srv.getPeristedData(this.offline_key).then(i => {
                        if( (i != undefined) && (i != null) ){
                            items = [];
                            i.forEach(x => {
                                if( x.off_id != obj.off_id ){
                                    items.push(x);
                                }

                                this.srv.persistData(this.offline_key, items).then(s => {
                                    this.offline_items = items;
                                    //Swal.fire(this.translations.deleted, '', 'success');
                                });
                            });
                        }
                    });
                }
            }).then((result) => {
            });
        }
    }

    public showOfflineInfo(content:any, obj:any){
        //console.log(obj);

        this.openUpdateModalStatic(content, {});

        this.filterSites(obj['CompAquasymId']);
        this.filterUnits(obj['SitAquasymId']);
        //this.u_obj = obj;
        this.u_obj = JSON.parse(JSON.stringify(obj));
        this.u_obj['offline'] = true;
        /*var head:any;

        head = {};
        head['off_id'] = obj['off_id'];
        head['date'] = obj['date'];
        head['CompAquasymId'] = obj['CompAquasymId'];
        this.filterSites(head['CompAquasymId']);
        head['SitAquasymId'] = obj['SitAquasymId'];
        this.filterUnits(head['SitAquasymId']);
        head['requested'] = obj['requested'];
        head['sel_sector'] = obj['sel_sector'];
        head['pdiRecommendation'] = obj['pdiRecommendation'];
        head['offline'] = true;*/

        this.fields = [];
        obj.fields.forEach(x => {
            let tmp = new Itempdi(x['unitid'], x['unitname']);
            if( x.isSlin ){
                tmp.isSlin = true;
            }

            tmp.comment = x.comment;
            tmp.days = x.days;
            tmp.img = x.img;
            tmp.items = x.items;
            tmp.pdidId = x.pdidId;
            tmp.shrimps = x.shrimps;
            tmp.weight = x.weight;

            this.fields.push(tmp);
        });

        /*this.u_obj = obj;
        this.u_obj['offline'] = true;*/
    }

    compressFile(image,fileName) {
        var orientation = -1;
        //let sizeOfOriginalImage = this.imageCompress.byteCount(image)/(1024*1024);
        let sizeOfOriginalImage = this.imageCompress.byteCount(image)/(1024*1024);
        //console.warn('Size in Mbytes is now:',  sizeOfOriginalImage);

        if( sizeOfOriginalImage > this.max_img_size ){
            // try compressing
            console.log('with conversion');
            return this.imageCompress.compressFile(image, orientation, 50, 50).then(
                result => {
                    /*this.imgResultAfterCompress = result;
                    this.localCompressedURl = result;*/
                    //let sizeOFCompressedImage = this.imageCompress.byteCount(result)/(1024*1024)
                    let sizeOFCompressedImage = this.imageCompress.byteCount(result)/(1024*1024);
                    console.log('Size in Mbytes after compression:',  sizeOFCompressedImage);

                    if( sizeOFCompressedImage > this.max_img_size ){
                        return false;
                    }
                    else{
                        return result;
                    }

                    //console.log(result);

                    // create file from byte
                    //const imageName = fileName;
                    // call method that creates a blob from dataUri
                    //const imageBlob = this.dataURItoBlob(this.imgResultAfterCompress.split(',')[1]);
                    //imageFile created below is the new compressed file which can be send to API in form data
                    //const imageFile = new File([result], imageName, { type: 'image/jpeg' });
                });
        }
        else{
            //no action return same image
            console.log('no conversion');
            return new Promise(function(resolve, reject) { resolve(image) });
        }
    }

    convertToMB(qty:any){

    }

}


