export class Itempdi {
	unitid:number;
	unitname:string;
	shrimps:number;
	days:string;
	weight:any;
	items:any;
	comment:string;
	img:any;
        pdidId:any;
        isSlin:boolean;

	constructor(unit:number, name:string){
		this.unitid = unit;
		this.unitname = name;
		this.shrimps = 0;
		this.days = '';
		this.weight = 0;
		this.comment = '';
		this.img = [];
                this.pdidId = null;
                this.isSlin = false;

		this.items = [
                	/*{group:'apect_ext', items:[
                		{name:'texture', fname:'pdidTextureName', qty:'pdidTextureQty', source:'PA_TEXT', fval:'', qval:''},
                		{name:'cromat_expand', fname:'', qty:'pdidChromatophoresQty', source:'', fval:'', qval:''},
                		{name:'ant', fname:'pdidAntennaName', qty:'pdidAntennaQty', source:'PA_ANTE', fval:'', qval:''},
                		{name:'urop', fname:'pdidUropodsName', qty:'pdidUropodsQty', source:'PA_UROP', fval:'', qval:''}
                	]}*//*,
                	{group:'branquias', items:[
                		{name:'dendobranq', fname:'pdidGillName', qty:'pdidGillQty', source:'PA_DEND', fval:'', qval:''},
                		{name:'necrosis', fname:'pdidNecrosisName', qty:'pdidNecrosisQty', source:'PA_NECR', fval:'', qval:''},
                		{name:'ectopar', fname:'pdidEctoparasiteName', qty:'pdidEctoparasiteQty', source:'PA_ECTO', fval:'', qval:''}
                	]},
                	{group:'hepatopac', items:[
                		{name:'niv_lip', fname:'pdidLipidsName', qty:'pdidLipidsQty', source:'PA_NIVE', fval:'', qval:''},
                		{name:'def_tub', fname:'pdidTubularDeformityName', qty:'pdidTubularDeformityQty', source:'PA_DEFO', fval:'', qval:''}
                	]},
                	{group:'intestino', items:[
                		{name:'grega', fname:'pdidGregarineName', qty:'pdidGregarineQty', source:'PA_GREG', fval:'', qval:''},
                		{name:'nemat', fname:'pdidNematodesName', qty:'pdidNematodesQty', source:'PA_NEMA', fval:'', qval:''},
                		{name:'cont_intes', fname:'pdidIntestinalName', qty:'pdidIntestinalQty', source:'PA_CONTE', fval:'', qval:''}
                	]},
                	{group:'ampolla_rect', items:[
                		{name:'gamet', fname:'pdidGametocyteName', qty:'pdidGametocyteQty', source:'PA_GAME', fval:'', qval:''}
                	]}*/
                ];

                /*this.img = [
                	{src:'', obj:undefined, fname:'', imId:null, comment:''}
                ];*/
	}
}

