export const Global = {
    //url: 'http://13.90.229.9:81/api/',
    //url: 'http://localhost:55245/api/',
    url: 'https://ctec-api.support-royalticgroup.com/api/',
    pdi_filters: [
    		'PA_ANTE',
    		'PA_CONTE',
    		'PA_DEFO',
    		'PA_DEND',
    		'PA_ECTO',
    		'PA_GAME',
    		'PA_GREG',
    		'PA_NECR',
    		'PA_NEMA',
    		'PA_NIVE',
    		'PA_TEXT',
    		'PA_UROP'
		],
    sapproval_filters: ['SAM_01'],
    sampling_status: [
        { id:1, name:'PENDIENTE', approv_actions:['take'], samp_actions:['edit', 'delete'], origid: 75 },
        { id:2, name:'RECIBIDA', approv_actions:['assign', 'reject', 'overdue'], samp_actions:[], origid: 466 },
        { id:3, name:'ASIGNADO', approv_actions:['reset', 'reject', 'overdue'], samp_actions:[], origid: 76 },
        { id:4, name:'EN PROGRESO', approv_actions:['overdue'], samp_actions:['download'], origid: 77 },
        { id:5, name:'TERMINADO', approv_actions:['approve', 'reject', 'download', 'overdue'], samp_actions:['download'], origid: 89 },
        { id:6, name:'RECHAZADA', approv_actions:[], samp_actions:[], origid: 78 },
        { id:7, name:'APROBADO', approv_actions:['download', 'overdue'], samp_actions:['download', 'followup'], origid: 90 },
        { id:8, name:'OFFLINE', approv_actions:[], samp_actions:['offline'], origid: 0 }
    ],
  rptTxtColor:'#003D6A',
  rptBgColor:'#E8E7E8'
};
