import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

@Component({
  selector: 'app-analysis',
  templateUrl: './analysis.component.html'
})
export class AnalysisComponent extends BaseCrudComponent {

    id:any;
    loadingRequired = false;
    listAgr:any;
    translations:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'Analysis/All';
        this.create_url = 'Analysis/Create';
        this.update_url = 'Analysis/Update';
        this.delete_url = 'Analysis/LogicalDelete';
        this.search_fields = ['anName', 'anKey', 'anDescription'];

        this.c_obj['AnalysisFeature'] = [];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

              this.loadingRequired = true;
              this.getAnalysisGroupData().subscribe(x => {
                  //console.log(x);
                  if(x != false){
                      this.loadingRequired = false;
                      this.listAgr = x;
                  }
              });

              this.getList();
        });
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.success){
                this.c_obj = {};
                this.c_obj['AnalysisFeature'] = [];
                this.getList();
                this.closeModal(content);
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.msg, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'anKey': new FormControl(fields.anKey, [Validators.required]),
            'anName': new FormControl(fields.anName, [Validators.required]),
            'anGroupId': new FormControl(fields.anGroupId, [Validators.required]),
            'anIsSpecialType': new FormControl(fields.anIsSpecialType, [Validators.required])
        });

        return mygroup.valid;
    }

    public getAnalysisGroupData(){
        var par_url = 'AnalysisGroup/All';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);
                        
                        if(mydata.success == true ){
                          return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

    public feature(condition: number, position: number) {
        if ( condition ===  1) {
          this.c_obj['AnalysisFeature'].push({anfName: '' });
        } else if (condition === 0) {
          this.c_obj['AnalysisFeature'].splice(position, 1);
        }
    }

    public featureUpdate(condition: number, position: number) {
        if ( condition ===  1) {
          this.u_obj['AnalysisFeature'].push({anfName: '' });
        } else if (condition === 0) {
          this.u_obj['AnalysisFeature'].splice(position, 1);
        }
    }

}
