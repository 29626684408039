import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

@Component({
  selector: 'app-sample',
  templateUrl: './sample.component.html'
})
export class SampleComponent extends BaseCrudComponent {

    id:any;
    loadingRequired = false;
    listGrupo:any;
    translations:any;
    curr_gro:any;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'Sample/All';
        this.create_url = 'Sample/Create';
        this.update_url = 'Sample/Update';
        this.delete_url = 'Sample/LogicalDelete';
        this.search_fields = ['samName', 'samKey', 'samDescription'];

        //this.c_obj['AnalysisGroupFeature'] = [];
        this.c_obj['SampleDetail'] = [];
        this.curr_gro = 0;

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();
              this.getList();
        });
    }

    //1
    public getGrupoData(){
        var par_url = 'AnalysisGroup/All';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);
                        
                        if(mydata.success == true ){
                          return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }
    //fin 1
    //2
    openCreateModal(content:any){
        //super.openCreateModalLG(content);
        this.c_obj['SampleDetail'] = [];
        this.curr_gro = 0;

        super.openCreateModal(content);
        this.loadingRequired = true;
        this.getGrupoData().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.listGrupo = x;
            }
        });
    }
    //fin 2
    //3
    openUpdateModal(content:any, obj:any){
        this.curr_gro = 0;
        super.openUpdateModal(content, obj);
        this.loadingRequired = true;
        this.getGrupoData().subscribe(x => {
            this.loadingRequired = false;
            if(x != false){
                this.listGrupo = x;
            }
        });
    }
    //fin 3

    validateCreateInfo(fields:any){
        //debugger;
        let mygroup:any = {};
        mygroup = new FormGroup({
            'samKey': new FormControl(fields.samKey, [Validators.required]),
            'samName': new FormControl(fields.samName, [Validators.required])
        });

        return mygroup.valid;
    }

    public createItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.success){
                this.c_obj = {};
                //this.c_obj['AnalysisGroupFeature'] = [];
                this.c_obj['SampleDetail'] = [];
                this.curr_gro = 0;
                this.getList();
                this.closeModal(content);
            }//end success
            else{
                //Swal.fire(this.translations[x.msg], '', 'error');
                Swal.fire(x.msg, '', 'error');
            }
        });
    }

    public updateItem(content:any, form:any){
        var validated = true;
//debugger; 
//console.log('aqui');
        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }
//debugger;
        var to:any;
        to = form;

        var qst = this.update_url;
        this.isUpdating = true;
//debugger;
        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            //debugger;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    //5
    public addGroup(obj:any) { 
        //debugger;
        if( this.curr_gro == 0 ){
            return;
        }
        else{
            let groupsam = this.listGrupo.filter(f => {
                return f.agrId == this.curr_gro;
            });

            let checker = obj['SampleDetail'].filter(f => {
                return f.SamDGroupId == this.curr_gro;
            });
//debugger;
            if( (groupsam.length > 0) && (checker.length == 0) ){
                let groups = groupsam[0];
                obj['SampleDetail'].push({SamDGroupId: groups['agrId'],  FullName:groups['agrName'], SamDSampleId:groups['samId']});
                //debugger;
            }
        }
    }// fin 5

    //6
    public delGroup(obj:any, position:number){
        obj['SampleDetail'].splice(position, 1);
    }
    //fin 6
   

}
