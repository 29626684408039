<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body nopadding">
                <div class="row button-group">
                    <div class="col-md-11 col-9 m-b-30" style="text-align: left;">
                        <input type="text" class="form-control" [(ngModel)]="search" name="search" placeholder="{{'search_msg' | translate}}" (keyup)="filterAll()">
                    </div>
                    <div class="col-md-1 col-3 m-b-30" style="text-align: right;">
                        <button type="button" class="btn btn-custom-003d6a" (click)="showFilters = !showFilters" style="color: #ffffff;" >
                            <i *ngIf="!showFilters" class="far fa-caret-square-down"></i>
                            <i *ngIf="showFilters" class="far fa-minus-square"></i>
                        </button>
                    </div>
                    <!-- filter box -->
                    <div class="col-12 m-b-30" style="text-align: left;" *ngIf="showFilters">
                        <div class="card filter-box">
                            <div class="card-body row">
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'date' | translate}} {{'from' | translate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp1" [(ngModel)]="f_datefrom" ngbDatepicker #d="ngbDatepicker" [readonly]="true">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                                <i class="far fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'date' | translate}} {{'to' | translate}}</label>
                                    <div class="input-group">
                                        <input class="form-control" placeholder="yyyy-mm-dd" name="dp2" [(ngModel)]="f_dateto" ngbDatepicker #d2="ngbDatepicker" [readonly]="true">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                                <i class="far fa-calendar-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 col-lg-3 m-b-30">
                                    <label>{{'status' | translate}}</label>
                                    <select class="custom-select custom-select-md" [(ngModel)]="f_custom1" name="cf1">
                                        <option *ngFor="let p of all_status" value="{{p.origid}}">{{p.name}}</option>
                                    </select>
                                </div>
                                <div class="col-12 m-b-30" style="text-align: right;">
                                    <button type="button" class="btn btn-danger" (click)="clearSentFilters()">
                                        <i class="far fa-trash-alt"></i> {{'clear' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- filters -->
                    <div class="col-lg-6 col-xlg-6 col-5 m-b-30">
                        <button type="button" class="btn btn-custom-003d6a" (click)="openCreateModal(create1)" style="color: #ffffff;" [disabled]="loadingRequired">
                            <i class="fas fa-plus"></i> {{'create' | translate}}
                        </button>
                    </div>
                    <div class="col-lg-6 col-xlg-6 col-7 m-b-30" style="text-align: right;">
                        <button [ladda]="loadingList" data-style="expand-left" type="button" class="btn btn-custom-003d6a" (click)="getList()" style="color: #ffffff;"><i class="fas fa-sync"></i> {{'update' | translate}}</button>
                    </div>
                </div>

                <div *ngIf="isOffline" class="offline-banner">{{'offline_mode' | translate}}</div>
                <!-- <div *ngIf="srv.offline" class="offline-banner">{{'offline_mode' | translate}}</div> -->

                <div *ngIf="offline_items.length > 0">
                    <h4>{{'offline_saved_list' | translate}}</h4>
                    <div class="table-responsive">
                        <table class="table">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th class="sh">Temp ID</th>
                                    <th class="sh">{{'site' | translate}}</th>
                                    <!-- <th>{{'place' | translate}}</th> -->
                                    <th class="sh">{{'date' | translate}}</th>
                                    <th class="sh">{{'samp_ag' | translate}}</th>
                                    <th>{{'action' | translate}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let row of offline_items">
                                    <td>{{row.off_id}}</td>
                                    <td>{{row.site_name}}</td>
                                    <!-- <td>test place</td> -->
                                    <td>{{row.date | date:'y-MM-dd'}}</td>
                                    <td>{{row.ag_obj.name}}</td>
                                    <td class="action-buttons">
                                        <i (click)="showOfflineInfo(update1, row)" style="cursor:pointer;" class="fa fa-external-link-alt text-info m-r-10"></i>
                                        <i (click)="deleteItemLocal(row)" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10"></i>
                                    </td>
                                </tr>
                            </tbody>
                            <!-- <tfoot>
                                <td colspan="8">
                                    <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                                </td>
                            </tfoot> -->
                        </table>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table">
                        <thead class="bg-danger text-white">
                            <tr>
                                <th (click)="setOrder('sampId')" class="sh">
                                    ID<i *ngIf="(orderKey!==null)&&(orderKey=='sampId')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <!-- <th (click)="setOrder('cmpName')" class="sh">
                                    {{'company' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='cmpName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th> -->
                                <th (click)="setOrder('sitName')" class="sh">
                                    {{'site' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='sitName')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'place' | translate}}</th>
                                <th (click)="setOrder('sampDate')" class="sh">
                                    {{'date' | translate}}<i *ngIf="(orderKey!==null)&&(orderKey=='sampDate')" [ngClass]="{'m-l-10':true, 'ti-arrow-up':!orderDesc, 'ti-arrow-down':orderDesc}"></i>
                                </th>
                                <th>{{'delivery_date' | translate}}</th>
                                <th>{{'overdue' | translate}}</th>
                                <!-- <th>{{'requested_by' | translate}}</th> -->
                                <th>{{'status' | translate}}</th>
                                <th>{{'action' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let row of rows | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize | orderBy:orderKey:orderDesc" [ngClass]="{'selected-row':row['sampOverdue']}">
                                <td>{{row.sampId}}</td>
                                <!-- <td>{{row.cmpName}}</td> -->
                                <td>{{row.sitName}}</td>
                                <td *ngIf="row.SampleLocationInstanceName != null">{{row.SampleLocationInstanceName}}</td>
                                <td *ngIf="row.UnitName != null">{{row.UnitName}}</td>
                                <td>{{row.sampDate | date:'y-MM-dd'}}</td>
                                <td>{{row.estimatedDelivery}}</td>
                                <td>{{ ( (row.sampOverdue) ? 'yes' : 'no') | translate }}</td>
                                <!-- <td>{{row.UserTechnicalName}}</td> -->
                                <td>{{row.StatusName}}</td>
                                <td class="action-buttons">

                                    <i (click)="showInfo(update1, row)" style="cursor:pointer;" class="fa fa-external-link-alt text-info m-r-10"></i>

                                    <i *ngIf="isAllowed('delete', row)" (click)="deleteItem({sampId:row.sampId})" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10"></i>
                                    <i *ngIf="!isAllowed('delete', row)" style="cursor:pointer;" class="far fa-trash-alt text-muted m-r-10"></i>

                                    <i *ngIf="(row.sampId !== isDownloading)" (click)="downloadReport(row)" style="cursor:pointer;" title="{{'export_pdf' | translate}}" [ngClass]="{'fa fa-cloud-download-alt m-r-10': true, 'text-info': isAllowed('download', row), 'text-muted': !isAllowed('download', row)}"></i>

                                    <i *ngIf="(row.sampId === isDownloading)" class="fas fa-sync-alt fa-spin m-r-10 text-info"></i>

                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <td colspan="8">
                                <ngb-pagination [collectionSize]="pageTotal" [(page)]="page" [pageSize]="pageSize" [maxSize]="5" style="float: right;"></ngb-pagination>
                            </td>
                        </tfoot>
                    </table>
                </div>

                <ng-template #create1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'samp_title_new' | translate}}</h4>
                    </div>
                    <div class="modal-body">
                        <!-- main row -->
                        <div class="row">

                            <div class="col-12">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'date' | translate}} *</label>
                                                <input type="date" class="form-control" name="ndate" [(ngModel)]="c_obj['date']" required disabled="true">
                                            </div>
                                            <div class="col-md-6"></div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'company' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['CompAquasymId']" name="cmp" (change)="filterSites($event.target.value)" required>
                                                    <option *ngFor="let p of raw_companies" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'site' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['SitAquasymId']" name="site" (change)="filterUnits($event.target.value)" required>
                                                    <option *ngFor="let p of sites" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_type' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['stypeID']" name="stypeID" required>
                                                    <option *ngFor="let p of sample_types" value="{{p.stypeID}}">{{p.stypeName}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_location_type' | translate}} *</label>
                                                <!-- <select class="custom-select custom-select-md" [(ngModel)]="c_obj['stype']" name="stype" required>
                                                    <option value="1" selected="">{{'samp_unit' | translate}}</option>
                                                    <option value="2">{{'samp_others' | translate}}</option>
                                                </select> -->
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['loctype']" name="loctype" (change)="filterLocations($event.target.value, c_obj['SitAquasymId'])">
                                                    <option value="-1">{{'unit' | translate}}</option>
                                                    <option *ngFor="let p of loctypes" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="c_obj['loctype'] == -1" class="col-md-6 ha-ibox">
                                                <label>{{'pdi_production' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['curr_unit_active']" name="cunitactive" (change)="filterActiveUnits($event.target.value, c_obj['SitAquasymId'])">
                                                    <option value="-1">{{'all' | translate}}</option>
                                                    <option value="1">{{'is_open' | translate}}</option>
                                                    <option value="0">{{'is_closed' | translate}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="c_obj['loctype'] == -1" class="col-md-6 ha-ibox">
                                                <label>{{'samp_unit' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['unit_id']" name="cunit" required>
                                                    <option *ngFor="let p of units" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="c_obj['loctype'] > -1" class="col-md-6 ha-ibox">
                                                <label>{{'samp_spot' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['spot_id']" name="cspot" required>
                                                    <option *ngFor="let p of spots" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_qty' | translate}} *</label>
                                                <input type="number" min="0" class="form-control" name="sampSampleQuantity" [(ngModel)]="c_obj['sampSampleQuantity']" required>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_container' | translate}} *</label>
                                                <input type="text" class="form-control" name="sampContainer" [(ngModel)]="c_obj['sampContainer']" required>
                                            </div>
                                            <div class="col-md-6 ha-ibox" style="padding-top: 34px">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" [attr.checked]="(c_obj['sampRequestedByClient']) ? 'checked' : null" id="requestedby" name="requestedby" (change)="c_obj['sampRequestedByClient']=!c_obj['sampRequestedByClient'];">
                                                    <label class="custom-control-label" for="requestedby">{{'samp_reqbyclient' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 ha-ibox">
                                                <label>{{'samp_comment' | translate}}</label>
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="3" name="sug" [(ngModel)]="c_obj['comment']"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_ag' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['agid']" name="agid" (change)="filterAnalysisGroup($event.target.value)" required>
                                                    <option *ngFor="let p of groupselect" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- end general info -->

                            <!-- PCR additional params -->
                            <div class="col-12" *ngIf="c_obj['ag_obj']['name'] == 'PCR'">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampCode' | translate}}</label>
                                                <input type="text" class="form-control" name="sampCode" [(ngModel)]="c_obj['sampCode']" maxlength="15">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampProductType' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['sampProductType']" name="sampProductType">
                                                    <option *ngFor="let p of this.pcr_filters['ProductTypePCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampReferenceNumber' | translate}}</label>
                                                <input type="text" class="form-control" name="sampReferenceNumber" [(ngModel)]="c_obj['sampReferenceNumber']" maxlength="15">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampBatchCode' | translate}}</label>
                                                <input type="text" class="form-control" name="sampBatchCode" [(ngModel)]="c_obj['sampBatchCode']" maxlength="15">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampPresentation' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['sampPresentation']" name="sampPresentation">
                                                    <option *ngFor="let p of this.pcr_filters['PresentationPCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampConditionSample' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['sampConditionSample']" name="sampConditionSample">
                                                    <option *ngFor="let p of this.pcr_filters['ConditionSamplePCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampEnvironmentTemperature' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['sampEnvironmentTemperature']" name="sampEnvironmentTemperature">
                                                    <option *ngFor="let p of this.pcr_filters['EnvironmentTemperaturePCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampRH' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="c_obj['sampRH']" name="sampRH">
                                                    <option *ngFor="let p of this.pcr_filters['RHPCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- END PCR additional params -->
                  <!-- SUELO additional params name-->
                    <div class="col-12" *ngIf="c_obj['ag_obj']['name'] =='FÝSICO QUÝMICO DEL SUELO'">
                        <form class="">
                           <div class="form-group">
                              <div class="row">
                                  <div class="col-md-6 ha-ibox">
                                     <label>{{'sampDepth' | translate}}</label>
                                       <input type="number" class="form-control" name="sampDepth" [(ngModel)]="c_obj['sampDepth']" min="0" maxlength="2">
                                 </div>
                             </div>
                          </div>
                         </form>
                    </div>
                    <!-- END SUELO additional params -->

                        </div>
                        <!-- end row main -->

                        <!-- group features -->
                        <form class="form-horizontal">
                            <div class="row title-row" *ngIf="c_obj['ag_obj']['group_features'].length > 0">
                                <div class="col-12">{{'samp_features' | translate}}</div>
                            </div>
                            <div *ngIf="c_obj['ag_obj']['MaxFeatures'] > 0">
                                <p *ngIf="c_obj['ag_obj']['SelFeatures'] == 0" class="gf-info">
                                    {{'samp_reqfeatures' | translate}}: {{c_obj['ag_obj']['MinFeatures']}}
                                </p>
                                <p *ngIf="c_obj['ag_obj']['SelFeatures'] > 0">
                                    <ngb-progressbar type="{{c_obj['ag_obj']['actualcolor']}}" [max]="c_obj['ag_obj']['MaxFeatures']" [value]="c_obj['ag_obj']['SelFeatures']"></ngb-progressbar>
                                </p>
                            </div>
                            <div class="row" *ngFor="let f of c_obj['ag_obj']['group_features']">
                                <div class="col-11 form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" [attr.checked]="(f.sel) ? 'checked' : null" id="{{f.name}}-gf" name="{{f.name}}-gf" (change)="c_obj['ag_obj'].toggleFeature(f)">
                                        <label class="custom-control-label" for="{{f.name}}-gf">&nbsp;&nbsp;&nbsp;{{f.name}}</label>
                                    </div>
                                </div>
                                <!-- <div class="col-11">tooltip</div> -->
                            </div>
                        </form>
                        <!-- end group features -->

                        <!-- Analysis -->
                        <form class="form-horizontal">
                            <div class="row title-row" *ngIf="c_obj['ag_obj']['analysis'].length > 0">
                                <div class="col-12">{{'samp_analysis' | translate}}</div>
                            </div>
                            <div class="row title-row2" *ngIf="c_obj['ag_obj']['analysis'].length > 0">
                                <div class="col-11">{{'name' | translate}}</div>
                                <!-- <div class="col-2">Reps</div> -->
                                <div class="col-1"></div>
                            </div>
                            <div class="row" *ngFor="let a of c_obj['ag_obj']['analysis']">
                                <div class="col-11 form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" [attr.checked]="(a.sel) ? 'checked' : null" id="{{a.name}}" name="{{a.name}}" (change)="a.sel=!a.sel;">
                                        <label class="custom-control-label" for="{{a.name}}">&nbsp;&nbsp;&nbsp;{{a.name}}</label>
                                    </div>
                                    <div class="inner-items" *ngIf="a['special']">
                                        <div class="row">
                                            <div class="col-12" style="padding: 10px 15px">
                                                <input type="text" class="form-control" name="{{a.name+'comm'}}" [(ngModel)]="a.comment" placeholder="{{'samp_comment' | translate}}" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inner-items" *ngIf="a['feat'].length > 0">
                                        <div class="row title-row title-sm">
                                            <div class="col-12">{{'samp_features_an' | translate}}</div>
                                        </div>
                                        <div *ngIf="a['MaxFeatures'] > 0" style="padding: 10px">
                                            <p *ngIf="a['SelFeatures'] == 0" class="gf-info">
                                                {{'samp_reqfeatures' | translate}}: {{a['MinFeatures']}}
                                            </p>
                                            <p *ngIf="a['SelFeatures'] > 0">
                                                <ngb-progressbar type="{{a['featColor']}}" [max]="a['MaxFeatures']" [value]="a['SelFeatures']"></ngb-progressbar>
                                            </p>
                                        </div>
                                        <div class="row" *ngFor="let f of a['feat']">
                                            <div class="col-11 form-group" style="padding-left: 15px">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" [attr.checked]="(f.sel) ? 'checked' : null" id="{{f.name}}" name="{{f.name}}" (change)="c_obj['ag_obj'].toggleAnalysisFeature(f)">
                                                    <label class="custom-control-label" for="{{f.name}}">&nbsp;&nbsp;&nbsp;{{f.name}}</label>
                                                </div>
                                            </div>
                                            <!-- <div class="col-11">tooltip</div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-2">
                                    <input type="number" class="form-control" name="rep-{{a.name}}" [(ngModel)]="a['reps']" min="1">
                                </div> -->
                                <div class="col-1 ha-tooltip" placement="left" container="body" ngbTooltip="{{a.tooltip}}" triggers="click:blur:hover">
                                    <i class="fas fa-info-circle text-info"></i>
                                </div>
                            </div>
                        </form>
                        <!-- end Analysis -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(create1)">{{'cancel' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(create1, c_obj)">{{'save' | translate}}</button>
                        <button [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(create1, c_obj, true)">{{'save_continue' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #update1 let-c="close" let-d="dismiss">
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'samp_edit' | translate}} #{{u_obj['sampId']}}</h4>
                    </div>

                    <div class="modal-body">
                        <!-- main row -->
                        <div class="row">

                            <div class="col-12">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'date' | translate}} *</label>
                                                <input type="text" class="form-control" name="ndate" [(ngModel)]="u_obj['date']" disabled="true">
                                            </div>
                                            <div class="col-md-6">
                                                <label>{{'delivery_date' | translate}} *</label>
                                                <input type="text" class="form-control" name="ndate2" [(ngModel)]="u_obj['estimatedDelivery']" disabled="true">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'company' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['CompAquasymId']" name="cmp" (change)="filterSites($event.target.value)" required [disabled]="u_obj['noedit']">
                                                    <option *ngFor="let p of raw_companies" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'site' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['SitAquasymId']" name="site" (change)="filterUnits($event.target.value)" required [disabled]="u_obj['noedit']">
                                                    <option *ngFor="let p of sites" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_type' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['stypeID']" name="stypeID" required [disabled]="u_obj['noedit']">
                                                    <option *ngFor="let p of sample_types" value="{{p.stypeID}}">{{p.stypeName}}</option>
                                                </select>
                                            </div>
                                            <!-- <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_location_type' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['stype']" name="stype" required [disabled]="u_obj['noedit']">
                                                    <option value="1" selected="">{{'samp_unit' | translate}}</option>
                                                    <option value="2">{{'samp_others' | translate}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['stype']==1">
                                                <label>{{'samp_unit' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['unit_id']" name="cunit" required [disabled]="u_obj['noedit']">
                                                    <option *ngFor="let p of units" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="u_obj['stype']==2">
                                                <label>{{'samp_spot' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['spot_id']" name="cspot" required [disabled]="u_obj['noedit']">
                                                    <option *ngFor="let p of spots" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div> -->
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_location_type' | translate}} *</label>
                                                <!-- <select class="custom-select custom-select-md" [(ngModel)]="u_obj['stype']" name="stype" required>
                                                    <option value="1" selected="">{{'samp_unit' | translate}}</option>
                                                    <option value="2">{{'samp_others' | translate}}</option>
                                                </select> -->
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['loctype']" name="loctype" (change)="filterLocations($event.target.value, u_obj['SitAquasymId'])">
                                                    <option value="-1">{{'unit' | translate}}</option>
                                                    <option *ngFor="let p of loctypes" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="u_obj['loctype'] == -1" class="col-md-6 ha-ibox">
                                                <label>{{'pdi_production' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['curr_unit_active']" name="cunitactive" (change)="filterActiveUnits($event.target.value, u_obj['SitAquasymId'])">
                                                    <option value="-1">{{'all' | translate}}</option>
                                                    <option value="1">{{'is_open' | translate}}</option>
                                                    <option value="0">{{'is_closed' | translate}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="u_obj['loctype'] == -1" class="col-md-6 ha-ibox">
                                                <label>{{'samp_unit' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['unit_id']" name="cunit" required>
                                                    <option *ngFor="let p of units" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="u_obj['loctype'] > -1" class="col-md-6 ha-ibox">
                                                <label>{{'samp_spot' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['spot_id']" name="cspot" required>
                                                    <option *ngFor="let p of spots" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_qty' | translate}} *</label>
                                                <input type="number" min="0" class="form-control" name="sampSampleQuantity" [(ngModel)]="u_obj['sampSampleQuantity']" required [disabled]="u_obj['noedit']">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_container' | translate}} *</label>
                                                <input type="text" class="form-control" name="sampContainer" [(ngModel)]="u_obj['sampContainer']" required [disabled]="u_obj['noedit']">
                                            </div>
                                            <div class="col-md-6 ha-ibox" style="padding-top: 34px">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" [attr.checked]="(u_obj['sampRequestedByClient']) ? 'checked' : null" id="requestedby" name="requestedby" (change)="u_obj['sampRequestedByClient']=!u_obj['sampRequestedByClient'];" [disabled]="u_obj['noedit']">
                                                    <label class="custom-control-label" for="requestedby">{{'samp_reqbyclient' | translate}}</label>
                                                </div>
                                            </div>
                                            <div class="col-md-12 ha-ibox">
                                                <label>{{'samp_comment' | translate}}</label>
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="3" name="sug" [(ngModel)]="u_obj['comment']" [disabled]="u_obj['noedit']"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-12 ha-ibox">
                                                <label>{{'sampapp_reasonoverdue' | translate}}</label>
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="3" name="overdue" [(ngModel)]="u_obj['sampOverdueComment']" disabled="true" style="resize: none;"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'samp_ag' | translate}} *</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['agid']" name="agid" (change)="filterAnalysisGroup($event.target.value, true)" required [disabled]="u_obj['noedit']">
                                                    <option *ngFor="let p of groupselect" value="{{p.id}}">{{p.name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox" *ngIf="isAllowed('followup', u_obj)">
                                                <label>&nbsp;</label>
                                                <div class="form-group" style="text-align: right;">
                                                    <button type="button" class="btn btn-custom-003d6a" style="color: #ffffff;" (click)="showFollowup(follow1, u_obj)">
                                                        <i class="fa fa-comment-alt m-r-10"></i>{{'samp_followup' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- end general info -->

                            <!-- PCR additional params -->
                            <div class="col-12" *ngIf="u_obj['ag_obj']['name'] == 'PCR'">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampCode' | translate}}</label>
                                                <input type="text" class="form-control" name="sampCode" [(ngModel)]="u_obj['sampCode']" maxlength="15">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampProductType' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['sampProductType']" name="sampProductType">
                                                    <option *ngFor="let p of this.pcr_filters['ProductTypePCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampReferenceNumber' | translate}}</label>
                                                <input type="text" class="form-control" name="sampReferenceNumber" [(ngModel)]="u_obj['sampReferenceNumber']" maxlength="15">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampBatchCode' | translate}}</label>
                                                <input type="text" class="form-control" name="sampBatchCode" [(ngModel)]="u_obj['sampBatchCode']" maxlength="15">
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampPresentation' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['sampPresentation']" name="sampPresentation">
                                                    <option *ngFor="let p of this.pcr_filters['PresentationPCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampConditionSample' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['sampConditionSample']" name="sampConditionSample">
                                                    <option *ngFor="let p of this.pcr_filters['ConditionSamplePCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampEnvironmentTemperature' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['sampEnvironmentTemperature']" name="sampEnvironmentTemperature">
                                                    <option *ngFor="let p of this.pcr_filters['EnvironmentTemperaturePCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-6 ha-ibox">
                                                <label>{{'sampRH' | translate}}</label>
                                                <select class="custom-select custom-select-md" [(ngModel)]="u_obj['sampRH']" name="sampRH">
                                                    <option *ngFor="let p of this.pcr_filters['RHPCR']" value="{{p.Id}}">{{p.Name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <!-- END PCR additional params -->

                        <!-- SUELO additional params -->
                            <div class="col-12" *ngIf="u_obj['ag_obj']['name'] =='FÝSICO QUÝMICO DEL SUELO'">
                                 <form class="">
                                   <div class="form-group">
                                     <div class="row">
                                        <div class="col-md-6 ha-ibox">
                                            <label>{{'sampDepth' | translate}}</label>
                                              <input type="number" class="form-control" name="sampDepth" [(ngModel)]="u_obj['sampDepth']" min="0" maxlength="2">
                                        </div>
                                     </div>
                                   </div>
                                 </form>
                            </div>
                        <!-- END SUELO additional params -->

                        </div>
                        <!-- end row main -->

                        <!-- group features -->
                        <form class="form-horizontal">
                            <div class="row title-row" *ngIf="u_obj['ag_obj']['group_features'].length > 0">
                                <div class="col-12">{{'samp_features' | translate}}</div>
                            </div>
                            <div *ngIf="u_obj['ag_obj']['MaxFeatures'] > 0">
                                <p *ngIf="u_obj['ag_obj']['SelFeatures'] == 0" class="gf-info">
                                    {{'samp_reqfeatures' | translate}}: {{u_obj['ag_obj']['MinFeatures']}}
                                </p>
                                <p *ngIf="u_obj['ag_obj']['SelFeatures'] > 0">
                                    <ngb-progressbar type="{{u_obj['ag_obj']['actualcolor']}}" [max]="u_obj['ag_obj']['MaxFeatures']" [value]="u_obj['ag_obj']['SelFeatures']"></ngb-progressbar>
                                </p>
                            </div>
                            <div class="row" *ngFor="let f of u_obj['ag_obj']['group_features']">
                                <div class="col-11 form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" [attr.checked]="(f.sel) ? 'checked' : null" id="{{f.name}}-gf" name="{{f.name}}-gf" (change)="u_obj['ag_obj'].toggleFeature(f)" [disabled]="u_obj['noedit']">
                                        <label class="custom-control-label" for="{{f.name}}-gf">&nbsp;&nbsp;&nbsp;{{f.name}}</label>
                                    </div>
                                </div>
                                <!-- <div class="col-11">tooltip</div> -->
                            </div>
                        </form>
                        <!-- end group features -->

                        <!-- Analysis -->
                        <form class="form-horizontal">
                            <div class="row title-row" *ngIf="u_obj['ag_obj']['analysis'].length > 0">
                                <div class="col-12">{{'samp_analysis' | translate}}</div>
                            </div>
                            <div class="row title-row2" *ngIf="u_obj['ag_obj']['analysis'].length > 0">
                                <div class="col-9">{{'name' | translate}}</div>
                                <!-- <div class="col-2">Reps</div> -->
                                <div class="col-1"></div>
                            </div>
                            <div class="row" *ngFor="let a of u_obj['ag_obj']['analysis']">
                                <div class="col-11 form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" [attr.checked]="(a.sel) ? 'checked' : null" id="{{a.name}}" name="{{a.name}}" (change)="a.sel=!a.sel;" [disabled]="u_obj['noedit']">
                                        <label class="custom-control-label" for="{{a.name}}">&nbsp;&nbsp;&nbsp;{{a.name}}</label>
                                    </div>
                                    <div class="inner-items" *ngIf="a['special']">
                                        <div class="row">
                                            <!-- <div class="col-3">{{'samp_comment' | translate}}</div> -->
                                            <!-- <div class="col-2"></div> -->
                                            <div class="col-12" style="padding: 10px 15px">
                                                <input type="text" class="form-control" name="{{a.name+'comm'}}" [(ngModel)]="a.comment" placeholder="{{'samp_comment' | translate}}" required [disabled]="u_obj['noedit']">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inner-items" *ngIf="a['feat'].length > 0">
                                        <div class="row title-row title-sm">
                                            <div class="col-12">{{'samp_features_an' | translate}}</div>
                                        </div>
                                        <div *ngIf="a['MaxFeatures'] > 0" style="padding: 10px">
                                            <p *ngIf="a['SelFeatures'] == 0" class="gf-info">
                                                {{'samp_reqfeatures' | translate}}: {{a['MinFeatures']}}
                                            </p>
                                            <p *ngIf="a['SelFeatures'] > 0">
                                                <ngb-progressbar type="{{a['featColor']}}" [max]="a['MaxFeatures']" [value]="a['SelFeatures']"></ngb-progressbar>
                                            </p>
                                        </div>
                                        <div class="row" *ngFor="let f of a['feat']">
                                            <div class="col-11 form-group" style="padding-left: 15px">
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" [attr.checked]="(f.sel) ? 'checked' : null" id="{{f.name}}" name="{{f.name}}" (change)="u_obj['ag_obj'].toggleAnalysisFeature(f)" [disabled]="u_obj['noedit']">
                                                    <label class="custom-control-label" for="{{f.name}}">&nbsp;&nbsp;&nbsp;{{f.name}}</label>
                                                </div>
                                            </div>
                                            <!-- <div class="col-11">tooltip</div> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-2">
                                    <input type="number" class="form-control" name="rep-{{a.name}}" [(ngModel)]="a['reps']" min="1">
                                </div> -->
                                <div class="col-1 ha-tooltip" placement="left" container="body" ngbTooltip="{{a.tooltip}}" triggers="click:blur:hover">
                                    <i class="fas fa-info-circle text-info"></i>
                                </div>
                            </div>
                        </form>
                        <!-- end Analysis -->

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="closeModal(update1)">{{'cancel' | translate}}</button>
                        <button *ngIf="!isAllowed('offline', u_obj)" [ladda]="isUpdating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="updateItem(update1, u_obj)" [disabled]="!isAllowed('edit', u_obj)">{{'update' | translate}}</button>
                        <button *ngIf="isAllowed('offline', u_obj)" [ladda]="isCreating" data-style="expand-left" type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="createItem(update1, u_obj)">{{'save' | translate}}</button>
                    </div>
                </ng-template>

                <ng-template #follow1 let-follow1>
                    <div class="modal-header" style="background-color: #0076b3;">
                        <h4 class="modal-title" style="color: #ffffff;">{{'samp_followup' | translate}} {{'samp_edit' | translate}} #{{u_obj['sampId']}}</h4>
                    </div>
                    <div class="modal-body">

                        <div class="row">
                            <div class="col-12">
                                <form class="">
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-md-12 ha-ibox">
                                                <label>{{'samp_comment' | translate}}</label>
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="4" name="followcomment" [(ngModel)]="f_obj['followcomment']" style="resize: none;" [disabled]="isUpdating"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-md-12 ha-ibox">
                                                <div class="form-group" style="text-align: right;">
                                                    <button type="button" [ladda]="isUpdating" data-style="expand-left" class="btn btn-custom-003d6a" style="color: #ffffff;" (click)="saveFollowup(f_obj)">
                                                        <i class="fas fa-plus m-r-10"></i>{{'add' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div *ngIf="loadingFollow" class="common-loader">
                            <i class="fas fa-sync-alt fa-spin fa-3x" style="color: gray"></i>
                        </div>

                        <!-- comments row -->
                        <div *ngIf="!loadingFollow" class="row comments-list">

                            <!-- begin follow comments -->
                            <div class="col-12">
                                <div class="row title-row">
                                    <div class="col-12">{{'samp_followcomments' | translate}}</div>
                                </div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>{{'comment' | translate}}</th>
                                                <th>{{'date' | translate}}</th>
                                                <th>{{'action' | translate}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let r of f_obj['history']">
                                                <td>{{r.logText}}</td>
                                                <td>{{r.logCreationTime | date:'y-MM-d H:mm'}}</td>
                                                <td>
                                                    <i (click)="deleteFollowup({logId:r.logId})" style="cursor:pointer;" class="far fa-trash-alt text-danger m-r-10"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- end follow comments -->

                        </div>
                        <!-- end row comments -->

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-custom-005c3c" style="color: #ffffff;" (click)="follow1.dismiss('Cross click')">{{'close' | translate}}</button>
                    </div>
                </ng-template>

            </div>
        </div>
    </div>
</div>
