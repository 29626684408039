export class Itemag {
        sampAnalysisGroupId:number;
        name:string
        group_features:any;
        analysis:any;
        MinFeatures:any;
        MaxFeatures:any;
        SelFeatures:any;
        actualcolor:any;

        constructor(id:number, name:string){
            this.sampAnalysisGroupId = id;
            this.name = name;
            this.group_features = [];
            this.analysis = [];
            this.MinFeatures = 0;
            this.MaxFeatures = 0;
            this.SelFeatures = 0;
            this.actualcolor = 'danger';
        }

        public toggleFeature(f:any){
            f.sel = !f.sel;
            this.recalculateGroupFeatures();
        }

        public recalculateGroupFeatures(){
            this.MaxFeatures = this.group_features.length;
            this.SelFeatures = 0;
            
            this.group_features.forEach(x => {
                if( x.sel ){
                    this.SelFeatures++;
                }
            });

            if( this.SelFeatures >= this.MinFeatures ){
                this.actualcolor = 'success';
            }
            else{
                this.actualcolor = 'danger';
            }
        }

        public toggleAnalysisFeature(f:any){
            f.sel = !f.sel;
            this.recalculateAnalysisFeatures();
        }

        public recalculateAnalysisFeatures(){

            this.analysis.forEach(x => {
                x['MaxFeatures'] = x['feat'].length;
                x['SelFeatures'] = 0;

                x['feat'].forEach(i => {
                    if( i['sel'] ){
                        x['SelFeatures']++;
                    }
                });

                if( x['SelFeatures'] >= x['MinFeatures'] ){
                    x['featColor'] = 'success';
                }
                else{
                    x['featColor'] = 'danger';
                }
            });
        }

}

