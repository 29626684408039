import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/main/api.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Global } from '../../../services/global';
import { BaseCrudComponent } from '../../base-crud/base-crud.component';
import { TranslateService } from '@ngx-translate/core';

import { Observable, from, of, concat } from 'rxjs';
import { mergeMap, catchError, timeout, retry } from 'rxjs/operators';

@Component({
  selector: 'app-refmethod',
  templateUrl: './refmethod.component.html',
  styleUrls: ['./refmethod.component.css']
})
export class RefmethodComponent extends BaseCrudComponent {

  	id:any;
    loadingRequired = false;
    listAgr:any;
    translations:any;
    file=null;

    constructor(
        srv: ApiService,
        modalService: NgbModal,
        storage: Storage,
        router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService
      ) {
        super(srv, modalService, storage, router);
        this.id = route.snapshot.params.id;

        this.read_url = 'Meta/All';
        this.create_url = 'Meta/ReadFile';
        this.update_url = 'Meta/Update';
        this.delete_url = 'Meta/LogicalDelete';
        this.search_fields = ['refDetReference', 'refDetDescription', 'anName'];

        this.reload.subscribe(val => {
            if( val ){
                super.clearData();
                this.getList();
            }
        });
    }

    ngOnInit() {
        this.translate.getTranslation(this.translate.currentLang)
        .subscribe((translations) => {
              this.translations = translations;
              super.ngOnInit();

              this.loadingRequired = true;
              /*this.getAnalysisData().subscribe(x => {
                  //console.log(x);
                  if(x != false){
                      this.loadingRequired = false;
                      this.listAgr = x;
                  }
              });*/
          this.loadingRequired = false;
              this.getList();
        });
    }
  public SaveFile(object) {
    let form = new FormData();
    form.append('ImageId', object.ImageId);
    form.append('CreatorUser', object.CreatorUser);
    form.append('File', this.file);
    this.srv.AddFiles(this.create_url, form).subscribe(data => {
      if (data) {
        //this.messageDialog("Successful creation", '', 'success');
        Swal.fire("Subido correctamente", '', 'success');
        this.clearData();
        this.getList();
        //this.closeModal(content);
      }
    });
  }
    public createItem(content:any, object:any){
      let form = new FormData();
      form.append('ImageId', object.ImageId);
      form.append('CreatorUser', object.CreatorUser);
      form.append('File', this.file);
      this.srv.AddFiles(this.create_url, form).subscribe(data => {
        if (data) {
          //this.messageDialog("Successful creation", '', 'success');
          Swal.fire("Subido correctamente", '', 'success');
          this.clearData();
          this.getList();
          this.closeModal(content);
        }
      });
        /*var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;
        to.refDetActive = (to.refDetActive == 'true') ? true : false;

        var qst = this.create_url;
        this.isCreating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isCreating = false;
            if(x.success){
                this.c_obj = {};
                this.getList();
                this.closeModal(content);
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
            }
        });*/
    }

    public updateItem(content:any, form:any){
        var validated = true;

        if( form == undefined ){
          form = {};
        }

        validated = this.validateCreateInfo(form);
        if( !validated ){
            Swal.fire(this.translations['msg_all_required'], '', 'error');
            return;
        }

        var to:any;
        to = form;
        to.refDetActive = (to.refDetActive == 'true') ? true : false;

        var qst = this.update_url;
        this.isUpdating = true;

        this.srv.postCall(qst, to).subscribe(x => {
            this.isUpdating = false;
            if(x.success){
                this.getList();
                this.closeModal(content);
                Swal.fire(this.translations['updated'], '', 'success');
            }//end success
            else{
                Swal.fire(x.msg, '', 'error');
              //this.closeModal(content);
            }
        });
    }

    validateCreateInfo(fields:any){
        let mygroup:any = {};
        mygroup = new FormGroup({
            'refDetReference': new FormControl(fields.refDetReference, [Validators.required]),
            'refDetDescription': new FormControl(fields.refDetDescription, [Validators.required]),
            'refDetAnalysisId': new FormControl(fields.refDetAnalysisId, [Validators.required]),
            'refDetActive': new FormControl(fields.refDetActive, [Validators.required])
        });

        return mygroup.valid;
    }

    public getAnalysisData(){
        var par_url = 'Analysis/All';

        return this.srv.getCall(par_url).pipe(
                    mergeMap(data => {
                        let mydata:any = {success: false, msg:'', val:[]};
                        mydata = data;
                        //console.log(data);

                        if(mydata.success == true ){
                          return of(mydata.val);
                        }
                        else{
                          return of(false);
                        }
                      }),
                      //timeout(MAX_TIME),
                      catchError(data => of(false))
                );
    }

  public UploadFile(event) {
    this.file = event.target.files.length > 0 ? event.target.files[0] : null;
  }
}
