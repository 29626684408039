import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/authentication/login.service';
import {environment} from '../../../environments/environment';
import {AlertComponent} from 'ngx-bootstrap/alert';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  	translations:any;
    available_languages:any;
    currlang:any;
    version:any;vers:any;
    items:any;
    permissions = [];

  	constructor(
  		public _router: Router,
      public translate: TranslateService,
      public auth: LoginService,
      public srv: LoginService
  	) { }

  	async ngOnInit() {
        this.items = [
          {title: 'menu_forms', url: '/formshome', icon: 'icon icon-note', perm: 'HA_FORM'},
          {title: 'menu_processes', url: '/prochome', icon: 'icon icon-chemistry', perm: 'HA_PROC'},
          {title: 'menu_crud', url: '/cruds', icon: 'icon icon-globe', perm: 'HA_MANT'},
          {title: 'menu_general', url: '/settings', icon: 'icon icon-settings', perm: 'sett'}
        ];
      await this.auth.getServerVersion().then(x => {
        this.vers = x;
      });//debugger;
      //setTimeout(()=> {debugger;
      console.log(this.vers);
        /**/if (this.vers===environment.version){

        }else{
          let parts = this.vers.split('.');
          let f3 = parts[2];
          let partsW = environment.version.split('.');
          let fw3 = partsW[2];debugger;
          if( (f3 !== null) && (f3 !== undefined) && (fw3 !== null) && (fw3 !== undefined) ){
            //debugger;
            if (fw3 < f3){
              Swal.fire({
                title: 'Se detectó una nueva versión, se procederá a actualizar.',
                text: '',
                icon: 'error',
                showCancelButton: false,
                //cancelButtonText: 'Cancelar',
                confirmButtonText: 'Aceptar'
              }).then(async(result) => {
                if (result.value) {


                  /**/Swal.fire({
                    title: 'Se cerrará la sesión, ingrese nuevamente para seguir usando el aplicativo.',
                    text: '',
                    icon: 'warning',
                    showCancelButton: false,
                    //cancelButtonText: this.translations.cancel,
                    confirmButtonText: 'Aceptar'
                  }).then(async ( result) => {
                    if (result.value) {
                      //console.log(this.srv);
                      this.srv.clearData().then(() => {
                        this._router.navigate(['/authentication/login']);
                        window.location.reload(true);
                      });
                    }
                  });

                }
              });
            }/*else{
              alert("Nada que hacer")
            }*/
          }
        }
      /*},3000
    );*/
        this.auth.getPermissions().then(x => {
            this.permissions = x;
            this.permissions.push('sett');
        });

  	}
    async Actualizar(){
      /*Swal.fire(
        'Good job!',
        'You clicked the button!',
        'success'
      )*/
      /**/
      /*Swal.fire({
        title: 'Good job!',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        preConfirm: () => {
          window.location.reload(true);
        }
      }).then((result) => {
      });*/

    }
    hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }

}
