import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../services/authentication/login.service';
import {ApiService} from '../../../services/main/api.service';
import {environment} from '../../../../environments/environment';
import {from, of} from 'rxjs';

@Component({
  selector: 'app-prochome',
  templateUrl: './prochome.component.html',
  styleUrls: ['./prochome.component.css']
})
export class ProchomeComponent implements OnInit {

  	translations:any;
    available_languages:any;
    currlang:any;
    version:any;
    items:any;
    permissions = [];

  	constructor(
  		public _router: Router,
      public translate: TranslateService,
      public auth: LoginService,public api: ApiService
  	) { }

  	ngOnInit() {
        this.items = [
          {title: 'menu_sapproval', url: '/sapproval', icon: 'mdi mdi-adjust', perm: 'HA_SAMPLING_APPROVE'},
          {title: 'menu_sinput', url: '/sinput', icon: 'mdi mdi-adjust', perm: 'HA_SAMPLING_INPUT'},
          {title: 'menu_repocreator', url: '/repocreator', icon: 'mdi mdi-adjust', perm: 'HA_REPOCREATOR'},//'HA_SAMPLING_INPUT'},
          {title: 'menu_reportapproval', url: '/reportingapproval', icon: 'mdi mdi-adjust', perm: 'HA_REPORTING_APPROVE'},
          {title: 'menu_reporting', url: '/reporting', icon: 'mdi mdi-adjust', perm: 'HA_REPORTING'}//'HA_SAMPLING_INPUT'}
        ];

        this.auth.getPermissions().then(x => {
            this.permissions = x;
        });
      if( navigator.onLine ){
        this.getVersion();
      }
  	}

    hasPermission(str:string){
        if( this.permissions.indexOf(str) > -1 ){
            return true;
        }
        else{
            return false;
        }
    }
  public getVersion(){
    var ent_url = 'SystemParameter/GetVersion/'+environment.version+'/';
    return this.api.getCall(ent_url).subscribe(data => {
      let mydata:any = {success: false, msg:'', val:[]};
      mydata = data;
      if(mydata.success == true ){
        return from(this.api.persistData('appversion', mydata.val.value));
      }
      else{
        return of(false);
      }
    });
  }
}
