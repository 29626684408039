import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

import { FullComponent } from './layouts/full/full.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { setTheme } from 'ngx-bootstrap/utils';
setTheme('bs4');

import { Ic2routes } from './ic2-routing';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Ic2Component } from '../ic2/ic2.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// import { AgmCoreModule } from '@agm/core';
// import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { QuillModule } from 'ngx-quill';
import {NgxImageCompressService} from 'ngx-image-compress';

import { BaseCrudComponent } from './base-crud/base-crud.component';
import { CompaniesComponent } from './companies/companies.component';
import { SitesComponent } from './sites/sites.component';
import { UnitsComponent } from './units/units.component';

import { LaddaModule } from 'angular2-ladda';
import { LightboxModule } from 'ngx-lightbox';
import { OrderModule } from 'ngx-order-pipe';

import { SettingsComponent } from './settings/settings.component';
import { PdiComponent } from './forms/pdi/pdi.component';
import { SamplingComponent } from './forms/sampling/sampling.component';
import { SamplingApprovalComponent } from './processes/samplingapproval/sapproval.component';
//CRUDS
import { CrudComponent } from './maint/crudhome/crud.component';
import { AnalysisComponent } from './maint/analysis/analysis.component';
import { ParameterComponent } from './maint/parameter/parameter.component';

import { AnalysisGoupComponent } from './maint/analysis-goup/analysis-goup.component';
import { CompanyComponent } from './maint/company/company.component';
import { CountryComponent } from './maint/country/country.component';
import { LaboratoryComponent } from './maint/laboratory/laboratory.component';
import { ProductionGroupComponent } from './maint/production-group/production-group.component';
import { SampleComponent } from './maint/sample/sample.component';
import { SampleLocationComponent } from './maint/sample-location/sample-location.component';
import { SampleLocationTypeComponent } from './maint/sample-location-type/sample-location-type.component';
import { SampleTypeComponent } from './maint/sample-type/sample-type.component';
import { ShrimpSectorComponent } from './maint/shrimp-sector/shrimp-sector.component';
import { SiteComponent } from './maint/site/site.component';
import { SiteGroupComponent } from './maint/site-group/site-group.component';
import { SkrettingSectorComponent } from './maint/skretting-sector/skretting-sector.component';
import { SkrettingSiteComponent } from './maint/skretting-site/skretting-site.component';
import { UserComponent } from './maint/user/user.component';
import { FormshomeComponent } from './forms/formshome/formshome.component';
import { ProchomeComponent } from './processes/prochome/prochome.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UnitComponent } from './maint/unit/unit.component';
import { SamplinginputComponent } from './processes/samplinginput/samplinginput.component';
import { RefmethodComponent } from './maint/refmethod/refmethod.component';
import { VisitComponent } from './maint/visit/visit.component';
import { ReportingComponent } from './processes/reporting/reporting.component';
import { RepocreatorComponent } from './processes/repocreator/repocreator.component';
import { SamplingallComponent } from './forms/samplingall/samplingall.component';
import { PdiallcompanyComponent } from './forms/pdiallcompany/pdiallcompany.component';
import { ReportingapprovalComponent } from './processes/reportingapproval/reportingapproval.component';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    FullComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    Ic2Component,
    //MapgoogleComponent,
    BaseCrudComponent,
    CrudComponent,
    //GlobalparamsComponent,
    CompaniesComponent,
    //CompanycontactsComponent,
    SitesComponent,
    //SiteparamsComponent,
    UnitsComponent,
    //UnitparamsComponent,
    //SitecontactsComponent,
    //AlarmsComponent,
    //DatapanelComponent,
    SettingsComponent,
    PdiComponent,
    SamplingComponent,
    SamplingApprovalComponent,
    AnalysisComponent,
    ParameterComponent,
    AnalysisGoupComponent,
    CompanyComponent,
    CountryComponent,
    LaboratoryComponent,
    ProductionGroupComponent,
    SampleComponent,
    SampleLocationComponent,
    SampleLocationTypeComponent,
    SampleTypeComponent,
    SamplingComponent,
    ShrimpSectorComponent,
    SiteComponent,
    SiteGroupComponent,
    SkrettingSectorComponent,
    SkrettingSiteComponent,
    UserComponent,
    FormshomeComponent,
    ProchomeComponent,
    DashboardComponent,
    UnitComponent,
    SamplinginputComponent,
    RefmethodComponent,
    VisitComponent,
    ReportingComponent,
    RepocreatorComponent,
    SamplingallComponent,
    PdiallcompanyComponent,
    ReportingapprovalComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
   NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    NgbModule,
    //RouterModule.forRoot(Ic2routes, {onSameUrlNavigation: 'reload'}),
    //RouterModule.forRoot(Ic2routes, {onSameUrlNavigation: 'reload'}),
RouterModule.forRoot(Ic2routes, { relativeLinkResolution: 'legacy' }),
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    // AgmCoreModule,
    // AgmJsMarkerClustererModule,
    BsDatepickerModule.forRoot(),
    LaddaModule,
    TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    LightboxModule,
    OrderModule,
    QuillModule.forRoot()
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DatePipe,
    NgxImageCompressService
  ]/*,
  bootstrap: [AppComponent]*/
})
export class Ic2Module {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
    //return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
